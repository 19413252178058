import React from "react";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import { Chip, Tooltip } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";
import moment from "moment";
import WordLimitTooltip from "./WordToolTip";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const InternshipSummaryCard = ({ data, setCurrPostId, setModalOpen }) => {
  // Provide default fallback values for missing data

  const [DetailsDialogOpen, setDetailsDialogOpen] = React.useState(false);
  const uType = useSelector((state) => state.uType);

  const handleDialogOpen = () => {
    debugger;
    setDetailsDialogOpen(true);
  };
  const handleDialogClose = () => setDetailsDialogOpen(false);
  const {
    _id = null,
    title = "No Title Available", // Default if title is missing
    slug = "no-slug", // Default slug
    skills = [], // Default to an empty array if skills are missing
    workModel = "not specified", // Default value for workModel
    workTime = "not specified", // Default value for workTime
    locations = ["Location not specified"], // Default to an array with a message
    numOpening = 0, // Default to 0 if numOpening is not provided
    duration = "Not specified", // Default value for duration
    startDate = "Not specified", // Default value for start date
    stipend = "Not specified", // Default value for stipend
    perks = {}, // Default to an empty object if perks are missing
    stats = {}, // Default to an empty object if stats are missing
    ownerId = {}, // Default to an empty object for ownerId
    createdAt = new Date(), // Default to the current date if missing
    updatedAt = new Date(), // Default to the current date if missing
    isApproved = false, // Default to false if approval status is missing
    status = "inactive", // Default to "inactive" if status is missing
    ppo = false, // Default to false for PPO
    reason = "No reason specified", // Default to this if reason is missing
    cLogo = "/web-assets/images/icons/building.png",
    cName,
  } = data || {};

  const companyName = data.ownerId?.company?.name || "Unknown Company";

  const handleViewDetails = (lPostid) => {
    if (setCurrPostId) {
      setCurrPostId(lPostid);
      setModalOpen(true);
    } else {
      //open in a new page
      window.open(`/internships/detail/${slug}`, "_self");
    }
  };

  const navigate = useNavigate();

  const handleInternshipDetails = (id, slug) => {
    navigate(`/internships/details/${slug}`, "_self");
  };

  // console.log("Data", data);
  return (
    <div className="internship-summary-card">
      <img
        alt={cName}
        src="/web-assets/images/icons/building.png"
        className="internship-company-logo"
      />

      <div className="internship-head-box">
        <h1 className="hi-title1">
          <a href={`/internships/detail/${slug}`}>
            <WordLimitTooltip Limit={30} Text={title} />
          </a>
          <span className="blue-badge">Actively Hiring</span>
        </h1>

        <h6 className="hi-para1">{cName}</h6>
      </div>

      <div className="summary-info-box">
        <div className="internship-short-details">
          <LocationOnOutlinedIcon />
          {workModel === "remote"
            ? "Remote"
            : locations.slice(0, 2).length > 0
            ? locations.join(", ")
            : "Location details not available"}
        </div>

        <div className="internship-short-details">
          <DateRangeOutlinedIcon /> {duration} Months
        </div>

        <div className="internship-short-details">
          <span className="txt-primary">
            <CurrencyRupeeOutlinedIcon /> {stipend}
          </span>{" "}
          / Month
        </div>

        <div className="internship-short-details">
          <DateRangeOutlinedIcon /> Start -{" "}
          {startDate !== "N/A"
            ? moment(startDate).format("DD MMM, YYYY")
            : "Start date not specified"}
        </div>
      </div>

      <h6 className="skills-para">
        <div>
          Skills -{" "}
          {skills.length > 0 ? (
            <Tooltip
              classes={{ tooltip: "text-cap" }}
              title={skills.join(", ")}
              arrow
            >
              <span>
                {skills.slice(0, 2).join(", ")}
                {skills.length > 1 ? " ..." : ""}
              </span>
            </Tooltip>
          ) : (
            "No skills listed"
          )}
        </div>

        <Stack direction="row" spacing={1} className="my-2 ml-5">
          <Chip
            label={`Openings ${numOpening}`}
            size="small"
            sx={{
              backgroundColor: numOpening > 0 ? "info.main" : "error.main",
              color: "#fff", // White text
            }}
          />
        </Stack>
      </h6>

      <div className="internship-summary-card-footer">
        <p>
          <RestoreOutlinedIcon /> Posted {moment(createdAt).fromNow()}
        </p>
        <Button
          onClick={() => handleViewDetails(_id)}
          variant="outlined"
          size="small"
        >
          View Details Page
        </Button>
      </div>
    </div>
  );
};

export default InternshipSummaryCard;
