import React from "react";
import {Helmet} from 'react-helmet-async'

const About = () => {
  return (
    <div>
      <Helmet>
        <title>HeyInterns | About Us </title>
      </Helmet>
      <img src="/heyinterns-banner.jpg" alt="" className="img-fluid" />{" "}
      <div className="section-full job-category content-inner-1 bg-white">
        <div className="container pb-5">
          <div className="heading-box">
            <h2>About HeyInterns</h2>
            <p>
              Welcome to Heyinterns, India’s No.1 career platform, where talent
              meets opportunity!
            </p>
          </div>
          <div className="row align-items-center">
            <div className="col-md-12 col-lg-6">
              <h2 className="title2">Who Are We?</h2>
              <p className="m-b15 p2 text-justify">
                Heyinterns is a platform fueled by technology and a commitment
                to empowering students across India. Our mission is to provide
                free access to internships that build skills, offer real-world
                experiences, and unlock opportunities to launch successful
                careers. Imagine a future where every student can explore their
                passion, gain hands-on exposure, and transform their dreams into
                achievements. A future where students graduate not just with a
                degree, but with the confidence, knowledge, and experience
                needed to thrive in the professional world.
              </p>
              <p className="m-b15 p2">
                At Heyinterns, we believe talent should never go unnoticed due
                to financial barriers. By offering internships at no cost, we
                strive to bridge the gap between ambition and opportunity,
                creating a brighter future for every student in India.
              </p>
              <p className="m-b15 p2">
                With Heyinterns, we aim to bridge the gap between talent and
                opportunity, making career aspirations attainable for every
                student.
              </p>
              {/* <a href="javascript:void(0);" className="site-button">
                      Read More
                    </a> */}
            </div>
            <div className="col-md-12 col-lg-6">
              <img
                src="/heyinterns-about.jpg"
                className="img1s img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
