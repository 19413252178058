import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const employerLogin = async (email, password) => {
  console.log(email,password)
  try {
    const response = await heyapi.post(`/employer/login`, {
      email,
      password,
    });
    return response;
  } catch (err) {
    return err.response.data;
  }
};
