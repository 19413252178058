import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, IconButton, Typography, Chip } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; // Import date adapter for Day.js
import dayjs from 'dayjs';
import { CustomAlert, showToastAlert } from "../../admin/components/CustomAlert";

const AssignmentModal = ({ showAssigmentModal, setShowAssigmentModal, selectedIds, getNamesFromIds, handleSendAssignment }) => {
  console.log("selectedIds=" + selectedIds);
  const [deadline, setDeadline] = useState(null);
  const [task, setTask] = useState("");
  const [names, setNames] = useState([]);
  const [error, setError] = useState({ task: "", deadline: "" });

  const handleClose = () => {
    setShowAssigmentModal(false);
  }

  const handleAssignment = () => {
    setError({ task: "", deadline: "" });
    if (task.trim() === "") {
      setError({ task: "Task cannot be empty" });
    } else if (task.length < 50 || task.length > 300) {
      setError({ task: "Task must be at least 50 and max 300 characters" });
    } else if (!deadline || deadline.isBefore(dayjs())) {
      setError({ deadline: "Deadline must be later than today" });
      showToastAlert("Please pick valid date from calendar, it must be later than today", "error");
    } else {
      handleSendAssignment(task, deadline);
      setShowAssigmentModal(false);
    }
  }

  React.useEffect(() => {
    const lnames = getNamesFromIds(selectedIds);
    setNames(lnames);
  }, [selectedIds])

  return (
    <Dialog open={showAssigmentModal} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Send assignment
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Assignment must be fair and relevant to the role. Any attempt to obtain free work including but not limited to promoting app/social media via this assignment is against our policies.
        </Typography>

        {names && names.map((name) => {
          return <Chip label={name} />
        })}

        <TextField
          fullWidth
          margin="normal"
          label="Message"
          multiline
          rows={4}
          placeholder="Thank you for your interest in our internship opening. As a next step, we are expecting you to complete a short assignment."
          onChange={(e) => setTask(e.target.value)}
          error={error.task !== "" && error.task !== undefined}
          helperText={error.task}
          inputProps={{ maxLength: 300 }}
        />

        {/* Wrap DatePicker with LocalizationProvider */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Submission deadline"
            value={deadline}
            onChange={(newValue) => setDeadline(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth margin="normal" error={error.deadline !== "" && error.deadline !== undefined} helperText={error.deadline} />}
          />
        </LocalizationProvider>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleAssignment} variant="contained" color="primary">
          Send assignment
        </Button>
      </DialogActions>
      <CustomAlert />
    </Dialog>
  );
};

export default AssignmentModal;