import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

//since student can't create room, it always be initiated by employer
//one student can have only one chat per post
export const getActiveRooms = async (token) => {
    console.log(`/st/chat/rooms`)

    try {
        const response = await heyapi.get(
            `/student/chat/rooms`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        console.log(response.data);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const loadRoomData = async (token, roomid) => {
    console.log(`/student/chatroom/load-data`)
    try {
        const response = await heyapi.get(
            `/student/chatroom/load-data?roomid=${roomid}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response;
    } catch (err) {
        console.log(err);
    }
};

export const getRoomMessages = async (token, roomid) => {
    console.log(`/student/chat/messages?roomid=${roomid}`)
    try {
        const response = await heyapi.get(
            `/student/chat/messages?roomid=${roomid}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        console.log(response.data);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const getRoomEmployer = async (token, roomid) => {
    console.log(`/student/chat/employer?roomid=${roomid}`)
    try {
        const response = await heyapi.get(
            `/student/chat/employer?roomid=${roomid}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        console.log(response.data);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};