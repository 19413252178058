import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link, useNavigate, useParams } from "react-router-dom";
import { userResetPassword } from '../services/studentRegister';
import { useLocation } from 'react-router-dom';
import { CustomAlert, showToastAlert } from '../admin/components/CustomAlert';
export default function ResetPassword() {
    // State for password fields
    const [password, setPassword] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [token, setToken] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [err, setErr] = React.useState('');
    const {utype} = useParams();
    const navigate = useNavigate();
    // Hook to get the current location (URL)
    const location = useLocation();
    // Extract tokenid and email from the query parameters
    React.useEffect(() => {
        const params = new URLSearchParams(location.search); // Parse query string
        const tokenid = params.get('tokenid'); // Get tokenid
        const emailParam = params.get('email'); // Get email
        if(!tokenid || !emailParam) {
            return navigate('/404');
        }
        // Update state with extracted values
        setToken(tokenid);
        setEmail(emailParam);
    }, [location.search]); // Re-run the effect if the URL changes


    // Handle Reset Password logic
    const handleResetPassword = async () => {
        if (password !== confirmPassword) {
            showToastAlert("Passwords do not match", "success");
            return;
        }

        try {
            const response = await userResetPassword(utype, email, token, password);
            if (response.status === 200) {
                showToastAlert(response.data.message, "success");
                navigate(`/password-reset-success`);
            } else {
                showToastAlert(response.data.message, "error");
                //setErr("Failed to reset password.");
            }
        } catch (error) {
            showToastAlert("Error occurred while resetting the password", "error");
            //setErr("Error occurred while resetting the password.");
            console.log(error);
        }
    };

    return (
        <div className="themeprovider-wrapper">
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 2,
                        marginBottom: 5,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "30px",
                        borderRadius: "10px",
                        boxShadow:
                            "-2px -2px 6px rgba(0,107,194,.12), 2px 2px 6px rgba(0,107,194,.12)",
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Reset Password
                    </Typography>
                    <Box noValidate sx={{ mt: 3 }}>
                        {err && (
                            <p
                                style={{
                                    color: "red",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    textAlign: "center",
                                }}
                            >
                                {err}
                            </p>
                        )}
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    onChange={(e) => setPassword(e.target.value)}
                                    size="small"
                                    required
                                    fullWidth
                                    id="password"
                                    label="New Password"
                                    name="password"
                                    type="password"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    size="small"
                                    required
                                    fullWidth
                                    id="confirm-password"
                                    label="Confirm New Password"
                                    name="confirmPassword"
                                    type="password"
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleResetPassword}
                        >
                            Reset Password
                        </Button>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link to={`/${utype}/login`} variant="body2">
                                    Remembered your password? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                    <CustomAlert />
                </Box>
            </Container>
        </div>
    );
}
