import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const studentRegister = async (
  email,
  password,
  name,
  answer,
  captchaHex
) => {
  try {
    const response = await heyapi.post(`/student/register`, {
      email,
      password,
      name,
      answer,
      captchaHex,
    });
    console.log(response.data);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export const studentGoogleRegister = async (
  token
) => {
  try {
    const response = await heyapi.post(`/student/google/register`, {
      token,
    });
    console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const studentDeleteAccount = async (
  token,
  password,
  answer,
  captchaHex
) => {
  try {
    const response = await heyapi.post(`/student/delete-account`, {
      password,
      answer,
      captchaHex,
    },   {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return response;
  } catch (err) {
    return err.response.data;
  }
};

export const studentChangePassword = async (
  token,
  current,
  password
) => {
  try {
    const response = await heyapi.post(`/student/change-password`, {
      current,
      password,
    },   {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return response;
  } catch (err) {
    return err.response.data;
  }
};

export const studentUpdateOnboardStatus = async (
  token
) => {
  try {
    const response = await heyapi.post(`/student/onboarded`, {
    },   {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    return err.response.data;
  }
};


export const userForgotPassword = async (utype, email, answer, captchaHex) => {
  try {
    const response = await heyapi.post(`/user/${utype}/forgotpassword`, {
      email,
      answer,
      captchaHex,
    });
    console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const userResetPassword = async (utype, email, tokenid, password) => {
  try {
    const response = await heyapi.post(`/user/${utype}/resetpassword`, {
      email,
      tokenid,
      password,
    });
    console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
};
