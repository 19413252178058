import React from "react";
import { Container, Box, Typography, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EmailIcon from "@mui/icons-material/Email";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const StorySubmitSuccess = () => {
  const navigate = useNavigate();

  const handleGoToLogin = () => {
    navigate("/");
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 6, textAlign: "center" }}>
      {/* Success Message */}
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 3 }}>
        <CheckCircleIcon color="success" sx={{ fontSize: 60, mb: 1 }} />
        <Typography variant="h5" color="success.main" fontWeight="bold">
          Your Internship Story Submitted Successfully!
        </Typography>
      </Box>


      {/* Additional Info */}
      <Typography variant="body1" sx={{ mb: 2 }}>
  Our team will review your content <br />
  <Button 
    component={Link} 
    to="/student/stories" 
    variant="contained" 
    sx={{
      mt: 1, 
      backgroundColor: "black", 
      color: "white",
      "&:hover": { backgroundColor: "#333" }
    }}
  >
    Go Back
  </Button>
</Typography>
    </Container>
  );
};

export default StorySubmitSuccess;
