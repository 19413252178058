export const testimonialsData = [
    {
      brandLogo: "web-assets/images/interns/sleepwell-logo.jpg",
      profilePic: "web-assets/images/interns/soni-intern.jpg",
      profileName: "Soni Bhandari",
      internRole: "Web Development Intern",
      testimonialText:
        "HeyInterns has been a game changer for me. Completing two virtual internships through their platform helped me build the confidence and skills I needed to secure a role as a Software Engineer at Sleepwell. It's been an incredible start to my career!",
      companyName: "Sleepwell",
      jobTitle: "Software Engineer",
    },
    {
      brandLogo: "web-assets/images/interns/aajtak-logo.jpg",
      profilePic: "web-assets/images/interns/arpan-intern.jpg",
      profileName: "Arpan Maurya",
      internRole: "Web Development Intern",
      testimonialText:
        "I owe a lot to HeyInterns for guiding me through two web development internships. These experiences really boosted my technical knowledge and helped me land my dream job as a Full-Stack Developer at Aaj Tak.",
      companyName: "Aaj Tak",
      jobTitle: "Full-Stack Developer",
    },
    {
      brandLogo: "web-assets/images/interns/chl-logo.jpg",
      profilePic: "web-assets/images/interns/supriya-intern.jpg",
      profileName: "Supriya",
      internRole: "Digital Marketing Intern",
      testimonialText:
        "The internships I completed through HeyInterns gave me real-world exposure to digital marketing. That hands-on experience directly contributed to me securing a job at CHL Softech as a Digital Marketing Executive. I’m so grateful for this opportunity!",
      companyName: "CHL Softech",
      jobTitle: "Digital Marketing Executive",
    },
    {
      brandLogo: "web-assets/images/interns/genpact-logo.jpg",
      profilePic: "web-assets/images/interns/rohit-intern.jpg",
      profileName: "Rohit Singh",
      internRole: "Software Engineer Intern",
      testimonialText:
        "Thanks to HeyInterns, I was able to gain practical experience in software testing during my internship. This played a key role in helping me secure a position as an Automation Tester at Genpact. It's been an amazing journey!",
      companyName: "Genpact",
      jobTitle: "Software Engineer QA",
    },
    {
      brandLogo: "web-assets/images/interns/sdgloabl-logo.jpg",
      profilePic: "web-assets/images/interns/akash-intern.jpg",
      profileName: "Akash",
      internRole: "Software Engineer Intern",
      testimonialText:
        "HeyInterns gave me the platform to work on exciting projects during my internship. The experience I gained was invaluable and helped me start my career as a Software Engineer at SD Global. Highly recommended!",
      companyName: "SD Global",
      jobTitle: "Software Engineer",
    },
    {
      brandLogo: "web-assets/images/interns/aresourcepool-logo.jpg",
      profilePic: "web-assets/images/interns/prince-intern.jpg",
      profileName: "Prince Tiwary",
      internRole: "Graphic Designer Intern",
      testimonialText:
        "I had an amazing experience with HeyInterns! My internship helped me hone my design skills and laid the foundation for my role as a UI/UX Designer at Aresourcepool. I'm grateful for this opportunity.",
      companyName: "Aresourcepool",
      jobTitle: "UI/UX Designer",
    },
    {
      brandLogo: "web-assets/images/interns/83-logo.jpg",
      profilePic: "web-assets/images/interns/alok-intern.jpg",
      profileName: "Alok Singh",
      internRole: "Java Backend Intern",
      testimonialText:
        "My Java backend internship through HeyInterns was the perfect stepping stone for my career. It gave me the practical knowledge I needed to become a Software Engineer at 83incs. I highly recommend their platform!",
      companyName: "83incs",
      jobTitle: "Software Engineer",
    },
    {
      brandLogo: "web-assets/images/interns/saffire-logo.jpg",
      profilePic: "web-assets/images/interns/kajal-intern.jpg",
      profileName: "Kajal Bisht",
      internRole: "HR Intern",
      testimonialText:
        "HeyInterns helped me gain hands-on experience during my HR internship, which proved invaluable. Today, I’m an HR Analyst at Saffire Softech, and I couldn’t have done it without their support!",
      companyName: "Saffire Softech",
      jobTitle: "HR Analyst",
    },
    {
      brandLogo: "web-assets/images/interns/hocalwire-logo.jpg",
      profilePic: "web-assets/images/interns/kuldeep-intern.jpg",
      profileName: "Kuldeep Singh",
      internRole: "Mobile App Intern",
      testimonialText:
        "My internship with HeyInterns provided me with practical mobile app development experience, which opened the door for my role as a Frontend Developer at Hocalwire. It was an incredible learning journey!",
      companyName: "Hocalwire",
      jobTitle: "Frontend Developer",
    },
  ];
  

export const quoteIcon = (
    <svg
      width={64}
      height={46}
      viewBox="0 0 64 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.6293 17.2257C50.5004 15.8322 50.599 12.0441 54.2279 6.77654C54.5024 6.37904 54.4526 5.84295 54.1117 5.50214C52.6322 4.02264 51.7162 3.08904 51.0737 2.43575C50.2289 1.57434 49.8432 1.18184 49.2787 0.670045C48.9018 0.331244 48.3315 0.325345 47.9506 0.657445C41.6254 6.16134 34.6 17.5343 35.6166 31.4679C36.2123 39.6495 42.1801 45.588 49.8061 45.588C57.6322 45.588 63.9994 39.2218 63.9994 31.3956C63.9994 23.8458 58.0737 17.6544 50.6293 17.2257ZM49.8061 43.588C43.2572 43.588 38.1293 38.4298 37.6108 31.3234C36.4672 15.6525 45.7826 5.47284 48.5971 2.77754C48.8715 3.04805 49.185 3.36634 49.6469 3.83704C50.2035 4.40345 50.9653 5.17884 52.1176 6.33514C47.7123 13.1222 48.5434 17.9581 48.9076 18.6515C49.0805 18.9806 49.435 19.2023 49.8061 19.2023C56.5297 19.2023 61.9994 24.672 61.9994 31.3956C61.9994 38.1183 56.5297 43.588 49.8061 43.588Z"
        fill="#2E55FA"
      />
      <path
        d="M15.1137 17.2257C14.9838 15.8361 15.0795 12.0509 18.7123 6.77654C18.9858 6.37904 18.9369 5.84294 18.5961 5.50214C17.1195 4.02554 16.2045 3.09294 15.5629 2.43964C14.7153 1.57634 14.3285 1.18274 13.7641 0.670039C13.3871 0.331239 12.8168 0.326339 12.436 0.656439C6.11077 6.16034 -0.915635 17.5314 0.0989652 31.4679C0.696665 39.6485 6.66537 45.588 14.2914 45.588C22.1176 45.588 28.4848 39.2218 28.4848 31.3956C28.4848 23.8449 22.559 17.6525 15.1137 17.2257ZM14.2914 43.588C7.74357 43.588 2.61267 38.4298 2.09316 31.3224C0.952565 15.6476 10.268 5.47184 13.0824 2.77754C13.3578 3.04804 13.6723 3.36834 14.1352 3.84004C14.6908 4.40644 15.4516 5.18084 16.602 6.33514C12.1967 13.1232 13.0278 17.9581 13.392 18.6505C13.5649 18.9796 13.9203 19.2023 14.2914 19.2023C21.0151 19.2023 26.4848 24.672 26.4848 31.3956C26.4848 38.1183 21.0151 43.588 14.2914 43.588Z"
        fill="#2E55FA"
      />
    </svg>
  );
