import axios from 'axios';
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const allTraining=async(token)=>{
    try{
        const response = await heyapi.get(`/student/resume`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
            console.log(response.data)
        return response.data;
    }
    catch(err){
        console.log(err);
    }
}

export const myPreferences =async(token)=>{
  try{
      const response = await heyapi.get(`/student/preferences`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
          console.log(response.data)
      return response.data;
  }
  catch(err){
      console.log(err);
  }
}