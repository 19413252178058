import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const AddPreference = async (id,preference,skills, token) => {
  console.log(id, preference, skills, token,"ap");
  try {
    const response = await heyapi.post(
      `/student/preference/add`,
      { id,preference,skills },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
  }
};
