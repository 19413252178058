import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const addPostEmployee = async (
  postId,
  title,
  skills,
  locations,
  workType,
  numOpening,
  startDate,
  duration, //selectedMonth, //num of months 
  responsibility,
  stipendType,
  stipend,
  perksState, //perks
  ppo,
  questions,
  mobile,
  token
) => {

  console.log(
    postId,
    title,
    skills,
    locations,
    workType,
    numOpening,
    startDate,
    duration, //selectedMonth, //num of months 
    responsibility,
    stipendType,
    stipend,
    perksState, //perks
    ppo,
    questions,
    mobile
  );
  try {
    const response = await heyapi.post(
      `/employer/post/add`,
      {
        postId,
        title,
        skills,
        locations,
        workType,
        numOpening,
        duration,
        startDate,
        responsibility,
        stipend,
        perksState,
        ppo,
        questions,
        mobile
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error("API error:", err);
    throw new Error("Failed to add post. Please try again later.");
  }
};


export const getAiResponsibilities = async (
  profile,
  token
) => {
  try {
    const response = await heyapi.get(
      `/employer/post/ai-responsibilties?profile=${profile}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error("API error:", err);
    throw new Error("Failed to get ai responsibilties. Please try again later.");
  }
};