import React from "react";
import { Link } from "react-router-dom";
import InternshipStoryComponent from "../story/components/InternshipStory";
import { showToastAlert } from "../admin/components/CustomAlert";
import { useNavigate, useParams } from "react-router";
import { getStoryByTitle } from "../services/filterStory";
import { Box, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Helmet } from "react-helmet-async";

const InternshipStoryDetailsPage = ({ }) => {
    const { slug } = useParams();
    const [story, setStory] = React.useState(null);
    const navigate = useNavigate();
    React.useEffect(() => {
        fetchData(slug);
    }, [slug]);

    const fetchData = async (slug) => {
        const response = await getStoryByTitle(slug);
        if (response.status !== 200) {
            navigate("/404");
            return;
        }
        setStory(response.data.story);
    };
    return (
        <>
        <Helmet>
                <title>HeyInterns | {story? story.title : "Story"} </title>
            </Helmet>
        <Box sx={{ marginBottom: "30px", display: "flex", flexDirection: "column", gap: 2 }}>
            
            {/* Internship Story Component */}
            <InternshipStoryComponent story={story} />

            {/* Back Button Centered */}
            <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <Button
                    component={Link}
                    to="/internship-story"
                    variant="contained"
                    color="primary"
                    startIcon={<ArrowBackIcon />}
                >
                    Back
                </Button>
            </Box>
        </Box>
        </>
    );
};

export default InternshipStoryDetailsPage;
