import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const getAllSkill = async (req, res) => {
  try {
    const response = await heyapi.get(
      `/data/skills`
    );
    console.log(response.data);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
