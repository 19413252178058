import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useState } from 'react';
import { useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import {
  TableBody,
  TableRow,
  TableCell,
  FormGroup,
  TextField,
  Box,
  Checkbox,
  FormControlLabel,
  tableCellClasses,
  Container
} from "@mui/material";
import { AddExperience, postExperience } from '../services/postExperience';
import PopUp from '../components/PopUp';
import { getAllPreference } from '../services/getAllPreferences';
import { deleteInternship } from '../services/deleteInternship';
import { editInternship } from '../services/editInternship';
import { getAllCity } from '../services/getAllCities';
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import { showToastAlert } from '../admin/components/CustomAlert';

const { DateTime } = require("luxon");
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddInternship({ internship, getTraining }) {
  const [open, setOpen] = React.useState(false);
  const [profile, setProfile] = useState("");
  const [company, setCompany] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [workMode, setWorkMode] = useState("");
  const [description, setDescription] = useState("");
  const [success, setSuccess] = useState(false);
  const [allProf, setAllProf] = React.useState();
  const [location, setLocation] = React.useState();
  const [locations, setLocations] = React.useState();
  const [loader, setLoader] = React.useState();
  const [editMode, setEditMode] = React.useState(false);
  const [internshipId, setInternshipId] = React.useState(null);
  const [errors, setErrors] = useState({});
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const userId = useSelector((state) => state.id);
  const token = useSelector((state) => state.token);

  const initializeForm = () => {
    setProfile("");
    setCompany("");
    setWorkMode(false);
    setStart(null);
    setEnd(null);
    setDescription("");
  };

  const handleClickOpen = (internshipData) => {
    setOpen(true);
    if (internshipData) {
      // Set state variables with the training data if available
      setEditMode(true);
      setInternshipId(internshipData._id);
      setProfile(internshipData.profile);
      setCompany(internshipData.company);
      setWorkMode(internshipData.workMode === "remote");
      setStart(new Date(internshipData.startDate));
      setEnd(new Date(internshipData.endDate));
      setDescription(internshipData.description);
    } else {
      // Initialize form fields if no training data is provided
      setEditMode(false);
      initializeForm();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validate = () => {
    const errors = {};
    if (!profile) {
      errors.profile = 'Profile is required';
    }
    if (!company) {
      errors.company = 'Company is required';
    }
    if (!start) {
      errors.start = 'Start date is required';
    }
    if (!end) {
      errors.end = 'End date is required';
    }
    if (!description) {
      errors.description = 'Description is required';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const postInternship = async () => {
    if (validate()) {
      try {
        if (!editMode) {
          const response = await postExperience(
            token,
            profile,
            company,
            workMode ? "remote" : "office",
            moment(start).format("MM/DD/YYYY"),
            moment(end).format("MM/DD/YYYY"),
            description,
            location
          );
          if (response.status === 200) {
            showToastAlert("internship saved", "success");
            getTraining();
            setOpen(false);
          } else {
            showToastAlert(response.data.message, "error");
          }
        } else {
          const response = await editInternship(
            token,
            internshipId,
            profile,
            company,
            workMode ? "remote" : "office",
            moment(start).format("MM/DD/YYYY"),
            moment(end).format("MM/DD/YYYY"),
            description,
            location
          );
          if (response.status === 200) {
            showToastAlert("internship saved", "success");
            getTraining();
            setOpen(false);
          } else {
            showToastAlert(response.data.message, "error");
          }
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      showToastAlert("Fix empty/invalid fields", "error");
    }
  };

  React.useEffect(() => {
    getPref();
  }, [token]);

  const getPref = async () => {
    try {
      const allProf = await getAllPreference();
      const locations = await getAllCity();
      setLocations(locations.cities)
      setAllProf(allProf.preferences);
      setLoader(false);
    } catch (error) {
      console.error("Error fetching internships data:", error);
    }
  };
  //for delete education
  const handleDeleteClick = async (id) => {
    // Function to open the delete confirmation dialog

    setDeleteId(id); // Set the selected education ID directly
    setOpenConfirmDialog(true); // Open the dialog

  }

  //for delete internship
  const dltInternship = async () => {
    try {
      const response = await deleteInternship(deleteId, token);
      if (response.status !== 200) {
        showToastAlert(response.data.message, "error");
        return;
      }
      getTraining();
    } catch (err) {
      console.log(err);
    }
  };

  const handleWFHModeChange = (e) => {
    setWorkMode(e.target.checked)
    if (e.target.checked) {
      setLocation("");
    }
  }

  return (
    <TableBody style={{ marginTop: "20px" }}>
      <TableRow
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
      >
        <TableCell style={{ fontSize: "12px" }}>INTERNSHIPS</TableCell>
        {internship ? internship?.experience?.map((experience, index) => (
          <TableRow key={index}>
            <TableCell
              style={{
                padding: "8px 20px  16px",
                borderBottom: "none",
                width: "20vw",
              }}
            >
              <p style={{ fontSize: "14px", fontWeight: "400" }}>
                {experience.profile} At {experience.company}
                <br />
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#6B6B6B",
                  }}
                >
                  {experience.workMode}
                </span>
                <br />
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#6B6B6B",
                  }}
                >
                  {moment(experience.startDate).format("MMM YYYY")}
                  &nbsp;-&nbsp;
                  {moment(experience.endDate).format("MMM YYYY")}
                </span>
                <br />
                <span>{experience.description}</span>
              </p>
            </TableCell>
            <TableCell>
              <IconButton edge="end" aria-label="edit">
                <EditIcon onClick={() => handleClickOpen(experience)} />
              </IconButton>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <IconButton edge="end" aria-label="delete">
                <DeleteIcon
                  onClick={() => handleDeleteClick(experience._id)}
                />
              </IconButton>
            </TableCell>
          </TableRow>
        )) : ""}
        <TableRow>
          <TableCell>
            <Button
              onClick={() => {
                handleClickOpen();
                initializeForm();
              }}
              style={{ padding: "0px" }}
            >
              <AddIcon style={{ fontSize: "14px" }} color="primary" /> &nbsp;
              <span style={{ fontSize: "12px" }}>Add Internships</span>
            </Button>
          </TableCell>
        </TableRow>
      </TableRow>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{ width: "600px" }}
        >
          InternShip details
        </BootstrapDialogTitle>
        {success && (
          <center>
            <PopUp />
          </center>
        )}
        {!loader && (
          <DialogContent dividers>
            <FormGroup sx={{ alignItems: "flex-start", marginBottom: "10px" }}>
              <Typography>Profile</Typography>
              < Autocomplete
                size="small"
                id="tags-outlined"
                style={{
                  width: "100%",
                }}
                value={
                  Array.isArray(allProf)
                    ? allProf.find((v) => v.name === profile) || { name: "" }
                    : { name: "" }
                }
                options={allProf}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                onChange={(event, selectedOption) => {
                  const selectedString = selectedOption
                    ? selectedOption.name
                    : "";
                  setProfile(selectedString);
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="e.g. Marketing" />
                )}
              />
              {errors.profile && (
                <Typography color="error">{errors.profile}</Typography>
              )}
            </FormGroup>
            <FormGroup sx={{ alignItems: "flex-start", marginBottom: "10px" }}>
              <Typography>Company Name</Typography>
              <TextField
                size="small"
                fullWidth
                id="fullWidth"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                inputProps={{ maxLength: 60 }}
              />
              {errors.company && (
                <Typography color="error">{errors.company}</Typography>
              )}
            </FormGroup>
            <FormGroup sx={{ alignItems: "flex-start", marginBottom: "10px" }}>
              <Typography>Location</Typography>
              <Autocomplete
                size="small"
                id="tags-outlined"
                style={{
                  width: "100%",
                }}
                options={locations}
                disabled={workMode} // Disable Autocomplete if workMode (WFH) is true
                // value={locations.find((v) => v.title === location) || { title: "" }}
                getOptionLabel={(option) => option.city}
                filterSelectedOptions
                onChange={(event, selectedOption) => {
                  const selectedString = selectedOption
                    ? selectedOption.city
                    : "";
                  setLocation(selectedString);
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="e.g. Delhi" />
                )}
              />
            </FormGroup>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox defaultChecked={workMode} onChange={(e) => handleWFHModeChange(e)} />
                }
                label="Is work from Home"
              />
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box style={{ width: "48%", margin: "5px" }}>
                <FormGroup
                  sx={{ alignItems: "flex-start", marginBottom: "10px" }}
                >
                  <Typography>Start date</Typography>
                  <DatePicker
                    selected={start}
                    onChange={(date) => setStart(date)}
                    dateFormat="dd/MM/yyyy"
                    customInput={
                      <TextField
                        placeholder="start"
                        size="small"
                        style={{ width: "100%" }}
                      />
                    }
                    wrapperClassName="date-picker-wrapper"
                    className="date-picker-input"
                  />
                  {errors.start && (
                    <Typography color="error">{errors.start}</Typography>
                  )}
                </FormGroup>
              </Box>
              <Box style={{ width: "48%", margin: "5px" }}>
                <FormGroup
                  sx={{ alignItems: "flex-start", marginBottom: "10px" }}
                >
                  <Typography>End date</Typography>
                  <DatePicker
                    selected={end}
                    onChange={(date) => setEnd(date)}
                    dateFormat="dd/MM/yyyy"
                    customInput={
                      <TextField
                        placeholder="start"
                        size="small"
                        style={{ width: "100%" }}
                      />
                    }
                    wrapperClassName="date-picker-wrapper"
                    className="date-picker-input"
                  />
                  {errors.end && (
                    <Typography color="error">{errors.end}</Typography>
                  )}
                </FormGroup>
              </Box>
            </Box>
            <Box>
              <div class="mb-3">
                <label for="exampleFormControlTextarea1" class="form-label">
                  Description (Optional)
                </label>
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
                {errors.description && (
                  <Typography color="error">{errors.description}</Typography>
                )}
              </div>
            </Box>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              postInternship();
            }}
          >
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <DeleteConfirmationDialog
        open={openConfirmDialog}
        onConfirm={() => {
          dltInternship();  // Perform delete
          setOpenConfirmDialog(false);  // Close dialog
        }}
        onClose={() => setOpenConfirmDialog(false)}  // Handle closing of the dialog
      />
    </TableBody>
  );
}