import React from 'react';
import { Typography, Box, Button, Grid, Paper, Chip, Divider, Stack } from '@mui/material';
import { LocationOn, AccessTime, Work, School } from '@mui/icons-material';

const JobListing = () => {
  return (
    <Paper elevation={3} sx={{ padding: 4, maxWidth: 800, margin: 'auto' }}>
      <Grid container spacing={2}>
        {/* Header */}
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Creative Head Job
          </Typography>
          <Typography variant="subtitle1">
            Finesse Interactive Solutions
          </Typography>
        </Grid>

        {/* Job Details */}
        <Grid item xs={12}>
          <Stack direction="row" spacing={1}>
            <Chip label="Actively hiring" color="success" />
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Stack spacing={1}>
            <Typography variant="body2"><strong>Location:</strong> Delhi</Typography>
            <Typography variant="body2"><strong>Start Date:</strong> Immediately</Typography>
            <Typography variant="body2"><strong>CTC (ANNUAL):</strong> ₹7,50,000 - 10,00,000</Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Stack spacing={1}>
            <Typography variant="body2"><strong>Experience:</strong> 4-5 years</Typography>
            <Typography variant="body2"><strong>Apply By:</strong> 28 Oct '24</Typography>
            <Typography variant="body2">40 Applicants</Typography>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        {/* About the Job */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>About the job</Typography>
          <Typography variant="body1">
            Key responsibilities:
          </Typography>
          <ul>
            <li>Manage multiple projects from concept through completion.</li>
            <li>Create design programs and concepts that meet the business objectives of the organization.</li>
            <li>Establish creative direction for online services and programs.</li>
            <li>Collaborate with internal teams to generate ideas and pitches.</li>
            <li>Lead a team of designers and control overall design output.</li>
            <li>Provide quality control for concepts and projects.</li>
          </ul>
        </Grid>

        {/* Skills and Qualifications */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>Skills Required</Typography>
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            <Chip label="Adobe After Effects" />
            <Chip label="Adobe Creative Suite" />
            <Chip label="Adobe Indesign" />
            <Chip label="Adobe XD" />
            <Chip label="Canva" />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1">
            Earn certifications in these skills:
          </Typography>
          <ul>
            <li><a href="#">Learn Colour Theory for Designers</a></li>
            <li><a href="#">Learn Adobe After Effects</a></li>
            <li><a href="#">Learn Graphic Design</a></li>
          </ul>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        {/* Who Can Apply */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>Who can apply</Typography>
          <Typography variant="body1">
            Candidates with a minimum of 4 years of experience. Those who are from or open to relocating to Delhi.
          </Typography>
        </Grid>

        {/* Salary & Perks */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>Salary</Typography>
          <Typography variant="body1">
            Annual CTC: ₹7,50,000 - 10,00,000/year
          </Typography>
          <Typography variant="h6" gutterBottom>Perks</Typography>
          <ul>
            <li>Informal dress code</li>
            <li>5 days a week</li>
          </ul>
        </Grid>

        {/* Company Info */}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>About Finesse Interactive Solutions</Typography>
          <Typography variant="body1">
            With over 10 years of experience, Finesse Interactive is a Delhi-based digital marketing agency standing as a leader in the marketing industry. Known for strategic growth plans, it offers services like social media marketing, SEO, ORM, PPC, content marketing, and web design & development.
          </Typography>
        </Grid>

        {/* Apply Button */}
        <Grid item xs={12}>
          <Box sx={{ textAlign: 'center', marginTop: 2 }}>
            <Button variant="contained" color="primary" size="large">
              Apply Now
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default JobListing;
