import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import {
  IconButton,
  Divider,
  InputAdornment,
} from '@mui/material'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link, useNavigate } from "react-router-dom";
import { studentRegister } from '../services/studentRegister';
import { fetchCaptchaApi } from '../services/captchaApi';
import { CustomAlert, showToastAlert } from '../admin/components/CustomAlert';
import { useDispatch, useSelector } from "react-redux";
import {
  loginSuccess,
  userID,
  userType,
  userVerifyStatus,
  setUserDetails,
  setToken,
  updateStepCompletion
} from "../Store/Action";
import { Helmet } from 'react-helmet-async';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import GoogleSignupBtn from '../components/GoogleSignupBtn';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" to="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignUp() {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [answer, setAnswer] = React.useState('');
  const [captchaHex, setCaptchaHex] = React.useState("");
  const [captchaImg, setCaptchaImg] = React.useState("");
  const [err, setErr] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  
  const [errors, setErrors] = React.useState({});
  const [showEmailSignup, setShowEmailSignup] = React.useState(false);
  // Toggle password visibility
  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  React.useEffect(() => {
    fetchCaptcha();
  }, []);

  const fetchCaptcha = async () => {
    try {
      const res = await fetchCaptchaApi();
      if (res) {
        setCaptchaHex(res.hex);
        setCaptchaImg(res.img);
      }
    } catch (error) {
      console.error('Error fetching CAPTCHA', error);
    }
  };

  const validateForm = () => {
    let validationErrors = {};
    if (!firstName) validationErrors.firstName = 'First Name is required';
    if (!lastName) validationErrors.lastName = 'Last Name is required';
    if (!email) validationErrors.email = 'Email is required';
    if (!password) validationErrors.password = 'Password is required';
    if (!answer) validationErrors.answer = 'CAPTCHA answer is required';
    if(password.length < 6) validationErrors.password = "Password minimum 6 characters";
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0; // Returns true if no errors
  };

  const registerStudent = async () => {
    if (!validateForm()) return; // If validation fails, don't proceed

    try {
      const data = await studentRegister(
        email,
        password,
        firstName + " " + lastName,
        answer,
        captchaHex
      );
      if (data.success) {
        setErr("");
        navigate("/account-created");
      } else {
        showToastAlert(data.Error, "error");
        setErr(data.Error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleResponse = (response) => {
      try {
        if (response.status === 200) {
          const data = response.data;
          dispatch(setToken(data.token));
          dispatch(userID(data.user._id));
          dispatch(updateStepCompletion(data.stepsCompleted));
          dispatch(userType("student"));
          dispatch(
            setUserDetails({ name: data.user.name, email: data.user.email, status: data.user.status, isApproved: data.user.isApproved })
          );
          dispatch(loginSuccess(true));
          //update profile and resume status 
          navigate("/student/applications")
        } else {
          showToastAlert(response.data.message, "error");
        }
      }  catch (error) {
        const errorText = "Signup failed. Please try again.";
        console.error(error);
        showToastAlert(errorText, "error");
      }
  }

  return (
    <div className="themeprovider-wrapper">
      <Helmet>
          <title>HeyInterns | Student Sign Up </title>
      </Helmet>
      
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            marginBottom: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "30px",
            borderRadius: "10px",
            boxShadow:
              "-2px -2px 6px rgba(0,107,194,.12), 2px 2px 6px rgba(0,107,194,.12)",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up (Student)
          </Typography>
          <Box noValidate sx={{ mt: 3 }}>
          <GoogleSignupBtn handleResponse={handleResponse} /> 
          <Divider sx={{ my: 3 }}>or</Divider>
          {!showEmailSignup && (
            <Button
              fullWidth
              variant="outlined"
              onClick={() => setShowEmailSignup(true)}
            >
              Sign up with Email
            </Button>
          )}
            </Box>
            {showEmailSignup && (
          <Box noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              

              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={(e) => setFirstName(e.target.value)}
                  size="small"
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                  inputProps={{ maxLength: 25 }} // Sets max character limit
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={(e) => setLastName(e.target.value)}
                  size="small"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                  inputProps={{ maxLength: 25 }} // Sets max character limit
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => setEmail(e.target.value)}
                  size="small"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  error={!!errors.email}
                  helperText={errors.email}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => setPassword(e.target.value)}
                  type={showPassword ? 'text' : 'password'}
                  size="small"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  id="password"
                  autoComplete="new-password"
                  error={!!errors.password}
                  helperText={errors.password}
                  InputProps={{
                    maxLength: 15,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={togglePasswordVisibility} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <img src={captchaImg} alt="CAPTCHA" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={(e) => setAnswer(e.target.value)}
                  size="small"
                  required
                  fullWidth
                  label="Enter CAPTCHA"
                  error={!!errors.answer}
                  helperText={errors.answer}
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={registerStudent}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-start">
              <Grid item>
                <Link to="/employer/signup" variant="body2">
                  If you are an employer ? Sign up
                </Link>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/student/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
            
          </Box> )}
        </Box>
        <CustomAlert />

      </Container>
    </div>
  );
}
