import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import InternshipCard from "./InternshipCard";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getSinglePostDetail } from "../services/getSinglePostDetail";
import { get } from "../services";
import { useSelector } from "react-redux";
import { ApplyPost } from "../services/ApplyPost";
import PopUp from "./PopUp";
import { calculateDaysPassed } from "../common/commonFunctions";
import WebsiteFooter from "../website/website-components/WebsiteFooter";
import InternshipApplyModal from "../student/modals/InternshipApplyModal";
import { CustomAlert, showToastAlert } from "../admin/components/CustomAlert";
import GroupIcon from '@mui/icons-material/Group';
import ViewInternshipDetailsPage from "./ViewInternshipDetailsPage";

const InternshipDetailsPage = () => {
  const location = useLocation();
  const { slug } = useParams();
  console.log(slug);

  useEffect(() => {
  }, []);

  return (
    <>
        <ViewInternshipDetailsPage slug={slug} ismodel={false}/>
    </>
  );
};

export default InternshipDetailsPage;
