import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const studentLogin = async (
  email,password
) => {
  console.log(email,password)
  try {
    const response = await heyapi.post(`/student/login`, {
     email,password
    });
     return response;
    
  } catch (err) {
    return err.response.data
  }
};

export const studentGoogleLogin = async (
  token //google token
) => {
  console.log("send google token: " + token)
  try {
    const response = await heyapi.post(`/student/google/login`, {
     token
    });
    return response;
    
  } catch (err) {
    return err.response.data
  }
};
