import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link, useNavigate, useParams } from "react-router-dom";
import { post } from "../services";
import { setToken } from "../services/session";
import { userForgotPassword } from "../services/studentRegister";
import { fetchCaptchaApi } from "../services/captchaApi";
import { CustomAlert, showToastAlert } from "../admin/components/CustomAlert";
import { toCamelCase } from "../common/commonFunctions";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://heyinterns.com/">
        HeyInterns
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

export default function ForgotPassword() {
  const [email, setEmail] = React.useState("");
  const [answer, setAnswer] = React.useState("");
  const [captchaHex, setCaptchaHex] = React.useState("");
  const [captchaImg, setCaptchaImg] = React.useState("");
  const [err, setErr] = React.useState(null);
  const navigate = useNavigate();
  const { utype } = useParams();
  const handleForgotPasswd = async () => {
    try {
      if (!email || !answer) {
        showToastAlert("Please fill all fields", "error");
        return;
      }
      const response = await userForgotPassword(utype, email, answer, captchaHex);
      if (response.status === 200) {
        navigate(`/password-reset-mail-success`);
      } else {
        setErr(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    fetchCaptcha();
  }, []);

  const fetchCaptcha = async () => {
    try {
      const res = await fetchCaptchaApi();
      if (res) {
        setCaptchaHex(res.hex);
        setCaptchaImg(res.img);
      }
    } catch (error) {
      console.error("Error fetching CAPTCHA", error);
    }
  };

  return (
    <div className="themeprovider-wrapper">
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            marginBottom: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "30px",
            borderRadius: "10px",
            boxShadow:
              "-2px -2px 6px rgba(0,107,194,.12), 2px 2px 6px rgba(0,107,194,.12)",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
            If you are an {utype === "student" ? "employer" : "student"},
            <Link to={`/${utype === "student" ? "employer" : "student"}/forgotpassword`} style={{ color: "blue", marginLeft: 5 }}>
              click here to reset your password.
            </Link>

          </Typography>
          <Box noValidate sx={{ mt: 3 }}>
            {err !== "" && (
              <p
                style={{
                  color: "red",
                  fontSize: "16px",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                {err}
              </p>
            )}
            <Typography
              variant="h5"
              color="textPrimary"
              sx={{ marginBottom: 1, fontWeight: 600 }}
            >
              {utype ? `${toCamelCase(utype)} Account` : "Loading..."}
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  size="small"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <img src={captchaImg} alt="CAPTCHA" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={(e) => setAnswer(e.target.value)}
                  size="small"
                  required
                  fullWidth
                  label="Enter CAPTCHA"
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleForgotPasswd}
            >
              Send reset email
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to={`/${utype}/login`} variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
          <CustomAlert />
        </Box>
      </Container>
    </div>
  );
}
