import React, { useContext } from "react";
import { Box, Snackbar, Typography, Container, TextField, Button, Autocomplete } from "@mui/material";
import { Alert } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import MaleIcon from "@mui/icons-material/MaleTwoTone";
import FemaleIcon from "@mui/icons-material/Female";
import TransgenderIcon from "@mui/icons-material/Transgender";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/userContext";
import PopUp from "../../components/PopUp";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo, updateUserDetail } from "../../services/getUserInfo";
import { getAllCity } from "../../services/getAllCities";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { showToastAlert, CustomAlert } from "../../admin/components/CustomAlert";
import { updateStepCompletion } from "../../Store/Action";
import { isValidIndianMobileNumber } from "../../common/commonFunctions";

const EditProfileComponent = ({onComplete=null}) => {
  const [value, setValue] = React.useState("1");
  const [open, setOpen] = React.useState(false);
  const [apiData, setApiData] = React.useState("");
  const [name, setName] = React.useState("");
  const [mail, setMail] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [loader, setLoader] = React.useState(true);
  const [success, setSuccess] = React.useState(false);
  const [selectedGender, setSelectedGender] = React.useState("Male");
  const [selectedProfession, setSelectedProfession] = React.useState(null);
  const [selectedWorkMode, setSelectedWorkMode] = React.useState("Office");
  const [locations, setLocations] = React.useState(""); // available locations
  const [userLocation, setUserLocation] = React.useState("");
  const [userLangs, setUserLangs] = React.useState([]);
  const [errors, setErrors] = React.useState({});
  const [isVerified, setIsVeified] = React.useState(false);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const userId = useSelector((state) => state.id);
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const query = new URLSearchParams(window.location.search);
  const errorMessage = query.get("error_message");


  const genders = [
    { label: "Male", icon: MaleIcon },
    { label: "Female", icon: FemaleIcon },
    { label: "Other", icon: TransgenderIcon },
  ];

  const professions = [
    { label: "Fresher" },
    { label: "Student" },
    { label: "Professional" },
  ];

  const workModes = [
    { label: "Office" },
    { label: "Remote" },
  ];

  const languages = [
    "Hindi",
    "English",
    "French",
    "Bengali",
    "Marathi",
    "Telugu",
    "Tamil",
    "Gujarati",
    "Urdu",
    "Kannada",
    "Odia",
    "Malayalam",
    "Punjabi",
    "Assamese",
    "Maithili",
    "Santali",
    "Kashmiri",
    "Nepali",
    "Sindhi",
    "Konkani",
    "Manipuri",
    "Bodo"
  ];
  

  React.useEffect(() => {
    userInfo();
  }, [token]);

  const userInfo = async () => {
    try {
      const data = await getUserInfo(token);
      const allLocations = await getAllCity();
      setLocations(allLocations.cities.map((item) => item.city));
      setApiData(data);
      setLoader(false);
      setName(data.name);
      setMail(data.email);
      setMobile(data.mobile);
      setUserLangs(data.languages);
      setUserLocation(data.location);
      setSelectedProfession(data.profession);
      setSelectedGender(data.gender);
      setIsVeified(data.verifyStatus ? data.verifyStatus.mobile : false)
    } catch (error) {
      console.log(error);
    }
  };

  const validateFields = () => {
    let validationErrors = {};

    if (!name) validationErrors.name = "Name is required";
    if (!mobile) validationErrors.mobile = "Mobile number is required";
    if (!selectedGender) validationErrors.gender = "Please select a gender";
    if (!selectedWorkMode) validationErrors.workMode = "Please select a work mode";
    if (!selectedProfession) validationErrors.profession = "Please select a profession";
    if (userLangs.length === 0) validationErrors.languages = "Please select at least one language";
    if (!userLocation) validationErrors.location = "Location is required";
    if(!isValidIndianMobileNumber(mobile)) {
        validationErrors.mobile = "Mobile number is not valid";
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0; // Returns true if no errors
  };

  const saveUserDetails = async () => {
    if (validateFields()) {
      try {
        const response = await updateUserDetail({
          name: name,
          email: mail,
          mobile: mobile,
          location: userLocation,
          gender: selectedGender,
          languages: userLangs,
          profession: selectedProfession,
          workMode: selectedWorkMode,
          token: token,
        });
        if(response.status === 200) {
          dispatch(updateStepCompletion({profileCompleted: true}));
          setSuccess(true);
          handleScrollToTop();
          showToastAlert("Data saved successfully", "success");
          if(onComplete)
            onComplete();
          else
            return navigate("/student/editprofile");
        } else {
          setSuccess(false);
          showToastAlert(response.data.messsage, "error");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div className="student-box2">
      {loader ? "Loading...." : (
        <Box
          sx={{
            width: "100%",
            maxWidth: "750px",
            padding: "20px",
            borderRadius: "10px",
            background: "#ffffff",
            margin: "0 auto",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          {success && (
            <center>
              <PopUp />
            </center>
          )}
          {errorMessage && (<div className="alert alert-danger" role="alert">
          <h5> Your profile is not complete. Complete this to continue your journey </h5> 
          </div>)}
          <Container maxWidth="md">
            <Box sx={{ mb: 2 }}>
              <label className="epLabel">Name</label>
              <TextField
                fullWidth
                required
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={!!errors.name}
                helperText={errors.name}
              />
            </Box>

            <Box sx={{ mb: 2 }}>
              <label className="epLabel">E-mail</label>
              <TextField
                fullWidth
                required
                value={mail}
                disabled
              />
            </Box>

            <Box sx={{ mb: 2 }}>
              <label className="epLabel">Mobile Number</label>
              <TextField
                fullWidth
                required
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                error={!!errors.mobile}
                helperText={errors.mobile}
              />
            </Box>

            <Box sx={{ mb: 2 }}>
              <label className="epLabel">Gender</label>
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {genders.map((gender, index) => {
                  const IconComponent = gender.icon;
                  return (
                    <Button
                      key={index}
                      variant={selectedGender === gender.label ? "contained" : "outlined"}
                      sx={{ margin: "5px", borderRadius: "20px", display: "flex", alignItems: "center" }}
                      onClick={() => setSelectedGender(gender.label)}
                      error={!!errors.gender}
                    >
                      {gender.label} <IconComponent style={{ marginLeft: "8px", fontSize: "22px" }} />
                    </Button>
                  );
                })}
              </Box>
              {errors.gender && <Typography color="error">{errors.gender}</Typography>}
            </Box>

            <Box sx={{ mb: 2 }}>
              <label className="epLabel">Work Mode Preference</label>
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {workModes.map((workMode, index) => (
                  <Button
                    key={index}
                    variant={selectedWorkMode === workMode.label ? "contained" : "outlined"}
                    sx={{ margin: "5px", borderRadius: "20px" }}
                    onClick={() => setSelectedWorkMode(workMode.label)}
                    error={!!errors.workMode}
                  >
                    {workMode.label}
                  </Button>
                ))}
              </Box>
              {errors.workMode && <Typography color="error">{errors.workMode}</Typography>}
            </Box>

            <Box sx={{ mb: 2 }}>
              <label className="epLabel">Profession</label>
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {professions.map((profession, index) => (
                  <Button
                    key={index}
                    variant={selectedProfession === profession.label ? "contained" : "outlined"}
                    sx={{ margin: "5px", borderRadius: "20px" }}
                    onClick={() => setSelectedProfession(profession.label)}
                  >
                    {profession.label}
                  </Button>
                ))}
              </Box>
              {errors.profession && <Typography color="error">{errors.profession}</Typography>}
            </Box>

            <Box sx={{ mb: 2 }}>
              <label className="epLabel">Location</label>
              <Autocomplete
                freeSolo
                disableClearable
                options={locations.map((option) => option)}
                value={userLocation}
                onChange={(event, newValue) => {
                  setUserLocation(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    placeholder="Enter your location"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                    error={!!errors.location}
                    helperText={errors.location}
                  />
                )}
              />
            </Box>

            <Box sx={{ mb: 3 }}>
              <label className="epLabel">Languages Known</label>
              <Autocomplete
                multiple
                options={languages.map((option) => option)}
                value={userLangs}
                onChange={(event, newValue) => {
                  setUserLangs(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select languages"
                    error={!!errors.languages}
                    helperText={errors.languages}
                  />
                )}
              />
            </Box>

            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={saveUserDetails}
              startIcon={<CloudUploadIcon />}
            >
              Save
            </Button>
          </Container>
          <CustomAlert />
        </Box>
      )}
    </div>
  );
};

export default EditProfileComponent;
