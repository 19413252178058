import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const editTraining = async (
  trainingId,
  id,
  title,
  company,
  workMode,
  startDate,
  endDate,
  description,
  token
) => {
  
  try {
    const response = await heyapi.put(
      `/student/training/${trainingId}
`,
      { id, title, company, workMode, startDate, endDate, description },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
