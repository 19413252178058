import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import { getStatusTag } from "../common/commonFunctions";
import Tooltip from '@mui/material/Tooltip';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import CustomLoader from "../components/CustomLoader";
import DataTable from "./components/DataTable";
import { getAllStudents, updateStudentState } from "../services/adminStudents";
import RejectReasonBox from "../dialogBoxes/SetReason";
import EditIcon from '@mui/icons-material/Edit';
import { CustomAlert, showToastAlert } from "./components/CustomAlert";
import InfoIcon from "@mui/icons-material/Info";

const AdminAllStudentList = () => {
    const [loaderOpen, setLoaderOpen] = React.useState(true);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [apiData, setApiData] = React.useState([]);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [selectedId, setSelectedId] = useState("");
    const [showOptions, setShowOptions] = useState("Approve");
    const [selectedOptions, setSelectedOptions] = useState(new Map());
    const [showAlert, setShowAlert] = useState(false);
    const [showReasonPopup, setShowReasonPopup] = useState(false);
    const [reason, setReason] = useState("");
    const [currStudentId, setCurrStudentId] = React.useState("");
    const admin = useSelector((state) => state.admin);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const {state} = useParams();
    // Access the specific search key value
    let searchKeyValue = state;
    if (searchKeyValue == undefined) {
        searchKeyValue = "all";
    }
    
    const {id} = useParams();


    const tableColumns = [
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            editable: false,
            renderCell: (params) => (
                <p>{params.row.name}</p>
            ),
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
            editable: false,
            renderCell: (params) => (
                <p>{params.row.email}</p>
            ),
        },
        {
            field: "mobile",
            headerName: "Mobile",
            flex: 1,
            editable: false,
            renderCell: (params) => (
                <p>{params.row.mobile}</p>
            ),
        },
        {
            field: "Email-Status",
            headerName: "Email-Status",
            flex: 1,
            editable: false,
            renderCell: (params) => (
                <p style={{ color: params.row?.verifyStatus?.email ? "green" : "red" }}>
                    {params.row?.verifyStatus?.email ? "Verified" : "Not Verified"}
                </p>
            ),
        },
        {
            field: "createdAt",
            headerName: "Created At",
            flex: 1,
            editable: false,
            renderCell: (params) => (
                <p >
                    {new Date(params.row?.createdAt).toLocaleString()}
                </p>
            ),
        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            minWidth: 230,
            editable: false,
            renderCell: (params) => (
                <div>
                    <Tooltip title="Show Student Details">
                        <Button
                            variant="outlined"
                            onClick={() => handleStudentView(params)}
                            className="circular-btn me-2"
                            startIcon={<RemoveRedEyeRoundedIcon />}
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    useEffect(() => {
        getStudents(searchKeyValue)
    }, [admin, searchKeyValue]);

    const getStudents = async (tag) => {
        try {
            setLoaderOpen(true);
            const response = await getAllStudents(tag, admin.token);
            if(response.status !== 200) {
                showToastAlert(response.data.msg, "error");
                return;
            }
            setApiData(response.data.students);
            //set initial post status array 
            let tempStatusMap = new Map();
            response.data.students.map((item, stIndex) => {
                tempStatusMap.set(item._id, item.status);
            })
            setSelectedOptions(tempStatusMap);
            setLoaderOpen(false);
        }
        catch (err) {
            throw new Error(err)
        }
    }
    const handleUpdate = async (params) => {
        const id = params.row._id;
        const status = params.row.status;
        // Call your update function here with the row ID and status value
        const response = await updateStudentState(admin.token, id, status);
        if(response.status !== 200) {
            showToastAlert(response.data.msg, "error");
            return;
        }
        console.log(`Updating row with ID: ${id}, Status: ${status}`);
        // Your update logic here
    };
    const handleStudentView = (params) => {
        const id = params.row._id;  
        setCurrStudentId(id);
        setModalOpen(true);
    };

    const editStudentView = (params) => {
        const id = params.row._id;  
        navigate(`/admin/post/edit/${id}`);
    };

    const closeStudentView = () => {
        setModalOpen(false);
    }

    const postOptions = ["pending", "approved", "closed", "rejected"]; //live is just approved with live condition
    const handleOptionChange = (rowIndex, value) => {
        setSelectedOptions((prevValues) => {
            const tempValues = new Map(prevValues);
            tempValues.set(rowIndex, value);
            return tempValues;
        })
    }

    const callStudentStateUpdateApi = async (storyid, aReason) => {
        const curSelOption = selectedOptions.get(storyid)
        const response = await updateStudentState(storyid, curSelOption, admin.token, aReason);
        if(response.status !== 200) {
            showToastAlert(response.data.msg, "error");
            return;
        }
        showToastAlert("Student state updated", "success");
        // Remove the row from the table
        setApiData((prevApiData) => prevApiData.filter((row) => row._id !== storyid));
    }

    const handleClose = () => {
        setShowAlert(false);
    }

    const handleReasonSubmit = async (storyid, aReason) => {
        if (aReason === "")
            return;
        setReason(aReason);
        setShowReasonPopup(false);
        await callStudentStateUpdateApi(storyid, aReason);
    }
    const handleStudentStateUpdate = async (params) => {
        const storyid = params.row._id;
        setSelectedId(storyid);
        const curSelOption = selectedOptions.get(storyid)
        if(curSelOption === searchKeyValue) //do nothing 
            return; 
        if (curSelOption === "rejected" || curSelOption == "closed") {
            // Show the input popup for the reason
            setShowReasonPopup(true);
        } else {
            await callStudentStateUpdateApi(storyid, "");
        }
    }

    return (
        <div>
            <h1 className="page-title-1 my-3 asdsd">Employer Students</h1>
            <CustomLoader setLoaderOpen={setLoaderOpen} loaderOpen={loaderOpen} />
            <DataTable data={apiData} columns={tableColumns} id="_id" />
            <RejectReasonBox selectedId={selectedId} showReasonPopup={showReasonPopup} setShowReasonPopup={setShowReasonPopup} setReason={setReason} handleReasonSubmit={handleReasonSubmit} />
            <CustomAlert />
        </div>
    );
};

export default AdminAllStudentList;
