import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Link,
  Button,
  Modal,
  TextField,
  Alert,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { formatMessageTime } from "../../../services/utilApis";

const AssignmentReceivedMsg = ({ item, itemtype = "received", sendAnswer }) => {
  const [open, setOpen] = useState(false);
  const [answer, setAnswer] = useState("");
  const [projectLink, setProjectLink] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = () => {
    // Submit the assignment answer logic here
    console.log("Answer:", answer);
    console.log("Project Link:", projectLink);
    sendAnswer(answer, projectLink);
    // Show success alert
    setShowSuccess(true);

    // Close modal after submission
    setTimeout(() => {
      setShowSuccess(false);
      handleClose();
    }, 2000);
  };

  return (
    <>
      <Card
        style={{
          backgroundColor: "#F8F8F8", // Light blue background
          borderRadius: "12px",
          padding: "10px",
          maxWidth: "400px", // Adjust width as needed
        }}
      >
        <CardContent style={{ padding: "8px 16px" }}>
          <Box display="flex" alignItems="center" marginBottom="8px">
            <DoneIcon style={{ color: "#43a047", marginRight: "8px" }} />
            <Typography variant="subtitle1" style={{ fontWeight: 200 }}>
              Assignment {itemtype}
            </Typography>
          </Box>
          {/* Long link handling */}
          <Typography
            variant="body2"
            style={{
              wordBreak: "break-word", // Ensures the long link wraps
              marginBottom: "16px",
            }}
          >
            {item.content}
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginTop="8px"
          >
            <Typography variant="caption" color="textSecondary">
              {formatMessageTime(item.createdAt)}
            </Typography>
            <AccessTimeIcon fontSize="small" color="action" />
          </Box>
        </CardContent>
        <Box textAlign="center" paddingBottom="16px">
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpen}
          >
            Send Assignment Answer
          </Button>
        </Box>
      </Card>

      {/* Modal for Assignment Answer */}
      <Modal open={open} onClose={handleClose}>
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            borderRadius: "8px",
            padding: "24px",
            maxWidth: "400px",
            width: "100%",
          }}
        >
          <Typography variant="h6" marginBottom="16px">
            Send Assignment Answer
          </Typography>
          <TextField
            label="Answer"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            style={{ marginBottom: "16px" }}
          />
          <TextField
            label="Project Link"
            fullWidth
            variant="outlined"
            value={projectLink}
            onChange={(e) => setProjectLink(e.target.value)}
            style={{ marginBottom: "16px" }}
          />
          <Box display="flex" justifyContent="space-between">
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Success Alert */}
      {showSuccess && (
        <Alert
          severity="success"
          style={{
            position: "fixed",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 9999,
          }}
        >
          Assignment answer submitted successfully!
        </Alert>
      )}
    </>
  );
};

export default AssignmentReceivedMsg;
