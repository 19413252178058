import axios from 'axios';
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const getAllStudents = async (state, token) => {
    console.log(state)
    try {
        const response = await heyapi.get(`/admin/student/filter?state=${state}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        console.log(response)
        return response;
    }
    catch (err) {
        console.log(err);
    }
}

export const updateStudentState = async (studentid, state, token, reason) => {
    try {
        const data = {
            studentid: studentid, 
            status: state, 
            reason: reason
        }
        const response = await heyapi.patch(`/admin/student/updatestate`, 
            data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        //console.log(response.data)
        return response;
    }
    catch (err) {
        console.log(err);
    }
}