import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const AddTraining = async (
  token,
  trainingid,
  title,
  company,
  workMode,
  startDate,
  endDate,
  description
) => {
  console.log(
    trainingid,
    title,
    company,
    workMode,
    startDate,
    endDate,
    description,
    token
  );
  try {
    const response = await heyapi.post(
      `/student/training/add`,
      { trainingid, title, company, workMode, startDate, endDate, description },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
