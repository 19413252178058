import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  TablePagination,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router";
import { closePost, getPostByEmployeeId } from "../services/getPostByEmployeeId";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import MenuIcon from "./../components/MenuDot";
import { getStatusTag } from "../common/commonFunctions";
import "./../Style/Employer.css";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SingleInternshipModal from "../modals/SingleInternshipModal";
import { showToastAlert } from "../admin/components/CustomAlert";
import { Helmet } from "react-helmet-async";
export default function BasicTable() {
  const navigate = useNavigate();
  const [apiData, setApiData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const token = useSelector((state) => state.token);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [currPostId, setCurrPostId] = React.useState("");
  const userDetails = useSelector((state) => state.userDetails);
  useEffect(() => {
    getPost();
  }, [token]);

  const getPost = async () => {
    try {
      const response = await getPostByEmployeeId(token);
      if(response.status !== 200) {
        showToastAlert(response.data.message, "error");
        return;
      }
      setApiData(response.data.posts);
    } catch (err) {
      console.log(err);
    }
    setLoader(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseInternship = async (postid) => {
    try {
      const response = await closePost(token, postid);
      if(response.status !== 200) {
        showToastAlert(response.data.message, "error");
        return;
      }
      //navigate(0);
    } catch (err) {
      console.log(err);
    }
  }

  const handlePostView = (postid) => {
    setCurrPostId(postid);
    setModalOpen(true);
};

const closePostView = () => {
    setModalOpen(false);
}

  return (
    <div className="container-fluid">      
      <Helmet>
        <title>HeyInterns | Employer Dashboard </title>
      </Helmet>
      <div className="text-center my-3">
        <h3> Welcome {userDetails?.name}</h3><hr/>
        {(!userDetails?.isApproved) && (
          <div className="alert alert-danger" role="alert">
          <h5> Your account is under review and not approved. </h5> 
          <h6> Please contact us in case of urgent activation.</h6>
          <h7> You can't create internship post till account activation.</h7> <br/>
          <p> If you received activation email from us then logout and login again to remove this message</p>
          </div>
          )}
      </div>
      <Box>
        <div>
          {loader ? null : (
            <div className="container">
              <TableContainer className="custom-mui-table ">
                <Table
                  stickyHeader
                  sx={{ width: "100%" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Applications</TableCell>
                      <TableCell>Total Views</TableCell>
                      <TableCell>Action</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {apiData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <TableRow key={row._id}>
                          <TableCell
                            component="th"
                            scope="row"
                            className="table-title-first"
                          >
                            {row.title}
                          </TableCell>
                          <TableCell>
                            {getStatusTag(row.status)}
                            {row.status === "rejected" && (
                              <Tooltip title={row.reason}>
                                <IconButton
                                  size="small"
                                  // onClick={() => handleInfoClick(params.row._id)} // Add a function to handle the info click if needed
                                >
                                  <InfoOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell>{row.stats.application}</TableCell>
                          <TableCell>{row.stats.views}</TableCell>
                          <TableCell>
                            { row.status === "approved" && <Button
                              variant="outlined"
                              onClick={() =>
                                navigate(
                                  `/employer/applications/${row._id}/pending`,
                                  {
                                    state: { id: row._id},
                                  }
                                )
                              }
                            >
                              View applications
                            </Button> }
                          </TableCell>
                          <TableCell align="right">
                            <MenuIcon id={row._id} slug={row.slug} poststatus={row.status} handlePostView={handlePostView} handleCloseInternship={handleCloseInternship}/>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={apiData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          )}
        </div>
        <SingleInternshipModal open={modalOpen} postid={currPostId} closePostView={closePostView} />
      </Box>
    </div>
  );
}
