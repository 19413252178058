import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Drawer, useMediaQuery } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Chip from "@mui/material/Chip";
import SettingsPowerIcon from "@mui/icons-material/SettingsPower";
import { logout } from "./../../Store/Action";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { useParams } from "react-router-dom";

const PostApplicationsSidebar = ({
  counts,
  setOpenChatSidebar,
  openChatSidebar,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width: 800px)"); // Check if device is mobile÷

  console.log("Dashboard Sidebar", openChatSidebar);

  console.log("count updated:" + JSON.stringify(counts));
  const isAuthenticated = useSelector((state) => state.admin);
  const handleLogout = () => {
    dispatch(logout());
    navigate("/admin/login");
  };

  const { id, state } = useParams();
  // Access the specific search key value
  let searchKeyValue = state;
  if (searchKeyValue == undefined) {
    searchKeyValue = "pending";
  }

    
  const sideMenuArr = [
    {
      label: "Pending",
      url: `/employer/applications/${id}/pending`,
      count: counts.pending,
    },
    {
      label: "Shortlisted",
      url: `/employer/applications/${id}/shortlist`,
      count: counts.shortlist,
    },
    {
      label: "Rejected",
      url: `/employer/applications/${id}/rejected`,
      count: counts.rejected,
    },
    {
      label: "Hired",
      url: `/employer/applications/${id}/hired`,
      count: counts.hired,
    },
    {
      label: "Application Received",
      url: `/employer/applications/${id}/all`,
      count: counts.all,
    },
    /*
    { label: 'Assignment', url: `/employer/applications/${id}/live` },
    { label: 'Interviews', url: `/employer/applications/${id}/live` },
    { label: 'Chat Messages', url: `/employer/applications/${id}/live` },*/
  ];

  const renderSidebarContent = () => (
    <div className="dashboard-sidebar">
      <div className="dashboard-userdata-box">
        <img
          src="https://cdn-icons-png.flaticon.com/512/560/560199.png"
          alt="Admin"
          className="img-fluid"
        />
      </div>
      <ul>
        <li>
          <NavLink
            to={`/employer/dashboard`}
            onClick={() => {
              setOpenChatSidebar(false);
            }}
            className="my-3 text-white d-block"
          >
            <ArrowBackIcon /> Back
          </NavLink>
        </li>
        {sideMenuArr.map((tab, tabIndex) => (
          <li>
            <NavLink
              to={tab.url}
              className="menu-item"
              onClick={() => {
                setOpenChatSidebar(false);
              }}
            >
              {tab.label}{" "}
              <Chip label={tab.count} sx={{ backgroundColor: "white" }} />
            </NavLink>
          </li>
        ))}
        <hr/>
        <li>
            <NavLink
              to={`/employer/applications/${id}/assignments`}
              className="menu-item"
              onClick={() => {
                setOpenChatSidebar(false);
              }}
            >
              Assignments{" "}
              <Chip label="*" sx={{ backgroundColor: "white" }} />
            </NavLink>
        </li>
      </ul>
      <div className="dashboard-sidebar-fixed-btns">
        <ul>
          <li>
            <NavLink onClick={handleLogout} to={"/admin/logout"}>
              <SettingsPowerIcon /> Logout
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
  const mobileDrawer = (
    <Drawer
      anchor="left"
      classes={{ paper: "dashboard-sidebar-drawer" }}
      sx={{
        "& .MuiDrawer-paper": { width: "300px" },
      }}
      open={openChatSidebar}
      onClose={() => {
        setOpenChatSidebar(false);
      }}
      // Optional: close the drawer when clicking outside
    >
      <IconButton
        variant="contained"
        aria-label="more"
        className="cancel-drawer-btn"
        onClick={() => {
          setOpenChatSidebar(false);
        }}
      >
        <CancelIcon />
      </IconButton>
      {renderSidebarContent()}
    </Drawer>
  );
  return (
    <React.Fragment>
      {isMobile
        ? mobileDrawer // Use Drawer when on mobile
        : renderSidebarContent()}
    </React.Fragment>
  );
};

export default PostApplicationsSidebar;
