import React from "react";
import Tooltip from "@mui/material/Tooltip";

const LimitTooltip = ({ Text, Limit }) => {
  const words = Text.split("");
  const exceedsLimit = words.length > Limit;
  const displayText = exceedsLimit
    ? words.slice(0, Limit).join("") + "..."
    : Text;

  return (
    <Tooltip title={exceedsLimit ? Text : ""} arrow>
      {displayText}
    </Tooltip>
  );
};

export default LimitTooltip;
