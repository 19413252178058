import axios from 'axios';
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const verifyEmail = async (tokenid, uType) => {
    try {
        let url = `/student/verify-email?tokenid=${tokenid}`;
        if(uType === "employer") {
            url = `/employer/verify-email?tokenid=${tokenid}`
        } else if(uType === "college") {
            url = `/college/verify-email?tokenid=${tokenid}`
        }
        const response = await heyapi.get(url, {});
        //console.log(response.data)
        return response.data;
    }
    catch (err) {
        console.log(err);
    }
}

export const sendOTP = async (token, mobile, uType) => {
    try {
        let url = `/student/sms/send`;
        if(uType === "employer") {
            url = `/employer/sms/send`
        }
        const response = await heyapi.post(url, 
            {
                mobile
            },
            {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            }
        );
        console.log(response)
        return response;
    }
    catch (err) {
        console.log(err);
        return err;
    }
}

export const verifyOTP = async (token, mobile, answer, uType) => {
    try {
        let url = `/student/sms/verify`;
        if(uType === "employer") {
            url = `/employer/sms/verify`
        }
        const response = await heyapi.post(url, 
            {
                mobile,
                answer
            },
            {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            }
        );
        console.log(response)
        return response;
    }
    catch (err) {
        console.log(err);
    }
}