import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const UpdateEmployerState = async (token,empid,status,reason
) => {
      console.log(token,empid,status,reason)
    try {
        const response = await heyapi.patch(
            `/admin/employer/updatestate`,
            {empid,status,reason},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        console.log(response.data);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};
