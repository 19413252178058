import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import { sendOTP, verifyOTP } from '../services/verification';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateStepCompletion, userType } from '../Store/Action';
import { isValidIndianMobileNumber } from '../common/commonFunctions';
import {checkStudentSteps} from '../common/redirectFunctions';

const PhoneVerifyPage = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [cooldown, setCooldown] = useState(0); // For timer cooldown
  const token = useSelector((state) => state.token);
  const stepsCompleted = useSelector((state) => state.stepsCompleted);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Use an effect to handle the cooldown timer
  useEffect(() => {
    let timer;
    if (cooldown > 0) {
      timer = setTimeout(() => {
        setCooldown(cooldown - 1);
      }, 1000);
    }
    return () => clearTimeout(timer); // Clear the timer on unmount or when cooldown changes
  }, [cooldown]);

  const getUserTypeFromUrl = () => {
    const path = window.location.pathname; // Example: "/employer/verify-mobile"

    if (path.includes("/employer/verify-mobile")) {
      return "employer";
    } else if (path.includes("/student/verify-mobile")) {
      return "student";
    } else {
      return "unknown";
    }
  };

  const handleRequestSMS = async () => {
    if ((!phoneNumber) || (!(isValidIndianMobileNumber(phoneNumber)))) {
      setMessage('Please enter a valid phone number.');
      setSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    const uType = getUserTypeFromUrl();
    if (uType === "unknown")
      return;
    setLoading(true);
    try {
      const data = await sendOTP(token, phoneNumber, uType);
      setLoading(false);
      setMessage('Verification code sent successfully!');
      setSeverity('success');
      setOpenSnackbar(true);

      // Start the 20-second cooldown
      setCooldown(20);
    } catch (error) {
      setLoading(false);
      setMessage('Failed to send verification code.');
      setSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleVerifyCode = async () => {
    if (!verificationCode) {
      setMessage('Please enter the verification code.');
      setSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    const uType = getUserTypeFromUrl();
    if (uType === "unknown")
      return;
    setLoading(true);
    try {
      const response = await verifyOTP(token, phoneNumber, verificationCode, uType);
      setLoading(false);
      if (response.status !== 200) {
        setMessage(response.data.message);
        setSeverity('error');
        setOpenSnackbar(true);
      } else {
        dispatch(updateStepCompletion({mobileVerified: true}));
        setMessage('Phone number verified successfully!');
        setSeverity('success');
        setOpenSnackbar(true);
        if (uType === "student") {
          const redirectPath = checkStudentSteps(uType, stepsCompleted);
          if(redirectPath)  {
            return navigate(redirectPath);
          } else {
            navigate('/student/editprofile');
          }
        }          
        else if (uType === "employer")
          navigate('/employer/dashboard');
        else
          navigate('/404');

      }
    } catch (error) {
      console.log("error:" + error);
      setLoading(false);
      setMessage('Failed to verify the code.');
      setSeverity('error');
      setOpenSnackbar(true);
    }
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: '50px' }}>
      <Typography variant="h5" align="center" gutterBottom>
        Phone Verification
      </Typography>
      <TextField
        label="Phone Number (+91)"
        variant="outlined"
        fullWidth
        margin="normal"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        inputProps={{ maxLength: 10 }} // Limit input to 10 digits
      />


      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleRequestSMS}
        disabled={loading || cooldown > 0} // Disable button during cooldown
      >
        {loading ? (
          <CircularProgress size={24} />
        ) : cooldown > 0 ? (
          `Resend OTP in ${cooldown}s`
        ) : (
          'Send Verification SMS'
        )}
      </Button>
      {/* Show message when SMS is sent */}
      {message && severity === 'success' && (
        <Typography variant="body2" color="green" gutterBottom>
          {message}
        </Typography>
      )}
      {message && severity === 'error' && (
        <Typography variant="body2" color="red" gutterBottom>
          {message}
        </Typography>
      )}


      <TextField
        label="Verification Code"
        variant="outlined"
        fullWidth
        margin="normal"
        value={verificationCode}
        onChange={(e) => setVerificationCode(e.target.value)}
      />
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        onClick={handleVerifyCode}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : 'Verify Code'}
      </Button>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default PhoneVerifyPage;
