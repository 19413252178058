import React from "react";
import AssignmentReceivedMsg from "./AssignmentReceivedMsg";
import MsgReceivedCard from "../../../components/chat/MsgReceivedCard";
import { Box } from "@mui/material";

const ChatMsgReceived = ({item, itemtype="Received"}) => {
  return (
    <Box sx={{marginBottom: "10px"}}>
    {item.type === "answer" ? <AssignmentReceivedMsg item={item} itemtype={itemtype}/> : (
        <MsgReceivedCard item={item} />)}
    </Box>
  )};

export default ChatMsgReceived;
