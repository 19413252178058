import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const RedirectRoute = () => {
  const userType = useSelector((state) => state.uType);

  // If the user is an employer, redirect to employer dashboard
  if (userType === "employer") {
    return <Navigate to="/employer/dashboard" replace />;
  }

  // Otherwise, render the requested route
  return <Outlet />;
};

export default RedirectRoute;
