import React, { useState, useEffect, useContext } from "react";
import "../App.css";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Link, useNavigate } from "react-router-dom";
import { employerRegister } from "../services/employerRegister";
import { fetchCaptchaApi } from "../services/captchaApi";
import UserContext from "../context/userContext";
import { CustomAlert, showToastAlert } from "../admin/components/CustomAlert";
import websiteSettings from "../common/siteSettings";
import { Helmet } from "react-helmet-async";
function SignUp() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [answer, setAnswer] = useState("");
  const [captchaHex, setCaptchaHex] = useState("");
  const [captchaImg, setCaptchaImg] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { user, updateUser } = useContext(UserContext);

  useEffect(() => {
    fetchCaptcha();
  }, []);

  const fetchCaptcha = async () => {
    try {
      const res = await fetchCaptchaApi();
      if (res) {
        setCaptchaHex(res.hex);
        setCaptchaImg(res.img);
      }
    } catch (error) {
      console.error("Error fetching CAPTCHA", error);
    }
  };

  const validateForm = () => {
    let validationErrors = {};
    if (!firstName) validationErrors.firstName = "First Name is required";
    if (!lastName) validationErrors.lastName = "Last Name is required";
    if (!email) validationErrors.email = "Email is required";
    if (!password) validationErrors.password = "Password is required";
    if (!answer) validationErrors.answer = "CAPTCHA answer is required";
    if(password.length < 6) validationErrors.password = "Password minimum 6 characters";
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const registerEmployer = async () => {
    if (!validateForm()) return;
    try {
      const response = await employerRegister(
        email,
        password,
        firstName + " " + lastName,
        answer,
        captchaHex
      );
      if (response.status === 200) {
        navigate("/account-created");
      } else {
        showToastAlert(response.data.message, "error");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 5 }}>
      <Helmet>
          <title>HeyInterns | Employer Sign Up </title>
      </Helmet>
      <Grid container spacing={3}>
        {/* Left Section */}
        <Grid item xs={12} md={6}>
          <Typography variant="h3" gutterBottom>
            Introducing <strong>{websiteSettings.name}</strong>
          </Typography>
          <Typography variant="h5" gutterBottom>
            Hire faster, powered by AI
          </Typography>
          <div>
            {[
              {
                icon: <GroupIcon fontSize="large" />,
                title: "Source",
                text: "1000+ candidates with different skills, we help you find the perfect fit for your organization.",
              },
              {
                icon: <SearchIcon fontSize="large" />,
                title: "Screen",
                text: "With our specialists, filter profiles, giving you highly qualified individuals.",
              },
              {
                icon: <CheckCircleOutlineIcon fontSize="large" />,
                title: "Select",
                text: "Assign tasks and filter the top profiles.",
              },
            ].map((step, index) => (
              <Box key={index} mb={3} display="flex" alignItems="center">
                <Box mr={2}>{step.icon}</Box>
                <Box>
                  <Typography variant="h6">{step.title}</Typography>
                  <Typography>{step.text}</Typography>
                </Box>
              </Box>
            ))}
          </div>
        </Grid>

        {/* Right Section */}
        <Grid item xs={12} md={6}>
          <Container className="bg-light border rounded p-4">
            <Typography variant="h4" gutterBottom>
              Sign Up
            </Typography>
            <Box component="form" noValidate sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={(e) => setFirstName(e.target.value)}
                    size="small"
                    required
                    fullWidth
                    label="First Name"
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                    inputProps={{ maxLength: 25 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={(e) => setLastName(e.target.value)}
                    size="small"
                    required
                    fullWidth
                    label="Last Name"
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                    inputProps={{ maxLength: 25 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={(e) => setEmail(e.target.value)}
                    size="small"
                    required
                    fullWidth
                    label="Company Email Address"
                    error={!!errors.email}
                    helperText={errors.email}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={(e) => setPassword(e.target.value)}
                    size="small"
                    required
                    fullWidth
                    label="Password"
                    type="password"
                    error={!!errors.password}
                    helperText={errors.password}
                    inputProps={{ maxLength: 15 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <img src={captchaImg} alt="CAPTCHA" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={(e) => setAnswer(e.target.value)}
                    size="small"
                    required
                    fullWidth
                    label="Enter CAPTCHA"
                    error={!!errors.answer}
                    helperText={errors.answer}
                    inputProps={{ maxLength: 15 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    label="I want to receive updates via email."
                  />
                </Grid>
              </Grid>
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={registerEmployer}
              >
                Sign Up
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link to="/student/login">Already have an account? Sign in</Link>
                </Grid>
              </Grid>
            </Box>
            <CustomAlert />
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
}

export default SignUp;
