import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { grey } from "@mui/material/colors";
import {
    TableBody,
    TableRow,
    TableCell,
    Container,
    tableCellClasses
    
  } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddPositionResponseblity() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <TableBody>
      <TableRow
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
      >
        <TableCell style={{ fontSize: "12px" }}>
          POSITIONS OF RESPONSIBILITY
        </TableCell>
          <TableRow>
            <TableCell
              style={{
                padding: "8px 20px 16px",
                borderBottom: "none",
                width: "20vw",
              }}
            >
              <p style={{ fontSize: "14px", fontWeight: "500" }}>
                
                <br />
                <br />
              </p>
            </TableCell>
            {/* <TableCell>
              <IconButton edge="end" aria-label="edit">
                <EditIcon     />
              </IconButton>
              &nbsp; &nbsp; &nbsp;
              <IconButton edge="end" aria-label="delete">
                <DeleteIcon
                   
                />
              </IconButton>
            </TableCell> */}
          </TableRow>
        <TableRow>
          <TableCell>
            <Button
              onClick={() => {
                handleClickOpen();
              }}
              style={{ padding: "0px" }}
            >
              <AddIcon style={{ fontSize: "14px" }} color="primary" /> &nbsp;
              <span style={{ fontSize: "12px" }}>Add Position Of Responsibility</span>
            </Button>
          </TableCell>
        </TableRow>
      </TableRow>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Position of responsibility
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>Description</Typography>
          <div class="mb-3">
            <label for="exampleFormControlTextarea1" class="form-label">
              If you have been/are an active part of societies, conducted any
              events or led a team, add details here
            </label>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder="e.g. if you lead a team in your college event"
            ></textarea>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </TableBody>
  );
}