import React, { useState, useEffect } from "react";
import { Container, TextField, Button, Typography, Paper, Autocomplete, MenuItem, Select, Grid, Box } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { getAllPreference } from "../services/getAllPreferences";
import { toCamelCaseForFirstChar } from "../services/utilApis";
import { CustomAlert, showToastAlert } from "../admin/components/CustomAlert";
import { addStory, getStoryDetails } from "../services/appliedInternship";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { useRef } from "react";

const companies = ["Google", "Microsoft", "Amazon", "Facebook", "Apple", "Tesla", "Netflix"];
const years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i + 2);
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const durationOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const InternshipStoryForm = ({type="add"}) => {
  const navigate = useNavigate();
  const {storyid} = useParams(); 
  const [formData, setFormData] = useState({
    storyid: "",
    profile: "",
    company: "",
    year: "",
    month: "",
    duration: "",
    rounds: "",
    howApplied: "",
    questions: "",
    advice: "",
    otherComment: "",
    linkedin : ""
  });
  const [story, setStory] = useState(null);
  const [allPrefs, setAllPrefs] = React.useState([]);
  const isFirstLoad = useRef(true);
  const token = useSelector((state) => state.token);
  useEffect(() => {
    getProf(storyid);
  }, [storyid]);

  const getProf = async (sid) => {
    console.log("called getProf");
    try {
      const allPrefsArr = await getAllPreference();
      const allPrefs = allPrefsArr?.preferences?.map((item) => item.name);
      setAllPrefs(allPrefs);
      if(sid) {
        const response = await getStoryDetails(token, sid, "student");
        if(response.status !== 200) {
          showToastAlert("Invalid request", "error");
          return;
        } 
        setStory(response.data.story);
        setFormData({
          storyid: sid,
          linkedin: response.data.story.linkedin || "",
          profile: response.data.story.profile || "",
          company: response.data.story.company || "",
          year: response.data.story.year || "",
          month: response.data.story.month || "",
          duration: response.data.story.duration || "",
          rounds: response.data.story.rounds || "",
          howApplied: response.data.story.howApplied || "",
          questions: response.data.story.questions || "",
          advice: response.data.story.advice || "",
          otherComment: response.data.story.otherComment || "",
        });
        isFirstLoad.current = false;  // ✅ Mark first load as completed
      }
    } catch(error) {

    }
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleEditorChange = (type, content) => {
    //if (isFirstLoad.current) return;  // ✅ Skip update on first load
    if (formData[type] === content) return;
    setFormData({ ...formData, [type]: content });
  };

  const setProfile = (content) => {
    setFormData({ ...formData, profile: content });
  }

  const setLinkedin = (content) => {
    setFormData({ ...formData, linkedin: content });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if ( !formData.profile || !formData.company || !formData.year 
      || !formData.month || !formData.duration || !formData.questions
      || !formData.rounds || !formData.otherComment || !formData.advice || !formData.howApplied
      || !formData.linkedin) {
      showToastAlert("All fields are mandatory.", "error");
      return;
    }
    /*
    if (formData.story.replace(/<(.|\n)*?>/g, "").trim().split(" ").length < 100) {
      showToastAlert("Your story must be at least 500 words long.", "error");
      return;
    }*/
    const response = await addStory(token, formData);
    if(response.status !== 200) {
      showToastAlert(response.data.message, "error");
      return;
    }
    navigate('/story-submitted')
  };

  return (
    <Container maxWidth="md" sx={{ marginTop: 4, width: { xs: "100%", md: "80%" } }}>
      <Helmet>
              <title>HeyInterns | New Internship Story </title>
            </Helmet>
      <Paper elevation={1} sx={{ padding: 4, borderRadius: 2, backgroundColor: "#f9f9f9" }}>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold", color: "#333" }}>
          {story ? story.title : "Share Your Internship Story" }
        </Typography>
        <form>
          <Box >
             <Typography>Linkedin or Web Profile URL</Typography>
              <TextField
                  size="small"
                  fullWidth
                  placeholder="Add your linkedin profile"
                  id="fullWidth"
                  onChange={(e) => {
                      setLinkedin(e.target.value);
                  }}
                  value={formData?.linkedin}
              />
            <Typography>Job Profile</Typography>
            <Autocomplete
              freeSolo
              disableClearable
              options={allPrefs.map((option) => option)}
              value={formData.profile}
              onChange={(event, newValue) => {
                setProfile(toCamelCaseForFirstChar(newValue));
              }}
              onInputChange={(event, newValue) => {
                if (event !== null && newValue.trim() !== "") {
                  setProfile(toCamelCaseForFirstChar(newValue)); // Capture custom input
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  placeholder="e.g. Android App Development.."
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
            <Typography>Company Name</Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="e.g. Microsoft."
              onChange={(event) => setFormData({ ...formData, company: event.target.value })}
              inputProps={{ maxLength: 100 }} // Limits input to 500 characters
              value={formData.company}
              sx= {{marginTop: "10px"}}
            />
            <Typography>Select starting date</Typography>
            <Grid container spacing={2} sx={{ marginTop: 2 }}>
              <Grid item xs={6}>
                <Select fullWidth displayEmpty value={formData.year} onChange={handleChange} name="year" required>
                  <MenuItem value="" disabled>Year</MenuItem>
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>{year}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6}>
                <Select fullWidth displayEmpty value={formData.month} onChange={handleChange} name="month" required>
                  <MenuItem value="" disabled>Month</MenuItem>
                  {months.map((month) => (
                    <MenuItem key={month} value={month}>{month}</MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Select fullWidth displayEmpty value={formData.duration} onChange={handleChange} name="duration" sx={{ marginTop: 2 }} required>
              <MenuItem value="" disabled>Duration (Months)</MenuItem>
              {durationOptions.map((duration) => (
                <MenuItem key={duration} value={duration}>{duration} Month(s)</MenuItem>
              ))}
            </Select>

          </Box>
          <Typography variant="body1" sx={{ marginTop: 2, color: "#666" }}>How You Applied:</Typography>
          <ReactQuill value={formData.howApplied} onChange={(e) => handleEditorChange("howApplied", e)} theme="snow" style={{ height: "150px", backgroundColor: "#fff" }}  />

          <Typography variant="body1" sx={{ marginTop: 10, color: "#666" }}>Explain Rounds:</Typography>
          <ReactQuill value={formData.rounds} onChange={(e) => handleEditorChange("rounds", e)} theme="snow" style={{ height: "250px", backgroundColor: "#fff" }}  />

          <Typography variant="body1" sx={{ marginTop: 10, color: "#666" }}>Question Asked:</Typography>
          <ReactQuill value={formData.questions} onChange={(e) => handleEditorChange("questions", e)} theme="snow" style={{ height: "150px", backgroundColor: "#fff" }}  />

          <Typography variant="body1" sx={{ marginTop: 10, color: "#666" }}>Advice For Aspirants:</Typography>
          <ReactQuill value={formData.advice} onChange={(e) => handleEditorChange("advice", e)} theme="snow" style={{ height: "250px", backgroundColor: "#fff" }}  />

          <Typography variant="body1" sx={{ marginTop: 10, color: "#666" }}>Complete your story:</Typography>
          <ReactQuill value={formData.otherComment} onChange={(e) => handleEditorChange("otherComment", e)} theme="snow" style={{ height: "250px", backgroundColor: "#fff" }}  />

          <Button onClick={handleSubmit} type="submit" variant="contained" color="primary" fullWidth sx={{ marginTop: 10, backgroundColor: "#4caf50", color: "#fff", '&:hover': { backgroundColor: "#388e3c" }, fontWeight: "bold", borderRadius: 2 }}>
            {storyid ? "Update" :"Submit" } Story
          </Button>
        </form>
        <CustomAlert />
      </Paper>
    </Container>
  );
};

export default InternshipStoryForm;
