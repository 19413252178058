import React from "react";
import { testimonialsData, quoteIcon } from "../../WebsiteData";

const StudentTestimonials = () => {
  return (
    <div>
      <div className="section-full content-inner-2 testimonials bg-white">
        <div className="container">
          <div className="section-head style-1 text-center">
            <h6>Student Testimonials</h6>
            <h2 className="section-title-3">
              What successful interns have to say
            </h2>
            <p className="dz-text-2">
              There are countless experiences shared by our interns, but the
              majority highlight our commitment to genuine opportunities,
              professional growth, and valuable real-world exposure.
            </p>
          </div>
          <div className="review-testimonial">
            {testimonialsData.map((testimonial, index) => (
              <div className="item" key={index}>
                <div className="testimonial-wrapper">
                  <div className="testimonial-inner">
                    <img
                      className="brandlogo-t"
                      alt=""
                      src={testimonial.brandLogo}
                    />
                    <div className="bg-img">{quoteIcon}</div>
                    <div className="testimonial-pic style-1">
                      <div className="testimonial-pic-circle" />
                      <div className="profile-pic">
                        <img
                          src={testimonial.profilePic}
                          alt="HeyInterns Student"
                        />
                      </div>
                    </div>
                    <div className="profile-info">
                      <h5 className="profile-name">
                        {testimonial.profileName}
                      </h5>
                      <span>{testimonial.internRole}</span>
                    </div>
                    <p className="dz-text-3">{testimonial.testimonialText}</p>
                    <p className="companynamee">
                      {testimonial.companyName}{" "}
                      <span>{testimonial.jobTitle}</span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentTestimonials;
