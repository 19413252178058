import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
} from "@mui/material";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import SingleInternshipComponent from "../components/SingleIntershipComponent";
import ViewInternshipDetailsPage from "../components/ViewInternshipDetailsPage";
const SingleInternshipModal = ({
  open,
  postid,
  closePostView,
  showApplyBtn,
}) => {
  const handleClose = () => {
    closePostView();
  };
  return (
    <Dialog
      open={open}
      aria-labelledby="internship-details"
      className="internship-details-popup"
      fullWidth
      maxWidth={false} // Disable default max width
      sx={{
        "& .MuiDialog-paper": {
          // Target the dialog's paper (the actual content container)
          width: "80vw", // Set width to 80% of viewport width
          maxWidth: "none", // Disable MUI's default maxWidth for the paper
          maxHeight: "90vh",
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        className="popup-close-btn"
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <ViewInternshipDetailsPage
          postid={postid}
          showApplyBtn={showApplyBtn}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SingleInternshipModal;
