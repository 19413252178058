import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { NavLink } from "react-router-dom";

import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const Breadcrumb = ({ breadcrumbs, heading }) => {
  return (
    <div className="page-breadcrumb">
      {breadcrumbs?.length > 0 && (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs.map((breadcrumb, index) => {
            const isLast = index === breadcrumbs.length - 1;
            if (isLast) {
              return (
                <Typography key={index} sx={{ color: "text.primary" }}>
                  {breadcrumb.label}
                </Typography>
              );
            }
            return <NavLink to={breadcrumb.href}> {breadcrumb.label}</NavLink>;
          })}
        </Breadcrumbs>
      )}

      {heading && <h2 class="d-page-title">{heading}</h2>}
    </div>
  );
};

export default Breadcrumb;
