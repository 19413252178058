import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EmailIcon from "@mui/icons-material/Email";
import { useNavigate } from "react-router";

const PasswordResetMailSuccess = () => {
  const navigate = useNavigate();

  const handleGoToLogin = () => {
    navigate("/");
  };

  return (
    <Container
      maxWidth="sm"
      style={{
        marginTop: "50px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flex: 1
      }}
    >
      {/* Success Icon */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <CheckCircleIcon
          color="success"
          style={{ fontSize: "60px", marginRight: "8px" }}
        />
        <Typography variant="h5" color="success.main">
          Password reset mail send Successfully!
        </Typography>
      </Box>

      {/* Email Information */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <Typography variant="body1" style={{ lineHeight: "1.6" }}>
          A password reset link has been sent to your registered email address.
          <br />
          Please click on the link to reset your password!
        </Typography>
        
        <EmailIcon color="primary" style={{ fontSize: "40px"}} />
      </Box>

      <Typography variant="h5">Note: Check your email spam folder also for reset email</Typography>
      {/* Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoToLogin}
        style={{ marginTop: "20px" }}
      >
        Go to Home
      </Button>
      <br />
      <br />
    </Container>
  );
};

export default PasswordResetMailSuccess;
