import React, { useContext } from "react";
import { Box, Snackbar, Tab } from "@mui/material";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import check from "../assets/images/check.png";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Container from "@mui/material/Container"
import TextField from "@mui/material/TextField"
import { Alert } from "@mui/material";
import Button from "@mui/material/Button"
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { TabContext, TabPanel } from "@mui/lab"; // Import TabContext and TabPanel from @mui/lab
import Menu from "@mui/material/Menu";
import { useNavigate,useLocation } from "react-router-dom";
import UserContext from "../context/userContext";
import userImg from '../assets/images/user.png'
import office from '../assets/images/office.png'
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { getEmployerDetails } from "../services/getAllEmployerForAdminDashboard";
import { useSelector } from "react-redux"
import { UpdateEmployerCompanyDetail } from "../services/updateEmployerDetail";
import PopUp from "../components/PopUp";
const EmployerDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [value, setValue] = React.useState("1");
    const [open, setOpen] = React.useState(false)
    const [apiData, setApiData] = React.useState("")
    const [loader, setLoader] = React.useState(true);
    
    const admin=useSelector((state)=>state.admin)
    React.useEffect(() => {
        // Split the pathname on '/' to get path segments
        const pathSegments = location.pathname.split('/');
        // Get the last token (if it exists)
        const id = pathSegments.length > 0 ? pathSegments[pathSegments.length - 1] : undefined;
        if(id == undefined) {
            navigate('/dashboard');
            return;
        }
        userInfo(id, admin.token);
    }, [admin]);

    const userInfo = async (id, token) => {
        try {
            const data = await getEmployerDetails(id, token);
            console.log(data)
            setApiData(data);
            setLoader(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    const VisuallyHiddenInput = styled("input")({
        clip: "rect(0 0 0 0)",
        clipPath: "inset(50%)",
        height: 1,
        overflow: "hidden",
        position: "absolute",
        bottom: 0,
        left: 0,
        whiteSpace: "nowrap",
        width: 1,
    });
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#f8f8f8",
                paddingTop: "20px",
                paddingBottom: "20px",
            }}
        >
            {loader ? null : (
                <Box
                    sx={{
                        width: "750px",
                        padding: "10px",
                        borderRadius: "10px",
                        background: "#ffffff",
                    }}
                >
                     
                    <TabContext value={value}>
                        <Tabs value={value} onChange={handleChange} centered>
                            <Tab
                                icon={<Avatar alt="Tab 1 Avatar" src={userImg} />}
                                label="Personal Details"
                                value="1"
                                style={{ width: "375px" }}
                            />
                            <Tab
                                icon={<Avatar alt="Tab 2 Avatar" src={office} />}
                                label="Organisation Details"
                                value="2"
                                style={{ width: "375px" }}
                            />
                        </Tabs>

                        {/* Use TabPanel inside TabContext */}
                        <TabPanel value="1">
                            <center
                                style={{ fontSize: "34px", fontWeight: "600", color: "black" }}
                            >
                                Personal Details
                            </center>
                            <Container
                                maxWidth="md"
                                style={{ marginTop: "20px", color: grey[500], flex: 1 }}
                                sx={{ border: 1, borderRadius: "10px", paddingY: "20px" }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "100%",
                                            marginRight: "10px",
                                        }}
                                    >
                                        <label
                                            style={{
                                                fontSize: "16px",
                                                textAlign: "left",
                                                color: "black",
                                                fontWeight: "500",
                                            }}
                                        >
                                            First Name
                                        </label>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            placeholder="e.g.delhi"
                                            multiline={true}
                                            value={apiData.employer.name}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "100%",
                                            marginLeft: "10px",
                                        }}
                                    >
                                        <label
                                            style={{
                                                fontSize: "16px",
                                                textAlign: "left",
                                                color: "black",
                                                fontWeight: "500",
                                            }}
                                        >
                                            Last Name
                                        </label>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            placeholder="e.g.delhi"
                                            multiline={true}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            value={apiData.employer.name}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <label
                                        style={{
                                            fontSize: "16px",
                                            textAlign: "left",
                                            color: "black",
                                            fontWeight: "500",
                                        }}
                                    >
                                        E-mail
                                    </label>
                                    <TextField
                                        multiline={true}
                                        value={apiData.employer.email}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "30%",
                                            marginRight: "10px",
                                        }}
                                    >
                                        <label
                                            style={{
                                                fontSize: "16px",
                                                textAlign: "left",
                                                color: "black",
                                                fontWeight: "500",
                                            }}
                                        >
                                            Mobile Number
                                        </label>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            multiline={true}
                                            value={"+91"}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "70%",
                                            marginLeft: "10px",
                                        }}
                                    >
                                        <br />
                                        <TextField
                                            required
                                            id="outlined-required"
                                            placeholder="e.g.delhi"
                                            multiline={true}
                                            value={apiData.employer.mobile}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <br />
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            sx={{ ml: 3, py: 2, width: "150px" }}
                                        >
                                            Not Verified
                                        </Button>
                                    </div>
                                </div>
                            </Container>
                            <Button
                                type="primary"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                // onClick={updateEmpDetail}
                            >
                                Save
                            </Button>
                        </TabPanel>
                        <TabPanel value="2">
                            <center
                                style={{ fontSize: "34px", fontWeight: "600", color: "black" }}
                            >
                                Organisation Details
                            </center>
                            <Container
                                maxWidth="md"
                                style={{ marginTop: "20px", color: grey[500], flex: 1 }}
                                sx={{ border: 1, borderRadius: "10px", paddingY: "20px" }}
                            >
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <label
                                        style={{
                                            fontSize: "16px",
                                            textAlign: "left",
                                            color: "black",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Organisation name
                                    </label>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        placeholder="e.g.delhi"
                                        value={apiData.employer.company.name}
                                        // onChange={(e) => setOrgName(e.target.value)}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <label
                                        style={{
                                            fontSize: "16px",
                                            textAlign: "left",
                                            color: "black",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Organisation description
                                    </label>
                                    <TextField
                                        multiline
                                        rows={3}
                                        fullWidth
                                        variant="outlined"
                                        id="outlined-required"
                                        placeholder="e.g.delhi"
                                        // value={orgDes}
                                        // onChange={(e) => setOrgDes(e.target.value)}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <label
                                        style={{
                                            fontSize: "16px",
                                            textAlign: "left",
                                            color: "black",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Organisation Address
                                    </label>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        placeholder="e.g.delhi"
                                        multiline={true}
                                        // value={orgAdd}
                                        // onChange={(e) => setOrgAdd(e.target.value)}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <label
                                        style={{
                                            fontSize: "16px",
                                            textAlign: "left",
                                            color: "black",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Industry
                                    </label>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        placeholder="e.g.delhi"
                                        multiline={true}
                                        // value={industry}
                                        // onChange={(e) => setIndustry(e.target.value)}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <label
                                        style={{
                                            fontSize: "16px",
                                            textAlign: "left",
                                            color: "black",
                                            fontWeight: "500",
                                        }}
                                    >
                                        No. of employees
                                    </label>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        value={apiData.employer.company.numEmployee}
                                    />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <label
                                        style={{
                                            fontSize: "16px",
                                            textAlign: "left",
                                            color: "black",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Organisation logo
                                    </label>
                                    <Avatar
                                        alt="Remy Sharp"
                                        style={{
                                            width: "100px",
                                            height: "100px",
                                            marginTop: "10px",
                                        }}
                                        src="https://i.pinimg.com/280x280_RS/79/dd/11/79dd11a9452a92a1accceec38a45e16a.jpg"
                                    />
                                    <Button
                                        component="label"
                                        variant="outlined"
                                        style={{ width: "200px", marginTop: "10px" }}
                                        startIcon={<CloudUploadIcon />}
                                    >
                                        Upload file
                                        <VisuallyHiddenInput type="file" />
                                    </Button>
                                </div>
                            </Container>
                            <Button
                                type="primary"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                // onClick={updateEmpDetail}
                            >
                                Save
                            </Button>
                        </TabPanel>
                    </TabContext>
                </Box>
            )}
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
                    This is a success message!
                </Alert>
            </Snackbar>
        </div>
    );
};

export default EmployerDetail;
