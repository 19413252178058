import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const addEducation = async (token, eduId, school, board, percentage, startYear, endYear, degree, stream, graduationType) => {

  console.log( eduId, school, board, percentage, startYear, endYear, degree, stream, graduationType);
  try {
    const response = await heyapi.post(
      `/student/education/add`,
      { eduId, school, board, percentage, startYear, endYear, degree, stream, graduationType },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return {success: false, msg: "Invalid reuest"}
  }
};


export const getEducation = async (id, token) => {

  console.log(id);
  try {
    const response = await heyapi.get(
      `/student/education/get?id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getEducationAll = async (token) => {
  try {
    const response = await heyapi.get(
      `/student/education/all`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteEducation = async (id, token) => {

  console.log(id);
  try {
    const response = await heyapi.delete(
      `/student/education/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
  }
};
