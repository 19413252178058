import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Typography,
  Chip,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import websiteSettings from "../common/siteSettings";

import CloseIcon from "@mui/icons-material/Close";
import Login from "../student/Login";

import { useLocation } from "react-router-dom";

const LoginPopup = ({ open, onClose, onSubmit, setLoginModalOpen }) => {
  const location = useLocation();

  const handleLoginModalClose = () => {
    setLoginModalOpen(false);
  };

  React.useEffect(() => {
    if (open) {
      onClose(); // Close the dialog when route changes
    }
  }, [location.pathname]); // Runs when the route changes


  return (
    <Dialog open={open} onClose={handleLoginModalClose} maxWidth="sm">
      <IconButton
        aria-label="close"
        variant=""
        className="mb-5"
        size="small"
        onClick={handleLoginModalClose}
        sx={{ position: "absolute", right: 8, top: 8 }}
      >
        <CloseIcon />
      </IconButton>
      <div className="login-modal-wrapper">
        <Login handleLoginModalClose={handleLoginModalClose} />
      </div>
      <DialogActions className="login-popup-footer">
        <Typography>
          New to {websiteSettings.name}? Register (
          <Link
            to={"/student/signup"}
            onClick={handleLoginModalClose}
            className="small-link"
          >
            Student
          </Link>{" "}
          /{" "}
          <Link
            to={"/employer/signup"}
            onClick={handleLoginModalClose}
            className="small-link"
          >
            Company
          </Link>
          )
        </Typography>
      </DialogActions>
    </Dialog>
  );
};

export default LoginPopup;
