import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const updateApplicationStateApi = async (token, appId, state) => {
  console.log(token, appId, state)
  try {
    const response = await heyapi.patch(
      `/employer/post/application/state`,
      {
        id: appId,
        state: state,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const allPostApplications = async (token, postId, state) => {
  console.log(token, postId)
  try {
    const response = await heyapi.get(
      state
        ? `/employer/post/applications?postid=${postId}&state=${state}`
        : `/employer/post/applications?postid=${postId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const allPostApplicationsStatus = async (token, postId) => {
  console.log(token, postId)
  try {
    const response = await heyapi.get(`/employer/post/applications-status?postid=${postId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const sendAssignment = async (token, postId, userids, content, deadline) => {
  console.log(token, postId)
  try {
    const response = await heyapi.post(`/employer/post/send-assignment`,
      {
        postId,
        userids,
        content,
        deadline
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getApplicant = async (token, appid) => {
  console.log(appid)
  try {
      const response = await heyapi.get(`/employer/applicant/${appid}`, {
          headers: {
              Authorization: `Bearer ${token}`,
          },
      });
      return response;
  }
  catch (err) {
      console.log(err);
  }
}

export const allPostAssignments = async (token, postId, state) => {
  console.log(token, postId)
  try {
    const response = await heyapi.get(`/employer/post/assignments?postid=${postId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getApplicantId = async (token, postId, userid) => {
  try {
    const response = await heyapi.get(`/employer/post/applicantid?postid=${postId}&studentid=${userid}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (err) {
    console.log(err);
  }
};
