import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Button, Chip } from "@mui/material";
import { useParams } from "react-router";
import { getApplicant } from "../../services/getAllApplicationEmployee";
import { useSelector } from "react-redux";
import { calculateDaysPassed, formatDate, toCamelCase } from "../../common/commonFunctions";
import { CustomAlert, showToastAlert } from "../../admin/components/CustomAlert";

const Applicant = ({ appid }) => {
  const [applicant, setApplicant] = React.useState(null);
  const [application, setApplication] = React.useState(null);
  const token = useSelector((state) => state.token);
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  React.useEffect(() => {
    if (appid) {
      getApplicantDeatils(appid);
    }
  }, [appid])

  const getApplicantDeatils = async (appid) => {
    try {
      const response = await getApplicant(token, appid);
      if (response.status === 200) {
        setApplicant(response.data.applicant.userId);
        setApplication(response.data.applicant);
      } else {
        showToastAlert(response.data.message, "error");
      }
    } catch (error) {
      console.log(error);
    }

  }

  return (
    <div className="application-details-page container py-3 custom-form">
      {applicant ? (
        <>
          <div className="inpuxbox-group">
            <div className="heading-box">
              <h2>
                <AccountCircleIcon /> {applicant.name}{" "}
              </h2>
              <p className="applied-text">Applied {calculateDaysPassed(application?.createdAt)} days ago</p>
              <p>
                {applicant.location}
                <Tooltip title="Includes both internship and job" arrow>
                  <InfoOutlinedIcon className="info-icon" />
                </Tooltip>
              </p>
            </div>
            <div className="ad-box">
              <div className="row">
                <div className="col-sm-3">
                  <Typography className="input-label1">
                    Portfolio/ Work samples
                  </Typography>
                </div>
                <div className="col-sm-9">
                  <div className="st-iconsbox">
                    {applicant?.samples?.github && ( 
                    <a
                      href={applicant?.samples?.github}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-github"></i>
                    </a>)}
                    {applicant?.linkedin && ( 
                    <a
                      href={applicant?.samples?.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>)}
                    {applicant?.samples?.bio && ( 
                    <a
                      href={applicant?.samples?.bio}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-solid fa-link"></i>
                    </a>)}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3">
                  <Typography className="input-label1">SKILL(S)</Typography>
                </div>
                <div className="col-sm-9">
                  <p>
                    {applicant?.skills?.map((skill) => {
                      return <Chip label={skill} />
                    })}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3">
                  <Typography className="input-label1">Language(S)</Typography>
                </div>
                <div className="col-sm-9">
                  <p>
                    {applicant?.languages.map((lang) => {
                      return <Chip label={lang} />
                    })}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="inpuxbox-group">
            <div className="heading-box">
              <h2>Resume</h2>
              <strong>{application?.coverLetter}</strong>
            </div>
            <div className="ad-box">
              <div className="row">
                <div className="col-sm-3">
                  <Typography className="input-label1">EDUCATION</Typography>
                </div>
                <div className="col-sm-9">
                  {applicant?.education?.map((edu) => {
                    return (<div>
                      <p>
                        <b>{edu.degree}</b> <br />
                        {edu.school} <br />
                        Completion Year: {edu.endYear}
                      </p>
                    </div>)
                  })}
                </div>
              </div>
              {/* Internships Section */}
              {applicant.experience.length > 0 &&
                <div className="row">
                  <div className="col-sm-3">
                    <Typography className="input-label1">INTERNSHIPS</Typography>
                  </div>
                  <div className="col-sm-9">
                    {applicant?.experience?.map((item) => {
                      return (
                        <p>
                          <b>{toCamelCase(item.profile)} At {item.company}</b> <br />
                          {formatDate(item.startDate)} - {formatDate(item.endDate)} <br />
                          {item.description}
                        </p>
                      )
                    })
                    }
                  </div>
                </div>
              }
              {/* Projects Section */}
              {applicant.projects.length > 0 &&
                <div className="row">
                  <div className="col-sm-3">
                    <Typography className="input-label1">PROJECTS</Typography>
                  </div>
                  <div className="col-sm-9">
                    {applicant?.projects.map((item) => {
                      return (
                        <div>
                          <p>
                            <b>{item.title}</b> <br />
                            {formatDate(item.startDate)} - {formatDate(item.endDate)} <br />
                            {item.link && <Link
                              href={item.link}
                              target="_blank"
                              rel="noopener"
                              className="highlight-link"
                            >
                              Project Link
                            </Link>}
                            {item.description}
                          </p>
                        </div>)
                    })}
                  </div>
                </div>}

              {/* Contact Section */}
              <div className="row">
                <div className="col-sm-3">
                  <Typography className="input-label1">CONTACT</Typography>
                </div>
                <div className="col-sm-9">
                  <p>
                    <b>Email:</b>{" "}
                    <span>
                      {applicant.email}
                    </span>
                    <br />
                    <b>Phone:</b> +91 *********
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : <Typography>Loading applicant details...</Typography>}
      <CustomAlert />
    </div>
  );
};

export default Applicant;
