import React, { useState, useEffect } from "react";

function OfflineIndicator() {
  const [isVisible, setIsVisible] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const [shouldRestart, setShouldRestart] = useState(false);

  useEffect(() => {
    const onlineStatusHandler = () => {
      if (!window.navigator.onLine) {
        // User is offline
        setIsVisible(true);
        // Start the initial countdown from 10 seconds
        let currentCountdown = 10;
        setCountdown(currentCountdown);

        // Update the countdown every second
        const countdownInterval = setInterval(() => {
          setCountdown((prevCountdown) => {
            if (prevCountdown > 0) {
              return prevCountdown - 1;
            } else if (!window.navigator.onLine) {
              // Countdown reached 0, but user is still offline, restart the countdown
              setCountdown(10);
            } else {
              // Countdown reached 0, hide the indicator
              setIsVisible(false);
              clearInterval(countdownInterval);

              // Check if the countdown should be restarted
              if (shouldRestart) {
                currentCountdown = 10;
                setIsVisible(true);
                setShouldRestart(false);

                // Restart the countdown every second
                const restartCountdownInterval = setInterval(() => {
                  setCountdown((prevCountdown) => {
                    if (prevCountdown > 0) {
                      return prevCountdown - 1;
                    } else {
                      // Countdown reached 0 again, hide the indicator
                      setIsVisible(false);
                      clearInterval(restartCountdownInterval);
                      return 0;
                    }
                  });
                }, 1000);
              }

              return 0;
            }
          });
        }, 1000);
      } else {
        // User is back online, show the online notification for 2 seconds
        setIsVisible(true);

        setTimeout(() => {
          setIsVisible(false);
        }, 2000);

        // Set shouldRestart to true to restart the countdown if the user goes offline again
        setShouldRestart(true);
      }
    };

    // Add online/offline event listeners
    window.addEventListener("online", onlineStatusHandler);
    window.addEventListener("offline", onlineStatusHandler);

    // Cleanup the event listeners on component unmount
    return () => {
      window.removeEventListener("online", onlineStatusHandler);
      window.removeEventListener("offline", onlineStatusHandler);
    };
  }, [shouldRestart]);

  return (
    <>
      {isVisible && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            top: "0%",
            left: window.navigator.onLine ? "40%" : "35%",
            transform: "translate(-50%, -50%)",
            zIndex: 6,
            backgroundColor: window.navigator.onLine ? "#AAF705" : "red",
            color: window.navigator.onLine ? "black" : "#fff",
            fontWeight: 500,
            fontSize: "18px",
            width: window.navigator.onLine ? "20%" : "30%",
            padding: "px",
            borderRadius: "5px",
            opacity: 0.9,
            animation: "slideIn 0.5s ease-out forwards",
            boxShadow: window.navigator.onLine
              ? `0 0 5px 5px #00FF00`
              : `0 0 5px 5px #FF0000`,
          }}
        >
          {window.navigator.onLine
            ? "Back Online"
            : `Offline - Retry in ${countdown}s`}
          <style>
            {`
              @keyframes slideIn {
                from {
                  transform: translateY(-50px);
                  opacity: 0;
                }
                to {
                  transform: translateY(0);
                  opacity: 0.9;
                }
              }
            `}
          </style>
        </div>
      )}
      {!window.navigator.onLine && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: " rgba(0,0,0,0),",
            zIndex: 4,
          }}
        ></div>
      )}
    </>
  );
}

export default OfflineIndicator;
