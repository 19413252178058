import React, { useState } from "react";
import { TextField, Autocomplete } from "@mui/material";
import { getAllCity } from "../../services/getAllCities";
import axios from "axios";

const CityAutoComplete = ({label, value, setCity}) => {
  const [allLocations, setAllLocations] = React.useState()

React.useEffect(() => {
    getPref();
}, []);

const getPref = async () => {
    try {
        const locs = await getAllCity();
        setAllLocations(locs.cities.map((item) => item.city));
        
    } catch (error) {
     console.error("Error fetching training data:", error);
    }
};

  return (
    <Autocomplete
      label={label}
      freeSolo
      fullWidth
      value={value}
      options={allLocations}
      onInputChange={(event, newInputValue) => {
        setCity(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" fullWidth />
      )}
    />
  );
};

export default CityAutoComplete;
