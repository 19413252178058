import React, { useState } from "react";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
//import Link from "@mui/material/Link";
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';
const YourComponent = ({ id, slug, handlePostView, handleCloseInternship, poststatus }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton
        aria-label="menu"
        aria-controls="menu"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right", // Adjust the horizontal alignment
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right", // Adjust the horizontal alignment
        }}
        style={{ marginTop: 10 }}
      >
        <List>
          <ListItem>
            <Link
              onClick={(e) => {
                e.preventDefault(); // Prevent the default link behavior
                handlePostView(id);
              }}
              style={{
                textDecoration: "none",
                padding: "5px",
                cursor: "pointer",
              }}
            >
              View Internship detail
            </Link>
          </ListItem>
          { (poststatus === "approved") && (<ListItem>
            <Link
              onClick={(e) => {
                e.preventDefault(); // Prevent the default link behavior
                handleCloseInternship(id);
              }}
              style={{
                textDecoration: "none",
                padding: "5px",
                cursor: "pointer",
              }}
            >
              Close Internship
            </Link>
          </ListItem> )}
          { (poststatus === "pending" || poststatus === "rejected" ) && (<ListItem>
            <Link
              onClick={(e) => {
                e.preventDefault(); // Prevent the default link behavior
                window.open(`/employer/jobs/edit/${slug}`, "_self"); // Open the link in a new tab
              }}
              style={{
                textDecoration: "none",
                padding: "5px",
                // color: "#04C53A",
              }}
            >
              Edit Post
            </Link>
          </ListItem>) }
        </List>
      </Popover>
    </div>
  );
};

export default YourComponent;
