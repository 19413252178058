import React from "react";

const FooterDashboard = () => {
  return (
    <div className="job-page-footer">
      <p>
        Have a question? mail us on <span style={{color:"#0980fe"}}> support@heyinterns.com </span >
      </p>
    </div>
  );
};

export default FooterDashboard;
