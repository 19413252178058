import React, { useEffect, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import { addPostEmployee, getAiResponsibilities } from "../services/addPostbyEmployee";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getAllSkill } from "../services/getAllSkill";
import { useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { getAllCity } from "../services/getAllCities";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import {
  getSinglePostDetail,
  getSinglePostDetailById,
} from "../services/getSinglePostDetail";
import {
  getArrForAutoComplete,
  toCamelCaseForFirstChar,
} from "../services/utilApis";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Checkbox,
  FormGroup,
  Button,
  Grid,
  Switch,
} from "@mui/material";
import { CustomAlert, showToastAlert } from "../admin/components/CustomAlert";
import Breadcrumb from "../components/Breadcrumb";
import { getAllPreference } from "../services/getAllPreferences";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

function Postjob() {
  const weblocation = useLocation();
  const navigate = useNavigate();
  const { slug } = useParams();
  const token = useSelector((state) => state.token);
  const [assessmentCount, setAssessmentCount] = useState(0);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [postId, setPostId] = useState("new");
  const [title, setTitle] = useState("");
  const [skills, setSkills] = useState([]);
  const [numOpening, setNumOpening] = useState("2");
  const [responsibility, setResponsibility] = useState("1.\n2.");
  const [stipend, setStipend] = useState("5000");
  const [globalLocations, setGlobalLocations] = useState("");
  const [locations, setLocations] = useState([]);
  const [error, setError] = useState(false);
  const [allSkill, setAllSkill] = React.useState();
  const [allPrefs, setAllPrefs] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEll, setAnchorEll] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("2");
  const [workType, setWorkType] = useState("office");
  const [stipendType, setStipendType] = useState("fixed");
  const [ppo, setPpo] = useState(false);
  const [mobile, setMobile] = useState("");
  const [questions, setQuestions] = useState([]);

  const [perksState, setPerksState] = useState({
    certificate: false,
    lor: false,
    flexi: false,
    fivedays: false,
  });

  const [validationErrors, setValidationErrors] = useState({});
  const handleGenerateWithAI = async () => {
    if (title === "") {
      showToastAlert("Set title from drop down menu", "error");
      return;
    }
    const response = await getAiResponsibilities(title, token);
    if (response.status !== 200) {
      showToastAlert("Error in fetching suggestions", "error");
      return;
    }
    setResponsibility(response.data.items.join("\n"));
  }

  const validateFields = () => {
    const errors = {};
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time for accurate date comparison
    if (!title.trim()) errors.title = "Title is required.";
    if (title.length > 100) errors.title = "Title should not exceed 100 characters.";
    if (!skills.length) errors.skills = "At least one skill is required.";
    if (skills.length > 6) errors.skills = "Maximum 6 skill are allowed.";
    if ((workType === "office") && (!locations.length)) errors.locations = "At least one location is required.";
    if (!responsibility.trim()) errors.responsibility = "Responsibilities are required.";
    if (responsibility.length > 800) errors.responsibility = "Responsibilities should not exceed 800 characters..";
    if (isNaN(numOpening)) errors.numOpening = "Valid number of openings is required.";
    if (isNaN(selectedMonth)) errors.selectedMonth = "Valid number of duration is required.";
    if (!selectedDate) {
      errors.selectedDate = "Start date is required.";
    } else {
      const startDate = new Date(selectedDate);
      const maxDate = new Date();
      maxDate.setMonth(maxDate.getMonth() + 2); // 2 months from today

      if (startDate < today) {
        errors.selectedDate = "Start date cannot be in the past.";
      } else if (startDate > maxDate) {
        errors.selectedDate = "Start date cannot be more than 2 months from today.";
      }
    }
    if (isNaN(stipend)) errors.stipend = "Valid stipend amount is required.";
    if (stipend < 1000) errors.stipend = "Stipend must be greater than 1000.";

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const updateQuestion = (event) => {
    let qIdName = event.target.id;
    if (qIdName == undefined) return;
    let qIndex = qIdName.split("-")[1];
    let tQuestions = [...questions];
    tQuestions[qIndex] = event.target.value;
    setQuestions(tQuestions);
  };
  const deleteQuestion = (event) => {
    let qIdName = event.target.id;
    if (qIdName == undefined) return;
    let qIndex = qIdName.split("-")[1];
    let tQuestions = [...questions];
    tQuestions.splice(qIndex, 1);
    setQuestions(tQuestions);
    setAssessmentCount(assessmentCount - 1);
  };
  const handleChange1 = (event) => {
    setPerksState({ ...perksState, [event.target.name]: event.target.checked });
  };

  function convertDateFormatUsingMoment(dateString) {
    const date = moment(dateString);
    setSelectedDate(date.format("MM/DD/YYYY"));
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClicked = (event) => {
    setAnchorEll(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClosed = () => {
    setAnchorEll(null);
  };
  const handleMonthSelect = (month) => {
    setSelectedMonth(month);
    handleClose();
  };
  const handleOpeningSelect = (opening) => {
    setNumOpening(opening);
    handleClosed();
  };

  const handleWorkType = (event) => {
    if (event.target.value === "remote") {
      // Clear the locations if the work type is remote
      setLocations([]);
    }
    setWorkType(event.target.value);
  };

  const handleStipendType = (event) => {
    setStipendType(event.target.value);
  };

  const postInternship = async () => {
    if (!validateFields()) {
      showToastAlert("Please fix validation errors. Fill all required fields", "error");
      return;
    }
    try {
      const response = await addPostEmployee(
        postId,
        title,
        skills,
        locations,
        workType,
        numOpening,
        moment(selectedDate).format("MM/DD/YYYY"),
        selectedMonth, //num of months
        responsibility,
        stipendType,
        stipend,
        perksState, //perks
        ppo,
        questions,
        mobile,
        token
      );
      if (response.status === 200) {
        showToastAlert("Post added successfully", "success");
        handleScrollToTop();
        setError(false);
        navigate('/employer/jobs/success');
        //TODO: send mail also
        //redirect to success message and under review page
      } else {
        showToastAlert(response.data.message, "error");
        return;
      }
    } catch (err) {
      console.error("Error adding post:", error.message);
      // You can set the error state in your component if needed
      setError(true);
      showToastAlert("All field are required", "error");
      handleScrollToTop();
    }
  };
  useEffect(() => {
    console.log("slug:" + slug);
    getProf(slug);
    setLoader(false);
  }, [slug]);

  const getProf = async (slug) => {
    console.log("called getProf");
    try {
      const allSkill = await getAllSkill();
      const allLocatins = await getAllCity();
      const allPrefsArr = await getAllPreference();
      const allPrefs = allPrefsArr?.preferences?.map((item) => item.name);

      console.log("allLocatins", allLocatins);
      setGlobalLocations(allLocatins.cities);
      setAllSkill(allSkill.skills);
      setAllPrefs(allPrefs);

      if (slug) {
        //edit form
        const response = await getSinglePostDetail(slug, token);
        if (response.status !== 200) {
          showToastAlert(response.data.message, "error");
          return;
        }
        const postData = response.data;
        if (postData) {
          setTitle(postData.post.title);
          setPostId(postData.post._id);
          setNumOpening(postData.post.numOpening);
          setResponsibility(postData.post.responsibility);
          setStipend(postData.post.stipend);
          setPpo(postData.post.ppo);
          setSelectedMonth(postData.post.duration);
          setWorkType(postData.post.workModel);
          setSelectedDate(new Date(postData.post.startDate));
          setPpo(postData.post.ppo);
          setPerksState(postData.post.perks);
          setQuestions(postData.post.questions);
          setAssessmentCount(postData.post.questions.length);
          const skillsArr = getArrForAutoComplete(postData.post.skills);
          setSkills(skillsArr);
          let iLocs = getArrForAutoComplete(postData.post.locations, "city");

          console.log("iLocs:" + iLocs);
          setLocations(iLocs);
        } else {
          showToastAlert("Invalid post", "error");
          return navigate("/404page");
        }
      } else {
        //new form
      }
      setLoader(false);
    } catch (error) {
      console.error("Error fetching training data:", error);
      setLoader(false);
    }
  };

  // console.log("global", globalLocations.splice(1,6));
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const internshipTitle =
    postId === "new" ? "Post internship" : "Edit internship";

  const handleBreadcrumbClick = (event) => {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  };

  const breadcrumbsData = [
    {
      label: "Dashboard",
      href: "/employer/dashboard",
    },

    { label: internshipTitle, href: null }, // Last breadcrumb (no link)
  ];

  return (
    <div>
      {loader ? null : (
        <div>
          <div className="custom-form container py-3">
            <CustomAlert />

            <Breadcrumb
              breadcrumbs={breadcrumbsData}
              heading={internshipTitle}
            />

            <Box className="inpuxbox-group">
              <div className="heading-box">
                <h2>Internship Details</h2>
                <p>Hire early talent with work experience up to 2 years</p>
              </div>

              {/* Internship Profile */}
              <div className="row align-items-center">
                <div className="col-sm-3">
                  <Typography className="input-label1">
                    Internship Profile
                  </Typography>
                </div>
                <div className="col-sm-9">
                  <Autocomplete
                    freeSolo
                    disableClearable
                    options={allPrefs.map((option) => option)}
                    value={title}
                    onChange={(event, newValue) => {
                      setTitle(toCamelCaseForFirstChar(newValue));
                    }}
                    onInputChange={(event, newValue) => {
                      if (event !== null && newValue.trim() !== "") {
                        setTitle(toCamelCaseForFirstChar(newValue)); // Capture custom input
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        placeholder="e.g. Android App Development.."
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                        error={!!validationErrors.title}
                        helperText={validationErrors.title}
                      />
                    )}
                  />
                </div>
              </div>

              {/* Internship Type */}
              <div className="row mt-4">
                <div className="col-sm-3">
                  <Typography className="input-label1">
                    Internship Type:
                  </Typography>
                </div>
                <div className="col-sm-9">
                  <RadioGroup
                    aria-labelledby="worktype-radio-buttons-group-label"
                    name="worktype-buttons-group"
                    value={workType}
                    onChange={handleWorkType}
                  >
                    <FormGroup row>
                      <FormControlLabel
                        value="office"
                        control={<Radio />}
                        label="In-office"
                      />
                      <FormControlLabel
                        value="remote"
                        control={<Radio />}
                        label="Remote"
                      />
                    </FormGroup>
                  </RadioGroup>
                </div>
              </div>

              {/* Skills Required */}
              <div className="row mt-4">
                <div className="col-sm-3">
                  <Typography className="input-label1">
                    Skills Required{" "}
                    <small className="small-txt">(optional)</small>
                  </Typography>
                </div>
                <div className="col-sm-9">
                  <FormGroup row>
                    <Autocomplete
                      size="small"
                      multiple
                      id="tags-skills"
                      style={{ width: "100%" }}
                      options={allSkill || ""}
                      value={skills}
                      getOptionLabel={(option) => option.name}
                      filterSelectedOptions
                      onChange={(event, selectedOption) =>
                        setSkills(selectedOption)
                      }
                      filterOptions={(options, { inputValue }) =>
                        options.filter((option) =>
                          option.name
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        )
                      }
                      renderInput={(params) => (
                        <TextField {...params} placeholder="e.g. Marketing"
                          error={!!validationErrors.skills}
                          helperText={validationErrors.skills}
                        />
                      )}
                    />
                  </FormGroup>
                </div>
              </div>

              {/* Locations */}
              <div className="row mt-4">
                <div className="col-sm-3">
                  <Typography className="input-label1">Locations</Typography>
                </div>
                <div className="col-sm-9">
                  <FormGroup row>
                    <Autocomplete
                      size="small"
                      multiple
                      fullWidth
                      id="tags-locations"
                      options={globalLocations || ""}
                      value={locations}
                      disabled={workType === "remote"}
                      getOptionLabel={(option) => option.city}
                      filterSelectedOptions
                      onChange={(event, selectedOption) =>
                        setLocations(selectedOption)
                      }
                      filterOptions={(options, { inputValue }) =>
                        options.filter((option) =>
                          option.city
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        )
                      }
                      renderInput={(params) => (
                        <TextField {...params} placeholder="e.g. Locations"
                          error={!!validationErrors.locations}
                          helperText={validationErrors.locations}
                        />
                      )}
                    />
                  </FormGroup>
                </div>
              </div>

              {/* Internship Responsibilities */}
              <div className="row mt-4">
                <div className="col-sm-3">
                  <Typography className="input-label1">
                    Internship Responsibilities
                  </Typography>
                </div>
                <div className="col-sm-9">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={
                      <TipsAndUpdatesIcon
                        sx={{
                          animation: "blink 1s infinite"
                        }}
                      />
                    }
                    sx={{
                      marginBottom: 1,
                      "@keyframes blink": {
                        "0%": { opacity: 1 },
                        "50%": { opacity: 0 },
                        "100%": { opacity: 1 }
                      }
                    }}
                    onClick={handleGenerateWithAI}
                  >
                    Generate with AI
                  </Button>

                  <TextField
                    multiline
                    rows={10}
                    fullWidth
                    variant="outlined"
                    placeholder="e.g. React, Node etc."
                    inputProps={{ maxLength: 500 }} // Limits input to 500 characters
                    value={responsibility}
                    onChange={(e) => setResponsibility(e.target.value)}
                    error={!!validationErrors.responsibility}
                    helperText={validationErrors.responsibility}
                  />
                </div>
              </div>

              {/* Number of Openings, Start Date, Duration */}
              <div className="row mt-4">
                <div className="col-sm-4">
                  <Typography className="input-label1">
                    Number of Openings
                  </Typography>
                  <TextField
                    required
                    fullWidth
                    size="small"
                    value={numOpening}
                    onClick={handleClicked}
                    error={!!validationErrors.numOpening}
                    helperText={validationErrors.numOpening}
                  />
                  <Menu
                    anchorEl={anchorEll}
                    open={Boolean(anchorEll)}
                    onClose={handleClosed}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                    PaperProps={{ style: { width: "400px" } }}
                  >
                    {[...Array(12)].map((_, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => handleOpeningSelect(index + 1)}
                      >
                        {index + 1}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
                <div className="col-sm-4">
                  <Typography className="input-label1">
                    Internship Start Date
                  </Typography>
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="dd/MM/yyyy"
                    customInput={
                      <TextField placeholder="Start" size="small" fullWidth
                        error={!!validationErrors.selectedDate}
                        helperText={validationErrors.selectedDate}
                      />
                    }
                  />
                </div>
                <div className="col-sm-4">
                  <Typography className="input-label1">
                    Internship Duration{" "}
                    <small className="small-txt">(Months)</small>
                  </Typography>
                  <TextField
                    value={selectedMonth}
                    onClick={handleClick}
                    variant="outlined"
                    fullWidth
                    size="small"
                    error={!!validationErrors.selectedMonth}
                    helperText={validationErrors.selectedMonth}
                  />
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                    PaperProps={{ style: { width: "570px" } }}
                  >
                    {[...Array(12)].map((_, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => handleMonthSelect(index + 1)}
                      >
                        {index + 1}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </div>
            </Box>

            <Box className="inpuxbox-group">
              <div className="heading-box">
                <h2>Stipend & perks</h2>
                <p>Hire early talent with work experience up to 2 year</p>
              </div>
              <div className="row">
                <div className="col-sm-3">
                  <Typography className="input-label1">Stipend</Typography>
                </div>
                <div className="col-sm-9">
                  <RadioGroup
                    aria-labelledby="stipend-radio-buttons-group-label"
                    defaultValue="fixed"
                    name="radio-buttons-group"
                    onChange={handleStipendType}
                    row
                  >
                    <FormControlLabel
                      value="fixed"
                      control={<Radio />}
                      label="Fixed"
                    />
                    <FormControlLabel
                      value="performance"
                      control={<Radio />}
                      label="Performance based"
                    />
                  </RadioGroup>
                </div>
              </div>

              <div className="row mt-4">
                {/* Stipend Amount Input Field */}
                <div className="col-sm-3">
                  <Typography className="input-label1">Amount</Typography>
                </div>
                <div className="col-sm-9">
                  <TextField
                    required
                    fullWidth
                    placeholder="e.g. 10000 (only digit)"
                    value={stipend}
                    onChange={(e) => {
                      const onlyDigits = e.target.value.replace(/\D/g, "");
                      setStipend(onlyDigits);
                    }}
                    inputProps={{ pattern: "[0-9]*" }} // Only allow digits
                    size="small"
                    error={!!validationErrors.stipend}
                    helperText={validationErrors.stipend}
                  />
                </div>
              </div>

              <div className="row mt-4">
                {/* Perks Section */}
                <div className="col-sm-3">
                  <Typography className="input-label1">
                    Perks (optional)
                  </Typography>
                </div>
                <div className="col-sm-9">
                  <Box row>
                    <FormControlLabel
                      label="Certificate"
                      control={
                        <Checkbox
                          checked={perksState.certificate}
                          onChange={handleChange1}
                          name="certificate"
                        />
                      }
                    />
                    <FormControlLabel
                      label="Letter of recommendation"
                      control={
                        <Checkbox
                          checked={perksState.lor}
                          onChange={handleChange1}
                          name="lor"
                        />
                      }
                    />
                    <FormControlLabel
                      label="Flexible work hours"
                      control={
                        <Checkbox
                          checked={perksState.flexi}
                          onChange={handleChange1}
                          name="flexi"
                        />
                      }
                    />
                    <FormControlLabel
                      label="5 Days a week"
                      control={
                        <Checkbox
                          checked={perksState.fivedays}
                          onChange={handleChange1}
                          name="fivedays"
                        />
                      }
                    />
                  </Box>
                </div>
              </div>

              <div className="row mt-4">
                {/* PPO Switch Section */}
                <div className="col-sm-3">
                  <Typography className="input-label1">
                    Pre-placement offer (PPO)?
                  </Typography>
                </div>
                <div className="col-sm-9">
                  <Switch checked={ppo} onChange={() => setPpo(!ppo)} />
                </div>
              </div>
            </Box>

            <Box className="inpuxbox-group">
              <div className="heading-box">
                <h2>Opportunity type</h2>
                <p>Hire early talent with work experience up to 2 year</p>
              </div>
              <p className="small mb-3">
                Cover letter & availabality question will be asked to every
                applicant by default . If you wish, you may ask two more
                customized as an assessment.
              </p>
              {[...Array(assessmentCount)].map((_, index) => (
                <div key={index} className="assessment-headerbox">
                  <div className="assesment-top">
                    <label className="assesment-label">
                      Assessment Question {index + 1}
                    </label>

                    <Button type="text" color="error" onClick={deleteQuestion}>
                      Delete <RemoveIcon size="small" />
                    </Button>
                  </div>

                  <TextField
                    required
                    multiline
                    rows={2}
                    size="small"
                    fullWidth
                    className="custom-textfield"
                    inputProps={{ maxLength: 100 }} // Limits input to 100 characters
                    id={`question-${index}`}
                    placeholder="Type your question here"
                    onChange={updateQuestion}
                    value={questions[index]}
                  />
                </div>
              ))}
              <Button
                variant="outlined"
                color="info"
                onClick={() => setAssessmentCount(assessmentCount + 1)}
              >
                <AddIcon /> Add Assesment
              </Button>
            </Box>
            <div>
              <Button
                fullWidth
                onClick={postInternship}
                variant="contained"
                size="large"
                className="my-3"
              >
                Post Intership
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Postjob;
