import React from "react";
import check from "../assets/images/check.png";

const PopUp = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
          alignContent:"center",
          alignItems:"center",
        backgroundColor: "#c9fec9",
        fontWeight: 500,
        fontSize: "18px",
        width: "200px",
        padding:"10px",
        borderRadius: "5px",
        justifyContent: "center",
        opacity: 0,
        animation: "slideIn 0.5s ease-out forwards",
      }}
    >
        <img src={check} alt="" style={{marginRight:"10px"}} height={'20px'} width={'20px'} />
    Success 

      {/* Keyframes for the slideIn animation */}
      <style>
        {`
          @keyframes slideIn {
            from {
              transform: translateY(-50px);
              opacity: 0;
            }
            to {
              transform: translateY(0);
              opacity: 1;
            }
          }
        `}
      </style>
    </div>
  );
};

export default PopUp;
