import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Chip, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import io from "socket.io-client"; // Import socket.io-client
import {
  CustomAlert,
  showToastAlert,
} from "../../admin/components/CustomAlert";
import { baseUrl } from "../../common/siteSettings";
import { loadRoomData } from "../../services/studentChat";
import ChatFooter2 from "./ChatFooter2";
import ChatSideBar2 from "./ChatSideBar2";
import ChatMsgReceived from "./msg/ChatMsgReceived";
import ChatMsgSent from "./msg/ChatMsgSent";
import { useRef } from "react";
import MainHeader from "../../components/MainHeader";
const socket = io(baseUrl, {
  reconnection: true, // Enable auto-reconnection
  reconnectionAttempts: 5, // Number of reconnection attempts
  reconnectionDelay: 1000, // Time before trying to reconnect
  pingTimeout: 60000, // Timeout for receiving pings (60 seconds)
  pingInterval: 25000, // Interval to send pings (25 seconds)
});

const MainChatLayout = () => {
  const [selectedUser, setSelectedUser] = useState(null); //employer which will receive mesage
  const [curPost, setCurPost] = useState(null); //post of room
  const { roomid } = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [newMessage, setNewMessage] = React.useState("");
  const [selectedPostId, setSelectedPostId] = React.useState(null);
  const [selectedRoomId, setSelectedRoomId] = React.useState(null);
  const [messages, setMessages] = React.useState([]);
  const [roomStatus, setRoomStatus] = React.useState("active");
  const token = useSelector((state) => state.token);
  const userid = useSelector((state) => state.id);
  ////console.log("studentid:" + userid);

  const open = Boolean(anchorEl);
  ////console.log("roomid:" + roomid);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const loadRoom = async (roomid) => {
    const response = await loadRoomData(token, roomid);
    if (response.status !== 200) {
      showToastAlert(response.data.msg, "error");
      return;
    }
    const data = response.data;
    setMessages(data?.messages);
    setSelectedUser(data?.chatroom?.empId);
    setCurPost(data?.chatroom?.postId);
    setRoomStatus(data?.chatroom?.status);
  };

  const handleReportEmployer = async () => {
    //TODO report
  };

  const sendAnswer = async (text, link) => {
    onSendMessage(text, link, "answer");
  };

  const onSendMessage = (msg, mlink, mtype = "chat") => {
    if (msg.trim() === "") return; // Don't send empty messages
    if (roomid === undefined) {
      console.log("invalid onSendMessage: roomid:" + roomid);
    }

    const messageId = new Date().getUTCMilliseconds(); // Generate a unique ID for the message //TODO: check runtime of each api
    const messageTime = new Date().toISOString(); // Store the current time in ISO format

    const messageData = {
      messageId: messageId,
      chatroomId: roomid,
      sender: userid,
      content: msg,
      type: mtype,
      createdAt: messageTime, // Add the current time as createdAt
    };
    if (mlink) {
      messageData.link = mlink; //TODO: sanitise link
    }

    // Send the message to the server
    socket.emit("SEND_MSG", messageData);

    // Update the local state to include the new message
    setMessages((prevMessages) => [...prevMessages, messageData]);
  };

  // Add a ref for scrolling
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Call scrollToBottom when messages change
  React.useEffect(() => {
    scrollToBottom();
  }, [messages]);

  React.useEffect(() => {
    if (roomid) {
      setSelectedRoomId(roomid);
      loadRoom(roomid);
    }
  }, [roomid]);

  React.useEffect(() => {
    //console.log("room is to join:" + roomid);
    if (roomid !== undefined) {
      // Join the room
      //console.log("\t room join n:" + roomid);

      socket.emit("JOIN_ROOM", { chatroomId: roomid });

      // Listen for messages in the room
      socket.on("MESSAGES", (data) => {
        setMessages(data.messages);
      });

      // Handle new incoming messages
      socket.on("RECE_MSG", (message) => {
        //console.log("REC_MSF:" + JSON.stringify(message));
        setMessages((prevMessages) => [...prevMessages, message]);
      });

      // Handle socket disconnect and attempt reconnection
      socket.on("disconnect", () => {
        //console.log("Socket disconnected. Attempting to reconnect...");
      });

      socket.on("reconnect_attempt", (attempt) => {
        //console.log(`Reconnect attempt #${attempt}`);
      });

      socket.on("reconnect", () => {
        //console.log("Reconnected to the server.");
        socket.emit("JOIN_ROOM", { chatroomId: roomid }); // Rejoin the room on reconnect
      });

      socket.on("reconnect_failed", () => {
        //console.log("Reconnection failed.");
      });

      return () => {
        // Leave the room on component unmount
        if (roomid !== undefined) {
          socket.emit("LEAVE_ROOM", { chatroomId: roomid });
          socket.off("RECE_MSG");
          socket.off("reconnect");
          socket.off("reconnect_attempt");
          socket.off("reconnect_failed");
        }
      };
    }
  }, [roomid]);

  return (
    <div className="chat-outer-box">
      <MainHeader />
      <div className="chat-window student-chat-box     ">
        <div className="chat-leftbar">
          <ChatSideBar2 selectedUser={selectedUser} />
        </div>
        <div className="chat-rightbar">
          <div className="chat-message-box">
            <div className="chat-header">
              <div className="chat-header-user">
                <figure className="avatar">
                  <img
                    src="https://images.pexels.com/photos/2379005/pexels-photo-2379005.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                    className="rounded-circle"
                    alt="user pic"
                  />
                </figure>
                <div>
                  <h5>{selectedUser?.company.name}</h5>
                </div>
              </div>
              {selectedUser && (
                <div className="chat-header-action">
                  <p className="me-2 mb-0"> {curPost?.title} </p>
                  <Chip
                    label={roomStatus}
                    color={
                      roomStatus === "active"
                        ? "success"
                        : roomStatus === "blocked"
                        ? "error"
                        : "default"
                    }
                  />
                  <div>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      slotProps={{
                        paper: {
                          style: {
                            width: "20ch",
                          },
                        },
                      }}
                    >
                      <MenuItem onClick={handleReportEmployer}>
                        Report Employer
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              )}
            </div>
            <div className="chat-body">
              {messages &&
                messages.map((chat, index) => (
                  <>
                    {chat.sender !== userid && (
                      <ChatMsgReceived
                        item={chat}
                        onSendMessage={onSendMessage}
                        sendAnswer={sendAnswer}
                      />
                    )}
                    {chat.sender === userid && <ChatMsgSent item={chat} />}
                  </>
                ))}
              {/* Invisible div to scroll to last message */}
              <div ref={messagesEndRef} />
            </div>
            {selectedUser && roomStatus === "active" && (
              <ChatFooter2 onSendMessage={onSendMessage} />
            )}
            <CustomAlert />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainChatLayout;
