import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
//Note: asyncstorage will not able to persist state data on page full reload using window.location.href
//import AsyncStorage from '@react-native-async-storage/async-storage';
import {thunk} from 'redux-thunk';
import rootReducer from './Reducer';
//import { verifyStateMiddleware } from '../middleware/verifyState';

const persistConfig = {
  key: 'root',
  storage: storage, // AsyncStorage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, applyMiddleware(thunk),);
//export const store = createStore(persistedReducer, applyMiddleware(thunk, verifyStateMiddleware),);
export const persistor = persistStore(store);
