import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Select from '@material-ui/core/Select';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useNavigate } from "react-router";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux"
import Button from "@mui/material/Button"
import InputBase from '@material-ui/core/InputBase';
import { getAllEmployer } from "../services/getAllEmployerForAdminDashboard";
import moment from "moment";
import LinkIcon from '@mui/icons-material/Link';
import { UpdateEmployerState } from "../services/updateEmployerState";
export default function EmployerInformation() {
    const navigate = useNavigate();

    const [apiData, setApiData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showOptions, setShowOptions] = useState([]);
    const admin = useSelector((state) => state.admin);
    useEffect(() => {
        getEmployerDetail();
    }, admin)
    const handleOptionChange = (e, rowIndex) => {
        const { value } = e.target;
        setShowOptions(prevState => ({
            ...prevState,
            [rowIndex]: e.target.value
        }));
    };
    const handleOptionUpdate = async (rowIndex) => {
        try {
            const empState = showOptions[rowIndex];
            const empId = apiData.employers[rowIndex]._id;
            const data = await UpdateEmployerState(admin.token, empId, empState)
        }
        catch (err) {
            throw new Error(err)
        }
    }
    const getEmployerDetail = async () => {
        try {
            if (admin) {
                const data = await getAllEmployer(admin.token);
                setApiData(data)
                //set default option value
                let tempStatusArr = [];
                data.employers.map((item, rowIndex) => {
                    tempStatusArr.push(item.status);
                })
                setShowOptions(tempStatusArr);
                setLoader(false)
            }
        }
        catch (err) {
            throw new Error(err)
        }
    }

    const employerStatusOptions = ["approved", "pending", "rejected", "disable"];
    return (
        <div>
            {loader ? null : (
                <div>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ maxWidth: "100vw", minWidth: "60vw" }}
                        aria-label="simple table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontSize: "18px", fontWeight: "500" }}
                                    align="center">
                                    NAME
                                </TableCell>
                                <TableCell
                                    style={{ fontSize: "18px", fontWeight: "500" }}
                                    align="center"
                                >
                                    MOBILE NUMBER
                                </TableCell>
                                <TableCell
                                    style={{ fontSize: "18px", fontWeight: "500" }}
                                    align="center"
                                >
                                    EMAIL
                                </TableCell>
                                <TableCell
                                    style={{ fontSize: "18px", fontWeight: "500" }}
                                    align="center"
                                >
                                    ENROLLED DATE
                                </TableCell>
                                <TableCell
                                    style={{ fontSize: "18px", fontWeight: "500" }}
                                    align="center"
                                >
                                    STATUS
                                </TableCell>
                                <TableCell
                                    style={{ fontSize: "18px", fontWeight: "500" }}
                                    align="center"
                                >
                                    ALL POST
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {apiData.employers.map((item, rowIndex) => (
                                <TableRow
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                // onClick={() => navigate("/dashboard/application")}
                                >
                                    <TableCell
                                        style={{ fontSize: "18px", fontWeight: "500", cursor: "pointer" }}
                                        component="th"
                                        scope="row"
                                        onClick={() =>
                                            navigate(`/admin/employerdetail/${item._id}`, {
                                                state: { id: item._id },
                                            })
                                        }

                                    >
                                        {item.name}
                                        <LinkIcon style={{ color: '#04C53A', cursor: "pointer", marginLeft: 5 }} />
                                    </TableCell>
                                    <TableCell
                                        style={{ fontSize: "18px", fontWeight: "500" }}
                                        align="center"
                                    >
                                        {item.mobile}
                                    </TableCell>
                                    <TableCell
                                        style={{ fontSize: "18px", fontWeight: "500" }}
                                        align="center"
                                    >
                                        {item.email}
                                    </TableCell>
                                    <TableCell
                                        style={{ fontSize: "18px", fontWeight: "500" }}
                                        align="center"
                                    >
                                        {moment(item.createdAt).format('DD/MM/YYYY')}
                                    </TableCell>
                                    <TableCell
                                        style={{ fontSize: "18px", fontWeight: "500" }}
                                        align="right"
                                    >
                                        <select
                                            style={{ outline: "none", border: "none", color: '#04C53A', backgroundColor: "#ebfeeb", fontSize: 18, cursor: "pointer" }} 
                                            onChange={(event) => handleOptionChange(event, rowIndex)}
                                            value={showOptions[rowIndex]}>
                                            {employerStatusOptions.map((item) => {
                                                        return <option value={item}>{item}</option>
                                                })
                                            }
                                        </select>
                                        <Button sx={{ backgroundColor: "#ebfeeb", color: "#04C53A", marginLeft:"10px" }} onClick={ () => handleOptionUpdate(rowIndex)}>
                                            Update
                                        </Button>
                                    </TableCell>
                                    <TableCell
                                        style={{ fontSize: "18px", fontWeight: "500" }}
                                        align="center"
                                        onClick={() =>
                                            navigate(`/admin/employerpost/${item._id}`, {
                                                state: { id: item._id },
                                            })
                                        }
                                    >
                                        <RemoveRedEyeIcon style={{ color: '#04C53A', cursor: "pointer" }} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </div>
            )}
        </div>
    );
}
