import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { grey } from "@mui/material/colors";
import { useSelector } from "react-redux";
import {
    TableBody,
    TableRow,
    TableCell,
    FormGroup,
    TextField,
    Container,tableCellClasses
  } from "@mui/material";
import { addAdditional } from '../services/postAdditionalDetails';
import PopUp from '../components/PopUp';
import { deleteAdditonalDetails } from '../services/deleteAdditonalDetails';
import { editAdditonal } from '../services/editAdditonal';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddPersonalDetails({ getTraining, personalDetails }) {
   
  const [open, setOpen] = React.useState(false);
  const [details, setDetails] = React.useState("");
  const [editMode, setEditMode] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [additionalId, setAdditionalId] = React.useState();
  const token = useSelector((state) => state.token);
  const handleClickOpen = (additionalData) => {
    setOpen(true);
    console.log(additionalData)
    if (additionalData) {
      setEditMode(true);
      setAdditionalId(additionalData._id)
      setDetails(additionalData.details);
    } else {
      // Initialize form fields if no training data is provided
      setEditMode(false);
      initializeForm();
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
const initializeForm = () => {
   setDetails("")
};
  const postAdditionalDetails = async () => {
    try {
       if (!editMode) {
         const data = await addAdditional(token, details);
         getTraining();
         if (data.sucess) {
           setSuccess(true);
           setTimeout(() => {
             setSuccess(false);
           }, 2000);
         }
       } else {
         const data = await editAdditonal(
           additionalId,details,token
         );
         getTraining();
         if (data.sucess) {
           setSuccess(true);
           setTimeout(() => {
             setSuccess(false);
           }, 2000);
         }
       }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteAdditonal = async (id, token) => {
    try {
      const data = await deleteAdditonalDetails(id, token);
      getTraining();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <TableBody>
      <TableRow
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
      >
        <TableCell style={{ fontSize: "12px" }}>
          ACCOMPLISHMENTS/ ADDITIONAL DETAILS
        </TableCell>
        {personalDetails.additionals.map((addtional, index) => (
          <TableRow key={index}>
            <TableCell
              style={{
                padding: "8px 20px 16px",
                borderBottom: "none",
                width: "20vw",
              }}
            >
              <p style={{ fontSize: "14px", fontWeight: "500" }}>
                {addtional.details}
              </p>
            </TableCell>
            <TableCell>
              <IconButton edge="end" aria-label="edit">
                <EditIcon onClick={() => handleClickOpen(addtional)} />
              </IconButton>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <IconButton edge="end" aria-label="delete">
                <DeleteIcon
                  onClick={() => deleteAdditonal(addtional._id, token)}
                />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell>
            <Button
              onClick={() => {
                handleClickOpen();
                initializeForm();
              }}
              style={{ padding: "0px" }}
            >
              <AddIcon style={{ fontSize: "14px" }} color="primary" /> &nbsp;
              <span style={{ fontSize: "12px" }}>Add Additonal Details</span>
            </Button>
          </TableCell>
        </TableRow>
      </TableRow>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Additional details
        </BootstrapDialogTitle>
        {success && (
          <center>
            <PopUp />
          </center>
        )}
        <DialogContent dividers>
          <FormGroup sx={{ alignItems: "flex-start", marginBottom: "10px" }}>
            <Typography>
              Additional details Add your accomplishments such as rewards,
              recognitions, test scores, certifications, etc. here. You may also
              add information such as seminars/workshops you have attended or
              any interests/hobbies you have pursued.
            </Typography>
            <TextField
              size="small"
              multiline
              rows={3}
              fullWidth
              label="write somthing about you done"
              id="fullWidth"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              inputProps={{ maxLength: 300 }}
            />
          </FormGroup>
        </DialogContent>

        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              postAdditionalDetails();
            }}
          >
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </TableBody>
  );
}