/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import WebsiteBreadCrumb from "./website-components/WebsiteBreadCrumb";
import siteSettings from "../common/siteSettings";
import { Helmet } from "react-helmet-async";

const About = () => {
  return (
    <div>
      <Helmet>
        <title>HeyInterns | Contact Us </title>
      </Helmet>
      <WebsiteBreadCrumb title="Contact Us" />
      <div className="policy-page">
        <div className="container ">
          <div className="heading-box">
            <h2>Connect with Heyinterns</h2>
            <p>
              At HeyInterns, we are committed to fostering a trusted and
              reliable ecosystem for companies and interns alike. This policy
              outlines the guidelines and expectations for companies using our
              platform.
            </p>
          </div>
        </div>

        <div className="section-full pt-0 content-inner bg-white contact-style-1">
          <div className="container">
            <div className="row">
              {/* right part start */}
              <div className="col-lg-6 col-md-6 d-lg-flex d-md-flex">
                <div className="p-a30 border-1  m-b30 contact-area border-1 align-self-stretch radius-sm">
                  <h4 className="m-b10">Quick Contact</h4>
                  <p>
                    If you have any questions simply use the following contact
                    details.
                  </p>
                  <ul className="no-margin">
                    <li className="icon-bx-wraper left m-b30">
                      <div className="icon-bx-xs border-1">
                        <a href="#" className="icon-cell">
                          <i className="fa fa-map-marker-alt" />{" "}
                          {/* Font Awesome location pin */}
                        </a>
                      </div>
                    </li>
                    <li className="icon-bx-wraper left m-b30">
                      <div className="icon-bx-xs border-1">
                        <a href="#" className="icon-cell">
                          <i className="fa fa-envelope" />{" "}
                          {/* Font Awesome email icon */}
                        </a>
                      </div>
                      <div className="icon-content">
                        <h6 className="text-uppercase m-tb0 dez-tilte">
                          Email:
                        </h6>
                        <p>
                          <a
                            target="_blank"
                            href={`mailto:${siteSettings.social.email}`}
                            className=" text-primary"
                            rel="noreferrer"
                          >
                            {" "}
                            {siteSettings.social.email}
                          </a>
                        </p>
                      </div>
                    </li>
                  </ul>

                  <div className="m-t20">
                    <ul className="dez-social-icon dez-social-icon-lg">
                      <li>
                        <a
                          target="_blank"
                          href={siteSettings.social.youtube}
                          className="fab fa-youtube bg-primary"
                          rel="noreferrer"
                        />
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href={siteSettings.social.twitter}
                          className="fab fa-twitter bg-primary"
                          rel="noreferrer"
                        />
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href={siteSettings.social.linkedin}
                          className="fab fa-linkedin-in bg-primary"
                          rel="noreferrer"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* right part END */}
              {/* Left part start */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
