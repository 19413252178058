import React, { useEffect, useState } from "react";
import WebsiteBreadCrumb from "../website/website-components/WebsiteBreadCrumb";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getSinglePostDetailById } from "../services/getSinglePostDetail";
import { get } from "../services";
import { useSelector } from "react-redux";
import { ApplyPost } from "../services/ApplyPost";
import PopUp from "./PopUp";
import { calculateDaysPassed } from "../common/commonFunctions";
import { CustomAlert, showToastAlert } from "../admin/components/CustomAlert";
import GroupIcon from '@mui/icons-material/Group';
import InternshipApplyModal from "../student/modals/InternshipApplyModal";
import { getSinglePostDetail } from "../services/getSinglePostDetail";
import { Box } from "@mui/material";

const ViewInternshipDetailsPage = ({ postid, slug, showApplyBtn=false, ismodel=true }) => {
  const location = useLocation();
  const token = useSelector((state) => state.token);
  const userId = useSelector((state) => state.id);
  const userType = useSelector((state) => state.uType);
  const isAuthenticated = useSelector((state) => state.isAuthenticated);
  const stepsCompleted = useSelector((state) => state.stepsCompleted);
  const [postData, setPostData] = useState({});
  const [loader, setLoader] = useState(true);
  const [success, setSuccess] = useState(false);
  const [perks, setPerks] = useState([]);
  const [showApplyModal, setShowApplyModal] = useState(false);
  const [appliedStatus, setAppliedStatus] = useState("none");
  const [postTitle, setPostTitle] = useState("");

  const navigate = useNavigate();
  
  useEffect(() => {
    if(postid || slug)
      getApiData(postid, slug);
  }, [postid, slug]);

  const getApiData = async (postid, slug) => {
    try {
      setLoader(true);
      let response = undefined;
      if(slug) {
        response =  await getSinglePostDetail(slug, token);
      } else {
        response = await getSinglePostDetailById(postid, token);
      }
      if (response.status === 200) {
        const startDate = new Date(response.data.post.startDate);
        const endDate = new Date(startDate); // Create a new Date object
        endDate.setDate(startDate.getDate() + 30); // Add 30 days
        const lPostTitle = response?.data?.post?.title + " at " +   response?.data?.post?.cName;
        setPostTitle(lPostTitle);
        response.data.post.latestApplyDate = endDate;
      } else {
        showToastAlert(response.data.nessage, "error");
        return;
      }
      const data = response.data;

      setPostData(data.post);
      //setPostid(data.post._id);
      console.log("postData.perks:" + postData.perks);
      let tempPerks = [];
      if (data.post != undefined && data.post.perks != undefined) {
        console.log("type:" + typeof data.post.perks.certificate);
        if (data.post.perks.certificate && data.post.perks.certificate === true)
          tempPerks.push("Certificate");
        if (data.post.perks.flexi && data.post.perks.flexi === true)
          tempPerks.push("Flexible work hours");
        if (data.post.perks.freefood && data.post.perks.freefood === true)
          tempPerks.push("Free Food");
        if (data.post.perks.lor && data.post.perks.lor === true)
          tempPerks.push("Letter of recommendation");
        if (data.post.perks.days5 && data.post.perks.days5 === true)
          tempPerks.push("5 days work");
      }

      setPerks(tempPerks);
      setLoader(false);
      setAppliedStatus(data.appliedStatus);
    } catch (err) {
      //setData(err.data);
      setLoader(false);
    }
  };

  const handleApply = async () => {
    if(userType !== "student") {
      showToastAlert("Only a student can apply", "error");
      return;
    }
     
    if(!stepsCompleted.mobileVerified) {
      return navigate("/student/verify-mobile");
    } else if(!stepsCompleted.profileCompleted) {
      return navigate("/student/editprofile");
    } else if(!stepsCompleted.resumeMinInfoUpdated) {
      return navigate("/student/resume");
    } 
    setShowApplyModal(true);
  };

  const showSuccessPopup = async () => {
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 2000);
  };

  const companyLogo = postData.cLogo
    ? postData.cLogo
    : "/web-assets/images/icons/building.png";

  const companyName = postData.cName ? postData.cName : "Company Name N/A";
  const stipend = postData.stipend ? postData.stipend : "Not Disclossed";

  return (
    <div className="internshipt-details-box">
      { (!ismodel) && <Helmet>
                <title>HeyInterns | {postTitle} </title>
      </Helmet> }
      <WebsiteBreadCrumb title={postData.title} subtitle={companyName} />
      <div className="container py-5 details-content-box">
        <div className="heading-box ">
          <h2>{postData.title}</h2>
          <div className="summary-info-box mt-2">
            <div className="internship-short-details">
              <LocationOnOutlinedIcon />
              {postData?.workModel === "remote" ? "Remote" : "Office"}
            </div>

            <div className="internship-short-details">
              <DateRangeOutlinedIcon /> {postData?.duration} Months
            </div>

            <div className="internship-short-details">
              <span className="txt-primary">
                <CurrencyRupeeOutlinedIcon />
                {stipend}
              </span>
              / Month
            </div>

            <div className="internship-short-details">
              <DateRangeOutlinedIcon /> Start -
              {postData.startDate !== "N/A"
                ? moment(postData.startDate).format("DD MMM, YYYY")
                : "Start date not specified"}
            </div>
          </div>
        </div>
        <div className="content-block">
          {/* Job Detail */}
          <div className="company-details-box">
            <div className="row">
              <div className="col-sm-3">
                <div className="sticky-top">
                  <div className="shadow1 i-company-details">
                    <img
                      // src="/web-assets/images/icons/adobe.png"
                      src={companyLogo}
                      alt=""
                      className="img-fluid i-company-logo"
                    />
                    <h4 className="i-company-name">
                      {companyName}
                      <br />{" "}
                      <span className="blue-badge ms-0">Actively Hiring</span>
                    </h4>
                    <ul className="i-details-list">
                      <li>
                        <i className="fas fa-map-marker-alt" />
                        <strong strong>Location</strong>
                        <span className="details-text">
                          {postData?.locations?.join(", ")}
                        </span>
                      </li>
                      <li>
                        <i className="fa-solid fa-indian-rupee-sign"></i>
                        <strong strong>Stipend</strong>

                        <span className="details-text">{stipend} Monthly</span>
                      </li>
                      <li>
                        <i className="fa-solid fa-user-tie"></i>
                        <strong strong>Openings</strong>
                        <span className="details-text">{postData?.numOpening} Vacancies</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-9">
                <div className="internship-info-box">
                  <h3 className="title2"> {postData.title}</h3>

                  <div className="i-description-box mb-4">
                    <div className="heading-box">
                      <h2>About the internship</h2>
                      <p>Selected intern's day-to-day responsibilities include:</p>
                      <div style={{ 
                        fontSize: "inherit", 
                        whiteSpace: "pre-wrap", 
                        wordBreak: "break-word", 
                        padding: "10px", 
                        background: "#f9f9f9", 
                        borderRadius: "8px", 
                        lineHeight: "1.6",
                        border: "1px solid #ddd"
                    }}>
                      {postData.responsibility}
                    </div>

                    </div>
                  </div>
                  <div className="i-description-box mb-4">
                    <div className="heading-box">
                      <h2>Skill(s) required</h2>
                      <div className="skilss-box ">
                        {Array.isArray(postData.skills) &&
                        postData.skills.length > 0 ? (
                          postData.skills.map((item, index) => (
                            <span key={index} className="skills-chip">
                              {item}
                            </span>
                          ))
                        ) : (
                          <p>Anyone can apply</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="i-description-box mb-4">
                    <div className="heading-box">
                      <h2>Who Can Apply</h2>
                      <ol>
                        <li>
                          Ability to understand functional and non-functional
                          requirements
                        </li>
                        <li>Experience with required skills</li>
                        <li>Must be available to join immediately</li>
                      </ol>
                    </div>
                  </div>
                  <div className="i-description-box mb-4">
                    <div className="heading-box">
                      <h2>How to Apply</h2>
                      <ol>
                      <li>
                        <strong>Keep your resume updated</strong><p></p>
                      </li>
                      <li>
                        <strong>Click the "Apply" Button:</strong>
                        <p> Make sure to review the internship description and requirements carefully before proceeding.</p>
                      </li>
                      <li>
                        <strong>Fill Out the Application Form:</strong>
                        <p>After clicking "Apply," complete the application form by entering all the required details</p>
                      </li>
                      <li>
                        <strong>Submit Your Application:</strong>
                        <p>Once you submit. You'll receive a confirmation message indicating that your application has been sent to the employer.</p>
                      </li>
                    </ol>
                    </div>
                  </div>
                  <div className="i-description-box mb-4">
                    <div className="heading-box">
                      <h2>Perks</h2>
                      <ul className="list-num-count no-round">
                        {perks.map((item) => {
                          return <li>{item}</li>
                        })}
                      </ul>
                    </div>
                  </div>
                  <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "16px",
                      }}
                    >
                      {userType === "student" &&
                        appliedStatus &&
                        (appliedStatus === "none" ? (
                          <Button
                            onClick={handleApply}
                            variant="contained"
                            color="primary"
                          >
                            Apply for Internship
                          </Button>
                        ) : (
                          <Button
                            style={{
                              backgroundColor: "grey",
                              color: "#fff",
                              borderColor: "grey",
                            }}
                            variant="outlined"
                          >
                            Already Applied
                          </Button>
                        ))}
                      {userType === undefined || userType === "" ? (
                        <Button
                          variant="outlined"
                          onClick={() => {
                            navigate("/Student/Login");
                          }}
                        >
                          {" "}
                          Login to Apply{" "}
                        </Button>
                      ) : (
                        ""
                      )}
                    </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <InternshipApplyModal
                setShowApplyModal={setShowApplyModal}
                showApplyModal={showApplyModal}
                setAppliedStatus={setAppliedStatus}
                postData={postData}
                showSuccessPopup={showSuccessPopup}
              />
    </div>
  );
};

export default ViewInternshipDetailsPage;
