import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const  adminRegister = async (email, password, name) => {

    try {
        const response = await heyapi.post(`/admin/register`, {
            email,
            password,
            name,
        });
        console.log(response.data);
        return response.data;
    } catch (err) {
        return err.response.data;
    }
};

export const getSinglePostDetailForAdmin = async (postid,token) => {
    try {
      const headers = {}
      if(token != undefined && token != "") {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await heyapi.get(`/admin/post/details?id=${postid}`, {
        headers
        },
      );
      return response;
    } catch (err) {
      throw   err.response;
    }
  };
  

  export const updatePostByAdmin = async (
    postId,
    title,
    skills,
    locations,
    workType,
    numOpening,
    startDate,
    duration, //selectedMonth, //num of months 
    responsibility,
    stipendType,
    stipend,
    perksState, //perks
    ppo,
    questions,
    mobile,
    token
  ) => {
  
    console.log(
      postId,
      title,
      skills,
      locations,
      workType,
      numOpening,
      startDate,
      duration, //selectedMonth, //num of months 
      responsibility,
      stipendType,
      stipend,
      perksState, //perks
      ppo,
      questions,
      mobile
    );
    try {
      const response = await heyapi.post(
        `/admin/post/edit`,
        {
          postId,
          title,
          skills,
          locations,
          workType,
          numOpening,
          duration,
          startDate,
          responsibility,
          stipend,
          perksState,
          ppo,
          questions,
          mobile
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      return response;
    } catch (err) {
      console.error("API error:", err);
      throw new Error("Failed to add post. Please try again later.");
    }
  };

  export const getAiResponsibilities = async (
    profile,
    token
  ) => {
    try {
      const response = await heyapi.get(
        `/admin/post/ai-responsibilties?profile=${profile}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      return response;
    } catch (err) {
      console.error("API error:", err);
      throw new Error("Failed to get ai responsibilties. Please try again later.");
    }
  };