import React from "react";
import ReactDOM from "react-dom"; // Use react-dom for React 17
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";
import { GoogleOAuthProvider } from "@react-oauth/google";

const clientId = "981906895996-3rivemp1ura8jf03m6rlb0hk47a599fo.apps.googleusercontent.com";

// Render the app using ReactDOM.render
ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={clientId}>
    <HelmetProvider>
     <App />
    </HelmetProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById("root") // The DOM element where the app will mount
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();




