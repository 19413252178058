import React from "react";
import WebsiteBreadCrumb from "./website-components/WebsiteBreadCrumb";
import { Helmet } from "react-helmet-async";

const Career = () => {
  return (
    <div>
      <Helmet>
          <title>HeyInterns | Careers </title>
      </Helmet>
      <WebsiteBreadCrumb title="Careers at HeyInterns" />
      <div className="container policy-page pt-5">
        {/* Header Section */}
        <div className="heading-box">
          <h2>Join Our Team</h2>
          <p>
            At HeyInterns, we're building the future of internship recruitment.
            If you're passionate about innovation, growth, and making an impact,
            we want to hear from you! Explore our open positions and become a
            part of our dynamic team.
          </p>
        </div>

        {/* Content Section */}
        <div>
          <h3>Why Work With Us?</h3>
          <p>
            At HeyInterns, we offer more than just a job – we provide a chance
            to grow, innovate, and make a real impact. As part of our team,
            you'll have the opportunity to work in a collaborative and dynamic
            environment where your ideas are valued.
          </p>
          <p>
            Our culture is built on mutual respect, inclusivity, and a passion
            for excellence. Whether you’re just starting your career or looking
            to take the next step, you’ll find plenty of opportunities for
            growth and development at HeyInterns.
          </p>
          <hr />

          <h3>Our Values</h3>
          <p>
            We believe in fostering a workplace that supports both personal and
            professional growth. Our core values include:
          </p>
          <ul>
            <li>
              Innovation - We embrace creativity and strive to push boundaries.
            </li>
            <li>
              Integrity - We operate with transparency and accountability.
            </li>
            <li>
              Collaboration - We value teamwork and believe in the power of
              diverse perspectives.
            </li>
            <li>
              Excellence - We are dedicated to providing high-quality service
              and results.
            </li>
          </ul>
          <hr />

          <h3>Open Positions</h3>
          <p>
            We are currently looking for talented individuals to join our team
            in the following roles:
          </p>
          <ul>
            <li>
              <strong>Software Developer</strong> - Build innovative solutions
              and contribute to our platform’s evolution.
            </li>
            <li>
              <strong>Product Manager</strong> - Drive the development of new
              features and enhancements for our users.
            </li>
            <li>
              <strong>Marketing Specialist</strong> - Help us expand our reach
              and tell the story of HeyInterns.
            </li>
            <li>
              <strong>Customer Success Manager</strong> - Provide outstanding
              support to our clients and help them achieve their goals.
            </li>
          </ul>
          <hr />

          <h3>Benefits & Perks</h3>
          <p>
            We offer a range of benefits to support the well-being of our team
            members, including:
          </p>
          <ul>
            <li>Competitive salary and performance-based bonuses</li>
            <li>Health, dental, and vision insurance</li>
            <li>Flexible work hours and remote work options</li>
            <li>Generous paid time off and holidays</li>
            <li>Professional development and growth opportunities</li>
          </ul>
          <hr />

          <h3>How to Apply</h3>
          <p>
            Ready to take the next step in your career? We’d love to meet you!
            To apply, please send your resume and a cover letter to{" "}
            <a href="mailto:careers@heyinterns.com">
              <strong>careers@heyinterns.com</strong>
            </a>{" "}
            with the subject line corresponding to the position you’re
            interested in. We’ll get in touch if we think you’d be a great fit
            for the team.
          </p>
          <p>
            If you have any questions about the roles or the application
            process, feel free to reach out to us at{" "}
            <a href="mailto:careers@heyinterns.com">
              <strong>careers@heyinterns.com</strong>
            </a>
            .
          </p>
          <hr />

          <h3>Equal Opportunity Employer</h3>
          <p>
            HeyInterns is an equal opportunity employer. We celebrate diversity
            and are committed to creating an inclusive environment for all
            employees. We encourage applicants of all backgrounds to apply.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Career;
