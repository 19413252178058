import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  TextField,
  Autocomplete,
  OutlinedInput,
  Drawer,
  Slider,
  useMediaQuery,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { logout } from "../../Store/Action";
import "./Application.scss";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";

const PostApplicationsRightSidebar = ({
  setOpenFilterSidebar,
  openFilterSidebar,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isAuthenticated = useSelector((state) => state.admin);
  const isMobile = useMediaQuery("(max-width:800px)");

  const handleLogout = () => {
    dispatch(logout());
    navigate("/admin/login");
  };

  // Split the pathname on '/' to get path segments
  const pathSegments = location.pathname.split("/");
  const id =
    pathSegments.length > 0 ? pathSegments[pathSegments.length - 2] : undefined;

  const top100Films = [
    { title: "The Shawshank Redemption", year: 1994 },
    { title: "The Godfather", year: 1972 },
    { title: "The Godfather: Part II", year: 1974 },
  ];

  const SidebarContent = (
    <div className="application-rightsidebar">
      <div className="admin-right-filter">
        <h5>Filter</h5>
        <Box className="filter-box12">
          <p className="filter-box10-label">
            Applicants' locations <HelpOutlineIcon fontSize="extrasmall" />
          </p>
          <OutlinedInput placeholder="e.g. Mumbai" sx={{ height: "30px" }} />
        </Box>

          {/* <Box className="filter-box12">
            <p className="filter-box10-label">
              Skills <HelpOutlineIcon fontSize="extrasmall" />
            </p>
            <Autocomplete
              multiple
              size="small"
              id="tags-outlined"
              options={top100Films}
              getOptionLabel={(option) => option.title}
              defaultValue={[top100Films[0]]}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} placeholder="Favorites" />
              )}
            />
          </Box> */}
          {/* <Box className="filter-box12">
            <p className="filter-box10-label">
              Graduation year <HelpOutlineIcon fontSize="extrasmall" />
            </p>
            <Autocomplete
              multiple
              size="small"
              id="tags-outlined"
              options={top100Films}
              getOptionLabel={(option) => option.title}
              defaultValue={[top100Films[0]]}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} placeholder="Favorites" />
              )}
            />
          </Box> */}

          {/* <Box className="filter-box12">
            <p className="filter-box10-label">
              Minimum academic performance{" "}
              <HelpOutlineIcon fontSize="extrasmall" />
            </p>
            <Slider
              defaultValue={50}
              aria-label="Default"
              valueLabelDisplay="auto"
            />
          </Box> */}
      </div>
    </div>
  );

  return (
    <>
      {isMobile ? (
        <Drawer
          anchor="right"
          open={openFilterSidebar}
          classes={{ paper: "dashboard-sidebar-drawer" }}
          onClose={() => setOpenFilterSidebar(false)}
        >
          <IconButton
            variant="contained"
            aria-label="more"
            className="cancel-drawer-btn"
            onClick={() => {
              setOpenFilterSidebar(false);
            }}
          >
            <CancelIcon />
          </IconButton>
          {SidebarContent}
        </Drawer>
      ) : (
        SidebarContent
      )}
    </>
  );
};

export default PostApplicationsRightSidebar;
