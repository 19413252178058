import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LinkIcon from '@mui/icons-material/Link';
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import PagesIcon from '@mui/icons-material/Pages';
import { statusOptions } from "../../common/constants";
import { getStatusTag } from "../../common/commonFunctions";
import Tooltip from '@mui/material/Tooltip';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import CustomLoader from "../../components/CustomLoader";
import DataTable from "../components/DataTable";
import { getEmployerPost, updatePostState } from "../../services/employerAllPost";
import moment from "moment";
import SingleInternshipModal from "../../modals/SingleInternshipModal";

const AdminEmployerPostList = () => {
    const [loaderOpen, setLoaderOpen] = React.useState(true);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [apiData, setApiData] = React.useState([]);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [selectedId, setSelectedId] = useState("");
    const [showOptions, setShowOptions] = useState("Approve");
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [currPostId, setCurrPostId] = React.useState("");

    const admin = useSelector((state) => state.admin);
    const navigate = useNavigate();
    const location = useLocation();
    const {id, state} = useParams();
  
    let searchKeyValue = state
    if (searchKeyValue == undefined) {
        searchKeyValue = "all";
    }
    useEffect(() => {
            // Redirect to ":id/posts/all" if `state` is undefined
        if (!state) {
            navigate(`/admin/employer/${id}/posts/pending`, { replace: true });
        }
        employerPost(searchKeyValue)
    }, [admin, searchKeyValue, state]);

    const employerPost = async (tag) => {
        try {
            setLoaderOpen(true);
            const data = await getEmployerPost(id, tag, admin.token);
            setApiData(data.posts);
            //set initial post status array 
            let tempStatusArr = [];
            data.posts.map((item, stIndex) => {
                tempStatusArr[stIndex] = item.status;
            })
            setSelectedOptions(tempStatusArr);
            setLoaderOpen(false);
        }
        catch (err) {
            throw new Error(err)
        }
    }

    const handleUpdate = (params) => {
        const id = params.row._id;
        const status = params.row.status;
        // Call your update function here with the row ID and status value
        //UpdateEmployerState(admin.token, id, status);
        console.log(`Updating row with ID: ${id}, Status: ${status}`);
        // Your update logic here
    };
    const handlePostView = (params) => {
        const id = params.row._id;  
        setCurrPostId(id);
        setModalOpen(true);
    };

    const closePostView = () => {
        setModalOpen(false);
    }

    const postOptions = ["pending", "approved", "closed", "rejected"]; //live is just approved with live condition
    const handleOptionChange = (rowIndex, event) => {
      setSelectedOptions((prevValues) => {
        const tempValues  = [...prevValues];
        tempValues[rowIndex] = event.target.value;
        return tempValues;
      })
    }

    const handlePostStateUpdate = async (rowIndex) => {
      const curSelOption = selectedOptions[rowIndex]
      console.log("current selected value:" + curSelOption);
      const data= await updatePostState(id, apiData.posts[rowIndex]._id, curSelOption, admin.token);
      console.log("after post udpaet: " + data);
    }

    const tableColumns = [
        {
            field: "title",
            headerName: "TITLE",
            flex: 1,
            editable: false
        },
        {
            field: "numOpening",
            headerName: "NUMBER OF OPENING",
            flex: 1,
            editable: false
        },
        {
            field: "startDate",
            headerName: "Start At",
            flex: 1,
            editable: false,
            valueGetter: (value) => {
              const date = new Date(value);
              const year = date.getFullYear();
              const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
              const day = String(date.getDate()).padStart(2, "0");
      
              return `${day}-${month}-${year}`;
            },
        },
        {
            field: "status",
            headerName: "STATUS",
            flex: 1,
            renderCell: (params) => (
                <Select
                className="status-selectbox"
                    value={params.value}
                    onChange={(event) => {
                        const newValue = event.target.value;
                        setSelectedStatus(newValue);
                        params.api.updateRows([{ ...params.row, status: newValue }]);
                    }}
                    >
                    {postOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                        {getStatusTag(option) }
                        </MenuItem>
                    ))}
                </Select>
            ),
        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            minWidth: 230,
            editable: true,
            renderCell: (params) => (
              <div>
                <Button
                  className="circular-btn me-2"
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={() => handleUpdate(params)}
                >
                  Update
                </Button>
                <Tooltip title="Show Post Details">
                <Button
                  variant="outlined"
                  onClick={() => handlePostView(params)}
                  className="circular-btn me-2"
                  startIcon={<RemoveRedEyeRoundedIcon />}
                />
                </Tooltip>
              </div>
            ),
          },
    ];

 

    return (
        <div>
            <h1 className="page-title-1 my-3">Employer Posts</h1>

            <CustomLoader setLoaderOpen={setLoaderOpen} loaderOpen={loaderOpen} />
            <DataTable data={apiData} columns={tableColumns} id="_id" />
            <SingleInternshipModal open={modalOpen} postid={currPostId} closePostView={closePostView} />
        </div>
    );
};

export default AdminEmployerPostList;
