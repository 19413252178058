import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Container,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { studentUpdateOnboardStatus } from "../../services/studentRegister";
import { useSelector } from "react-redux";
import { showToastAlert } from "../../admin/components/CustomAlert";
import { useDispatch } from "react-redux";
import { updateStepCompletion } from "../../Store/Action";

const SuccessOnboard = () => {
  const token = useSelector((state) => state.token);
  const [loaded, setLoaded] = React.useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    updateOnboardStatus();
  }, []);

  async function updateOnboardStatus() {
    const response = await studentUpdateOnboardStatus(token);
    if(response.status !== 200) {
      showToastAlert(response.data.message, "error");
    } else {
      dispatch(updateStepCompletion({onboarded: true}));
      setLoaded(true);
    }
  }
  return (
    <>
    {loaded ? (<Container
      maxWidth="sm"
      sx={{
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
        marginTop: "40px"
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          marginBottom: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CheckCircleOutlineIcon
          color="success"
          sx={{ fontSize: 80, marginBottom: 2 }}
        />
        <Typography variant="h4" color="success.main" gutterBottom>
        🎉 Congratulations!
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          You’ve successfully completed the onboarding process.
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <Link to="/internships" style={{ color: "blue"}}> Check out latest internships</Link> 
        </Typography>
      </Box>
    </Container>) : "Loading onboarding status ..." } 
    </>
  );
};

export default SuccessOnboard;
