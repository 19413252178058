import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Avatar,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getEmployerCompanyInfo, uploadLogo } from "../../services/getEmployerInfo";
import { UpdateEmployerCompanyDetail } from "../../services/updateEmployerDetail";
import { CustomAlert, showToastAlert } from "../../admin/components/CustomAlert";
import { getAllPreference } from "../../services/getAllPreferences";
import { baseUrl } from "../../common/siteSettings";
import { updateStepCompletion } from "../../Store/Action";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function CompanyDetailsComponent({ onComplete = null }) {
  const [orgName, setOrgName] = useState("");
  const [orgAdd, setOrgAdd] = useState("");
  const [orgDes, setOrgDes] = useState("");
  const [industry, setIndustry] = useState("");
  const [orgWebsite, setOrgWebsite] = useState("");
  const [numEmp, setNumEmp] = useState("");
  const [anchorEll, setAnchorEll] = useState(null);
  const [loader, setLoader] = useState(true);
  const [allProf, setAllProf] = useState([]);
  const [selectedPrefs, setSelectedPrefs] = useState([]);
  const [isVerified, setIsVeified] = useState(false);
  const [imgFile, setImageFile] = useState(null);
  const [logo, setLogo] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const isApproved = useSelector((state) => state.userDetails?.isApproved);

  const steps = ["Personal Details", "Company Details"];

  const location = useLocation();

  const handleSelect = (value) => {
    setNumEmp(value);
    setAnchorEll(null);
  };

  useEffect(() => {
    userInfo();
  }, []);

  const validateImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const MAX_FILE_SIZE = 1 * 1024 * 1024;
      if (file.size > MAX_FILE_SIZE) {
        setLogo("");
        showToastAlert("File size must not exceed 1 MB", "error");
        return false;
      }
      try {
        await validateDimensions(file);
        setImageFile(file);
      } catch (error) {
        setLogo("");
        showToastAlert(error.message, "error");
        return false;
      }
    }
  };

  const validateDimensions = (file) => {
    const MAX_WIDTH = 500;
    const MAX_HEIGHT = 300;

    return new Promise((resolve, reject) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = () => {
        img.src = reader.result;
        setLogo(reader.result);
      };

      img.onload = () => {
        if (img.width > MAX_WIDTH || img.height > MAX_HEIGHT) {
          setLogo("");
          reject(new Error("Image dimensions must not exceed 500x300 pixels"));
        } else {
          resolve();
        }
      };

      img.onerror = () => {
        setLogo("");
        reject(new Error("Invalid image file"));
      };
      reader.readAsDataURL(file);
    });
  };

  const handleLogoUpload = async () => {
    if (imgFile) {
      const formData = new FormData();
      formData.append("logo", imgFile);

      try {
        const response = await uploadLogo(formData, token);
        if (response.data.success) {
          setLogo(response.data.logopath);
          showToastAlert("Logo uploaded successfully", "success");
          return true;
        } else {
          showToastAlert("Logo upload failed", "error");
        }
      } catch (error) {
        showToastAlert("Error uploading logo", "error");
      }
    }
    return false;
  };

  const userInfo = async () => {
    try {
      const response = await getEmployerCompanyInfo(token);
      if(response.status !== 200) {
        showToastAlert("Invalid access", "error");
        return;
      }
      const data = response.data;
      setLoader(false);
      setOrgName(data.employer?.company?.name || "");
      setOrgAdd(data.employer?.company?.address || "");
      setOrgDes(data.employer?.company?.description || "");
      setIndustry(data.employer?.company?.industry || "");
      setNumEmp(data.employer?.company?.numEmployee || "");
      setIsVeified(data.employer?.verifyStatus ? data.employer?.verifyStatus?.mobile : false);

      if (data.employer?.company?.industry) {
        const prefs = data.employer?.company?.industry.map((item) => ({ name: item }));
        setSelectedPrefs(prefs);
      }
      if (data.employer?.company?.logo) {
        setLogo(`${baseUrl}/${data.employer?.company?.logo}`);
      }
      setOrgWebsite(data.employer?.company?.domain?.url || "");

      const allProf = await getAllPreference();
      setAllProf(allProf?.preferences || []);
    } catch (error) {
      console.error(error);
    }
  };

  const validateFields = () => {
    if (!orgName.trim()) return { isValid: false, message: "Company Name is required." };
    if (!orgWebsite.trim()) return { isValid: false, message: "Website is required." };
    if (!orgAdd.trim()) return { isValid: false, message: "Company Address is required." };
    if (!orgDes.trim()) return { isValid: false, message: "Description is required." };
    if(orgDes.length < 50) return { isValid: false, message: "Description must be minimum 50 character." };
    if (!numEmp.trim()) return { isValid: false, message: "Number of Employees is required." };
    if (selectedPrefs.length === 0) return { isValid: false, message: "At least one Industry preference is required." };
    return { isValid: true };
  };

  const handlePrefChange = (event, newValue) => {
    setSelectedPrefs(newValue);
  };

  const updateEmpDetail = async () => {
    try {
      const validation = validateFields();
      if (!validation.isValid) {
        showToastAlert(validation.message, "error");
        return;
      }
      if (!handleLogoUpload()) {
        return;
      }
      const selectedPrefsNames = selectedPrefs.map((item) => item.name);
      const response = await UpdateEmployerCompanyDetail(token, orgName, orgWebsite, orgAdd, selectedPrefsNames, orgDes, numEmp, token);
      if (response.status === 200) {
        dispatch(updateStepCompletion({ companyInfoStatus: true }));
        showToastAlert("Update success", "success");
        if(onComplete)
          onComplete();
      } else {
        showToastAlert(response.data.message, "error");
      }
    } catch (error) {
      showToastAlert("Unsuccessful", "danger");
      console.error(error);
    }
  };

  return (
    <Box>
      <TextField
        label="Company Name"
        value={orgName}
        onChange={(e) => setOrgName(e.target.value)}
        fullWidth
        placeholder="Company Name"
        margin="normal"
        sx={{
          marginBottom: 2, // Adjust as needed
        }}
        disabled={isApproved}
      />
      <TextField
        label="Website"
        value={orgWebsite}
        onChange={(e) => setOrgWebsite(e.target.value)}
        fullWidth
        placeholder="company website"
        margin="normal"
        sx={{
          marginBottom: 2, // Adjust as needed
        }}
        disabled={isApproved}
      />
      <TextField
        label="Address"
        value={orgAdd}
        onChange={(e) => setOrgAdd(e.target.value)}
        fullWidth
        placeholder="company address"
        margin="normal"
        inputProps={{ maxLength: 150 }}
        disabled={isApproved}
      />
      <TextField
        label="Description"
        value={orgDes}
        onChange={(e) => setOrgDes(e.target.value)}
        fullWidth
        margin="normal"
        placeholder="Company short description"
        multiline
        rows={3}
        inputProps={{ maxLength: 400 }}
      />
      <Autocomplete
        size="small"
        multiple
        id="editprofile-outlined"
        options={allProf}
        value={selectedPrefs}
        onChange={handlePrefChange}
        getOptionLabel={(option) => option.name}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Industry"
            placeholder="e.g. Marketing"
          />
        )}
      />
      <TextField
        label="Number of Employees"
        value={numEmp}
        placeholder="0-50"
        onClick={(e) => setAnchorEll(e.currentTarget)}
        fullWidth
        margin="normal"
      />
      <Menu
        anchorEl={anchorEll}
        open={Boolean(anchorEll)}
        onClose={() => setAnchorEll(null)}
      >
        {["0-50", "51-100", "101-500", "501-1000", "1000+"].map((value) => (
          <MenuItem key={value} onClick={() => handleSelect(value)}>
            {value}
          </MenuItem>
        ))}
      </Menu>
      {/* Upload Logo Section */}
      {/* Upload Logo Section */}
      <Typography variant="h6">Upload Logo ( Max Size: 1 MB, Dimension: 300px X 300px )</Typography>
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <Avatar
          src={logo}
          alt="Company Logo"
          sx={{
            width: 150,
            height: 100,
            border: "1px solid #ccc", // Optional: Styling the border
            "& img": {
              objectFit: "contain", // Ensures the entire image fits within the avatar
            },
          }}
        />
        <label htmlFor="upload-logo">
          <VisuallyHiddenInput
            id="upload-logo"
            type="file"
            accept="image/*"
            onChange={(e) => validateImage(e)}
          />
          <Button
            variant="contained"
            component="span"
            startIcon={<CloudUploadIcon />}
          >
            Upload Logo
          </Button>
        </label>
        <CustomAlert />
      </Box>
      <Button variant="contained" color="primary" onClick={updateEmpDetail}>
                      Save
        </Button>

    </Box>
  );
}