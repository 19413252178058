import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import InternshipCard from "./InternshipCard";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getSinglePostDetailById } from "../services/getSinglePostDetail";
import { get } from "../services";
import { useSelector } from "react-redux";
import { ApplyPost } from "../services/ApplyPost";
import PopUp from "./PopUp";
import { calculateDaysPassed } from "../common/commonFunctions";
import { CustomAlert, showToastAlert } from "../admin/components/CustomAlert";
import GroupIcon from '@mui/icons-material/Group';
import InternshipApplyModal from "../student/modals/InternshipApplyModal";
import { checkOnboardState } from "../common/redirectFunctions";

const SingleIntershipComponent = ({ postid, showApplyBtn=false }) => {
  const location = useLocation();
  const token = useSelector((state) => state.token);
  const userId = useSelector((state) => state.id);
  const userType = useSelector((state) => state.uType);
  const isAuthenticated = useSelector((state) => state.isAuthenticated);
  const stepsCompleted = useSelector((state) => state.stepsCompleted);
  const [postData, setPostData] = useState({});
  const [loader, setLoader] = useState(true);
  const [success, setSuccess] = useState(false);
  const [perks, setPerks] = useState([]);
  const [showApplyModal, setShowApplyModal] = useState(false);
  const [appliedStatus, setAppliedStatus] = useState("none");

  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: smooth scrolling animation
    });
  };
  useEffect(() => {
    getApiData(postid);
  }, [postid]);

  const getApiData = async (postid) => {
    try {
      setLoader(true);
      const data = await getSinglePostDetailById(postid, token);
      if (data.post) {
        const startDate = new Date(data.post.startDate);
        const endDate = new Date(startDate); // Create a new Date object
        endDate.setDate(startDate.getDate() + 30); // Add 30 days
        data.post.latestApplyDate = endDate;
      }

      setPostData(data.post);
      //setPostid(data.post._id);
      console.log("postData.perks:" + postData.perks);
      let tempPerks = [];
      if (data.post != undefined && data.post.perks != undefined) {
        console.log("type:" + typeof data.post.perks.certificate);
        if (data.post.perks.certificate && data.post.perks.certificate === true)
          tempPerks.push("Certificate");
        if (data.post.perks.flexi && data.post.perks.flexi === true)
          tempPerks.push("Flexible work hours");
        if (data.post.perks.freefood && data.post.perks.freefood === true)
          tempPerks.push("Free Food");
        if (data.post.perks.lor && data.post.perks.lor === true)
          tempPerks.push("Letter of recommendation");
        if (data.post.perks.days5 && data.post.perks.days5 === true)
          tempPerks.push("5 days work");
      }

      setPerks(tempPerks);
      setLoader(false);
      setAppliedStatus(data.appliedStatus);
    } catch (err) {
      //setData(err.data);
      setLoader(false);
    }
  };

  const handleApply = async () => {
    if(userType !== "student") {
      showToastAlert("Only a student can apply", "error");
      return;
    }
    if(userType === "student") {
        if(!checkOnboardState(stepsCompleted)) {
          return navigate("/student/onboard" )
        } 
    }
    setShowApplyModal(true);
  };

  const showSuccessPopup = async () => {
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 2000);
  };

  console.log("Application Data", postData);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div>
        {loader ? (
          "loading..."
        ) : (
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "16px",
                margin: "16px 0",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              }}
            >
              {/* Additional Content */}
              <Box sx={{ marginTop: "32px" }}>
                <Typography variant="h4" gutterBottom>
                  {postData ? postData.title : ""} work from home job/internship
                  at{" "}
                  {postData && postData.ownerId
                    ? postData.ownerId.company.name
                    : ""}
                </Typography>
                {/* Main Job Info */}
                <Box
                  sx={{
                    border: "1px solid gray",
                    borderRadius: "8px",
                    padding: "16px",
                    marginBottom: "16px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Chip label="Actively hiring" color="success" />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" fontWeight="bold">
                        {postData ? postData.title : ""}
                      </Typography>
                      <Typography variant="subtitle1">
                        {postData && postData.ownerId
                          ? postData.ownerId.company.name
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="text.secondary">
                        Work From {postData ? postData.workModel : ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">
                        <strong>Start Date:</strong>
                        {postData
                          ? new Date(postData.startDate).toLocaleString(
                            "en-US",
                            {
                              day: "2-digit",
                              month: "long",
                              year: "numeric",
                            }
                          )
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">
                        <strong>Duration:</strong>{" "}
                        {postData ? postData.duration : ""} Months
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">
                        <strong>Stipend:</strong> ₹{" "}
                        {postData ? postData.stipend : ""} /month
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">
                        <strong>Apply By:</strong>{" "}
                        {postData.latestApplyDate.toLocaleString("en-US", {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                        })}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="text.secondary">
                        Posted {calculateDaysPassed(postData.createdAt)} days
                        ago
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        <strong>Internship:</strong> Part time
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        <strong> <GroupIcon /> {postData.stats.application} applicants</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}></Grid>
                  </Grid>
                </Box>

                <Box
                  sx={{
                    border: "1px solid gray",
                    borderRadius: "8px",
                    padding: "16px",
                    marginBottom: "16px",
                  }}
                >
                  {success && (
                    <Box sx={{ textAlign: "center", marginBottom: "16px" }}>
                      <PopUp />
                    </Box>
                  )}
                  <Box sx={{ padding: "10px" }}>
                    <Typography variant="h5" gutterBottom>
                      About the internship
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      Selected intern's day-to-day responsibilities include:
                      {postData && postData.responsibility
                        ? postData.responsibility
                        : ""}
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                      Skill(s) required
                    </Typography>
                    <Stack direction="row" spacing={1} gutterBottom>
                      {postData && postData.skills
                        ? postData.skills.map((item, index) => (
                          <Chip key={index} label={item} />
                        ))
                        : null}
                    </Stack>

                    <Typography variant="h6" gutterBottom>
                      Who can apply
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      Only those candidates can apply who:
                      <ol>
                        <li>are available for full time {postData.workModel === "remote" ? "remote" : "office"} internship</li>
                        <li>can start the internship from {new Date(postData.startDate).toLocaleString(
                          "en-US",
                          {
                            day: "2-digit",
                            month: "long",
                            year: "numeric",
                          }
                        )}</li>
                        <li>are available for duration of {postData.duration} month</li>
                        <li>have relevant skills and interests</li>
                      </ol>
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                      Perks
                    </Typography>
                    <Stack direction="row" spacing={1} gutterBottom>
                      {perks &&
                        perks.map((element, eindex) => (
                          <Chip key={eindex} label={element} />
                        ))}
                    </Stack>

                    <Typography variant="h6" gutterBottom>
                      Locations
                    </Typography>
                    <Stack direction="row" spacing={1} gutterBottom>
                      {postData &&
                        postData.locations &&
                        postData.locations.map((element, eindex) => (
                          <Chip key={eindex} label={element} />
                        ))}
                    </Stack>
                    <Typography variant="h6" gutterBottom>
                      Number of openings
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      {postData ? postData.numOpening : ""}
                    </Typography>

                    <Typography variant="h5" gutterBottom>
                      About{" "}
                      {postData && postData.ownerId
                        ? postData.ownerId.company.name
                        : ""}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {postData && postData.ownerId
                        ? postData.ownerId.company.description
                        : ""}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              { showApplyBtn && (<Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "16px",
                }}
              >
                {userType === "student" &&
                  appliedStatus &&
                  (appliedStatus === "none" ? (
                    <Button
                      onClick={handleApply}
                      variant="contained"
                      color="primary"
                    >
                      Apply
                    </Button>
                  ) : (
                    <Button
                      style={{
                        backgroundColor: "grey",
                        color: "#fff",
                        borderColor: "grey",
                      }}
                      variant="outlined"
                    >
                      Already Applied
                    </Button>
                  ))}
                {userType === undefined || userType === "" ? (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigate("/Student/Login");
                    }}
                  >
                    {" "}
                    Login to Apply{" "}
                  </Button>
                ) : (
                  ""
                )}
              </Box>) }

              <InternshipApplyModal
                setShowApplyModal={setShowApplyModal}
                showApplyModal={showApplyModal}
                setAppliedStatus={setAppliedStatus}
                postData={postData}
                showSuccessPopup={showSuccessPopup}
              />
            </Box>
          </Box>
        )}
        <CustomAlert />
      </div>
    </div>
  );
};

export default SingleIntershipComponent;
