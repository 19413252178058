import React from "react";
import { Card, CardContent, Typography, Box, Link } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { formatMessageTime } from "../../../services/utilApis";
import { InsertEmoticonSharp } from "@mui/icons-material";

const AnswerSentMsg = ({item, itemtype="sent"}) => {
  return (
    <Card
      style={{
        backgroundColor: "#e8f7ff", // Light blue background
        borderRadius: "12px",
        padding: "10px",
        maxWidth: "400px", // Adjust width as needed
      }}
    >
      <CardContent style={{ padding: "8px 16px" }}>
        <Box display="flex" alignItems="center" marginBottom="8px">
          <DoneIcon style={{ color: "#43a047", marginRight: "8px" }} />
          <Typography variant="subtitle1" style={{ fontWeight: 200 }}>
            Assignment {itemtype}
          </Typography>
        </Box>
        {/* Long link handling */}
        <Typography
          variant="body2"
          style={{
            wordBreak: "break-word", // Ensures the long link wraps
            marginBottom: "16px",
          }}
        >
          {item.content}
          {item.link && <>
            <br /> {/* Moves the link to the next line */}
            <Link
              href={item.link.startsWith("http") ? item.link : `https://${item.link}`}
              target="_blank" // Opens link in new tab
              rel="noopener noreferrer"
              style={{
                color: "blue", // Ensures blue color
                textDecoration: "underline", // Ensures underline
                display: "inline-block", // Helps with formatting
                marginTop: "4px", // Adds spacing from the content
              }}
            >
              {item.link}
            </Link>
          </>}
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginTop="8px"
        >
          <Typography variant="caption" color="textSecondary">
          {formatMessageTime(item.createdAt)}
          </Typography>
          <AccessTimeIcon fontSize="small" color="action" />
        </Box>
      </CardContent>
    </Card>
  );
};

export default AnswerSentMsg;
