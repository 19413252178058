import React from "react";
import { Outlet } from "react-router-dom";
import "./Employer.scss";
import MainHeader from "../components/MainHeader";
import FooterDashboard from "../components/FooterDashboard";
const EmployerLayout = () => {
  return (
    <div className="dashborad-page-outbox">
      <MainHeader />
      {/*<div className="container-fluid pt-100"> */}
      <div className="employer-dashboard-layout">
        <Outlet />
      </div>
      <FooterDashboard />
    </div>
  );
};

export default EmployerLayout;
