import React from "react";
import AssignmentSentMsg from "./AssignmentSentMsg";
import MsgSentCard from "../../../components/chat/MsgSentCard";
import { Box } from "@mui/material";

const ChatMsgSent = ({item, itemtype="sent"}) => {
  return (
    <Box sx={{marginBottom: "10px",
        display: "flex",
        justifyContent: "flex-end", // Align content to the right
    }} >
    {item.type === "assignment" ? <AssignmentSentMsg item={item} itemtype={itemtype}/> : (
        <MsgSentCard item={item} />)}
    </Box>
  )};

export default ChatMsgSent;
