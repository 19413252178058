import {
  Box,
  Typography,
  Button
} from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom"; // Import useNavigate
import { getActiveRooms } from "../../services/studentChat";
import ChatUserBox from "./ChatUserBox";
import StudentSafetyGuide from "../../website/StudentSafetyGuide";

const ChatSideBar2 = ({ uType, selectedUser, setSelectedUser }) => {
  const [alignment, setAlignment] = React.useState("web");
  const [selectedRoomId, setSelectedRoomId] = React.useState(null); // State to track selected user
  const token = useSelector((state) => state.token);
  const [safetyOpen, setSafetyOpen] = React.useState(false);
  const [rooms, setRooms] = React.useState([]);
  const navigate = useNavigate();
  const { roomid } = useParams();
  const handleRoomClick = (roomId) => {
    setSelectedRoomId(roomId);
    const nUrl = "/student/chat/" + roomId;
    navigate(nUrl);
  };

  const getChatRooms = async () => {
    const data = await getActiveRooms(token);
    setRooms(data ? data.rooms : []);
  };

  useEffect(() => {
    getChatRooms();
    setSelectedRoomId(roomid);
  }, []);

  return (
    <>
      <div className="chat-leftbar-body">
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "16px",
          backgroundColor: "primary.main",
          color: "primary.main",
          borderRadius: "8px",
          boxShadow: 1,
          maxHeight: "300px"
        }}>
          <Typography variant="h6" component="h2" sx={{  color: "white" }}>
            Internship Chats
          </Typography>
          <Button variant="contained" color="error" sx={{ mt: 2 }} onClick={() => setSafetyOpen(true)}>
            Safety Instructions
          </Button>
          <StudentSafetyGuide open={safetyOpen} onClose={() => setSafetyOpen(false)} />
        </Box>
        {/* <Box
          sx={{ display: "flex", flexDirection: "column", maxHeight: "300px" }}
        >
         <Card sx={{ padding: "10px" }}>
            <CardContent>
              
               
              <TextField
                fullWidth
                placeholder="Search applicant by name..."
                sx={{ mb: 2, marginTop: "10px" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </CardContent>
          </Card>Search Bar 
      </Box>*/}
      <ul className="chat-header-group">
        {rooms.map((tRoom, index) => {
          const isActive = tRoom._id === selectedRoomId;
          return (
            <li key={index} onClick={() => handleRoomClick(tRoom._id)}>
              <ChatUserBox isActive={isActive} tRoom={tRoom} />
            </li>
          );
        })}
      </ul>
    </div >
    </>
  );
};

export default ChatSideBar2;
