import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { studentGoogleLogin } from '../services/studentLogin';
import { Typography } from '@mui/material';
import { useState } from 'react';


const GoogleLoginBtn = ({ handleResponse }) => {
    const [error, setError] = React.useState(null);
    const handleLoginSuccess = async (credentialResponse) => {
        setError(null); // Clear error if any
        const token = credentialResponse.credential; // JWT from Google
        try {
            const response = await studentGoogleLogin(token);
            handleResponse(response, "student", "/student/applications")
        } catch (err) {
            console.error(err);
            alert('Login failed!');
        }
    };

    return (
        <>
        <GoogleLogin
            onSuccess={handleLoginSuccess}
            text="continue_with"
            size="large"
            theme="filled_black" // or "outline"
            onError={() => {
                setError('Google Login failed. Please try again.');
            }}
        />
        {error && (
            <Typography
                variant="body2"
                color="error"
                sx={{ mt: 1, textAlign: 'center' }}
            >
                {error}
            </Typography>
        )}
        </>
     );
};


export default GoogleLoginBtn;