import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button, Chip, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { baseUrl } from "../../common/siteSettings";
import { useRef } from "react";
import {
  getRoomInitialData,
  updateRoomStatus
} from "../../services/employerChat";
import ApplicantModal from "../modals/ApplicantModal";
import ChatSideBar2 from "./ChatSideBar2";

import io from "socket.io-client"; // Import socket.io-client
import { CustomAlert, showToastAlert } from "../../admin/components/CustomAlert";
import MainHeader from "../../components/MainHeader";
import ChatFooter2 from "./ChatFooter2";
import ChatMsgReceived from "./msg/ChatMsgReceived";
import ChatMsgSent from "./msg/ChatMsgSent";
const socket = io(baseUrl, {
  reconnection: true, // Enable auto-reconnection
  reconnectionAttempts: 5, // Number of reconnection attempts
  reconnectionDelay: 1000, // Time before trying to reconnect
  pingTimeout: 60000, // Timeout for receiving pings (60 seconds)
  pingInterval: 25000, // Interval to send pings (25 seconds)
});

const MainChatLayout = () => {
  const [selectedUser, setSelectedUser] = useState(null); //user which will receive mesage
  const { roomid } = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRoomId, setSelectedRoomId] = React.useState(null);
  const [roomData, setRoomData] = React.useState(null);
  const [selectedAppId, setSelectedAppId] = React.useState(null);
  const [messages, setMessages] = React.useState([]);
  const [showApplicantModal, setShowApplicanttModal] = React.useState(false);
  const [roomStatus, setRoomStatus] = React.useState("idle");
  const token = useSelector((state) => state.token);
  const userid = useSelector((state) => state.id);
  //console.log("userid:" + userid);

  const [openChatSidebar, setOpenChatSidebar] = React.useState(false);

  const open = Boolean(anchorEl);
  //console.log("roomid:" + roomid);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const loadRoomData = async (roomid) => {
    const response = await getRoomInitialData(token, roomid);
    if(response.status !== 200) {
      showToastAlert(response.data?.msg, "error");
    }
    setMessages(response?.data?.messages);
    setSelectedUser(response?.data?.chatroom?.studentId);
    setSelectedAppId(response?.data?.chatroom?.applicationId);//application id
    setRoomData(response?.data?.chatroom);
    setRoomStatus(response?.data?.chatroom?.status);
  };

  const handleViewApplication = () => {
    setShowApplicanttModal(selectedAppId);
  };

  const handleBlockUser = async () => {
    const response = await updateRoomStatus(token, selectedRoomId, "blocked", "Blocked by employer");
    if(response.status !== 200 ) {
      showToastAlert("not able to block student, contact admin", "error");
      return;
    } else {
      showToastAlert("Student blocked", "success");
      setRoomStatus("blocked");
    }
  }
  const handleUnBlockUser = async () => {
    const response = await updateRoomStatus(token, selectedRoomId, "active", "");
    if(response.status !== 200 ) {
      showToastAlert("not able to unblock student, contact admin", "error");
      return;
    } else {
      showToastAlert("Student unblocked", "success");
      setRoomStatus("active");
    }
  }

  const onSendMessage = (msg) => {
    if (msg.trim() === "") return; // Don't send empty messages
    if (roomid === undefined) {
      //console.log("invalid onSendMessage: roomid:" + roomid);
    }

    const messageId = new Date().getUTCMilliseconds(); // Generate a unique ID for the message //TODO: check runtime of each api
    const messageTime = new Date().toISOString(); // Store the current time in ISO format
    const messageData = {
      messageId: messageId,
      chatroomId: roomid,
      sender: userid,
      content: msg,
      type: "chat",
      createdAt: messageTime,
    };

    // Send the message to the server
    socket.emit("SEND_MSG", messageData);

    // Update the local state to include the new message
    setMessages((prevMessages) => [...prevMessages, messageData]);
  };

    // Add a ref for scrolling
  const messagesEndRef = useRef(null);
  
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  
  // Call scrollToBottom when messages change
  React.useEffect(() => {
    scrollToBottom();
  }, [messages]);
  

  React.useEffect(() => {
    if (roomid) {
      loadRoomData(roomid);
      setSelectedRoomId(roomid);
    }
  }, [roomid]);

  React.useEffect(() => {
    //console.log("room is to join:" + roomid);
    if (roomid !== undefined) {
      // Join the room
      //console.log("\t room join n:" + roomid);

      socket.emit("JOIN_ROOM", { chatroomId: roomid });

      // Listen for messages in the room
      socket.on("MESSAGES", (data) => {
        setMessages(data.messages);
      });

      // Handle new incoming messages
      socket.on("RECE_MSG", (message) => {
        //console.log("REC_MSF:" + JSON.stringify(message));
        setMessages((prevMessages) => [...prevMessages, message]);
      });

      // Handle socket disconnect and attempt reconnection
      socket.on("disconnect", () => {
        //console.log("Socket disconnected. Attempting to reconnect...");
      });

      socket.on("reconnect_attempt", (attempt) => {
        //console.log(`Reconnect attempt #${attempt}`);
      });

      socket.on("reconnect", () => {
        //console.log("Reconnected to the server.");
        socket.emit("JOIN_ROOM", { chatroomId: roomid }); // Rejoin the room on reconnect
      });

      socket.on("reconnect_failed", () => {
        //console.log("Reconnection failed.");
      });

      return () => {
        // Leave the room on component unmount
        if (roomid !== undefined) {
          socket.emit("LEAVE_ROOM", { chatroomId: roomid });
          socket.off("RECE_MSG");
          socket.off("reconnect");
          socket.off("reconnect_attempt");
          socket.off("reconnect_failed");
        }
      };
    }
  }, [roomid]);

  return (
    <div className="chat-outer-box">
      <MainHeader />
      <div className="chat-window admin-chat-box">
        <div className="chat-leftbar">
          <ChatSideBar2
            selectedUser={selectedUser}
            setOpenChatSidebar={setOpenChatSidebar}
            openChatSidebar={openChatSidebar}
          />
        </div>
        <div className="chat-rightbar">
          <div className="chat-message-box">
            <div className="chat-header">
              <div className="d-flex">
                <IconButton
                  variant="contained"
                  aria-label="more"
                  id="open-chat-user-btn"
                  onClick={() => {
                    setOpenChatSidebar((prev) => !prev);
                    //console.log("open ");
                  }}
                >
                  <MenuOpenOutlinedIcon />
                </IconButton>
                { roomid && <div className="chat-header-user">
                  <figure className="avatar">
                    <img
                      src="https://images.pexels.com/photos/2379005/pexels-photo-2379005.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                      className="rounded-circle"
                      alt="user pic"
                    />
                  </figure>
                  <div>
                    <h5>{selectedUser?.name}</h5>
                    <small>
                      <i>{selectedUser?.email}</i> 
                    </small>
                  </div>
                </div>}
              </div>

              { roomid && <div className="chat-header-action">
                <Chip 
                  label={roomStatus} 
                  color={
                    roomStatus === "active" ? "success" : 
                    roomStatus === "blocked" ? "error" : 
                    "default"
                  }
                />
                <Button
                  variant="outlined me-2"
                  onClick={handleViewApplication}
                >
                  <span className="full-application-text">
                    View Full Application
                  </span>
                  <Tooltip title="  View Full Application" arrow>
                    <OpenInNewIcon className="ms-2" />
                  </Tooltip>
                </Button>
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    slotProps={{
                      paper: {
                        style: {
                          width: "20ch",
                        },
                      },
                    }}
                  >
                    <MenuItem onClick={handleBlockUser}>Block User</MenuItem>
                    <MenuItem onClick={handleUnBlockUser}>UnBlock User</MenuItem>
                    {/*<MenuItem onClick={handleClose}>Approve</MenuItem>
                    <MenuItem onClick={handleClose}>Decline</MenuItem>*/}
                  </Menu>
                </div>
              </div> }
            </div>
            <div className="chat-body">
              {messages &&
                messages.map((chat, index) => (
                  <>
                    {chat.sender !== userid && (
                      <ChatMsgReceived item={chat} />
                    )}
                    {chat.sender === userid && (
                      <ChatMsgSent item={chat} />
                    )}
                  </>
                ))}
                {/* Invisible div to scroll to last message */}
               <div ref={messagesEndRef} />
            </div>
            {roomStatus === "active" && <ChatFooter2 onSendMessage={onSendMessage} /> }
            <ApplicantModal showApplicantModal={showApplicantModal} currApplicantId={selectedAppId} setShowApplicanttModal={setShowApplicanttModal}/>
            <CustomAlert />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainChatLayout;
