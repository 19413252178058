import axios from 'axios';
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const getAllColleges = async (state, token) => {
    console.log(state)
    try {
        const response = await heyapi.get(`/admin/college/filter?state=${state}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        console.log(response)
        return response;
    }
    catch (err) {
        console.log(err);
    }
}

export const updateCollegeState = async (collegeid, state, token, reason) => {
    try {
        const data = {
            collegeid: collegeid, 
            status: state, 
            reason: reason
        }
        const response = await heyapi.patch(`/admin/college/updatestate`, 
            data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        //console.log(response.data)
        return response;
    }
    catch (err) {
        console.log(err);
    }
}