import Citys from "./components/Citys";
import { ThemeProvider } from "@mui/material/styles";
import defaultTheme from "./assets/Theme/color";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Await,
} from "react-router-dom";
import Home from "./pages/Home";
import InternshipDetail from "./components/InternshipDetail";
import SignUp from "./pages/signup";
import EmployerDashboard from "./employer/Dashboard";
import Postjob from "./pages/Postjob";
import Chatbox from "./components/Chatbox";
import Application from "./components/Application";
import MyApplications from "./student/MyApplications";
import MyResume from "./student/MyResume";
import Preferences from "./student/Preferences";
import HelpCenter from "./student/HelpCenter";
import Forgotpassword from "./pages/ForgotPassword";
import StudentPersonalDetails from "./components/StudentPersonalDetails";
import ManageHiring from "./employer/ManageHiring";
import EmpSignUp from "./employer/SignUp";
import Login from "./student/Login";
import InternshiplistPage from "./student/InternshiplistPage";
import InternShipDetails from "./employer/InternShipDetails";

import { Provider, useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./Store/store";
import EditEmployerProfile from "./employer/EditProfile";
import EditCompanyDetails from "./employer/EditCompanyDetails";
import OfflineIndicator from "./components/oflineIndicator";
import AdminDashboard from "./admin/AdminDashboard";
import AdminSignUp from "./admin/AdminSignUp";
import AdminNavBar from "./admin/AdminNavBar";
import AdminLogin from "./admin/AdminLogin";
import EmployerDetail from "./admin/EmployerDetail";
import EmployerPost from "./admin/EmployerPost";
import "./scss/index.scss";
import AdminLayout from "./admin/AdminLayout";
import EmployersPage from "./admin/EmployersPage";
import EmployerLayout from "./pages/EmployerLayout";
import ApplicationBox from "./components/ApplicationBox";
import EmployerChatLayout from "./employer/chatroom/MainChatLayout";
import StudentChatLayout from "./student/chatroom/MainChatLayout";
import AdminEmployerLayout from "./admin/employers/Layout";
import AdminEmployerPostList from "./admin/employers/PostList";
import PostApplicationList from "./employer/applications/ApplicationList";
import PostApplicationsLayout from "./employer/applications/PostApplicationsLayout";
import StudentDashboardLayout from "./student/components/Layout";
import StudentLogout from "./student/Logout";
import StudentDeleteAccount from "./student/DeleteAccount";
import StudentChangePassword from "./student/ChangePassword";
import EmployerLogout from "./employer/Logout";
import AdminLogout from "./admin/AdminLogout";
import AdminAllPostList from "./admin/Posts";
import AdminAllStudentList from "./admin/Students";
import AdminAllCollegeList from "./admin/Colleges";
import ApplicationDetailsPage from "./employer/applications/ApplicationDetailsPage";
import HomePage from "./website/HomePage";
import JobListing from "./components/JobListing";
import Page404 from "./pages/Page404";
import EmailVerifyPage from "./student/EmailVerifyPage";
import PhoneVerifyPage from "./student/PhoneVerifyPage";
import EmpEmailVerifyPage from "./employer/EmailVerifyPage";
import CollegeEmailVerifyPage from "./college/EmailVerifyPage";
import ResetPassword from "./pages/ResetPassword";
import StudentPrivateRoute from "./auth/StudentPrivateRoute";
import EmployerPrivateRoute from "./auth/EmployerPrivateRoute";
import AdminPrivateRoute from "./auth/AdminPrivateRoute";
import WebsiteLayout from "./website/WebsiteLayout";
import About from "./website/About";
import Privacy from "./website/Privacy";
import ViewInternshipDetailsPage from "./components/ViewInternshipDetailsPage";

import Contact from "./website/Contact";
import AccountSuccess from "./pages/status/AccountSuccess";
import RedirectRoute from "./auth/RediretRoute";
import SingleEmployerDetails from "./admin/SingleEmployerDetails";
import PasswordResetMailSuccess from "./pages/status/PasswordResetMailSuccess";
import PasswordResetSuccess from "./pages/status/PasswordResetSuccess";
import CompanyPolicy from "./website/CompanyPolicy";
import Faqs from "./website/Faqs";
import HowItWorks from "./website/HowItWorks";
import StudentsInformation from "./website/StudentsInformation";
import EmployerInformation from "./website/EmployerInformation";

import VerifyInternship from "./website/VerifyInternship";
import TermsConditions from "./website/TermsConditions";
import Career from "./website/Career";
import StudentOnboard from "./student/onboard/StudentOnboard";
import EmployerOnboard from "./employer/onboard/EmployerOnboard";
import PostSubmissionSuccess from "./employer/status/PostSubmissionSuccess";
import InternshipDetailsPage from "./components/InternshipDetailsPage";
import PostAssignmentList from "./employer/applications/AssignmentList";
import CollegePrivateRoute from "./auth/CollegePrivateRoute";
import CollegeRegistration from "./college/Register";
import CollegeAccountSuccess from "./pages/status/CollegeAccountSuccess";
import EditPost from "./admin/EditPost";
import InternshipStoryList from "./story/InternshipStoryList";
import InternshipStoryForm from "./story/InternshipStoryForm";
import MyInternshipStories from "./student/MyInternshipStories";
import StorySubmitSuccess from "./pages/status/StorySubmitSuccess";
import AdminAllStoriesList from "./admin/Stories"
import InternshipStoryDetailsPage from "./components/InternshipStoryDetailsPage";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={defaultTheme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            {/* <UserContext.Provider
            value={{ user: myUser, logOut: logout, updateUser: updateUser }}
          > */}
            <Router>
              <OfflineIndicator />
              <Routes>
                <Route element={<RedirectRoute />}>
                  <Route path="/" element={<WebsiteLayout />}>
                    <Route index element={<HomePage />} />
                  </Route>
                </Route>
                <Route path="/" element={<WebsiteLayout />}>
                  <Route path="/about-us" element={<About />} />
                  <Route path="/contact-us" element={<Contact />} />
                  <Route
                    path="/:utype/forgotpassword"
                    element={<Forgotpassword />}
                  />
                  <Route
                    path="/:utype/reset-password"
                    element={<ResetPassword />}
                  />
                  <Route path="/account-created" element={<AccountSuccess />} />
                  <Route path="/story-submitted" element={<StorySubmitSuccess />} />
                  <Route path="/college-account-created" element={<CollegeAccountSuccess />} />
                  <Route
                    path="/password-reset-mail-success"
                    element={<PasswordResetMailSuccess />}
                  />
                  <Route
                    path="/password-reset-success"
                    element={<PasswordResetSuccess />}
                  />
                  <Route path="/privacy-policy" element={<Privacy />} />
                  <Route path="/company-policy" element={<CompanyPolicy />} />

                  <Route path="/faqs" element={<Faqs />} />

                  <Route path="/how-it-works" element={<HowItWorks />} />
                  <Route
                    path="/details-page"
                    element={<ViewInternshipDetailsPage />}
                  />
                  <Route
                    path="/terms-and-conditions"
                    element={<TermsConditions />}
                  />

                  <Route path="/careers" element={<Career />} />

                  <Route
                    path="/students-information"
                    element={<StudentsInformation />}
                  />
                  <Route
                    path="/employers-information"
                    element={<EmployerInformation />}
                  />

                  <Route
                    path="/verify-internship"
                    element={<VerifyInternship />}
                  />
                </Route>
                <Route element={<StudentPrivateRoute verifySteps={true} />}>
                  <Route path="/student" element={<StudentDashboardLayout />}>
                    <Route path="applications" element={<MyApplications />} />
                    <Route path="stories">
                      <Route index element={<MyInternshipStories />} />
                      <Route path="add" element={<InternshipStoryForm type="add" />} />
                      <Route path="edit/:storyid" element={<InternshipStoryForm type="edit" />} />
                    </Route>
                  </Route>
                </Route>
                <Route element={<StudentPrivateRoute verifySteps={true} />}>
                  <Route path="/student/chat" element={<StudentChatLayout />} />
                  <Route
                    path="/student/chat/:roomid"
                    element={<StudentChatLayout />}
                  />
                </Route>
                {/* <Route element={<EmployerPrivateRoute verifySteps={true} />}>
                  <Route
                    path="/employer/chat"
                    element={<EmployerChatLayout />}
                  />
                  <Route
                    path="/employer/chat/:roomid"
                    element={<EmployerChatLayout />}
                  />
                  <Route
                    path="applicant_detail/:appid"
                    element={<ApplicationDetailsPage />}
                  />
                </Route> */}
                <Route element={<StudentPrivateRoute />}>
                  <Route path="/student" element={<StudentDashboardLayout />}>
                    <Route path="onboard" element={<StudentOnboard />} />
                    <Route
                      path="editprofile"
                      element={<StudentPersonalDetails />}
                    />
                    <Route path="preference" element={<Preferences />} />
                    <Route path="resume" element={<MyResume />} />
                    <Route
                      path="delete-account"
                      element={<StudentDeleteAccount />}
                    />
                    <Route
                      path="change-password"
                      element={<StudentChangePassword />}
                    />
                    <Route path="helpcenter" element={<HelpCenter />} />
                    <Route path="verify-mobile" element={<PhoneVerifyPage />} />
                  </Route>
                </Route>

                <Route path="/student" element={<WebsiteLayout />}>
                  <Route path="verify-email" element={<EmailVerifyPage />} />
                  <Route path="signup" element={<SignUp />} />
                  <Route path="login" element={<Login />} />
                  <Route path="logout" element={<StudentLogout />} />
                  <Route path="helpcenter" element={<HelpCenter />} />
                </Route>

                <Route path="/employer" element={<WebsiteLayout />}>
                  <Route path="verify-email" element={<EmpEmailVerifyPage />} />
                  <Route path="signup" element={<EmpSignUp />} />
                </Route>
                <Route path="/employer" element={<EmployerLayout />}>
                  <Route index element={<EmployerDashboard />} />
                  <Route path="login" element={<Login />} />
                  <Route path="logout" element={<EmployerLogout />} />
                  <Route path="managed-hiring" element={<ManageHiring />} />
                </Route>
                <Route element={<EmployerPrivateRoute verifySteps={false} />}>
                  <Route path="/employer" element={<EmployerLayout />}>
                    <Route
                      path="editProfile"
                      element={<EditEmployerProfile />}
                    />
                    <Route
                      path="editCompany"
                      element={<EditCompanyDetails />}
                    />
                    <Route path="onboard" element={<EmployerOnboard />} />
                    <Route path="verify-mobile" element={<PhoneVerifyPage />} />
                  </Route>
                </Route>
                <Route element={<EmployerPrivateRoute verifySteps={true} />}>
                  <Route path="/employer" element={<EmployerLayout />}>
                    <Route path="applications1/:id" element={<Application />} />
                    <Route
                      path="applicationsbox"
                      element={<ApplicationBox />}
                    />
                    <Route
                      path="applications/details"
                      element={<InternShipDetails />}
                    />
                    {/* <Route path="chat" element={<EmployerChatLayout />} />
                    <Route
                      path="chat/:roomid"
                      element={<EmployerChatLayout />}
                    /> */}
                    <Route
                      path="applicant_detail/:appid"
                      element={<ApplicationDetailsPage />}
                    />
                    <Route path="dashboard" element={<EmployerDashboard />} />
                  </Route>
                  <Route path="/employer" element={<PostApplicationsLayout />}>
                    <Route
                      path="applications/:id/:state"
                      element={<PostApplicationList />}
                    />
                    <Route
                      path="applications/:id/assignments"
                      element={<PostAssignmentList />}
                    />
                  </Route>
                </Route>
                <Route
                  element={
                    <EmployerPrivateRoute
                      verifySteps={true}
                      verifyState={true}
                    />
                  }
                >
                  <Route path="/employer" element={<EmployerLayout />}>
                    <Route path="jobs/form" element={<Postjob />} />
                    <Route path="jobs/edit/:slug" element={<Postjob />} />
                    <Route
                      path="jobs/success"
                      element={<PostSubmissionSuccess />}
                    />
                  </Route>
                </Route>
                <Route element={<EmployerPrivateRoute verifySteps={true} />}>
                  <Route
                    path="/employer/chat"
                    element={<EmployerChatLayout />}
                  />
                  <Route
                    path="/employer/chat/:roomid"
                    element={<EmployerChatLayout />}
                  />
                  <Route
                    path="applicant_detail/:appid"
                    element={<ApplicationDetailsPage />}
                  />
                </Route>
                <Route path="/admin" element={<WebsiteLayout />}>
                  <Route path="signup" element={<AdminSignUp />} />
                  <Route path="login" element={<AdminLogin />} />
                  <Route path="logout" element={<AdminLogout />} />
                </Route>
                <Route element={<AdminPrivateRoute />}>
                  <Route path="/admin" element={<AdminLayout />}>
                    <Route path="dashboard" />
                    <Route
                      path="employers/:state"
                      element={<EmployersPage />}
                    />
                    <Route path="posts/:state" element={<AdminAllPostList />} />
                    <Route path="students/:state" element={<AdminAllStudentList />} />
                    <Route path="colleges/:state" element={<AdminAllCollegeList />} />
                    <Route path="post/edit/:slug" element={<EditPost />} />
                    <Route path="stories/:state" element={<AdminAllStoriesList />} />
                  </Route>
                  <Route path="/admin" element={<WebsiteLayout />}>
                    <Route index element={<AdminDashboard />} />
                    {<Route path="dashboard2" element={<AdminDashboard />} />}
                    <Route path="employerPost/:id" element={<EmployerPost />} />
                    <Route
                      path="employerdetail/:id"
                      element={<EmployerDetail />}
                    />
                    {/* <Route path="managed-hiring" element={<ManageHiring />} />
                    <Route path="applications/:id" element={<Application />} /> */}
                    <Route
                      path="applications/details"
                      element={<InternShipDetails />}
                    />
                    <Route path="employer" element={<AdminEmployerLayout />}>
                      <Route
                        path=":id/details"
                        element={<SingleEmployerDetails />}
                      />
                      <Route
                        path=":id/posts/:state?"
                        element={<AdminEmployerPostList />}
                      />
                    </Route>
                  </Route>
                </Route>
                <Route path="/college" element={<WebsiteLayout />}>
                  <Route
                      path="/college/register"
                      element={<CollegeRegistration />}
                  />
                  <Route path="verify-email" element={<CollegeEmailVerifyPage />} />
                </Route>

                <Route path="/internship-story" element={<WebsiteLayout />}>
                  <Route
                      index
                      element={<InternshipStoryList />}
                  />
                  <Route
                    path=":slug"
                    element={<InternshipStoryDetailsPage />}
                  />
                </Route>
                
                <Route element={<CollegePrivateRoute />}>
                  
                </Route>

                <Route path="/internships" element={<WebsiteLayout />}>
                  <Route index element={<InternshiplistPage />} />
                  <Route
                    path="detail/:slug"
                    element={<InternshipDetailsPage />}
                  />

                  <Route
                    path="details/:slug"
                    element={<InternshipDetailsPage />}
                  />

                  <Route path="details" element={<JobListing />} />
                  <Route path=":suburl" element={<InternshiplistPage />} />
                </Route>
                <Route path="/admininternship" element={<AdminNavBar />}>
                  <Route index element={<AdminDashboard />} />
                  <Route path="detail/:id" element={<InternshipDetail />} />
                </Route>

                {/* <Route path="/dashboard" element={<StudentNav/>}>
                <Route index element={<Dashboard/>} />
                <Route path="application" element={<Application/>} />
              </Route> */}

                <Route path="/chat" element={<WebsiteLayout />}>
                  <Route index element={<Chatbox />} />
                </Route>

                <Route path="/courses" element={<WebsiteLayout />}>
                  <Route index element={<Home />} />
                </Route>

                <Route path="/events" element={<WebsiteLayout />}>
                  <Route index element={<Home />} />
                </Route>
                <Route path="*" element={<Page404 />} />
              </Routes>
            </Router>
            {/* </UserContext.Provider> */}
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;
