import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { getEmployerDetails } from "../services/getAllEmployerForAdminDashboard";
import CustomLoader from "../components/CustomLoader";
import { useSelector } from "react-redux";

import { Box,   Tab } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { TabContext, TabPanel } from "@mui/lab"; // Import TabContext and TabPanel from @mui/lab
import userImg from "../assets/images/user.png";
import office from "../assets/images/office.png";
import { grey } from "@mui/material/colors";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});
export default function EmployeeDetailsModal({
  setModalOpen,
  modalOpen,
  title,
  employerId,
}) {
  const [loaderOpen, setLoaderOpen] = React.useState(true);
  const [apiData, setApiData] = React.useState("");
  const admin = useSelector((state) => state.admin);
  const [value, setValue] = React.useState("1");
  const handleClickOpen = () => {
    setModalOpen(true);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const userInfo = async (id, token) => {
    try {
      const data = await getEmployerDetails(id, token);
      setApiData(data);
      setLoaderOpen(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    modalOpen && userInfo(employerId, admin.token);
  }, [modalOpen]);

  return (
    <React.Fragment>
      <BootstrapDialog
      maxWidth={700}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={modalOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
 {loaderOpen ?      <CustomLoader setLoaderOpen={setLoaderOpen} loaderOpen={loaderOpen} /> :    <Box
 sx={{
 
   padding: "10px",
   borderRadius: "10px",
   background: "#ffffff",
 }}
>
 <TabContext value={value}>
   <Tabs value={value} onChange={handleChange} centered>
     <Tab
       icon={<Avatar alt="Tab 1 Avatar" src={userImg} />}
       label="Personal Details"
       value="1"
       style={{ width: "375px" }}
     />
     <Tab
       icon={<Avatar alt="Tab 2 Avatar" src={office} />}
       label="Organisation Details"
       value="2"
       style={{ width: "375px" }}
     />
   </Tabs>

   {/* Use TabPanel inside TabContext */}
   <TabPanel value="1">
     <center
       style={{
         fontSize: "34px",
         fontWeight: "600",
         color: "black",
       }}
     >
       Personal Details
     </center>
     <Container
       maxWidth="md"
       style={{ marginTop: "20px", color: grey[500], flex: 1 }}
       sx={{ border: 1, borderRadius: "10px", paddingY: "20px" }}
     >
       <div
         style={{
           display: "flex",
           flexDirection: "row",
           justifyContent: "space-between",
         }}
       >
         <div
           style={{
             display: "flex",
             flexDirection: "column",
             width: "100%",
             marginRight: "10px",
           }}
         >
           <label
             style={{
               fontSize: "16px",
               textAlign: "left",
               color: "black",
               fontWeight: "500",
             }}
           >
             First Name
           </label>
           <TextField
             required
             id="outlined-required"
             placeholder="e.g.delhi"
             multiline={true}
             value={apiData.employer.name}
             InputProps={{
               readOnly: true,
             }}
           />
         </div>
         <div
           style={{
             display: "flex",
             flexDirection: "column",
             width: "100%",
             marginLeft: "10px",
           }}
         >
           <label
             style={{
               fontSize: "16px",
               textAlign: "left",
               color: "black",
               fontWeight: "500",
             }}
           >
             Last Name
           </label>
           <TextField
             required
             id="outlined-required"
             placeholder="e.g.delhi"
             multiline={true}
             InputProps={{
               readOnly: true,
             }}
             value={apiData.employer.name}
           />
         </div>
       </div>
       <div style={{ display: "flex", flexDirection: "column" }}>
         <label
           style={{
             fontSize: "16px",
             textAlign: "left",
             color: "black",
             fontWeight: "500",
           }}
         >
           E-mail
         </label>
         <TextField
           multiline={true}
           value={apiData.employer.email}
           InputProps={{
             readOnly: true,
           }}
         />
       </div>
       <div
         style={{
           display: "flex",
           flexDirection: "row",
           justifyContent: "space-between",
         }}
       >
         <div
           style={{
             display: "flex",
             flexDirection: "column",
             width: "30%",
             marginRight: "10px",
           }}
         >
           <label
             style={{
               fontSize: "16px",
               textAlign: "left",
               color: "black",
               fontWeight: "500",
             }}
           >
             Mobile Number
           </label>
           <TextField
             required
             id="outlined-required"
             multiline={true}
             value={"+91"}
             InputProps={{
               readOnly: true,
             }}
           />
         </div>
         <div
           style={{
             display: "flex",
             flexDirection: "column",
             width: "70%",
             marginLeft: "10px",
           }}
         >
           <br />
           <TextField
             required
             id="outlined-required"
             placeholder="e.g.delhi"
             multiline={true}
             value={apiData.employer.mobile}
             InputProps={{
               readOnly: true,
             }}
           />
         </div>
         <div>
           <br />
           <Button
             color="primary"
             variant="contained"
             sx={{ ml: 3, py: 2, width: "150px" }}
           >
             Not Verified
           </Button>
         </div>
       </div>
     </Container>
     <Button
       type="primary"
       fullWidth
       variant="contained"
       sx={{ mt: 3, mb: 2 }}
       // onClick={updateEmpDetail}
     >
       Save
     </Button>
   </TabPanel>
   <TabPanel value="2">
     <center
       style={{
         fontSize: "34px",
         fontWeight: "600",
         color: "black",
       }}
     >
       Organisation Details
     </center>
     <Container
       maxWidth="md"
       style={{ marginTop: "20px", color: grey[500], flex: 1 }}
       sx={{ border: 1, borderRadius: "10px", paddingY: "20px" }}
     >
       <div style={{ display: "flex", flexDirection: "column" }}>
         <label
           style={{
             fontSize: "16px",
             textAlign: "left",
             color: "black",
             fontWeight: "500",
           }}
         >
           Organisation name
         </label>
         <TextField
           required
           id="outlined-required"
           placeholder="e.g.delhi"
           value={apiData.employer.company.name}
           // onChange={(e) => setOrgName(e.target.value)}
           InputProps={{
             readOnly: true,
           }}
         />
       </div>
       <div style={{ display: "flex", flexDirection: "column" }}>
         <label
           style={{
             fontSize: "16px",
             textAlign: "left",
             color: "black",
             fontWeight: "500",
           }}
         >
           Organisation description
         </label>
         <TextField
           multiline
           rows={3}
           fullWidth
           variant="outlined"
           id="outlined-required"
           placeholder="e.g.delhi"
           // value={orgDes}
           // onChange={(e) => setOrgDes(e.target.value)}
           InputProps={{
             readOnly: true,
           }}
         />
       </div>
       <div style={{ display: "flex", flexDirection: "column" }}>
         <label
           style={{
             fontSize: "16px",
             textAlign: "left",
             color: "black",
             fontWeight: "500",
           }}
         >
           Organisation Address
         </label>
         <TextField
           required
           id="outlined-required"
           placeholder="e.g.delhi"
           multiline={true}
           // value={orgAdd}
           // onChange={(e) => setOrgAdd(e.target.value)}
           InputProps={{
             readOnly: true,
           }}
         />
       </div>
       <div style={{ display: "flex", flexDirection: "column" }}>
         <label
           style={{
             fontSize: "16px",
             textAlign: "left",
             color: "black",
             fontWeight: "500",
           }}
         >
           Industry
         </label>
         <TextField
           required
           id="outlined-required"
           placeholder="e.g.delhi"
           multiline={true}
           // value={industry}
           // onChange={(e) => setIndustry(e.target.value)}
           InputProps={{
             readOnly: true,
           }}
         />
       </div>
       <div style={{ display: "flex", flexDirection: "column" }}>
         <label
           style={{
             fontSize: "16px",
             textAlign: "left",
             color: "black",
             fontWeight: "500",
           }}
         >
           No. of employees
         </label>
         <TextField
           required
           id="outlined-required"
           value={apiData.employer.company.numEmployee}
         />
       </div>
       <div style={{ display: "flex", flexDirection: "column" }}>
         <label
           style={{
             fontSize: "16px",
             textAlign: "left",
             color: "black",
             fontWeight: "500",
           }}
         >
           Organisation logo
         </label>
         <Avatar
           alt="Remy Sharp"
           style={{
             width: "100px",
             height: "100px",
             marginTop: "10px",
           }}
           src="https://i.pinimg.com/280x280_RS/79/dd/11/79dd11a9452a92a1accceec38a45e16a.jpg"
         />
         <Button
           component="label"
           variant="outlined"
           style={{ width: "200px", marginTop: "10px" }}
           startIcon={<CloudUploadIcon />}
         >
           Upload file
           <VisuallyHiddenInput type="file" />
         </Button>
       </div>
     </Container>
     <Button
       type="primary"
       fullWidth
       variant="contained"
       sx={{ mt: 3, mb: 2 }}
       // onClick={updateEmpDetail}
     >
       Save
     </Button>
   </TabPanel>
 </TabContext>
</Box>}
        
             
    
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
