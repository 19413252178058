

import heyapi from "../middleware/axiosInstance";
export const latestStories = async () => {
    try {
      const response = await heyapi.get(
        `/story/latest`,
        {
          headers: {
            
          },
        }
      );
      console.log(response);
      return response;
    } catch (err) {
      console.log(err);
    }
  };

  export const getStoryByTitle = async (slug) => {
    try {
      const response = await heyapi.get(
        `/story/details?title=${slug}`,
        {
          headers: {
            
          },
        }
      );
      console.log(response);
      return response;
    } catch (err) {
      console.log(err);
    }
  };