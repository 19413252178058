import React, { useEffect, useState } from "react";
import { Box, List, ListItem, ListItemText, Tab, Tabs } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { TabContext, TabPanel } from "@mui/lab";
import { useSelector } from "react-redux";
import EmployerInformation from "./EmployerInformation";
import { getAllEmployer } from "../services/getAllEmployerForAdminDashboard";
import LeftSidebar from "./components/Sidebar";
 
const sidebarWidth = 240;

const useStyles = makeStyles((theme) => ({
  sidebar: {
    width: sidebarWidth,
    flexShrink: 0,
    backgroundColor: "#f0f0f0", // Add your desired background color here
  },
  activeItem: {
    backgroundColor: "#D2FFBF", // Add your desired active item background color here
  },
}));

export default function AdminDashboard() {
  const classes = useStyles();
  const [value, setValue] = useState("1");
  const [tabShow, setTabShow] = useState("employer");  
  const [allEmployer, setAllEmployer] = useState([]);  
 const admin= useSelector((state) => state.admin);
  useEffect(()=>{
    getEmployerDetail();
  },admin)
 const getEmployerDetail=async()=>{
  try{
    if(admin){
    const data=await getAllEmployer(admin.token);
    setAllEmployer(data)}
  }
  catch(err){
    throw new Error(err)
  }

 }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <Box className={classes.sidebar}>
          <LeftSidebar setTabShow={setTabShow}/> 
       
        </Box>
        { 
          tabShow === "employer" && (
            <Box sx={{ flexGrow: 1 }}>
              <TabContext value={value}>
                <Tabs
                  value={value}
                  sx={{ minWidth: "80vw" }}
                  onChange={handleChange}
                  centered
                >
                  <Tab
                    label="Personal Details"
                    value="1"
                    style={{ width: "375px" }}
                  />
                  <Tab
                    label="Organisation Details"
                    value="2"
                    style={{ width: "375px" }}
                  />
                </Tabs>

                <TabPanel value="1">
                  <EmployerInformation apiData={allEmployer} />
                </TabPanel>
                <TabPanel value="2"></TabPanel>
              </TabContext>
            </Box>
          )
        }

      </Box>
    </div>
  );
}
