import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { grey } from "@mui/material/colors";
import { AddTraining } from "../services/postTraining";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  FormGroup,
  TextField,
  Box,
  FormControlLabel,
  Checkbox,
  Container
} from "@mui/material";
import { useSelector } from "react-redux"
import PopUp from '../components/PopUp';
import { deleteTraining } from '../services/deleteTraining';
import { editTraining } from '../services/editTraining';
import { getAllCity } from '../services/getAllCities';
import { getAllPreference } from '../services/getAllPreferences';
import Autocomplete from "@mui/material/Autocomplete";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog"; // Import DeleteConfirmationDialog
import { CustomAlert, showToastAlert } from '../admin/components/CustomAlert';

const { DateTime } = require("luxon");
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddTrainings({ getTraining, training }) {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [workMode, setWorkMode] = React.useState("");
  const [start, setStart] = React.useState("");
  const [end, setEnd] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [trainingId, setTrainingId] = React.useState("new");
  const [location, setLocation] = React.useState();
  const [locations, setLocations] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false); // State for delete confirmation
  const [deleteId, setDeleteId] = React.useState(null);
  const [errors, setErrors] = React.useState({});
  const initializeForm = () => {
    setTitle("");
    setCompany("");
    setWorkMode(false);
    setStart(null);
    setEnd(null);
    setDescription("");
  };

  const token = useSelector((state) => state.token);
  const handleClickOpen = (trainingData) => {
    if (trainingData) {
      // Set state variables with the training data if available
      setEditMode(true);
      setTrainingId(trainingData._id);
      setTitle(trainingData.title);
      setCompany(trainingData.company);
      setWorkMode(trainingData.workMode === "remote");
      setStart(new Date(trainingData.startDate));
      setEnd(new Date(trainingData.endDate));
      setDescription(trainingData.description);
    } else {
      // Initialize form fields if no training data is provided
      setEditMode(false);
      initializeForm();
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validate = () => {
    const errors = {};
    if (!title) {
      errors.title = 'title is required';
    }
    if (!company) {
      errors.company = 'Company is required';
    }
    if (!start) {
      errors.start = 'Start date is required';
    }
    if (!end) {
      errors.end = 'End date is required';
    }
    if (!description) {
      errors.description = 'Description is required';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  React.useEffect(() => {
    getPref();
  }, [token])

  const getPref = async () => {
    try {
      const locations = await getAllCity();
      setLocations(locations.cities)
      setLoader(false);
    } catch (error) {
      console.error("Error fetching training data:", error);
    }
  };

  const userId = useSelector((state) => state.id);
  const postTraining = async () => {
    if (!validate())
      return;
    try {
      if (!editMode) {
        const data = await AddTraining(
          token,
          trainingId,
          title,
          company,
          workMode ? "remote" : "wfo",
          moment(start).format("MM/DD/YYYY"),
          moment(end).format("MM/DD/YYYY"),
          description
        );
        getTraining();
        if (data.sucess) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 2000);
        }
        console.log(data);
      } else {
        const data = await editTraining(
          trainingId,
          userId,
          title,
          company,
          workMode ? "remote" : "wfo",
          moment(start).format("MM/DD/YYYY"),
          moment(end).format("MM/DD/YYYY"),
          description,
          token
        );
        getTraining();
        if (data.sucess) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 2000);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Delete confirmation handler
  const handleDeleteClick = (id) => {
    // Logic to delete the project
    setDeleteId(id)
    setDeleteOpen(true);
  };

  
  //for delete internship
  const handleDelete = async () => {
    try {
      const data = await deleteTraining(deleteId, token);
      setDeleteOpen(false);
      getTraining();
      console.log(data)
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <TableBody>
      <TableRow
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
      >
        <TableCell style={{ fontSize: "12px" }}>TRAININGS/ COURSES</TableCell>
        {training?.training?.map((training, index) => (
          <TableRow key={index}>
            <TableCell
              style={{
                padding: "8px 20px 16px",
                borderBottom: "none",
                width: "20vw",
              }}
            >
              <p style={{ fontSize: "14px", fontWeight: "500" }}>
                {training.title}
                <br />
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#6B6B6B",
                  }}
                >
                  {training.company},&nbsp;
                  {training.workMode}
                </span>
                <br />
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#6B6B6B",
                  }}
                >
                  {formatDate(training.startDate)}&nbsp;-&nbsp;
                  {formatDate(training.endDate)}
                </span>
              </p>
            </TableCell>
            <TableCell>
              <IconButton edge="end" aria-label="edit">
                <EditIcon onClick={() => handleClickOpen(training)} />
              </IconButton>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <IconButton edge="end" aria-label="delete">
                <DeleteIcon onClick={() => handleDeleteClick(training._id)} />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell>
            <Button
              onClick={() => {
                initializeForm();
                handleClickOpen(null);
              }}
              style={{ padding: "0px" }}
            >
              <AddIcon style={{ fontSize: "14px" }} color="primary" /> &nbsp;
              <span style={{ fontSize: "12px" }}>Add Trainings/Courses</span>
            </Button>
          </TableCell>
        </TableRow>
      </TableRow>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{ width: "600px" }}
        >
          Training
        </BootstrapDialogTitle>
        {success && (
          <center>
            <PopUp />
          </center>
        )}
        <DialogContent dividers>
          <FormGroup sx={{ alignItems: "flex-start", marginBottom: "10px" }}>
            <Typography>Traning Title</Typography>
            <TextField
              size="small"
              fullWidth
              id="fullWidth"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              inputProps={{ maxLength: 100 }}
            />
            {errors.title && (
              <Typography color="error">{errors.title}</Typography>
            )}
          </FormGroup>
          <FormGroup sx={{ alignItems: "flex-start", marginBottom: "10px" }}>
            <Typography>College/Company Name</Typography>
            <TextField
              size="small"
              fullWidth
              id="fullWidth"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              inputProps={{ maxLength: 50 }}
            />
            {errors.company && (
              <Typography color="error">{errors.company}</Typography>
            )}
          </FormGroup>
          <FormGroup sx={{ alignItems: "flex-start", marginBottom: "10px" }}>
            <Typography>Location</Typography>
            <Autocomplete
              size="small"
              id="tags-outlined"
              style={{
                width: "100%",
              }}
              options={locations}
              disabled={workMode} // Disable Autocomplete if workMode (WFH) is true
              getOptionLabel={(option) => option.city}
              filterSelectedOptions
              onChange={(event, selectedOption) => {
                const selectedString = selectedOption
                  ? selectedOption.city
                  : "";
                setLocation(selectedString);
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="e.g. Delhi" />
              )}
            />
          </FormGroup>
          <Box>
            <FormControlLabel
              control={
                <Checkbox defaultChecked={workMode} onChange={setWorkMode} />
              }
              label="online"
            />
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box style={{ width: "48%", margin: "5px" }}>
              <FormGroup
                sx={{ alignItems: "flex-start", marginBottom: "10px" }}
              >
                <Typography>Start date</Typography>
                <DatePicker
                  selected={start}
                  onChange={(date) => setStart(date)}
                  dateFormat="dd/MM/yyyy"
                  customInput={
                    <TextField
                      placeholder="start"
                      size="small"
                      style={{ width: "100%" }}
                    />
                  }
                  wrapperClassName="date-picker-wrapper"
                  className="date-picker-input"
                />
                {errors.start && (
                  <Typography color="error">{errors.start}</Typography>
                )}
              </FormGroup>
            </Box>
            <Box style={{ width: "48%", margin: "5px" }}>
              <FormGroup
                sx={{ alignItems: "flex-start", marginBottom: "10px" }}
              >
                <Typography>End date</Typography>
                <DatePicker
                  selected={end}
                  onChange={(date) => setEnd(date)}
                  dateFormat="dd/MM/yyyy"
                  customInput={
                    <TextField
                      placeholder="start"
                      size="small"
                      style={{ width: "100%" }}
                    />
                  }
                  wrapperClassName="date-picker-wrapper"
                  className="date-picker-input"
                />
                {errors.end && (
                  <Typography color="error">{errors.end}</Typography>
                )}
              </FormGroup>
            </Box>
          </Box>
          <Box>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Currently ongoing"
            />
          </Box>
          <Box>
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">
                Description (Optional)
              </label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                inputProps={{ maxLength: 400 }}
              ></textarea>
              {errors.description && (
                <Typography color="error">{errors.description}</Typography>
              )}
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              postTraining();
            }}
          >
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
      {/* Delete Confirmation Dialog */}
      <DeleteConfirmationDialog
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onConfirm={handleDelete}
        entityName="training"
      />
      <CustomAlert />
    </TableBody>
  );
}

function formatDate(dateString) {
  if (!dateString) {
    return "N/A";
  }

  const dateTime = DateTime.fromISO(dateString);

  return dateTime.toFormat("MMM yyyy");
}


