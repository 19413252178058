import React from "react";
import WebsiteBreadCrumb from "./website-components/WebsiteBreadCrumb";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Helmet } from "react-helmet-async";

const Faqs = () => {
  return (
    <div>
      <Helmet>
        <title>HeyInterns | Frequently Asked Questions (Faq) </title>
      </Helmet>
      <WebsiteBreadCrumb title="Faqs" />
      <div className="container policy-page">
        {/* Header Section */}
        <div className="heading-box">
          <h2>Frequently Asked Questions</h2>
          <p>
            At HeyInterns, we aim to provide a smooth and efficient experience
            for both employers and interns. Below are some of the most
            frequently asked questions.
          </p>
        </div>

        {/* Accordion Section */}
        <div>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>What is HeyInterns?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                HeyInterns is a platform connecting students and professionals
                with companies offering internships, jobs, and training
                opportunities.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Who can use HeyInterns?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                HeyInterns is designed for students, recent graduates, and young
                professionals looking for internships or jobs, as well as
                companies looking to hire talented individuals.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography>How do I create an account?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                You can create an account by clicking the "Sign Up" button on
                the homepage and filling in your details. You can also use
                Google or Facebook to sign up.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <Typography>How can I post an internship or job?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Employers can create an account and post openings by clicking on
                the "Post an Internship/Job" button in the dashboard.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5a-content"
              id="panel5a-header"
            >
              <Typography>What should I do if I forget my password?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Click on the "Forgot Password" link on the login page and follow
                the instructions to reset your password.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
