import React from "react";
import { Container, Box, Typography, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EmailIcon from "@mui/icons-material/Email";
import { useNavigate } from "react-router";

const CollegeAccountSuccess = () => {
  const navigate = useNavigate();

  const handleGoToLogin = () => {
    navigate("/");
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 6, textAlign: "center" }}>
      {/* Success Message */}
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 3 }}>
        <CheckCircleIcon color="success" sx={{ fontSize: 60, mb: 1 }} />
        <Typography variant="h5" color="success.main" fontWeight="bold">
          Your Institute Account Created Successfully!
        </Typography>
      </Box>

      {/* Email Verification Message */}
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1, mb: 2 }}>
        <EmailIcon color="primary" sx={{ fontSize: 40 }} />
        <Typography variant="body1">
          A verification link has been sent to your registered email address. <br />
          Please verify to continue your journey!
        </Typography>
      </Box>

      {/* Additional Info */}
      <Typography variant="body1" sx={{ mb: 2 }}>
        Our team will connect with you. <br />
        Check our latest internships:{" "}
        <a href="https://heyinterns.com/internships" target="_blank" rel="noopener noreferrer" style={{color:"blue"}}>
          Latest Internships
        </a>
      </Typography>

      <Typography variant="body2" fontWeight="bold" color="error" sx={{ mb: 3 }}>
        Note: Please check your spam folder also for the verification email.
      </Typography>
    </Container>
  );
};

export default CollegeAccountSuccess;
