import React from "react";
import { Button, Box } from "@mui/material";
import { useSelector } from "react-redux";
import ResumeComponent from "../components/ResumeComponent";
import { CustomAlert, showToastAlert } from "../../admin/components/CustomAlert";

const ResumeStep = ({ onComplete = null }) => {
  const stepsCompleted = useSelector((state) => state.stepsCompleted); // Replace with actual Redux selector

  const handleCheckCompletion = () => {
    if (stepsCompleted?.resumeMinInfoUpdated) {
      onComplete && onComplete(); // Call onComplete if the education is completed
    } else {
      showToastAlert("Please complete your education details before proceeding.", "error");
    }
  };

  return (
    <>
      <ResumeComponent onComplete={onComplete}/>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 2 }}>
        <Button variant="contained" onClick={handleCheckCompletion}>
          Check Education Completion
        </Button>
        <CustomAlert />
      </Box>
    </>
  );
};

export default ResumeStep;
