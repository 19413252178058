import axios from 'axios';
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const getEmployerPost = async (id,state, token) => {
    console.log(id)
    try {
        const response = await heyapi.get(`/admin/employer/posts?empid=${id}&state=${state}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        console.log(response.data)
        return response.data;
    }
    catch (err) {
        console.log(err);
    }
}

export const getAllPosts = async (state, token) => {
    console.log(state)
    try {
        const response = await heyapi.get(`/admin/posts?state=${state}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        console.log(response.data)
        return response.data;
    }
    catch (err) {
        console.log(err);
    }
}

export const updatePostState = async (postid, state, token, reason) => {
    try {
        const data = {
            postid: postid, 
            status: state, 
            reason: reason
        }
        const response = await heyapi.patch(`/admin/post/updatestate`, 
            data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        //console.log(response.data)
        return response.data;
    }
    catch (err) {
        console.log(err);
    }
}