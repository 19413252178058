// reducers.js
const initialState = {
  isAuthenticated: false,
  user: null,
  token:'',
  id:'',
  admin: null,
  loading: false,
  error: null,
  uType: '', //user type,
  userDetails: null,
  verifyStatus: null, //verification status
  stepsCompleted: {
    mobileVerified: false,
    companyInfoStatus: false,
    profileCompleted: false,
    resumeMinInfoUpdated: false,
    prefUpdated: false,
    onboarded:false
  },
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    case 'USERTYPE':
        return {
          ...state,
          uType: action.payload,
        };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token:'',
        id:'',
        admin: null,
        loading: false,
        error: null,
        uType: '', //user type,
        userDetails: null,
        verifyStatus: null, //verification status
        stepsCompleted: {
          mobileVerified: false,
          companyInfoStatus: false,
          resumeUploaded: false,
          profileCompleted: false,
          resumeMinInfoUpdated: false
        },
      };
    case 'TOKEN':
      return {
        ...state,
        token: action.payload,
      };
    case 'MOBILE_VERIFIED':
      return {
        ...state,
        verifyStatus: {
          ...state.verifyStatus,
          mobile: action.payload, // Set mobile as verified
        }
      };
    case 'USERID':
      return {
        ...state,
        id: action.payload,
      };
    case 'USER_DETAILS':
      return {
        ...state,
        userDetails:action.payload,
      };
    case 'USER_VERIFY_STATUS' :
      return {
        ...state,
         verifyStatus:action.payload,
      };
    case 'SET_STEP_COMPLETION': // New case for updating steps
      return {
        ...state,
        stepsCompleted: {
          ...state.stepsCompleted,
          ...action.payload,
        },
      };
    case "LOGIN_REQUEST":
      return { ...state, loading: true, error: null };
    case "ADMIN_LOGIN_SUCCESS":
      return {
        ...state,
        loading: false,
        admin: action.payload.user, // Access 'user' property from payload
        error: null,
      };
    case "ADMIN_LOGIN_FAILURE":
      return { ...state, loading: false, admin: null, error: action.payload };
    default:
      return state;
  }
};

export default rootReducer;
