import React from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import SettingsPowerIcon from "@mui/icons-material/SettingsPower";
import { logout } from "./../../Store/Action";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getEmployerDetails } from "../../services/getAllEmployerForAdminDashboard";
import { useEffect } from "react";

const AdminEmployerSidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const admin = useSelector((state) => state.admin);
  const [employer, setEmployer] = React.useState(null);
  const {id} = useParams();
  const isAuthenticated = useSelector((state) => state.admin);
  const handleLogout = () => {
    dispatch(logout());
    navigate("/admin/login");
  };

  useEffect(() => {
    // Fetch employer details
    fetchEmployerDetails(id, admin.token);
}, [id, admin]);

const fetchEmployerDetails = async (id, token) => {
    try {
        const data = await getEmployerDetails(id, token);
        console.log(data)
        setEmployer(data.employer);
    } catch (error) {
        console.log(error);
    }
};

  
  const tabsData = [
    { label: "All Posts", url: `/admin/employer/${id}/posts/all` },
    { label: "Approved", url: `/admin/employer/${id}/posts/approved` },
    { label: "Rejected", url: `/admin/employer/${id}/posts/rejected` },
    { label: "Pending", url: `/admin/employer/${id}/posts/pending` },
    { label: "Live", url: `/admin/employer/${id}/posts/live` },
  ];
  return (
    <React.Fragment>
      <div className="dashboard-sidebar">
        <div className="dashboard-userdata-box">
          <img
            src="https://cdn-icons-png.flaticon.com/512/560/560199.png"
            alt="Employer"
            className="img-fluid"
          />
          <h4> {employer?.name}</h4>
          <h5> {employer?.email}</h5>
          <p> {employer?.company?.name}</p>
        </div>
        <ul>
          <li className="my-3">
            <NavLink to={`/admin/dashboard`}>
              <ArrowBackIcon /> Back
            </NavLink>
          </li>
          <li>
            <NavLink className="menu-item" to={`/admin/employer/${id}/details`}>
              Details
            </NavLink>
          </li>
          {tabsData.map((tab, tabIndex) => (
            <li>
              <NavLink to={tab.url} className="menu-item">
                {tab.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default AdminEmployerSidebar;
