import React from "react";
import MsgSentCard from "../../../components/chat/MsgSentCard";
import { Box } from "@mui/material";
import AnswerSentMsg from "./AnswerSentMsg";

const ChatMsgSent = ({item, itemtype="sent"}) => {
  return (
    <Box sx={{marginBottom: "10px", 
      display: "flex",
      justifyContent: "flex-end", // Align content to the right
    }}>
    {item.type === "answer" ? <AnswerSentMsg item={item} itemtype={itemtype}/> : (
        <MsgSentCard item={item} />)}
    </Box>
  )};

export default ChatMsgSent;
