import React from "react";
import AssignmentReceivedMsg from "./AssignmentReceivedMsg";
import MsgReceivedCard from "../../../components/chat/MsgReceivedCard";
import { Box } from "@mui/material";

const ChatMsgReceived = ({item, sendAnswer, itemtype="received"}) => {
  return (
    <Box sx={{marginBottom: "10px"}}>
    {item.type === "assignment" ? <AssignmentReceivedMsg item={item} itemtype={itemtype} sendAnswer={sendAnswer}/> : (
        <MsgReceivedCard item={item} />)}
    </Box>
  )};

export default ChatMsgReceived;
