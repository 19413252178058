import React from "react";
import WebsiteBreadCrumb from "./website-components/WebsiteBreadCrumb";
import { Helmet } from "react-helmet-async";

const Privacy = () => {
  return (
    <div>
      <Helmet>
        <title>HeyInterns | Privacy Policy </title>
      </Helmet>
      <WebsiteBreadCrumb title="Privacy Policy" />
      <div className="container policy-page pt-5">
        {/* Header Section */}
        <div className="heading-box">
          <h2>Privacy Policy</h2>
          <p>
            Your privacy is important to us. This Privacy Policy explains how we
            collect, use, and protect your information.
          </p>
        </div>

        {/* Content Section */}
        <div>
          <h3>Privacy Policy</h3>
          <p>
            This Privacy Policy outlines the guidelines for how we manage and
            safeguard your personal information when you access the online
            services provided by HeyInterns, available at heyinterns.com, its
            subdomains.
            (collectively referred to as "Services").
          </p>
          <hr />
          <h3>Overview</h3>
          <p>
            We are committed to protecting your privacy and aim to offer a
            secure user experience. This Privacy Policy explains how we collect,
            use, and protect your information online. By using our Services, you
            agree to the terms described here. Your information may be stored
            and processed on servers within or outside India, and by using our
            Services, you consent to such transfers.
          </p>
          <p>
            Please note that our Services may include links to external websites
            beyond our control, and we are not responsible for the privacy
            practices of those sites. We encourage you to review the privacy
            policies of any external websites you visit. This policy applies
            solely to information collected through heyinterns.com and its
            subdomains.
          </p>
          <hr />
          <h3>Information Collection</h3>
          <h4>Personal Information You Provide:</h4>
          <p>
            We may collect personal details such as your name, address, email,
            phone number, billing details, educational background, and workplace
            information. Additionally, we may request demographic information
            like ZIP code, age, preferences, and gender when you interact with
            our Services (e.g., signing up, posting a resume, or participating
            in surveys).
          </p>
          <h4>Third-Party Services:</h4>
          <p>
            To enhance your experience, we may collect information through
            third-party integrations, such as Google or Facebook. This may
            include your name, email address, phone number, or account details.
            For employers, with explicit consent, we may access your social
            media page details or subscriber information for verification
            purposes.
          </p>
          <h4>Contact Information:</h4>
          <p>
            If you use features to share content (e.g., Google+ or SMS), we may
            collect and process information about your contacts, including email
            addresses or phone numbers.
          </p>
          <h4>Usage Data:</h4>
          <p>
            We may collect data about your interactions with our Services,
            including visited areas, accessed features, IP address, browser
            type, access times, and referring websites. This data helps us
            improve functionality and ensure a smooth user experience.
          </p>
          <h4>Chat History:</h4>
          <p>
            If you use HeyInterns's chat platform, we may collect and store
            your chat history for internal purposes.
          </p>
          <hr />
          <h3>Data Retention</h3>
          <p>
            We retain your data to make future use of our Services more
            efficient and relevant. You may update your account details or
            delete your account at any time. Upon account deletion, your data
            will either be removed or anonymized.
          </p>
          <hr />
          <h3>Information Sharing</h3>
          <ul>
            <li>
              <strong>Employers:</strong> Internship and job-related
              information, such as company details and job descriptions, may be
              publicly accessible. Personal details may also be shared with
              applicants or search engines.
            </li>
            <li>
              <strong>Applicants:</strong> Your details may be shared with
              employers or suggested for relevant opportunities.
            </li>
            <li>
              <strong>Third Parties:</strong> We may share data with service
              providers for training programs, marketing, or operational needs.
            </li>
            <li>
              <strong>Social Media:</strong> Any content you share on social
              media platforms falls under their respective policies.
            </li>
            <li>
              <strong>Public Forums:</strong> Information posted in public areas
              (e.g., forums, comments) is accessible to anyone and may be used
              by others.
            </li>
            <li>
              <strong>Legal Obligations:</strong> We may disclose your
              information to comply with legal processes or investigate
              potential violations.
            </li>
          </ul>
          <hr />
          <h3>Editing and Downloading Information</h3>
          <p>
            You can update your personal details through your account profile.
            For any assistance with downloading your information, please contact
            us at{" "}
            <a rel="noopener">
              <span>
                <strong>support@heyinterns.com</strong>
              </span>
            </a>
            .
          </p>
          <hr />
          <h3>Communication Preferences</h3>
          <ul>
            <li>
              <strong>Opt-In:</strong> To receive newsletters or promotional
              emails, you must verify your email via a confirmation link.
            </li>
            <li>
              <strong>Opt-Out:</strong> You can unsubscribe from marketing
              emails via the "Unsubscribe" link included in each communication.
              Transactional messages are essential for service functionality and
              can only be discontinued by contacting us.
            </li>
          </ul>
          <hr />
          <h3>Cookies and Pixel Tags</h3>
          <p>
            We use cookies and similar technologies to enhance your experience.
            Cookies help us remember your preferences, keep you logged in, and
            perform analytics. You can manage cookie settings in your browser,
            but disabling them may affect the functionality of our Services.
          </p>
          <hr />
          <h3>Children</h3>
          <p>
            Our Services are not intended for individuals under the age of 15.
            If we inadvertently collect information from a child, it will be
            deleted upon discovery.
          </p>
          <hr />
          <h3>Data Security</h3>
          <p>
            We employ industry-standard security measures to protect your data.
            However, no system is entirely secure, and we cannot guarantee
            complete protection. Users are advised to maintain the
            confidentiality of their account credentials.
          </p>
          <hr />
          <h3>GDPR Rights</h3>
          <p>
            EU residents have the right to access, rectify, or delete their
            data, restrict or object to processing, and request data
            portability. For assistance, contact{" "}
            <a rel="noopener">
              <span>
                <strong>support@heyinterns.com</strong>
              </span>
            </a>
            .
          </p>
          <hr />
          <h3>Changes to this Privacy Policy</h3>
          <p>
            We may update this policy periodically. Substantial changes will be
            communicated via email or a notice on our website. Please review
            this page for the latest updates.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
