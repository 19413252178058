import React from "react";
import WebsiteBreadCrumb from "./website-components/WebsiteBreadCrumb";
import { Helmet } from "react-helmet-async";

const TermsConditions = () => {
  return (
    <div>
      <Helmet>
        <title>HeyInterns | Terms & Conditions </title>
      </Helmet>
      <WebsiteBreadCrumb title="Terms & Conditions" />
      <div className="container policy-page pt-5">
        {/* Header Section */}
        <div className="heading-box">
          <h2>Terms & Conditions</h2>
          <p>
            Welcome to HeyInterns! These Terms & Conditions outline the rules
            and regulations for the use of our platform. By accessing or using
            the HeyInterns website or services, you agree to comply with and be
            bound by these Terms & Conditions.
          </p>
        </div>

        {/* Content Section */}
        <div>
          <h3>1. Introduction</h3>
          <p>
            These Terms & Conditions apply to all users of HeyInterns, including
            but not limited to employers, interns, and visitors. By using our
            services, you acknowledge and agree to these terms.
          </p>
          <hr />

          <h3>2. Eligibility</h3>
          <p>
            You must be at least 18 years old to use HeyInterns. By agreeing to
            these terms, you represent that you meet the eligibility
            requirements for using our platform.
          </p>
          <hr />

          <h3>3. User Account</h3>
          <p>
            To access certain features, users are required to create an account.
            Users must provide accurate and complete information during
            registration. You are responsible for maintaining the
            confidentiality of your account information and for all activities
            that occur under your account.
          </p>
          <hr />

          <h3>4. Service Usage</h3>
          <p>
            HeyInterns provides a platform to connect employers and interns. By
            using our platform, you agree to:
          </p>
          <ul>
            <li>Provide accurate, complete, and up-to-date information.</li>
            <li>Use the platform for lawful purposes only.</li>
            <li>
              Respect the rights of other users and the platform’s policies.
            </li>
          </ul>
          <hr />

          <h3>5. Intellectual Property</h3>
          <p>
            All content on HeyInterns, including text, graphics, logos, and
            software, is the property of HeyInterns or its licensors and is
            protected by intellectual property laws.
          </p>
          <hr />

          <h3>6. Privacy and Data Protection</h3>
          <p>
            We take your privacy seriously. Please refer to our Privacy Policy
            for details on how we collect, use, and protect your personal
            information.
          </p>
          <hr />

          <h3>7. User Conduct</h3>
          <p>
            You agree to use HeyInterns responsibly and not to engage in any
            activity that:
          </p>
          <ul>
            <li>Violates any laws or regulations.</li>
            <li>Harasses, abuses, or threatens other users.</li>
            <li>Impersonates any person or entity.</li>
            <li>Distributes harmful or unlawful content.</li>
          </ul>
          <hr />

          <h3>8. Payment and Fees</h3>
          <p>
            Certain services provided by HeyInterns may require payment. All
            payment terms will be clearly outlined before you make any
            transactions. Users agree to pay any applicable fees as set forth by
            the platform.
          </p>
          <hr />

          <h3>9. Termination of Service</h3>
          <p>
            HeyInterns reserves the right to suspend or terminate any user’s
            access to the platform at our sole discretion, without notice, for
            violations of these Terms & Conditions.
          </p>
          <hr />

          <h3>10. Limitation of Liability</h3>
          <p>
            To the fullest extent permitted by law, HeyInterns will not be
            liable for any indirect, incidental, or consequential damages
            arising out of or in connection with the use or inability to use the
            platform.
          </p>
          <hr />

          <h3>11. Dispute Resolution</h3>
          <p>
            Any disputes arising out of or related to these Terms & Conditions
            will be resolved through binding arbitration, in accordance with the
            applicable laws of our jurisdiction.
          </p>
          <hr />

          <h3>12. Modifications to Terms</h3>
          <p>
            HeyInterns reserves the right to modify or update these Terms &
            Conditions at any time. We will notify users of significant changes
            by posting an updated version on the website.
          </p>
          <hr />

          <h3>13. Governing Law</h3>
          <p>
            These Terms & Conditions are governed by the laws of the country
            where HeyInterns is based, without regard to its conflict of law
            principles.
          </p>
          <hr />

          <h3>14. Contact Information</h3>
          <p>
            If you have any questions or concerns about these Terms &
            Conditions, you can contact us at:
            <a href="mailto:support@heyinterns.com">
              <strong>support@heyinterns.com</strong>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
