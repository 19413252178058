import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const appliedInternship = async (token,state) => {
  try {
    const response = await heyapi.get(
      state ? `/student/post/applied?state=${state}` : `/student/post/applied`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getMyStories = async (token) => {
  try {
    const response = await heyapi.get(
      `/student/story/all`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getStoryDetails = async (token, storyid, uType) => {
  try {
    const response = await heyapi.get(
      `/${uType}/story/details?storyid=${storyid}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const addStory = async (token, storyData) => {
  try {
    const response = await heyapi.post(
      `/student/story/add`,
      storyData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
  }
};

