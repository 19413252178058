import React from "react";
import WebsiteBreadCrumb from "./website-components/WebsiteBreadCrumb";
import { Helmet } from "react-helmet-async";

const EmployerInformation = () => {
  return (
    <div>
      <Helmet>
          <title>HeyInterns | Information For Employers </title>
      </Helmet>
      <WebsiteBreadCrumb title="Employer Information" />
      <div className="container policy-page">
        {/* Header Section */}
        <div className="heading-box">
          <h2>How It Works for Employers</h2>
          <p>
            At HeyInterns, we make it easy for employers to find talented
            students for internships. Whether you're offering a part-time or
            full-time internship, our platform simplifies the process of
            connecting with the right candidates.
          </p>
        </div>

        {/* Employer Information to Post Internship Section */}
        <div className="steps-section">
          <h3>How to Post an Internship</h3>
          <div className="steps-container">
            <div className="step">
              <h4>Create Your Employer Account</h4>
              <p>
                Register as an employer on HeyInterns. It’s simple and free!
                Just provide the necessary company details and get started with
                posting internships.
              </p>
            </div>
            <div className="step">
              <h4>Fill in Internship Details</h4>
              <p>
                Provide clear and detailed information about the internship,
                including the role, responsibilities, stipend, duration, and
                skills required. A complete and attractive internship posting
                will attract better candidates.
              </p>
            </div>
            <div className="step">
              <h4>Review Internship Applications</h4>
              <p>
                After posting your internship, start reviewing applications. You
                can filter and search for the best candidates based on your
                needs.
              </p>
            </div>
            <div className="step">
              <h4>Schedule Interviews</h4>
              <p>
                Once you’ve shortlisted candidates, you can schedule interviews
                directly through the platform. Keep the process smooth and
                efficient by managing everything in one place.
              </p>
            </div>
          </div>
        </div>

        {/* Platform Policies Section */}
        <div className="policy-section">
          <h3>Platform Guidelines & Policies for Employers</h3>
          <p>
            To ensure the best experience for both employers and interns, we
            have set up some important guidelines that employers must follow
            when posting internships on HeyInterns:
          </p>

          <div className="policy-item">
            <h4>1. Company Verification</h4>
            <p>
              To ensure the authenticity of employers, we require companies to:
            </p>
            <ul>
              <li>
                Submit valid business registration documents during onboarding.
              </li>
              <li>
                Provide accurate company details, including office address and
                contact information.
              </li>
              <li>
                Undergo periodic verification checks to maintain platform
                integrity.
              </li>
            </ul>
          </div>

          <div className="policy-item">
            <h4>2. Stipend and Payment Commitments</h4>
            <p>Employers are required to:</p>
            <ul>
              <li>
                Honor the stipend or salary stated in their internship postings.
              </li>
              <li>Ensure timely payment as per the internship agreement.</li>
              <li>
                Communicate any changes to payment terms with prior consent from
                the intern.
              </li>
            </ul>
            <p className="policy-warning">
              Failure to comply with payment terms may result in suspension from
              the platform.
            </p>
          </div>

          <div className="policy-item">
            <h4>3. Professional Environment</h4>
            <p>
              We expect employers to provide a respectful and harassment-free
              workplace. This includes:
            </p>
            <ul>
              <li>
                Respecting interns' dignity and treating them professionally.
              </li>
              <li>Ensuring tasks align with the job description.</li>
              <li>
                Avoiding any tasks outside the scope of the internship without
                prior agreement.
              </li>
            </ul>
          </div>

          <div className="policy-item">
            <h4>4. Clear Job Descriptions</h4>
            <p>
              Employers must provide clear and comprehensive job descriptions,
              including:
            </p>
            <ul>
              <li>Role responsibilities and expectations.</li>
              <li>Internship duration and working hours.</li>
              <li>Details of stipend or payment terms, if applicable.</li>
            </ul>
          </div>

          <div className="policy-item">
            <h4>5. Privacy and Data Security</h4>
            <p>
              We prioritize the confidentiality of intern data. Companies must
              ensure:
            </p>
            <ul>
              <li>Intern data is used solely for hiring purposes.</li>
              <li>
                Personal information, such as resumes and contact details, is
                kept secure.
              </li>
              <li>
                Interns' consent is obtained before sharing any data externally.
              </li>
            </ul>
          </div>

          <div className="policy-item">
            <h4>6. Consequences of Violations</h4>
            <p>
              Violations of our policies may result in the following
              consequences:
            </p>
            <ul>
              <li>Temporary suspension of account.</li>
              <li>
                Permanent removal from the platform for severe or repeated
                violations.
              </li>
              <li>Legal action in cases of fraud or unlawful activities.</li>
            </ul>
          </div>

          <div className="policy-item">
            <h4>7. Feedback and Reporting</h4>
            <p>
              Interns are encouraged to share their experiences. Companies
              receiving consistent negative feedback will be reviewed, and
              actions will be taken accordingly.
            </p>
            <p>
              To report issues or seek assistance, contact us at:{" "}
              <a href="mailto:support@heyinterns.com">
                <strong>support@heyinterns.com</strong>
              </a>
            </p>
          </div>

          <div className="policy-item">
            <h4>8. Policy Updates</h4>
            <p>
              We periodically update our policies to improve the platform.
              Significant changes will be communicated via email or a notice on
              the website. Please review this page regularly for updates.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployerInformation;
