import React from "react";
import WebsiteBreadCrumb from "./website-components/WebsiteBreadCrumb";

const CompanyPolicy = () => {
  return (
    <div>
      <WebsiteBreadCrumb title="Company Policy" />
      <div className="container policy-page pt-5">
        {/* Header Section */}
        <div className="heading-box">
          <h2>Company Policy</h2>
          <p>
            At HeyInterns, we are committed to fostering a trusted and reliable
            ecosystem for companies and interns alike. This policy outlines the
            guidelines and expectations for companies using our platform.
          </p>
        </div>

        {/* Content Section */}
        <div>
          <h3>Overview</h3>
          <p>
            All companies listed on HeyInterns must adhere to the principles of
            transparency, integrity, and professionalism. By using our Services,
            companies agree to abide by these policies to ensure a fair and
            productive environment for interns.
          </p>
          <hr />

          <h3>Company Verification</h3>
          <p>To maintain trust and authenticity, all companies must:</p>
          <ul>
            <li>
              Provide valid business registration documents during the
              onboarding process.
            </li>
            <li>
              Submit accurate company details, including office address, contact
              information, and primary point of contact.
            </li>
            <li>
              Undergo periodic verification to ensure continued compliance with
              platform standards.
            </li>
          </ul>
          <hr />

          <h3>Stipend and Payment Commitments</h3>
          <p>Companies are required to:</p>
          <ul>
            <li>
              Honor the stipend or salary promised in their internship postings.
            </li>
            <li>
              Ensure timely payment of the agreed-upon amount, as per the terms
              outlined in the internship agreement.
            </li>
            <li>
              Clearly communicate any changes to payment terms with prior
              consent from the intern.
            </li>
          </ul>
          <p>
            Non-compliance with payment commitments will result in strict
            action, including suspension or removal from the platform.
          </p>
          <hr />

          <h3>Professional Environment</h3>
          <p>
            Companies must provide a safe, respectful, and harassment-free
            workplace. They are expected to:
          </p>
          <ul>
            <li>Treat interns with dignity and respect.</li>
            <li>
              Ensure that tasks and responsibilities align with the job
              description shared during the hiring process.
            </li>
            <li>
              Avoid assigning tasks outside the scope of the internship without
              prior agreement.
            </li>
          </ul>
          <hr />

          <h3>Clear Job Descriptions</h3>
          <p>
            Companies must provide accurate and comprehensive job descriptions,
            including:
          </p>
          <ul>
            <li>Details of roles and responsibilities.</li>
            <li>Duration of the internship and working hours.</li>
            <li>Stipend or payment terms, if applicable.</li>
          </ul>
          <hr />

          <h3>Privacy and Data Security</h3>
          <p>
            Companies must handle intern data with utmost confidentiality.
            Personal information such as resumes, contact details, or other
            sensitive data must only be used for legitimate hiring purposes and
            never shared without consent.
          </p>
          <hr />

          <h3>Consequences of Policy Violations</h3>
          <p>
            Companies found violating any of these policies may face the
            following consequences:
          </p>
          <ul>
            <li>Warnings or temporary suspension of their account.</li>
            <li>
              Permanent delisting from the HeyInterns platform for repeated or
              severe violations.
            </li>
            <li>
              Legal action, if deemed necessary, in cases of fraud or other
              unlawful activities.
            </li>
          </ul>
          <hr />

          <h3>Feedback and Reporting</h3>
          <p>
            Interns are encouraged to provide feedback on their internship
            experiences. Companies receiving consistent negative feedback will
            be reviewed, and appropriate action will be taken.
          </p>
          <p>
            To report violations or seek assistance, please contact us at:{" "}
            <a href="mailto:support@heyinterns.com">
              <strong>support@heyinterns.com</strong>
            </a>
          </p>
          <hr />

          <h3>Policy Updates</h3>
          <p>
            We may revise this Company Policy periodically. Substantial updates
            will be communicated via email or a notice on our website. Please
            review this page regularly for the latest version.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompanyPolicy;
