import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import { getStatusTag } from "../common/commonFunctions";
import Tooltip from '@mui/material/Tooltip';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import CustomLoader from "../components/CustomLoader";
import DataTable from "./components/DataTable";
import { getAllStories, updateStoryState } from "../services/adminStories";
import RejectReasonBox from "../dialogBoxes/SetReason";
import AlertMsg from "../dialogBoxes/AlertMsg";
import SingleInternshipModal from "../modals/SingleInternshipModal";
import EditIcon from '@mui/icons-material/Edit';
import { CustomAlert, showToastAlert } from "./components/CustomAlert";
import SingleInternshipStoryModal from "../modals/SingleInternshipStoryModal";
import InfoIcon from "@mui/icons-material/Info";

const AdminAllStoryList = () => {
    const [loaderOpen, setLoaderOpen] = React.useState(true);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [apiData, setApiData] = React.useState([]);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [selectedId, setSelectedId] = useState("");
    const [showOptions, setShowOptions] = useState("Approve");
    const [selectedOptions, setSelectedOptions] = useState(new Map());
    const [showAlert, setShowAlert] = useState(false);
    const [showReasonPopup, setShowReasonPopup] = useState(false);
    const [reason, setReason] = useState("");
    const [currStoryId, setCurrStoryId] = React.useState("");
    const admin = useSelector((state) => state.admin);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const {state} = useParams();
    // Access the specific search key value
    let searchKeyValue = state;
    if (searchKeyValue == undefined) {
        searchKeyValue = "all";
    }
    
    const {id} = useParams();


    const tableColumns = [
        {
            field: "title",
            headerName: "TITLE",
            flex: 1,
            editable: false
        },
        {
            field: "name",
            headerName: "name",
            flex: 1,
            editable: false,
            renderCell: (params) => (
                <p>{params.row.ownerId.name}</p>
            ),
        },
        {
            field: "status",
            headerName: "STATUS",
            flex: 1,
            renderCell: (params) => (
                <>
                <Select
                    className="status-selectbox"
                    value={params.value}
                    onChange={(event) => {
                        const newValue = event.target.value;
                        handleOptionChange(params.row._id, newValue);
                        params.api.updateRows([{ ...params.row, status: newValue }]);
                    }}
                >
                    {postOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            {getStatusTag(option)}
                        </MenuItem>
                    ))}
                </Select>
                {params.row.reason && 
                    <Tooltip title={params.row.reason}>
                    <InfoIcon color="primary" /> 
                    </Tooltip>}
                </>
            ),
        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            minWidth: 230,
            editable: false,
            renderCell: (params) => (
                <div>
                    <Button
                        className="circular-btn me-2"
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={() => handleStoryStateUpdate(params)}
                    >
                        Update
                    </Button>
                    <Tooltip title="Show Story Details">
                        <Button
                            variant="outlined"
                            onClick={() => handleStoryView(params)}
                            className="circular-btn me-2"
                            startIcon={<RemoveRedEyeRoundedIcon />}
                        />
                    </Tooltip>
                    <Tooltip title="Edit Story Details">
                        <Button
                            variant="outlined"
                            onClick={() => editStoryView(params)}
                            className="circular-btn me-2"
                            startIcon={<EditIcon />}
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    useEffect(() => {
        getStories(searchKeyValue)
    }, [admin, searchKeyValue]);

    const getStories = async (tag) => {
        try {
            setLoaderOpen(true);
            const response = await getAllStories(tag, admin.token);
            if(response.status !== 200) {
                showToastAlert(response.data.msg, "error");
                return;
            }
            setApiData(response.data.stories);
            //set initial post status array 
            let tempStatusMap = new Map();
            response.data.stories.map((item, stIndex) => {
                tempStatusMap.set(item._id, item.status);
            })
            setSelectedOptions(tempStatusMap);
            setLoaderOpen(false);
        }
        catch (err) {
            throw new Error(err)
        }
    }
    const handleUpdate = async (params) => {
        const id = params.row._id;
        const status = params.row.status;
        // Call your update function here with the row ID and status value
        const response = await updateStoryState(admin.token, id, status);
        if(response.status !== 200) {
            showToastAlert(response.data.msg, "error");
            return;
        }
        console.log(`Updating row with ID: ${id}, Status: ${status}`);
        // Your update logic here
    };
    const handleStoryView = (params) => {
        const id = params.row._id;  
        setCurrStoryId(id);
        setModalOpen(true);
    };

    const editStoryView = (params) => {
        const id = params.row._id;  
        navigate(`/admin/post/edit/${id}`);
    };

    const closeStoryView = () => {
        setModalOpen(false);
    }

    const postOptions = ["pending", "approved", "closed", "rejected"]; //live is just approved with live condition
    const handleOptionChange = (rowIndex, value) => {
        setSelectedOptions((prevValues) => {
            const tempValues = new Map(prevValues);
            tempValues.set(rowIndex, value);
            return tempValues;
        })
    }

    const callStoryStateUpdateApi = async (storyid, aReason) => {
        const curSelOption = selectedOptions.get(storyid)
        const response = await updateStoryState(storyid, curSelOption, admin.token, aReason);
        if(response.status !== 200) {
            showToastAlert(response.data.msg, "error");
            return;
        }
        showToastAlert("Story state updated", "success");
        // Remove the row from the table
        setApiData((prevApiData) => prevApiData.filter((row) => row._id !== storyid));
    }

    const handleClose = () => {
        setShowAlert(false);
    }

    const handleReasonSubmit = async (storyid, aReason) => {
        if (aReason === "")
            return;
        setReason(aReason);
        setShowReasonPopup(false);
        await callStoryStateUpdateApi(storyid, aReason);
    }
    const handleStoryStateUpdate = async (params) => {
        const storyid = params.row._id;
        setSelectedId(storyid);
        const curSelOption = selectedOptions.get(storyid)
        if(curSelOption === searchKeyValue) //do nothing 
            return; 
        if (curSelOption === "rejected" || curSelOption == "closed") {
            // Show the input popup for the reason
            setShowReasonPopup(true);
        } else {
            await callStoryStateUpdateApi(storyid, "");
        }
    }

    return (
        <div>
            <h1 className="page-title-1 my-3 asdsd">Employer Stories</h1>
            <CustomLoader setLoaderOpen={setLoaderOpen} loaderOpen={loaderOpen} />
            <DataTable data={apiData} columns={tableColumns} id="_id" />
            <SingleInternshipStoryModal open={modalOpen} storyid={currStoryId} closeStoryView={closeStoryView} uType="admin" />
            <RejectReasonBox selectedId={selectedId} showReasonPopup={showReasonPopup} setShowReasonPopup={setShowReasonPopup} setReason={setReason} handleReasonSubmit={handleReasonSubmit} />
            <CustomAlert />
        </div>
    );
};

export default AdminAllStoryList;
