import React from "react";
import { Card, CardContent, CardMedia, Typography, Grid, Container, Avatar, Box } from "@mui/material";
import { Link } from "react-router-dom";
const InternshipStoryCard = ({ blog }) => {
    return (
        <Link to={`/internship-story/${blog.slug}`} style={{ textDecoration: "none" }}>
        <Card sx={{ height: 350, display: "flex", flexDirection: "column", borderRadius: 3, boxShadow: 3, transition: "0.3s", '&:hover': { boxShadow: 6 } }}>
            <CardContent sx={{ flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                <div>
                    <Typography variant="h6" gutterBottom fontWeight="bold">
                        {blog.title}
                    </Typography>
                    <Typography variant="p" gutterBottom fontWeight="bold">
                        {blog.month} - {blog.year} | {blog.duration} Months
                    </Typography>
                </div>
                <Box display="flex" alignItems="center">
                    <Avatar src={blog.authorImage} alt={blog.author} sx={{ marginRight: 1 }} />
                    <Typography variant="body2" color="textPrimary">
                        {blog.ownerId?.name}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
        </Link>
    );
};

export default InternshipStoryCard;
