import React from "react";
import { Outlet } from "react-router-dom";
import StudentDashboardSidebar from "./Sidebar";
import WebsiteFooter from "../../website/website-components/WebsiteFooter";
import "./../student.css"; // Import the updated CSS
import StudentNav from "./Navbar";
import MainHeader from "../../components/MainHeader";

const StudentDashboardLayout = () => {
  return (
    <div className="student-dashboard-wrapper">
      <MainHeader />
      <div className="container-fluid py-4">
        <Outlet />
      </div>
    </div>
  );
};

export default StudentDashboardLayout;
