import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const postExperience = async (
  token,
  profile,
  company,
  workMode,
  startDate,
  endDate,
  description,
  location
) => {
  try {
    console.log(
      profile,
      company,
      workMode,
      startDate,
      endDate,
      description,
      location,
      token    );
    const response = await heyapi.post(
      `/student/experience/add`,
      {
        profile,
        company,
        workMode,
        startDate,
        endDate,
        description,
        location
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (err) {
    console.error(err);
    throw err; // Rethrow the error to handle it in the calling code if needed
  }
};
