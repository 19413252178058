import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance"

export const ApplyPost = async (id,postid, availability, coverLetter, answers, token
  ) => {
    console.log(id, postid, availability, coverLetter, answers, token);
  try {
    const response = await heyapi.post(`/student/post/apply`,{id,postid,availability, coverLetter, answers}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
