import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const filterPost = async (token, locs, skills, stipend, maxDuration, wfo) => {
  if (locs === undefined)
    locs = [];
  if (skills === undefined)
    skills = [];
  if (wfo === undefined)
    wfo = false;
  if (stipend == undefined)
    stipend = 0;
  const workModel = wfo ? "remote" : "all";
  //skills = skills.map((item) => item.name);
  //locs = locs.map((item) => item.city);
  console.log(`/post/filter?minStipend=${stipend * 100}&maxDuration=${maxDuration == null ? "" : maxDuration}&workModel=${workModel}&skills=${skills}&locs=${locs}`)

  try {
    const response = await heyapi.get(
      `/post/filter?minStipend=${isNaN(stipend) ? "" : stipend * 100}${maxDuration !== undefined && maxDuration !== null
        ? `&maxDuration=${maxDuration}`
        : ""
      }&workModel=${workModel}&skills=${skills}&locs=${locs}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const latestPosts = async () => {
  try {
    const response = await heyapi.get(
      `/post/latest`,
      {
        headers: {
          
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const skillsInDemand = async () => {
  try {
    const response = await heyapi.get(
      `/post/topskills`,
      {
        headers: {
          
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};