import React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import PropTypes from "prop-types";

const DataTable = ({ data, columns, id }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: {
          xs: 300, // height for small screens
          sm: 400, // height for medium screens
          md: 500, // height for large screens
          lg: 600, // height for extra-large screens
          xl: 800, // height for extra-large screens
        },
      }}
    >
      <DataGrid
        rows={data}
        columns={columns}
        getRowId={(row) => row[id]}
        pageSize={10}
        rowsPerPageOptions={[10]}
        pagination
        checkboxSelection
        disableRowSelectionOnClick
        slots={{ toolbar: GridToolbar }} 
        disableColumnFilter  
        disableColumnMenu
        disableColumnSelector
        
      />
    </Box>
  );
};

DataTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
};

export default DataTable;
