import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { logout } from "./../../Store/Action";

const LeftSidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.admin);

  const [openEmployersSubmenu, setOpenEmployersSubmenu] = useState(false);
  const [openPostsSubmenu, setOpenPostsSubmenu] = useState(false);
  const [openStudentsSubmenu, setOpenStudentsSubmenu] = useState(false);
  const [openCollegesSubmenu, setOpenCollegesSubmenu] = useState(false);
  const [openStoriesSubmenu, setOpenStoriesSubmenu] = useState(false);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/admin/login");
  };

  const toggleEmployersSubmenu = () => {
    setOpenEmployersSubmenu(!openEmployersSubmenu);
  };

  const togglePostsSubmenu = () => {
    setOpenPostsSubmenu(!openPostsSubmenu);
  };

  const toggleStoriessSubmenu = () => {
    setOpenStoriesSubmenu(!openStoriesSubmenu);
  };

  const toggleStudentsSubmenu = () => {
    setOpenStudentsSubmenu(!openStudentsSubmenu);
  };

  const toggleCollegesSubmenu = () => {
    setOpenCollegesSubmenu(!openCollegesSubmenu);
  };

  return (
    <React.Fragment>
      <div className="dashboard-sidebar">
        <div className="dashboard-userdata-box">
          <img
            src="https://cdn-icons-png.flaticon.com/512/560/560199.png"
            alt="Admin"
            className="img-fluid"
          />
          <h4>{isAuthenticated.user.name}</h4>
          <h5>{isAuthenticated.user.email}</h5>
        </div>
        <ul>
          <li>
            <div
              className="menu-item"
              onClick={toggleEmployersSubmenu}
            >

            Employers(Approval)  {openEmployersSubmenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>

            {openEmployersSubmenu && (
              <ul className="submenu2">
                <li>
                  <NavLink className="menu-item" to="/admin/employers/review"> 
                    Under review
                  </NavLink>
                </li>
                <li>
                  <NavLink className="menu-item" to="/admin/employers/pending"> 
                    Pending Approval
                  </NavLink>
                </li>
                <li>
                  <NavLink className="menu-item" to="/admin/employers/active">
                    Active Employers
                  </NavLink>
                </li>
                <li>
                  <NavLink className="menu-item" to="/admin/employers/rejected">
                    Rejected Employers
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
          <li>
            <div
              className="menu-item"
              onClick={togglePostsSubmenu}
            >

              Posts(Approval)  {openPostsSubmenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>

            {openPostsSubmenu && (
              <ul className="submenu2">
                <li>
                  <NavLink className="menu-item" to="/admin/posts/pending">
                    Pending Approval
                  </NavLink>
                </li>
                <li>
                  <NavLink className="menu-item" to="/admin/posts/approved">
                    Approved Posts
                  </NavLink>
                </li>
                <li>
                  <NavLink className="menu-item" to="/admin/posts/rejected">
                    Rejected Posts
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
          <li>
            <div
              className="menu-item"
              onClick={toggleStudentsSubmenu}
            >

              Students  {openStudentsSubmenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>

            {openStudentsSubmenu && (
              <ul className="submenu2">
                <li>
                  <NavLink className="menu-item" to="/admin/students/all">
                    All
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
          <li>
            <div
              className="menu-item"
              onClick={toggleCollegesSubmenu}
            >

              Colleges  {openCollegesSubmenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>

            {openCollegesSubmenu && (
              <ul className="submenu2">
                <li>
                  <NavLink className="menu-item" to="/admin/colleges/all">
                    All
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
          <li>
            <div
              className="menu-item"
              onClick={toggleStoriessSubmenu}
            >

              Stories(Approval)  {openStoriesSubmenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>

            {openStoriesSubmenu && (
              <ul className="submenu2">
                <li>
                  <NavLink className="menu-item" to="/admin/stories/pending">
                    Pending Approval
                  </NavLink>
                </li>
                <li>
                  <NavLink className="menu-item" to="/admin/stories/approved">
                    Approved Stories
                  </NavLink>
                </li>
                <li>
                  <NavLink className="menu-item" to="/admin/stories/rejected">
                    Rejected Stories
                  </NavLink>
                </li>
              </ul>
            )}
          </li>

        </ul>
      </div>
    </React.Fragment>
  );
};

export default LeftSidebar;
