import React from "react";

import { Typography } from "@mui/material";
import { formatDate } from "../../common/commonFunctions";

const generateBgColor = (str) => {
  if (!str || str.length === 0) return "#333333"; // Default dark color if the string is empty

  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Generate dark RGB values
  const r = (hash & 0xff0000) >> 16;
  const g = (hash & 0x00ff00) >> 8;
  const b = hash & 0x0000ff;
  const darkR = Math.floor(r / 2);
  const darkG = Math.floor(g / 2);
  const darkB = Math.floor(b / 2);

  return `#${[darkR, darkG, darkB]
    .map((val) => val.toString(16).padStart(2, "0"))
    .join("")}`;
};

const ChatUserBox = (props) => {
  const name = props.tRoom.studentId ? props.tRoom.studentId.name : "";
  const firstLetter = name.charAt(0).toUpperCase();

  return (
    <div className={`chat-user-box ${props.isActive ? "active" : ""}`}>
      <figure className="avatar avatar-state-success">
        <div
          className="rounded-circle"
          style={{
            backgroundColor: generateBgColor(props.tRoom.studentId.name),
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          {firstLetter}
        </div>
      </figure>
      <div className="users-list-body">
        <div>
          <h5 className="text-primary">
            {props.tRoom.studentId ? props.tRoom.studentId.name : ""}
          </h5>
          <Typography
            className="student-profile"
            sx={{ mb: 1.5 }}
            color="text.secondary"
          >
            {props.tRoom.postId.title}
          </Typography>
          <p>
            {props?.tRoom?.lastMessage?.slice(0, 20) }
            ...
          </p>
        </div>
        <div className="users-list-action">
          <div className="new-message-count">*</div>
          <small className="text-primary">{formatDate(props?.tRoom?.updatedAt)}</small>
        </div>
      </div>
    </div>
  );
};

export default ChatUserBox;
