import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const UpdateEmployerCompanyDetail = async (token, name, website, address, industry, description, numEmployee
) => {
  //console.log(name, website, address, industry, description, numEmployee, token );
  try {
    const response = await heyapi.patch(
      `/employer/company/update`,
      { name, website, address, industry,  description, numEmployee },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const UpdateEmployerProfileDetail = async (token, name, linkedin, mobile) => {
  try {
    const response = await heyapi.patch(
      `/employer/profile/update`,
      { name, linkedin, mobile },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const employerUpdateOnboardStatus = async (
  token
) => {
  try {
    const response = await heyapi.post(`/employer/onboarded`, {
    },   {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    return err.response.data;
  }
};