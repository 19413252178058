import React, { useState } from "react";
import heyapi from "../../middleware/axiosInstance";
import { TextField, Autocomplete } from "@mui/material";
import axios from "axios";

const CollegeAutoComplete = ({value, setCollege}) => {
  const [colleges, setColleges] = useState([]);

  const fetchColleges = async (query) => {
    if (query.length < 4) return; // Avoid unnecessary API calls for short inputs
    try {
        const response = await heyapi.get(
            `/data/colleges/autocomplete?search=${query}`
          );
      setColleges(response.data);
    } catch (error) {
      console.error("Error fetching colleges:", error);
    }
  };

  return (
    <Autocomplete
      freeSolo
      fullWidth
      value={value}
      options={colleges.map((college) => college.name)}
      onInputChange={(event, newInputValue) => {
        setCollege(newInputValue);
        fetchColleges(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" placeholder="National Institute..." fullWidth />
      )}
    />
  );
};

export default CollegeAutoComplete;
