
import moment from "moment"; // Optional: moment.js for simplified date formatting

export const toCamelCase = (str) => {
    return str
      .toLowerCase() // Convert the entire string to lowercase
      .replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase()); // Remove non-alphanumeric characters and capitalize the next character
}

export const toCamelCaseForFirstChar = (str) => {
    if (typeof str !== 'string' || str.trim() === '') {
        return ''; // Return an empty string for non-string or empty input
    }
    return str
    .split(' ') // Split the string by spaces
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first char of each word
    .join(' '); // Join the words back together with a space
}

export const getArrForAutoComplete = (str, property="name") => { 
    // Check if str is a valid array
    if (!Array.isArray(str) || str.length === 0) {
        return [];
    }

    const mapArr = str.filter(item => item !== "")
                      .map((item, index) => {
                            if(item !== "")
                                return { id: index, [property]: toCamelCaseForFirstChar(item) };
                        });
    return mapArr;
};

//TODO: fix corner cases in case wrong input 
export const getSkillAndLocations = (inputString) => {
    let skillsArray = [];
    let locationsArray = [];
    if (inputString === "")
        return [skillsArray, locationsArray];
    let skillsString= "";
    let locationsString = "";
    if(inputString.includes("_internship_in_")) {
        //both exists
        [skillsString, locationsString] = inputString.split('_internship_in_');
    } else if(inputString.includes("internship_in_")) {
        //only locations exits
        [skillsString, locationsString] = inputString.split('internship_in_');
    } else if(inputString.includes("_internship")) {
        //only skills exist
        [skillsString, locationsString] = inputString.split('_internship');
    }
   
    // Split the skills part into an array using ',' as a delimiter
    skillsArray = skillsString.split(',');
    //remove - with space 
    skillsArray = skillsArray.map((item) => item.replace('-', ' '))
    // Split the locations part into an array using ',' as a delimiter (if multiple locations)
    locationsArray = locationsString.split(',');
    locationsArray = locationsArray.map((item) => item.replace('-', ' '))

    return [skillsArray, locationsArray]
};

 // Function to generate a random color
 export const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export const formatMessageTime = (createdAt) => {
  const messageDate = new Date(createdAt);
  const now = new Date();

  const isToday = messageDate.toDateString() === now.toDateString();
  const isYesterday =
    messageDate.toDateString() ===
    new Date(now.setDate(now.getDate() - 1)).toDateString();

  if (isToday) {
    return `Today ${messageDate.getHours()}:${messageDate.getMinutes().toString().padStart(2, '0')}`;
  } else if (isYesterday) {
    return `Yesterday ${messageDate.getHours()}:${messageDate.getMinutes().toString().padStart(2, '0')}`;
  } else {
    return `${messageDate.getDate()}/${messageDate.getMonth() + 1}/${messageDate.getFullYear()} ${messageDate.getHours()}:${messageDate.getMinutes().toString().padStart(2, '0')}`;
  }
};
