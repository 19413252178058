import {
  Box,
} from "@mui/material";
import Breadcrumb from "../components/Breadcrumb";
import EmployerProfileComponent from "./components/EmployerProfileComponent";

const EditProfile = () => {
  return (
    <div className="container employer-profile-page py-3 custom-form">
      <Breadcrumb
        heading="Profile Setting"
        breadcrumbs={[
          { label: "Dashboard", href: "/employer/dashboard" },
          { label: "Profile Setting", href: null },
        ]}
      />
      
      <Box>
        <Box mt={4}><EmployerProfileComponent /> </Box>
      </Box>
    </div>
  );
};

export default EditProfile;
