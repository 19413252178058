import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const editInternship = async (
  token,
  internnshipId,
  profile,
  company,
  workMode,
  startDate,
  endDate,
  description,
  location
  
) => {
  try {
    console.log(
      token,
      internnshipId,
      profile,
      company,
      workMode,
      startDate,
      endDate,
      description,
      location
    );
    const response = await heyapi.put(
      `/student/experience/${internnshipId}`,{
      profile,
      company,
      workMode,
      startDate,
      endDate,
      description,
      location
      }
,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};
