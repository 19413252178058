import React, { useState, useEffect, useContext } from "react";
import "../App.css";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { post } from "../services";
import {
  getEmployer,
  setEmployer,
  setToken,
  setUser,
} from "../services/session";
import UserContext from "../context/userContext";
import { employerRegister } from "../services/employerRegister";
import { adminRegister } from "../services/adminRegister";

function AdminSignUp() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [err, setErr] = React.useState(false);
  const navigate = useNavigate();
  const registerEmployer = async () => {
    try {
      const data = await adminRegister(
        email,
        password,
        firstName + " " + lastName
      );
      console.log(data, "ad");
      if (data.success) {
        navigate("/admin/login");
      } else {
        setErr(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const isLogin = async () => {
  //     const employee = await getEmployer();
  //     console.log("my emoplbj", employee);
  //     if (employee != null) {
  //         navigate('/employer');
  //     }
  // }

  // useEffect(() => {
  //     isLogin();
  // }, []);

  return (
    <>
      <Container
        className="mt-5 job-container border rounded bg-light    "
        style={{ height: "600px", width: "500px" }}
      >
        <Box noValidate sx={{ mt: 3 }}>
          {err && (
            <p
              style={{
                color: "red",
                fontSize: "16px",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              * User axist Already
            </p>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                size="small"
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                size="small"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                size="small"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                size="small"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid>
          </Grid>
          <Button
            type="primary"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={registerEmployer}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="center">
            <Grid item>
              <Link to={"/student/login"} variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default AdminSignUp;
