import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const AddProjectApi = async (token, projectid, title,description, startDate,endDate,link) => {
    console.log(projectid, title, startDate, endDate, link, description);
  try {
    const response = await heyapi.post(
      `/student/project/add`,
      {
        projectid,
        title,
        startDate,
        endDate,
        link,
        description
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getProjectById = async (id, token) => {
  console.log(id);
  try {
    const response = await heyapi.get(
      `/student/project/get?id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteProject = async (id, token) => {

  console.log(id);
  try {
    const response = await heyapi.delete(
      `/student/project/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};