import React from "react";
import { Outlet } from "react-router-dom";
import { Box, Checkbox, FormControlLabel, Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import PostApplicationSummary from "./ApplicationSumary";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useParams } from "react-router-dom";
import {
  allPostApplications,
  allPostApplicationsStatus,
  allPostAssignments,
  sendAssignment,
  updateApplicationStateApi,
} from "../../services/getAllApplicationEmployee";
import ApplicantBox from "../../components/ApplicantBox";
import { useOutletContext } from "react-router-dom";
import AssignmentModal from "../modals/AssignmentModal";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import {
  CustomAlert,
  showToastAlert,
} from "../../admin/components/CustomAlert";
import ApplicantModal from "../modals/ApplicantModal";
import AssignmentCard from "../components/AssignmentCard";
const PostAssignmentList = () => {
  const { counts, setCounts } = useOutletContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { id, state } = useParams();
  // Access the specific search key value
  let searchKeyValue = state;
  if (searchKeyValue == undefined) {
    searchKeyValue = "pending";
  }

  const value1 = 2;
  const value2 = 1;
  const value3 = 3;
  const value4 = 3;

  const [value, setValue] = React.useState("one");
  const [apiData, setApiData] = useState();
  const [showAssigmentModal, setShowAssigmentModal] = useState(false);
  const [showApplicantModal, setShowApplicanttModal] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currApplicantId, setCurrApplicantId] = useState(null);

  const token = useSelector((state) => state.token);
  const updateApplicationState = async (appid, appState) => {
    //appid = application id
    try {
      const data = await updateApplicationStateApi(token, appid, appState);
      console.log(data);
      if (data.msg == "success") {
        //remove application from current array
        const newArr = assignments.filter((item) => item._id !== appid);
        setAssignments(newArr);
      }
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    viewApplication();
  }, [searchKeyValue]);
  const viewApplication = async () => {
    try {
      const response = await allPostAssignments(token, id);

      if(response.status !== 200) {
        showToastAlert(response.data.msg, "error")
        return;
      }
      console.log(response.data);
      setAssignments(response.data.assignments);
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box className="application-box-wrapper">
      <div>
        <div className="align-center  application-check-wrap">
          <h5 className="applicants-text">
            Total {assignments.length} Assigments
          </h5>
        </div>

        {assignments &&
          assignments.map((item) => (
            <AssignmentCard
              key={item._id}
              item={item}
              postid={id}
              updateApplicationState={updateApplicationState}
              setCurrApplicantId={setCurrApplicantId}
              setShowApplicanttModal={setShowApplicanttModal}
            />
          ))}
      </div>
      <CustomAlert />
      <ApplicantModal showApplicantModal={showApplicantModal} currApplicantId={currApplicantId} setShowApplicanttModal={setShowApplicanttModal}/>
    </Box>
  );
};

export default PostAssignmentList;
