import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const employerRegister = async (
  email,
  password,
  name,
  answer,
  captchaHex
) => {
  try {
    const response = await heyapi.post(`/employer/register`, {
      email,
      password,
      name,
      answer,
      captchaHex,
    });
    console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
};
