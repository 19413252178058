import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Define a function to show toast alerts
const showToastAlert = (message, type = "info") => {
  switch (type) {
    case "success":
      toast.success(message, { autoClose: 3000 });
      break;
    case "error":
      toast.error(message, { autoClose: 3000 });
      break;
    case "info":
      toast.info(message, { autoClose: 3000 });
      break;
    default:
      toast(message, { autoClose: 3000 });
  }
};

// Define a component to render the ToastContainer
const CustomAlert = () => (
  <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
);

export { CustomAlert, showToastAlert };
