export const getStatusTag = (status) => {
  switch (status) {
    case "approved":
      return <span className="status-tag status-approved">{status}</span>;
    case "pending":
      return <span className="status-tag status-pending">{status}</span>;
    case "rejected":
      return <span className="status-tag status-rejected">Clarification Needed</span>;
    case "disabled":
      return <span className="status-tag status-disable">{status}</span>;

    case "active":
      return <span className="status-tag status-active">{status}</span>;

    default:
      return <span className="status-tag ">{status}</span>;
  }
};

export const calculateDaysPassed = (createdDate) => {
  const created = new Date(createdDate);
  const now = new Date();
  const differenceInTime = now.getTime() - created.getTime();
  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
  return differenceInDays;
};

export const isValidIndianMobileNumber = (mobileNumber) => {
  const indianNumberRegex = /^[6-9]\d{9}$/;

  if (!mobileNumber) {
    return false;
  }

  if (!indianNumberRegex.test(mobileNumber)) {
    return false;
  } 

  return true;
};

export const isEmployerCompanyFieldCompleted = (user) => {
  if(!user) 
    return false;
  if(!(user.company))
    return false;
  if(!(user.company.description))
    return false;
  if(!(user.company.address))
    return false;
  if(!(user.company.industry))
    return false;
  if(!(user.company.domain))
    return false;
  if(!(user.company.domain.url))
    return false;
  return true;
};

export const formatDate = (isoDate, type = 0) => {
  // Convert to a Date object
  const date = new Date(isoDate);
  // Format to dd-mm-yy 

  const day = String(date.getDate()).padStart(2, '0');
  const monthName = date.toLocaleString('default', { month: 'short' });
  const year = String(date.getFullYear()).slice(-2);

  return `${day} ${monthName} ${year}`;
}

export const toCamelCase = (input) => {
  return input
    .toLowerCase() // Convert entire string to lowercase
    .split(/[^a-zA-Z0-9]/) // Split by non-alphanumeric characters
    .map((word, index) => {
      if (index === 0) {
        return word; // Keep the first word lowercase
      }
      return word.charAt(0).toUpperCase() + word.slice(1); // Capitalize first letter of subsequent words
    })
    .join(''); // Join all words together
}

export const validateUrl = (url, type) => {
  const regexMap = {
    linkedin: /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|company|pub)\/[a-zA-Z0-9_-]+\/?$/,
    youtube: /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|c\/|channel\/)|youtu\.be\/)[a-zA-Z0-9_-]+/,
    github: /^(https?:\/\/)?(www\.)?github\.com\/[a-zA-Z0-9_-]+\/?$/,
    website: /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[^\s]*)?$/,
  };

  if (!url) return false; // Handle empty URL

  // If missing http or https, add https://
  if (!/^https?:\/\//.test(url)) {
    url = `https://${url}`;
  }

  // If missing www, add www (only if it doesn't start with subdomain)
  const domainPattern = /^(https?:\/\/)(?!www\.)/;
  if (domainPattern.test(url)) {
    url = url.replace(domainPattern, '$1www.');
  }

  const regex = regexMap[type];
  return regex ? (regex.test(url) ? url : false) : false;
};
