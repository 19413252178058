import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../Store/Action';

const StudentLogout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(logout());
        navigate('/');
    }, []);
    return (
        <></>
    );
};

export default StudentLogout;