import React, { useEffect } from "react";
import { latestPosts } from "../../services/filterPost";
import { Box } from "@mui/material";
import { CircularProgress } from "@material-ui/core";
import InternshipSummaryCard from "../../components/InternshipSummaryCard";
const InternshipsSections = () => {
  const [posts, setPosts] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    getLatestPosts();
  }, []);

  const getLatestPosts = async () => {
    const tposts = await latestPosts();
    setPosts(tposts?.posts || []);
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <div className="section-full latest-jobs content-inner-1 bg-white">
          <div className="container">
            <div className="latest-jobs-inner">
              <div className="section-head style-1">
                <h6>Jumpstart Your Career with Fresh Internships</h6>
                <h2 className="section-title-3">
                  Latest Internship Opportunities
                </h2>
                <p className="dz-text-2">Discover 500+ New Internships Daily</p>
              </div>
              <a href="/internships" className="site-button style-1">
                Explore All
              </a>
            </div>
            <div className="row sp20 m-b20">
              {posts?.length > 0 ? (
                posts.map((postData, index) => (
                  <div className="col-sm-6">
                    <InternshipSummaryCard
                      data={postData}
                      key={postData.title + index}
                    />
                  </div>
                ))
              ) : (
                <p>No internships available.</p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InternshipsSections;
