import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import PostApplicationsSidebar from "./Sidebar";
import PostApplicationsRightSidebar from "./RightSidebar";
import "./../../pages/Employer.scss";
import MainHeader from "../../components/MainHeader";
import { Button, IconButton, Tooltip } from "@mui/material";
import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import TuneIcon from "@mui/icons-material/Tune";

const PostApplicationsLayout = () => {
  const [counts, setCounts] = useState({
    pending: 0,
    shortlist: 0,
    hired: 0,
    rejected: 0,
    all: 0,
  });
  const [openChatSidebar, setOpenChatSidebar] = React.useState(false);
  const [openFilterSidebar, setOpenFilterSidebar] = React.useState(false);

  return (
    <div className="admin-page-wrapper">
      <MainHeader />
      <div className="admin-page-box dashboard-page-box">
        <PostApplicationsSidebar
          counts={counts}
          setOpenChatSidebar={setOpenChatSidebar}
          openChatSidebar={openChatSidebar}
        />
        <div className="dashboard-page-content-wrapper">
          <div className="dashboard-contentbox d-flex">
            <Tooltip title="Open Dashboard" arrow>
              <IconButton
                variant="contained"
                aria-label="more"
                id="open-admin-dashboard-btn"
                className="icon-btn"
                onClick={() => {
                  setOpenChatSidebar((prev) => !prev);
                  console.log("open ");
                }}
              >
                <MenuOpenOutlinedIcon />
              </IconButton>
            </Tooltip>

            <h2 className="mobile-filter-btn">
              <Button
                variant="text"
                onClick={() => {
                  setOpenFilterSidebar((prev) => !prev);
                }}
              >
                Filters <TuneIcon />
              </Button>
            </h2>

            <Outlet context={{ counts, setCounts }} />
            <PostApplicationsRightSidebar
              setOpenFilterSidebar={setOpenFilterSidebar}
              openFilterSidebar={openFilterSidebar}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostApplicationsLayout;
