import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link, useNavigate } from "react-router-dom";
import { post } from "../services";
import { setToken } from "../services/session";
import { studentDeleteAccount } from "../services/studentRegister";
import { fetchCaptchaApi } from "../services/captchaApi";
import { logout } from "../Store/Action";
import { useDispatch, useSelector } from "react-redux";
import { CustomAlert, showToastAlert } from '../admin/components/CustomAlert';

// TODO remove, this demo shouldn't need to reset the theme.

export default function DeleteAccount() {
  const [password, setpassword] = React.useState("");
  const [answer, setAnswer] = React.useState("");
  const [captchaHex, setCaptchaHex] = React.useState("");
  const [captchaImg, setCaptchaImg] = React.useState("");
  const [err, setErr] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const deleteStudent = async () => {
    if(!password || !answer) {
      showToastAlert("Fill all fields", "error");
      return;
    }
    try {
      const response = await studentDeleteAccount(token, password, answer, captchaHex);
      if (response.status === 200) {
        showToastAlert("Account delete permanently", "success");
        dispatch(logout());
        navigate("/");
      } else {
        setErr(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    fetchCaptcha();
  }, []);

  const fetchCaptcha = async () => {
    try {
      const res = await fetchCaptchaApi();
      if (res) {
        setCaptchaHex(res.hex);
        setCaptchaImg(res.img);
      }
    } catch (error) {
      console.error("Error fetching CAPTCHA", error);
    }
  };

  return (
    <div className="themeprovider-wrapper">
      <CustomAlert />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            marginBottom: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "30px",
            borderRadius: "10px",
            boxShadow:
              "-2px -2px 6px rgba(0,107,194,.12), 2px 2px 6px rgba(0,107,194,.12)",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "red" }}>
            <DeleteForeverIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Delete Account
          </Typography>
          <Box noValidate sx={{ mt: 3 }}>
            {err !== "" && (
              <p
                style={{
                  color: "red",
                  fontSize: "16px",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                {err}
              </p>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => {
                    setpassword(e.target.value);
                  }}
                  size="small"
                  required
                  fullWidth
                  id="password"
                  label="current password"
                  name="password"
                  autoComplete="password"
                  type="password"
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <img src={captchaImg} alt="CAPTCHA" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={(e) => setAnswer(e.target.value)}
                  size="small"
                  required
                  fullWidth
                  label="Enter CAPTCHA"
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={deleteStudent}
            >
              Delete Account Permanently
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  );
}
