import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const getUserInfo = async (token) => {
  try {
    const response = await heyapi.get(`/student/details`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateUserDetail = async (body) => {
  console.log(body);
  try {
    const response = await heyapi.patch(
      `/student/profile/update`,
      { userData: body },
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
  }
};

