import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Container,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const PostSubmissionSuccess = () => {
 
  return (
    <><Container
      maxWidth="sm"
      sx={{
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
        marginTop: "40px"
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          marginBottom: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CheckCircleOutlineIcon
          color="success"
          sx={{ fontSize: 80, marginBottom: 2 }}
        />
        <Box textAlign="center">
            <Typography variant="h4" gutterBottom>
                🎉 Congratulations!
            </Typography>
            <Typography variant="h6" gutterBottom>
                You internship post is successfully submitted. It is under review and will take 24-72 hours to go live. 
            </Typography>
            <Typography variant="body1" gutterBottom>
                In case of any issue with your post, you will receive email from us.
            </Typography>
        </Box>
        <Typography variant="body2" color="textSecondary">
          <Link to="/employer/dashboard" style={{ color: "blue"}}> Dashboard</Link> 
        </Typography>
      </Box>
    </Container>
    </>
  );
};

export default PostSubmissionSuccess;
