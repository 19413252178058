import React, { useEffect } from "react";
import { Button, Select, MenuItem, TextField, Switch, Alert, Card, CardContent, CardActions, Typography, Chip, FormControl, InputLabel, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from "@material-ui/icons/Search";
import { getActiveRooms, getPostList } from '../../services/employerChat'
import { useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom'; // Import useNavigate
import { getPostApplicants } from "../../services/employerChat";
import { getRandomColor } from "../../services/utilApis";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const ChatFooter2  = ({ onSendMessage }) => {
     const [newMessage, setNewMessage] = React.useState('');

    const handleSendMessage = () => {
        onSendMessage(newMessage);
        setNewMessage(''); // Clear the input field after sending
    };

    return (
    <div className="chat-footer">
      <TextField
        className="chat-input"
        id="chat-inputbox"
        label="Write a message"
        variant="filled"
        value={newMessage}
        onChange={(event) => { setNewMessage(event.target.value) }}
        inputProps={{ maxLength: 100 }}
        style={{ width: "100%" }}
      />
      <Button variant="contained" color="info" className="send-btn" onClick={handleSendMessage}>
        Send <OpenInNewIcon />
      </Button>
    </div>)
};

export default ChatFooter2;