import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance.js";
import {
  AdminLoginRequest,
  adminLoginSuccess,
  AdminLoginFailure,
  loginSuccess,
  setToken
} from "./../Store/Action.js";

export const adminLogin = (email, password) => async (dispatch) => {
  console.log(email, password,"hi");
  dispatch(AdminLoginRequest());

  try {
    const response = await heyapi.post(`/admin/login`, {
      email,
      password,
    });
    console.log(response.data)
    dispatch(adminLoginSuccess(response.data));
    dispatch(loginSuccess(true));
    dispatch(setToken(response.data.token));
    return response.data;
  } catch (err) {
      dispatch(AdminLoginFailure(err.response.data));
      console.log(err.response.data)
    return err.response.data;
  }
};
