import { Typography } from "@mui/material";
import EditProfileComponent from "../student/components/EditProfileComponent";

const StudentPersonalDetails = () => {
  return (
    <>
    <Typography variant="h5" align="center" gutterBottom>Personal Details</Typography>
    <EditProfileComponent />
    </>
  );
};

export default StudentPersonalDetails;
