import LinkIcon from '@mui/icons-material/Link';
import {
  Box,
  Chip,
  CircularProgress,
  Container,
  FormGroup,
  Tooltip,
  Typography
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toCamelCase } from '../common/commonFunctions';
import SingleInternshipModal from '../modals/SingleInternshipModal';
import { appliedInternship } from '../services/appliedInternship';
import InfoIcon from '@mui/icons-material/Info';

const filter = ["pending", "rejected", "shortlist", "nointerest", "rejected", "hired"];

const MyApplications = () => {
  const token = useSelector((state) => state.token);
  const [apiData, setApiData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currFilter, setCurrFilter] = useState();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [currPostId, setCurrPostId] = React.useState("");
  const navigate = useNavigate();

  useEffect(() => {
    currFilter === undefined
      ? navigate(`/student/applications`)
      : navigate(`/student/applications?filter=${currFilter}`);
    getApplied();
  }, [currFilter, navigate]);

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "warning"; // Yellow
      case "shortlist":
        return "info"; // Blue
      case "hired":
        return "success"; // Green
      case "rejected":
          return "error";
      default:
        return "default"; // Gray
    }
  };

  const getApplied = async () => {
    try {
      const data = await appliedInternship(token, currFilter);
      console.log(data);
      setApiData(data.applications || []);
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleViewPost = (postid) => {
    setCurrPostId(postid);
    setModalOpen(true);
  };

  const closePostView = () => {
      setModalOpen(false);
  }

  const tableColumns = [
    {
      field: 'title', headerName: 'Title', flex: 1,
      renderCell: (params) => (
        <Tooltip title="click to view details">
        <p onClick={() => handleViewPost(params.row.postId._id)} style={{ cursor: 'pointer' }}>
          {params.row.postId ? params.row.postId.title : ""} <InfoIcon />
        </p>
        </Tooltip>
      ),
    },
    {
      field: 'companyName', headerName: 'Company Name', flex: 1,
      renderCell: (params) => (
        <p>{params.row.postId ? params.row.postId.ownerId.company.name : ""}</p>
      ),
    },
    {
      field: 'createdAt', headerName: 'Applied On', flex: 1,
      renderCell: (params) => (
        <p>{moment(new Date(params.row.createdAt)).format('DD-MM-YYYY')}</p>
      )
    },
    {
      field: 'applicationStatus',
      headerName: 'Application Status',
      flex: 1,
      renderCell: (params) => (
        <p>
          <Chip label={toCamelCase(params.row.state)} color={getStatusColor(params.row?.state)} />
        </p>
      ),
    },
  ];

  const rows = apiData.map((application, index) => ({
    id: index,
    companyName: application.postId?.ownerId?.name,
    profile: application.postId?.title,
    appliedOn: moment(application.postId?.createdAt).format("D MMM Y"),
    numApplication: application.postId?.numApplication,
    applicationStatus: application.state,
    postId: application.postId?._id,
  }));

  return (
    <div>
      <FormGroup
        sx={{
          marginBottom: "10px",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography style={{ fontSize: "30px" }}>My Applications</Typography>
        {/*<Autocomplete
          size="small"
          id="tags-outlined"
          style={{
            width: "15%",
            marginLeft: "10px",
          }}
          options={filter}
          getOptionLabel={(filter) => filter}
          filterSelectedOptions
          onChange={(event, selectedOption) => {
            const selectedString = selectedOption ? selectedOption : "";
            setCurrFilter(selectedString);
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder="e.g. Filter" />
          )}
        />*/}
      </FormGroup>
      {loader ? <CircularProgress /> : (
        <Container maxWidth="lg" style={{ marginTop: "20px", height: 400 }}>
          <Box
            sx={{
              width: "100%",
              height: {
                xs: 300, // height for small screens
                sm: 400, // height for medium screens
                md: 500, // height for large screens
                lg: 600, // height for extra-large screens
                xl: 800, // height for extra-large screens
              },
            }}
          >
            <DataGrid
              rows={apiData}
              columns={tableColumns}
              getRowId={(row) => row['_id']}
              pageSize={10}
              rowsPerPageOptions={[10]}
              pagination
              disableRowSelectionOnClick
              slots={{ toolbar: GridToolbar }}
              //disableColumnFilter
              //disableColumnMenu
              disableColumnSelector
            />
          </Box>
          <SingleInternshipModal open={modalOpen} postid={currPostId} closePostView={closePostView} />

        </Container>
      )}
    </div>
  );
};

export default MyApplications;
