import React from "react";
import LeftSidebar from "./components/Sidebar";
import { Outlet } from "react-router-dom";
import AccountMenu from "./../components/AccountMenu";
const AdminLayout = () => {
  return (
    <div className="admin-page-wrapper">
      <div className="admin-page-box dashboard-page-box">
        <LeftSidebar />
        <div className="dashboard-page-content-wrapper">
          <header className="admin-header">
            <h1 className="page-title-2"> </h1>
            <AccountMenu />
          </header>
          <div className="dashboard-contentbox">
          <Outlet />
          </div>
     
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
