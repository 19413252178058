import {
  Autocomplete,
  Box,
  Button,
  Paper,
  Slider,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import MenuItem from '@material-ui/core/MenuItem';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Select from '@material-ui/core/Select';
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import React, { useEffect, useState } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useLocation,useNavigate } from "react-router-dom";
import moment from "moment";
import LinkIcon from '@mui/icons-material/Link';
import { Link } from "react-router-dom";
// import {useNavigate } from "react-router-dom"; 
import { getEmployerPost, updatePostState } from "../services/employerAllPost";
import {useSelector} from "react-redux"

const EmployerPost = () => {
    const location = useLocation();
    const navigate=useNavigate()
    const admin = useSelector((state) => state.admin);
    const searchParams = new URLSearchParams(location.search);

    // Access the specific search key value
    let searchKeyValue = searchParams.get('state');
    if(searchKeyValue == undefined)
    {
      searchKeyValue = "all";
    }
    // Split the pathname on '/' to get path segments
    const pathSegments = location.pathname.split('/');

    // Get the last token (if it exists)
    const id = pathSegments.length > 0 ? pathSegments[pathSegments.length - 1] : undefined;

    useEffect(()=>{
        employerPost(searchKeyValue)
    },[admin, searchKeyValue]);

    const employerPost=async(tag)=>{
    try{
        setLoader(true);
        const data=await getEmployerPost(id, tag, admin.token);
        setApiData(data);
        //set initial post status array 
        let tempStatusArr = [];
        data.posts.map((item, stIndex) => {
          tempStatusArr[stIndex] = item.status;
        })
        setSelectedOptions(tempStatusArr);
        setLoader(false);
    }
    catch(err){
        throw new Error(err)
    }
}

    const [showOptions, setShowOptions] = useState("Approve");
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [apiData, setApiData] = useState([]);
    const [loader, setLoader] = useState(true);
    const tabsData = [
      { label: 'All Posts', url: `/admin/employerpost/${id}?state=all` },
      { label: 'Approved', url: `/admin/employerpost/${id}?state=approved` },
      { label: 'Rejected', url: `/admin/employerpost/${id}?state=rejected` },
      { label: 'Pending', url: `/admin/employerpost/${id}?state=pending` },
      { label: 'Live', url: `/admin/employerpost/${id}?state=live` },
    ];

    const postOptions = ["pending", "approved", "closed", "rejected"]; //live is just approved with live condition
    const handleOptionChange = (rowIndex, event) => {
      setSelectedOptions((prevValues) => {
        const tempValues  = [...prevValues];
        tempValues[rowIndex] = event.target.value;
        return tempValues;
      })
    }

    const handlePostStateUpdate = async (rowIndex) => {
      const curSelOption = selectedOptions[rowIndex]
      console.log("current selected value:" + curSelOption);
      const data= await updatePostState(apiData.posts[rowIndex]._id, curSelOption, admin.token);
      console.log("after post udpaet: " + data);
    }

    return (
    <>
    {!loader&&( 
        <div>
        <Box
            sx={{
            display: "flex",
            overflowX: "hidden",
            justifyContent: "center",
            }}
        >

            <Box
            sx={{
                display: "flex",
                justifyContent: "center",

                p: "1vw",
                pl: "20vw",
                paddingRight: "0px",
            }}
            ></Box>
        </Box>

        <Box
            sx={{
            minHeight: "66vw",
            display: "flex",
            mb: "2%",
            m: "5%",
            marginTop: "0",
            // justifyContent: "center",
            }}
        >
            <Box
            sx={{
                border: "1px solid #ebebeb",
                backgroundColor: "#f7f8f8",
            }}
            >
            <Tabs orientation="vertical" variant="scrollable" sx={{ borderRight: 1, borderColor: 'divider', width: '14vw' }}>
              {tabsData.map((tab, tabIndex) => (
                <Tab
                  key={tab.label}
                  value={tabIndex}
                  style={{ borderBottom: '1px grey' }}
                  label={
                    <Link to={tab.url} underline="none" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Paper elevation={0} sx={{ backgroundColor: '#f7f8f8', height: '16px', textAlign: 'left', marginLeft: '35px' }}>
                        <p style={{ fontSize: '16px', fontWeight: '500' }}>{tab.label}</p>
                      </Paper>
                    </Link>
                  }
                />
              ))}
            </Tabs>
            </Box>
            <Box>
                        <TableContainer component={Paper}>
                            <Table
                                sx={{ maxWidth: "100vw", minWidth: "80vw" }}
                                aria-label="simple table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontSize: "18px", fontWeight: "500" }}
                                            align="center">
                                            TITLE
                                        </TableCell>
                                        <TableCell
                                            style={{ fontSize: "18px", fontWeight: "500" }}
                                            align="center"
                                        >
                                            NUMBER OF OPENING
                                        </TableCell>
                                        {/* <TableCell
                                            style={{ fontSize: "18px", fontWeight: "500" }}
                                            align="center"
                                        >
                                            EMAIL
                                        </TableCell> */}
                                        <TableCell
                                            style={{ fontSize: "18px", fontWeight: "500" }}
                                            align="center"
                                        >
                                            DATE
                                        </TableCell>
                                        <TableCell
                                            style={{ fontSize: "18px", fontWeight: "500" }}
                                            align="center"
                                        >
                                            STATUS
                                        </TableCell>
                                        {/* <TableCell
                                            style={{ fontSize: "18px", fontWeight: "500" }}
                                            align="center"
                                        >STATUS</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {apiData.posts.map((item, rowIndex) => (
                                        <TableRow key={rowIndex}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        // onClick={() => navigate("/dashboard/application")}
                                        >
                                            <TableCell
                                                style={{ fontSize: "18px", fontWeight: "500", cursor: "pointer" }}
                                                align="center"
                                                component="th"
                                                scope="row"
                                                onClick={() => {
                                                    const url = `/internships/detail/${item._id}`;
                                                    window.open(url, '_blank', 'noopener,noreferrer');
                                                }}

                                            >
                                                {item.title}
                                        <LinkIcon style={{ color: '#04C53A', cursor: "pointer", marginLeft: 5 }} />
                                            </TableCell>
                                            <TableCell
                                                style={{ fontSize: "18px", fontWeight: "500" }}
                                                align="center"
                                            >
                                                {item.numOpening}
                                            </TableCell>
                                            {/* <TableCell
                                                style={{ fontSize: "18px", fontWeight: "500" }}
                                                align="center"
                                            >
                                                {/* {item.email}
                                            </TableCell>  */}
                                            <TableCell
                                                style={{ fontSize: "18px", fontWeight: "500" }}
                                                align="center"
                                            >
                                            
                                                {moment(item.startDate).format('DD/MM/YYYY')}
                                            </TableCell>
                                            <TableCell
                                                style={{ fontSize: "18px", fontWeight: "500" }}
                                                align="right"
                                            >
                                                  <select
                                                  style={{ outline: "none", border: "none", color: '#04C53A', backgroundColor: "#ebfeeb", fontSize: 18, cursor: "pointer" }} 
                                                  onChange={(event) => handleOptionChange(rowIndex, event)}
                                                  value={selectedOptions[rowIndex]}>
                                                  {postOptions.map((statusOption) => {
                                                      return <option key={statusOption} value={statusOption}>{statusOption}</option>
                                                  })}
                                                  </select>
                                                  <Button sx={{ backgroundColor: "#ebfeeb", color: "#04C53A", marginLeft: "5px" }} 
                                                    onClick={ () => handlePostStateUpdate(rowIndex)}>
                                                    Update
                                                </Button>

                                            </TableCell>
                                            <TableCell align="right">
                                                {/* <MenuIcon id={row._id} /> */}
                                            </TableCell>
                                        </TableRow>
                                    ))} 
                                </TableBody>
                            </Table>
                        </TableContainer>
            </Box>
        </Box>
        </div>
            )}
    </>
    );
};
export default EmployerPost;
