import React from "react";
import { NavLink } from "react-router-dom"; // Import NavLink or Link
import siteSettings from "../../common/siteSettings";

const WebsiteFooter = () => {
  return (
    <footer className="site-footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-sm-5">
              <div className="widget">
                <div className="logo-white">
                  <img
                    className="logo m-b15"
                    style={{ height: "60px" }}
                    src="/heyinterns-logo.png"
                    alt=""
                  />
                </div>
                <p className="text-capitalize m-b20">
                  {siteSettings.tagline}. <br/>
                  connect with top employers, and kickstart your dream internship today!
                </p>
                <ul className="list-inline m-a0">
                  <li>
                    <a
                      target="_blank"
                      href={siteSettings.social.linkedin}
                      className="site-button white linkedin circle"
                      rel="noreferrer"
                    >
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={siteSettings.social.youtube}
                      className="site-button white google-plus circle"
                      rel="noreferrer"
                    >
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={siteSettings.social.twitter}
                      className="site-button white twitter circle"
                      rel="noreferrer"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="widget border-0">
                <h5 className="m-b30 text-white">Find Useful Links</h5>
                <div className="row">
                  <div className="col-sm-6">
                    <ul className="list-2 list-line">
                      <li>
                        <NavLink
                          target="_blank"
                          to="https://blog.heyinterns.com/"
                        >
                          Blog{" "}
                        </NavLink>
                      </li>

                      <li>
                        <NavLink to="/employers-information">
                          For Employers
                        </NavLink>
                      </li>

                      <li>
                        <NavLink to="/students-information">
                          For Students
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/verify-internship">
                          Verify Internship
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/internship-story">
                          Internship Stories
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/careers">Careers</NavLink>
                      </li>
                      <li>
                        <NavLink to="/college/register">
                          College/TPO Register
                        </NavLink>
                      </li>
                      
                    </ul>
                  </div>
                  <div className="col-sm-6">
                    <ul className="list-2 list-line">
                      <li>
                        <NavLink to="/privacy-policy">
                          Privacy Policy
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/terms-and-conditions">
                          Terms & Conditions
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/company-policy">Company Policy</NavLink>
                      </li>
                      <li>
                        <NavLink to="/contact-us">Contact Us</NavLink>
                      </li>

                      <li>
                        <NavLink to="/verify-internship">
                          Verify Internship
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/faqs">FAQs</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="widget border-0">
                <h5 className="m-b30 text-white">Find Internships</h5>
                <ul className="list-2 w10 list-line">
                  <li>
                    <NavLink to="/internships/internship_in_work_from_home">Work From Home Internships</NavLink>
                  </li>
                  <li>
                    <NavLink to="/internships">Office Internships</NavLink>
                  </li>
                  <li>
                    <NavLink to="/internships">
                      Software Development Internships
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/internships/internship_in_delhi">
                      Internships in Delhi/NCR
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/internships/internship_in_bengaluru">
                      Internships in Bangalore
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default WebsiteFooter;
