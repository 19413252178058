export const  statusOptions = [
    "pending",
    "rejected",
    "disable",
    "active",
];

export const  empStatusOptions = [
  "pending",
  "rejected",
  "disable",
  "active",
  "approved"
];

export const  cityOptions  = [
    { city: "Noida", state: "Uttar Pradesh" },
    { city: "Delhi", state: "Delhi" },
    { city: "Mumbai", state: "Maharashtra" },
    { city: "Chennai", state: "Tamil Nadu" },
    { city: "Kolkata", state: "West Bengal" },
    { city: "Bengaluru", state: "Karnataka" },
    { city: "Hyderabad", state: "Telangana" },
    { city: "Ahmedabad", state: "Gujarat" },
    { city: "Pune", state: "Maharashtra" },
    { city: "Jaipur", state: "Rajasthan" }
  ];

  export const skillOptions = [
    { skill: "JavaScript", category: "Programming" },
    { skill: "Python", category: "Programming" },
    { skill: "Java", category: "Programming" },
    { skill: "C++", category: "Programming" },
    { skill: "SQL", category: "Database" },
    { skill: "HTML", category: "Web Development" },
    { skill: "CSS", category: "Web Development" },
    { skill: "React", category: "Web Development" },
    { skill: "Angular", category: "Web Development" },
    { skill: "Node.js", category: "Web Development" },
    { skill: "Django", category: "Web Development" },
    { skill: "Flask", category: "Web Development" },
    { skill: "Ruby on Rails", category: "Web Development" },
    { skill: "Machine Learning", category: "Data Science" },
    { skill: "Data Analysis", category: "Data Science" },
    { skill: "Project Management", category: "Management" },
    { skill: "Communication", category: "Soft Skills" },
    { skill: "Problem Solving", category: "Soft Skills" },
    { skill: "Critical Thinking", category: "Soft Skills" },
    { skill: "Teamwork", category: "Soft Skills" },
    { skill: "Digital Marketing", category: "Marketing" },
    { skill: "Content Writing", category: "Writing" },
    { skill: "Graphic Design", category: "Design" }
  ];

  export const degreeOptions = [
    { degree: "Bachelor of Technology (B.Tech)" },
    { degree: "Bachelor of Engineering (B.E)" },
    { degree: "Bachelor of Science (B.Sc)" },
    { degree: "Bachelor of Arts (B.A)" },
    { degree: "Bachelor of Commerce (B.Com)" },
    { degree: "Bachelor of Business Administration (BBA)" },
    { degree: "Bachelor of Computer Applications (BCA)" },
    { degree: "Master of Technology (M.Tech)" },
    { degree: "Master of Engineering (M.E)" },
    { degree: "Master of Science (M.Sc)" },
    { degree: "Master of Arts (M.A)" },
    { degree: "Master of Commerce (M.Com)" },
    { degree: "Master of Business Administration (MBA)" },
    { degree: "Master of Computer Applications (MCA)" },
    { degree: "Doctor of Philosophy (Ph.D.)" }
  ]

  export const yearOptions = [
    2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009,
    2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019,
    2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029,
    2030
  ]
  
  
  
  
  