import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  FormGroup,
  IconButton,
  InputAdornment,
  Tab,
  TextField,
  Divider,
  Typography
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  loginSuccess,
  userID,
  userType,
  userVerifyStatus,
  setUserDetails,
  setToken,
  updateStepCompletion
} from "../Store/Action";
import UserContext from "../context/userContext";
import { studentLogin } from "../services/studentLogin";
import { employerLogin } from "../services/employerLogin";
import { showToastAlert, CustomAlert } from "../admin/components/CustomAlert";
import { Helmet } from "react-helmet-async";
import GoogleLoginBtn from "../components/GoogleLoginBtn";

const Login = ({ handleLoginModalClose }) => {
  const [tabValue, setTabValue] = useState("1");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useContext(UserContext);
  const isAuthenticated = useSelector((state) => state.isAuthenticated);
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  // Redirect if already logged in
  useEffect(() => {
    if (user) navigate("/");
  }, [user, navigate]);

  // Common handler for input change
  const handleInputChange = (setter) => (e) => setter(e.target.value);

  // Toggle password visibility
  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

  // Form validation
  const validateForm = () => {
    let valid = true;
    let errors = {};

    if (!email) {
      errors.email = 'Email is required';
      valid = false;
    }

    if (!password) {
      errors.password = 'Password is required';
      valid = false;
    }

    setErrors(errors);
    return valid;
  };
  
  const handleResponse = (response, userTypeValue, redirectPath) => {
    try {
      if (response.status === 200) {
        const data = response.data;
        dispatch(setToken(data.token));
        dispatch(userID(data.user._id));
        dispatch(updateStepCompletion(data.stepsCompleted));
        dispatch(userType(userTypeValue));
        dispatch(
          setUserDetails({ name: data.user.name, email: data.user.email, status: data.user.status, isApproved: data.user.isApproved })
        );
        dispatch(loginSuccess(true));
        if (userTypeValue === "student") {
          //update profile and resume status 
          navigate("/student/applications")
        } else if (userTypeValue === "employer") {
          return navigate("/employer/dashboard");
        } else {
          navigate(redirectPath);
        }
      } else if (response.status === 202) {
        if (response.data.STATE === "EMAIL_NOT_VERIFIED") {
          showToastAlert(
            "E-mail not verified. Check your email for verification instructions. Check spam folder also.",
            "error"
          );
        } else {
          setErrMsg(response?.data?.message);
          showToastAlert(response.data.message, "error");
        }
      } else {
        setErrMsg(response.data.error || response.data.message);
        showToastAlert(response.data.message, "error");
      }
    }  catch (error) {
      const errorText = "Login failed. Please try again.";
      console.error(error);
      setErrMsg(errorText);
      showToastAlert(errorText, "error");
    }
  }
  // Common login logic
  const handleLogin = async (loginService, userTypeValue, redirectPath) => {
    try {
      if(!validateForm()) {
        return;
      }
      const response = await loginService(email, password);
      handleResponse(response, userTypeValue, redirectPath);
    } catch (error) {
      const errorText = "Login failed. Please try again.";
      console.error(error);
      setErrMsg(errorText);
      showToastAlert(errorText, "error");
    }
  };

  return (
    <div>
      <Helmet>
          <title>HeyInterns | Login - Sign in </title>
      </Helmet>
      <Box className="login-page">
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={(e, newValue) => setTabValue(newValue)}
              aria-label="Login Tabs"
              sx={{ width: "100%" }}
            >
              <Tab label="Student" value="1" sx={{ width: "50%" }} />
              <Tab label="Employer / T&P" value="2" sx={{ width: "50%" }} />
            </TabList>
          </Box>

          {/* Student Login */}
          <TabPanel value="1">
            <LoginForm
              errMsg={errMsg}
              email={email}
              password={password}
              showPassword={showPassword}
              setEmail={setEmail}
              setPassword={setPassword}
              utype="student"
              togglePasswordVisibility={togglePasswordVisibility}
              onLogin={() =>
                handleLogin(studentLogin, "student", "/student/applications")
              }
              errors={errors}
              handleResponse = {handleResponse}
            />

            <Button
              variant="contained"
              fullWidth
              disableElevation
              onClick={() =>
                handleLogin(studentLogin, "student", "/student/applications")
              }
            >
              Login
            </Button>
          </TabPanel>

          {/* Employer Login */}
          <TabPanel value="2">
            <LoginForm
              email={email}
              errMsg={errMsg}
              password={password}
              showPassword={showPassword}
              setEmail={setEmail}
              utype="employer"
              setPassword={setPassword}
              togglePasswordVisibility={togglePasswordVisibility}
              onLogin={() =>
                handleLogin(employerLogin, "employer", "/employer/dashboard")
              }
              errors={errors}
            />
            <Button
              fullWidth
              variant="contained"
              disableElevation
              onClick={() =>
                handleLogin(employerLogin, "employer", "/employer/dashboard")
              }
            >
              Login
            </Button>
          </TabPanel>
        </TabContext>
      </Box>
      <CustomAlert />
    </div>
  );
};

// Reusable LoginForm Component

const LoginForm = ({
  email,
  password,
  showPassword,
  setEmail,
  utype,
  setPassword,
  togglePasswordVisibility,
  onLogin,
  errMsg,
  errors,
  handleResponse
}) => {

  // Handler for "Enter" key press
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onLogin();
    }
  };

  React.useEffect(() => {

  }, [errors])

  return (
    <>
    {utype === "student" && (
      <>
      <GoogleLoginBtn handleResponse={handleResponse} /> 
      <Divider sx={{ my: 3 }}>or</Divider>
      </>)
    }
      {errMsg && (
        <Typography
          variant="body2"
          color="error"
          textAlign="center"
          sx={{ mt: -2 }}
        >
          {errMsg}
        </Typography>
      )}
      <FormGroup className="mb-3">
        <label className="login-form-label">Email</label>
        <TextField
          size="small"
          fullWidth
          placeholder="email@example.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={handleKeyPress} // Attach key press handler
          inputProps={{ maxLength: 50 }} // Sets max character limit
        />
      </FormGroup>

      <FormGroup className="mb-3">
        <label className="login-form-label">Password</label>
        <TextField
          size="small"
          fullWidth
          type={showPassword ? 'text' : 'password'}
          placeholder="must be at least 6 characters"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handleKeyPress} // Attach key press handler
          error={Boolean(errors?.password)}
          helperText={errors?.password}
          InputProps={{
            maxLength: 15,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FormGroup>

      <FormGroup
        sx={{ alignItems: 'flex-end', margin: '12px 0', fontSize: '0.8rem' }}
      >
        <Link to={`/${utype || 'student'}/forgotpassword`}>Forgot Password?</Link>
      </FormGroup>
    </>
  );
};


export default Login;
