import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
} from "@mui/material";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import ApplicationDetailPage from "../applications/ApplicationDetailsPage"
import Applicant from "../components/Applicant";
const ApplicantModal = ({ open, currApplicantId, showApplicantModal, setShowApplicanttModal }) => {
  const handleClose = () => {
    setShowApplicanttModal(false);
  }
  return (
    <Dialog
      open={showApplicantModal}
      aria-labelledby="applicant-details"
      className="applicant-details-popup"
      fullWidth
      maxWidth={false} // Disable default max width
      sx={{
          '& .MuiDialog-paper': { // Target the dialog's paper (the actual content container)
              width: '80vw', // Set width to 80% of viewport width
              maxWidth: 'none', // Disable MUI's default maxWidth for the paper
              maxHeight: '90vh'
          },
      }}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Applicant appid={currApplicantId} />
      </DialogContent>
    </Dialog>
  );
};

export default ApplicantModal;
