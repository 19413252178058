import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
    Box,
    Typography,
    CircularProgress,
    Card,
    CardContent,
    Divider,
} from "@mui/material";
import { getEmployerDetails } from "../services/getAllEmployerForAdminDashboard";
import { useSelector } from "react-redux";

const SingleEmployerDetails = () => {
    const { id } = useParams(); // Get employer ID from the route parameter
    const [loader, setLoader] = React.useState(true);
    const [employer, setEmployer] = useState(null);
    const [error, setError] = useState(null);
    const admin = useSelector((state) => state.admin);

    useEffect(() => {
        // Fetch employer details
        fetchEmployerDetails(id, admin.token);
    }, [id, admin]);

    const fetchEmployerDetails = async (id, token) => {
        try {
            const data = await getEmployerDetails(id, token);
            console.log(data)
            setEmployer(data.employer);
            setLoader(false);
        } catch (error) {
            console.log(error);
        }
    };

    if (loader) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box textAlign="center" mt={4}>
                <Typography variant="h6" color="error">
                    {error}
                </Typography>
            </Box>
        );
    }

    if (!employer) {
        return (
            <Box textAlign="center" mt={4}>
                <Typography variant="h6" color="textSecondary">
                    Employer details not found.
                </Typography>
            </Box>
        );
    }

    return (
        <Box p={3}>
            <Typography variant="h4" gutterBottom>
                Employer Details
            </Typography>
            <Card>
                <CardContent>
                    <Typography variant="h6">Basic Information</Typography>
                    <Divider sx={{ my: 1 }} />
                    <Typography><strong>Name:</strong> {employer.name}</Typography>
                    <Typography><strong>Email:</strong> {employer.email}</Typography>
                    <Typography><strong>Mobile:</strong> {employer.mobile}</Typography>
                    <Typography>
                    <strong>
                        <a style={{color: "blue"}} href={employer.linkedin} target="_blank" rel="noopener noreferrer">
                        LinkedIn
                        </a>
                    </strong>
                    </Typography>
                    <Typography><strong>Role:</strong> {employer.role}</Typography>

                    <Box mt={3}>
                        <Typography variant="h6">Company Information</Typography>
                        <Divider sx={{ my: 1 }} />
                        {employer.company ? (
                            <>
                                <Typography><strong>Company Name:</strong> {employer.company.name}</Typography>
                                <Typography><strong>Industry:</strong> {employer.company.industry.join(", ")}</Typography>
                                <Typography><strong>Address:</strong> {employer.company.address}</Typography>
                                <Typography><strong>Number of Employees:</strong> {employer.company.numEmployee}</Typography>
                                <Typography><strong>Description:</strong> <br/> {employer.company.description}</Typography>
                            </>
                        ) : (
                            <Typography>No company details available.</Typography>
                        )}
                    </Box>

                    <Box mt={3}>
                        <Typography variant="h6">Account Information</Typography>
                        <Divider sx={{ my: 1 }} />
                        <Typography><strong>Status:</strong> {employer.status}</Typography>
                        <Typography><strong>Approval:</strong> {employer.isApproved ? "Approved" : "Pending"}</Typography>
                        <Typography><strong>Verification (Email):</strong> {employer.verifyStatus?.email ? "Verified" : "Not Verified"}</Typography>
                        <Typography><strong>Verification (Mobile):</strong> {employer.verifyStatus?.mobile ? "Verified" : "Not Verified"}</Typography>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default SingleEmployerDetails;
