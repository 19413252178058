import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const collegeRegister = async (
  collegeData
) => {
  try {
    console.log(collegeData);
    const response = await heyapi.post(`/college/register`, collegeData, {
      headers: {
        "Content-Type": "application/json", // Ensure correct format
      },
    });
    
    console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
};
