import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const fetchCaptchaApi = async () => {
  try {
    const response = await heyapi.get(`/captcha/get`, {});
    console.log(response.data);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};