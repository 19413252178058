import React from "react";
import { Container, Paper, Typography, Box, Divider, Card, CardContent } from "@mui/material";
import { LinkedIn } from "@mui/icons-material";
import { Link } from 'react-router-dom';

const InternshipStoryComponent = ({ story }) => {
  /*const story = {
    profile: "Software Engineering Intern",
    company: "Google",
    year: "2024",
    month: "June",
    duration: "3",
    location: "Mountain View, California",
    stipend: "$5000 per month",
    mentor: "John Doe (Senior Engineer)",
    howApplied: `I applied through the company's careers portal and got a referral from a current employee. The application process required an updated resume, a cover letter, and answers to a few short essay questions about my experience and motivation. I made sure to highlight my past projects, technical skills, and enthusiasm for software development. Within a few weeks, I received an invitation to take an online assessment, which tested my problem-solving skills, coding ability, and knowledge of data structures and algorithms. After passing the assessment, I was contacted for the interview rounds, where I had the opportunity to demonstrate my technical and soft skills.`,
    rounds: `The interview process included three rounds. The first was a coding challenge that tested my knowledge of data structures and algorithms. The second round was a technical interview where I was asked to solve real-world problems related to system design and optimization. The final round was an HR interview, which focused on behavioral questions and cultural fit. I prepared by practicing coding problems daily, reading system design articles, and revising fundamental computer science concepts.`,
    questions: `During my interview, I was asked a mix of theoretical and practical questions. One question involved designing a URL shortener, requiring me to explain my approach to handling database storage, indexing, and performance optimization. Another question required me to implement a binary search algorithm efficiently. I was also asked about a past project, how I tackled challenges, and how I worked with a team to complete it.`,
    advice: `My advice for future aspirants is to start preparing early. Focus on building strong foundations in data structures, algorithms, and system design. Work on personal projects that showcase your problem-solving abilities, and contribute to open-source projects to gain real-world experience. Networking is also key—reach out to professionals on LinkedIn, attend career fairs, and try to get referrals from employees. Finally, stay consistent with your practice and be confident during your interviews.`,
    otherComment: `The internship was an amazing learning experience. The mentorship, real-world projects, and exposure to industry best practices were invaluable. The work culture was supportive, and I had the chance to collaborate with some of the brightest minds in tech. I highly recommend this internship to anyone looking to grow in their career. Be proactive, ask questions, and make the most out of your experience!`
  };*/

  const valToTitle = new Map([
    ["howApplied", "How I Applied"],
    ["rounds", "Rounds"],
    ["questions", "Questions Asked"],
    ["advice", "Advice"],
    ["otherComment", "Other Comments"]
  ]);

  return (
    <Container maxWidth="md" sx={{ mt: 4, fontFamily: "source-serif-pro, Georgia, Cambria, 'Times New Roman', Times, serif", color: "black", backgroundColor: "white" }}>
      <Paper elevation={2} sx={{ p: 4, borderRadius: 2, backgroundColor: "white", color: "black" }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", textAlign: "center" }}>
          {story?.profile} Internship at {story?.company}
        </Typography>
        <Typography variant="body1" textAlign="center" sx={{ mt: 1, mb: 3 }}>
          {story?.location} | {story?.month} {story?.year} | Duration: {story?.duration} Month(s)
        </Typography>
        {/* LinkedIn Section */}

        <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
          {story?.linkedin && (
            <Link to={story?.linkedin} target="_blank" rel="noopener" underline="hover">
              <LinkedIn sx={{ color: "#0077B5" }} />
            </Link>
          )}

          {story?.ownerId?.name}

        </Box>

        <Divider sx={{ my: 3, backgroundColor: "black" }} />

        {["howApplied", "rounds", "questions", "advice", "otherComment"].map((key, index) => (
          <Card key={index} sx={{ mb: 3, backgroundColor: "white", boxShadow: 0, border: "1px solid black" }}>
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>{valToTitle.get(key)}</Typography>
              <Typography component="div" sx={{ mt: 1, textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: story ? story[key] : "" }} />
            </CardContent>
          </Card>
        ))}
        <Divider sx={{ my: 3, backgroundColor: "black" }} />
        <Typography variant="h4" sx={{ fontWeight: "bold", textAlign: "center" }}>
          Written by: {story?.ownerId?.name}
        </Typography>
      </Paper>
    </Container>
  );
};

export default InternshipStoryComponent;
