import React from "react";
import { Outlet } from "react-router-dom";
import WebsiteFooter from "./website-components/WebsiteFooter";
import MainHeader from "./../components/MainHeader";

const WebsiteLayout = () => {
  return (
    <div className="heyinterns-website">
      <MainHeader />
      <Outlet />
      <WebsiteFooter />
    </div>
  );
};

export default WebsiteLayout;
