import React from "react";

const WebsiteBreadCrumb = ({ title, subtitle }) => {
  return (
    <div className="web-breadcremb">
      <img src="/heyinterns-breadcrumb.jpg" alt="" className="img-fluid" />
      <div className="breadcrumb-content">
        {title && <h2 className="breadcrumb-text">{title}</h2>}
        {subtitle && subtitle.length > 0 && (
          <h6 className="breadcrumb-subheading">{subtitle}</h6>
        )}
      </div>
    </div>
  );
};

export default WebsiteBreadCrumb;
