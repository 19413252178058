import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import {store} from "../Store/store"
import { logout } from "../Store/Action";

const heyapi = axios.create({
  baseURL: `${baseUrl}/api`,
});

// 🔹 Request Interceptor - Attach Token from Redux Store
heyapi.interceptors.request.use(
  (config) => {
    const state = store.getState(); // Get latest Redux state
    const token = state.token; // Access token from Redux

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// 🔹 Response Interceptor - Handle Expired Tokens (403)
heyapi.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 403) {
      console.warn("Access token expired. Redirecting to login...");
      // Dispatch logout action using Redux store
      store.dispatch(logout()); // ✅ Correct way to dispatch
      //window.location.href = "/"; // Redirect user to login
    }
    return Promise.reject(error);
  }
);

export default heyapi;
