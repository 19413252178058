import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { checkOnboardState } from "../common/redirectFunctions";

const StudentPrivateRoute = ({verifySteps=false}) => {
  const {uType, stepsCompleted} = useSelector((state) => ({
    uType: state.uType,
    stepsCompleted: state.stepsCompleted}));
  if(uType === "student") {
    //means logined
    if(verifySteps) {
      if(!checkOnboardState(stepsCompleted, "student")) {
        return <Navigate to="/student/onboard" />;
      } else {
        return <Outlet />;
      }
    } else {
      return <Outlet />;
    }
  } else {
    return <Navigate to="/student/Login" />;
  }
};

export default StudentPrivateRoute;
