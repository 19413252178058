export const loginSuccess = userData => ({
  type: 'LOGIN_SUCCESS',
  payload: userData,
});
 
export const logout = () => ({
  type: 'LOGOUT',
});
 
export const setToken = (token) => ({
  type: 'TOKEN',
  payload: token,
});

export const userID = id => ({
  type: 'USERID',
  payload: id,
});
export const setUserDetails = details => ({
  type: 'USER_DETAILS',
  payload: details,
});
export const userType = uType => ({
  type: 'USERTYPE',
  payload: uType,
});
export const userVerifyStatus = vStatus => ({
  type: 'USER_VERIFY_STATUS',
  payload: vStatus,
});
export const AdminLoginRequest = () => ({
  type: "ADMIN_LOGIN_REQUEST",
});

export const adminLoginSuccess = (user) => ({
  type: "ADMIN_LOGIN_SUCCESS",
  payload: { user },  
});

export const AdminLoginFailure = (error) => ({
  type: "ADMIN_LOGIN_FAILURE",
  payload: error,
});

export const updateStepCompletion = (steps) => ({
  type: 'SET_STEP_COMPLETION',
  payload: steps,
});