import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const getSinglePostDetail = async (title,token) => {
  try {
    const headers = {}
    console.log("token:" + token)
    if(token != undefined && token != "") {
      headers.Authorization = `Bearer ${token}`;
    }
    const response = await heyapi.get(`/post/details?title=${title}`, {
      headers
      },
    );
    return response;
  } catch (err) {
    throw   err.response;
  }
};

export const getSinglePostDetailById = async (postid,token) => {
  try {
    const headers = {}
    console.log("token:" + token)
    if(token != undefined && token != "") {
      headers.Authorization = `Bearer ${token}`;
    }
    const response = await heyapi.get(`/post/${postid}`, {
      headers
      },
    );
    return response;
  } catch (err) {
    throw   err.response;
  }
};
