import React from "react";
import WebsiteBreadCrumb from "./website-components/WebsiteBreadCrumb";
import { Helmet } from "react-helmet-async";
const StudentsInformation = () => {
  return (
    <div>
      <Helmet>
          <title>HeyInterns | Information For Students </title>
      </Helmet>
      <WebsiteBreadCrumb title="Students Information" />
      <div className="container policy-page">
        {/* Header Section */}
        <div className="heading-box">
          <h2>How It Works</h2>
          <p>
            At HeyInterns, we make it simple for students and companies to
            connect. Whether you're an intern looking for your next opportunity
            or an employer searching for talent, our platform is designed to
            make the process as smooth and efficient as possible.
          </p>
        </div>

        {/* Content Section */}
        <div className="steps-section">
          <h3>Getting Started</h3>
          <div className="steps-container">
            <div className="step">
              <h4>Create Your Account</h4>
              <p>
                Register on our platform to unlock a world of internship and job
                opportunities. It's quick, easy, and free!
              </p>
            </div>
            <div className="step">
              <h4>Search Internships</h4>
              <p>
                Use our smart filters to find internships that match your skills
                and interests. We provide a variety of options to suit all
                preferences.
              </p>
            </div>
            <div className="step">
              <h4>Create Your CV/Resume</h4>
              <p>
                Build a professional CV or resume directly on the platform to
                showcase your skills and increase your chances of landing your
                ideal internship.
              </p>
            </div>
            <div className="step">
              <h4>Apply for Internships</h4>
              <p>
                Apply for internships with just a few clicks! Our platform makes
                the process fast, easy, and seamless.
              </p>
            </div>
          </div>
        </div>

        {/* Platform Policies Section */}
        <div className="policy-section">
          <h3>Platform Guidelines & Policies</h3>
          <p>
            We aim to maintain a trustworthy and professional environment for
            both employers and interns. Below are the key guidelines you should
            know:
          </p>

          <div className="policy-item">
            <h4>1. Company Verification</h4>
            <p>
              To ensure the authenticity of employers, we require companies to:
            </p>
            <ul>
              <li>
                Submit valid business registration documents during onboarding.
              </li>
              <li>
                Provide accurate company details, including office address and
                contact information.
              </li>
              <li>
                Undergo periodic verification checks to maintain platform
                integrity.
              </li>
            </ul>
          </div>

          <div className="policy-item">
            <h4>2. Stipend and Payment Commitments</h4>
            <p>Employers are required to:</p>
            <ul>
              <li>
                Honor the stipend or salary stated in their internship postings.
              </li>
              <li>Ensure timely payment as per the internship agreement.</li>
              <li>
                Communicate any changes to payment terms with prior consent from
                the intern.
              </li>
            </ul>
            <p className="policy-warning">
              Failure to comply with payment terms may result in suspension from
              the platform.
            </p>
          </div>

          <div className="policy-item">
            <h4>3. Professional Environment</h4>
            <p>
              We expect employers to provide a respectful and harassment-free
              workplace. This includes:
            </p>
            <ul>
              <li>
                Respecting interns' dignity and treating them professionally.
              </li>
              <li>Ensuring tasks align with the job description.</li>
              <li>
                Avoiding any tasks outside the scope of the internship without
                prior agreement.
              </li>
            </ul>
          </div>

          <div className="policy-item">
            <h4>4. Clear Job Descriptions</h4>
            <p>
              Employers must provide clear and comprehensive job descriptions,
              including:
            </p>
            <ul>
              <li>Role responsibilities and expectations.</li>
              <li>Internship duration and working hours.</li>
              <li>Details of stipend or payment terms, if applicable.</li>
            </ul>
          </div>

          <div className="policy-item">
            <h4>5. Privacy and Data Security</h4>
            <p>
              We prioritize the confidentiality of intern data. Companies must
              ensure:
            </p>
            <ul>
              <li>Intern data is used solely for hiring purposes.</li>
              <li>
                Personal information, such as resumes and contact details, is
                kept secure.
              </li>
              <li>
                Interns' consent is obtained before sharing any data externally.
              </li>
            </ul>
          </div>

          <div className="policy-item">
            <h4>6. Consequences of Violations</h4>
            <p>
              Violations of our policies may result in the following
              consequences:
            </p>
            <ul>
              <li>Temporary suspension of account.</li>
              <li>
                Permanent removal from the platform for severe or repeated
                violations.
              </li>
              <li>Legal action in cases of fraud or unlawful activities.</li>
            </ul>
          </div>

          <div className="policy-item">
            <h4>7. Feedback and Reporting</h4>
            <p>
              Interns are encouraged to share their experiences. Companies
              receiving consistent negative feedback will be reviewed, and
              actions will be taken accordingly.
            </p>
            <p>
              To report issues or seek assistance, contact us at:{" "}
              <a href="mailto:support@heyinterns.com">
                <strong>support@heyinterns.com</strong>
              </a>
            </p>
          </div>

          <div className="policy-item">
            <h4>8. Policy Updates</h4>
            <p>
              We periodically update our policies to improve the platform.
              Significant changes will be communicated via email or a notice on
              the website. Please review this page regularly for updates.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentsInformation;
