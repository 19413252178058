import React from "react";
import { Card, CardContent, Typography, Box, Link } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { formatMessageTime } from "../../services/utilApis";
import { InsertEmoticonSharp } from "@mui/icons-material";

const MsgSentCard = ({item}) => {
  return (
    <Card
      style={{
        backgroundColor: "#e8f7ff", // Light blue background
        borderRadius: "12px",
        padding: "10px",
        maxWidth: "400px", // Adjust width as needed
      }}
    >
      <CardContent style={{ padding: "8px 16px" }}>
        <Typography
          variant="body2"
          style={{
            wordBreak: "break-word", // Ensures the long link wraps
            marginBottom: "16px",
          }}
        >
          {item.content}
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginTop="8px"
        >
          <Typography variant="caption" color="textSecondary">
          {formatMessageTime(item.createdAt)}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default MsgSentCard;
