import React, { useState } from "react";
import {
  Box,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import Logout from "@mui/icons-material/Logout";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { logout } from "./../Store/Action";
import "./AccountMenu.css";

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null); // For "Manage Account" submenu
  const open = Boolean(anchorEl);
  const submenuOpen = Boolean(anchorEl2);

  const userDetails = useSelector((state) => state.userDetails);
  const userType = useSelector((state) => state.uType);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // Define a condition to hide menu items based on the URL
  const hideMenuItems = location.pathname.includes("onboard");

  const handleMainMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMainMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSubmenuOpen = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleSubmenuClose = () => {
    setAnchorEl2(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleMainMenuClick}
            size="medium"
            className="account-menu-button"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              sx={{
                width: 35,
                height: 35,
                fontSize: 13,
                backgroundColor: "#fff",
                color: "black",
              }}
            >
              {userDetails?.name?.charAt(0).toUpperCase() || "M"}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleMainMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem className="userdate-dropdown">
          <Typography variant="subtitle1">{userDetails?.name}</Typography>
          <Typography variant="body2" color="textSecondary">
            {userDetails?.email}
          </Typography>
        </MenuItem>
        <Divider />
        {userType === "student" && (!hideMenuItems) && (
          <>
            <MenuItem onClick={handleMainMenuClose}>
              <NavLink className="naviagtion-link" to="/student/editprofile">
                Edit Profile
              </NavLink>
            </MenuItem>
            <MenuItem onClick={handleMainMenuClose}>
              <NavLink className="naviagtion-link" to="/student/resume">
                Edit Resume
              </NavLink>
            </MenuItem>
            <MenuItem onClick={handleMainMenuClose}>
              <NavLink className="naviagtion-link" to="/student/preference">
                Edit Preferences
              </NavLink>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleMainMenuClose}>
              <NavLink className="naviagtion-link" to="/student/stories">
                Internship Stories
              </NavLink>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleSubmenuOpen}>
              <Typography variant="body1" color="primary">
                Manage Account
              </Typography>
              {submenuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </MenuItem>
            <Menu
              anchorEl={anchorEl2}
              open={submenuOpen}
              onClose={handleSubmenuClose}
              PaperProps={{
                elevation: 0,
                sx: { mt: 1, ml: 2 },
              }}
              transformOrigin={{ horizontal: "left", vertical: "top" }}
              anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            >
              <MenuItem onClick={handleSubmenuClose}>
                <NavLink
                  className="naviagtion-link"
                  to="/student/change-password"
                >
                  Change Password
                </NavLink>
              </MenuItem>
              <MenuItem onClick={handleSubmenuClose}>
                <NavLink
                  className="naviagtion-link"
                  to="/student/delete-account"
                >
                  Delete My Account
                </NavLink>
              </MenuItem>
            </Menu>
          </>
        )}
        {userType === "employer" && (!hideMenuItems) && (
          <>
            <MenuItem onClick={handleMainMenuClose}>
              <NavLink className="naviagtion-link" to="/employer/editprofile">
                Edit Profile
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink className="naviagtion-link" to="/employer/editcompany">
                Edit Company Details
              </NavLink>
            </MenuItem>
          </>
        )}
        <Divider />
        <MenuItem onClick={handleLogout}>
          <Logout className="me-2" /> Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
