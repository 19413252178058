import React from "react";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getChatRoom } from "../../services/employerChat";
import {formatDate, toCamelCase} from '../../common/commonFunctions';
import { CustomAlert, showToastAlert } from "../../admin/components/CustomAlert";
import { Box, Typography, Chip, Button, IconButton, Link } from "@mui/material";
import { Download as DownloadIcon, Info as InfoIcon } from "@mui/icons-material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { getApplicantId } from "../../services/getAllApplicationEmployee";

const AssignmentCard = ({
  item,
  postid,
  searchKeyValue,
  updateApplicationState,
  handleSelect, 
  setCurrApplicantId,
  setShowApplicanttModal
}) => {

  const token = useSelector((state) => state.token);
  const location = useLocation();
  const navigate = useNavigate();

  // Calculate the difference in days from today's date
  const daysDifference = moment().diff(moment(item.createdAt), "days");

  const handleViewApplication = async (appId) => {
    const response = await getApplicantId(token, postid, item.sender._id);
    if(response.status === 200) {
      setCurrApplicantId(response?.data?.applicant?._id);
      setShowApplicanttModal(true);
    } else {
      //TODO: handle this
      console.log("error");
    }
  };

  const handleOpenChat = async () => {
    navigate(`/employer/chat/${item.chatroomId}`);
  };

  const appliedText = `Applied ${daysDifference} ago`;

  return (
    <Box
      sx={{
        border: "1px solid #ddd",
        borderRadius: "8px",
        padding: "16px",
        backgroundColor: "#fff",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 1.5,
      }}
    >
      {/* Header */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" fontWeight={600}>
          {item?.sender?.name} 
        </Typography>
        <Typography variant="caption" color="gray">
          Received on {formatDate(item?.createdAt)}
        </Typography>
      </Box>
      {/* Location and Experience */}
      <Typography variant="body2" color="text.secondary">
      {item?.sender?.location}  (Open to relocate)
      </Typography>

      {/* Assignment Section */}
      <Box display="flex" alignItems="center" gap={2}>
        <Typography variant="body2" fontWeight={600}>
          Assignment Received
        </Typography>
        <Typography>
          {item?.content}
          {item.link && <>
              <br /> {/* Moves the link to the next line */}
              <Link
                href={item.link.startsWith("http") ? item.link : `https://${item.link}`}
                target="_blank" // Opens link in new tab
                rel="noopener noreferrer"
                style={{
                  color: "blue", // Ensures blue color
                  textDecoration: "underline", // Ensures underline
                  display: "inline-block", // Helps with formatting
                  marginTop: "4px", // Adds spacing from the content
                }}
              >
                {item.link}
              </Link>
            </>}
        </Typography>
      </Box>
      {/* Action Buttons */}
      <Box display="flex" justifyContent="flex-start" gap={1.5} mt={1}>
        <Button variant="outlined" size="small" onClick={handleViewApplication}>
        View full application
        </Button>
        <Button variant="contained" size="small" onClick={handleOpenChat}>
          Open Chat
        </Button>
        {/* TODO:
        <select>
          <option>Next Step</option>
          <option value="rejected">Reject</option>
          <option value="shortlist">Shortlist</option>
          <option value="hired">Hire</option>
        </select>
         <Button variant="text" size="small">
          Mark as Evaluated
        </Button>*/}
      </Box>
    </Box>
  );
};

export default AssignmentCard;
