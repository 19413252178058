import React from "react";
import { NavLink } from "react-router-dom";
import ChatIcon from "@mui/icons-material/Chat";
import Badge from "@mui/material/Badge";

const ChatMenu = (props) => {
  return (
    <NavLink to={props.link} className="chat-link">
      <Badge badgeContent={props.messageCount} color="info">
        <ChatIcon />
      </Badge>
    </NavLink>
  );
};

export default ChatMenu;
