import React, { useState, useEffect } from "react";
import DataTable from "./components/DataTable";
import Button from "@mui/material/Button";
import CustomLoader from "../components/CustomLoader";
import { useLocation, useParams } from "react-router-dom";
import { getAllEmployer } from "../services/getAllEmployerForAdminDashboard";
import { useSelector } from "react-redux";
import { UpdateEmployerState } from "../services/updateEmployerState";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import PagesIcon from '@mui/icons-material/Pages';
import EmployeeDetailsModal from "./EmployeeDetailsModal";
import { empStatusOptions } from "../common/constants";
import { getStatusTag } from "../common/commonFunctions";
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";
import AlertMsg from "../dialogBoxes/AlertMsg";
import RejectReasonBox from "../dialogBoxes/SetReason";

const EmployersPage = () => {
    const [loaderOpen, setLoaderOpen] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [apiData, setApiData] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState(new Map());
    const [selectedId, setSelectedId] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [showReasonPopup, setShowReasonPopup] = useState(false);
    const [reason, setReason] = useState("");
    const admin = useSelector((state) => state.admin);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const {state} = useParams();

    // Access the specific search key value
    let searchKeyValue = state;
    if (searchKeyValue == undefined) {
        searchKeyValue = "all";
    }
    // Get the last token (if it exists)
    const {id} = useParams();

    useEffect(() => {
        getEmployerDetail(searchKeyValue);
    }, [admin, state]);

    const tableColumns = [
        { field: "name", headerName: "Name", flex: 1, editable: false },
        {
            field: "company",
            headerName: "company",
            flex: 1,
            editable: false,
            valueGetter: (value) => {
                return value?.name;
            },
        },
        { field: "email", headerName: "Email", flex: 1, editable: false },
        { field: "mobile", headerName: "Mobile", flex: 1, editable: false },
        {
            field: "createdAt",
            headerName: "Created At",
            flex: 1,
            editable: false,
            valueGetter: (value) => {
                const date = new Date(value);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, "0");
                const day = String(date.getDate()).padStart(2, "0");

                return `${day}-${month}-${year}`;
            },
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            editable: false,
            renderCell: (params) => (
                <Select
                    className="status-selectbox"
                    value={selectedOptions.get(params.row._id) || params.value}
                    onChange={(event) => handleOptionChange(params.row._id, event.target.value)}
                >
                    {empStatusOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            {getStatusTag(option)}
                        </MenuItem>
                    ))}
                </Select>
            ),
        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            minWidth: 230,
            editable: false,
            renderCell: (params) => (
                <div>
                    <Button
                        className="circular-btn me-2"
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={() => handleEmployerStateUpdate(params)}
                    >
                        Update
                    </Button>
                    <Tooltip title="Show Employer Details">
                        <Button
                            variant="outlined"
                            onClick={() => handleEmployerView(params)}
                            className="circular-btn me-2"
                            startIcon={<RemoveRedEyeRoundedIcon />}
                        />
                    </Tooltip>
                    {/*<Tooltip title="Show Employer Posts">
                        <Button
                            variant="outlined"
                            onClick={() => handleEmployerPosts(params)}
                            className="circular-btn me-2"
                            startIcon={<PagesIcon />}
                        />
                    </Tooltip>*/}
                </div>
            ),
        },
    ];

    const getEmployerDetail = async (tag) => {
        try {
            if (admin) {
                const data = await getAllEmployer(tag, admin.token);
                setApiData(data.employers);
                let tempStatusMap = new Map();
                data.employers.forEach((item) => tempStatusMap.set(item._id, item.status));
                setSelectedOptions(tempStatusMap);
                setLoaderOpen(false);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleOptionChange = (employerId, status) => {
        setSelectedOptions((prevOptions) => {
            const updatedOptions = new Map(prevOptions);
            updatedOptions.set(employerId, status);
            return updatedOptions;
        });
    };

    const callEmployerStateUpdateApi = async (employerId, reason) => {
        const status = selectedOptions.get(employerId);
        const data = await UpdateEmployerState(admin.token, employerId, status, reason);
        if (data.msg === "success") {
            setShowAlert(true);
            setApiData((prevApiData) => prevApiData.filter((row) => row._id !== employerId));
            setTimeout(() => setShowAlert(false), 2000);
        }
    };

    const handleEmployerStateUpdate = async (params) => {
        const employerId = params.row._id;
        setSelectedId(employerId);
        const curSelOption = selectedOptions.get(employerId)
        if(curSelOption === searchKeyValue) //do nothing 
            return; 
        if (curSelOption === "rejected" || curSelOption == "closed") {
            // Show the input popup for the reason
            setShowReasonPopup(true);
        } else {
            await callEmployerStateUpdateApi(employerId, "");
        }
    };

    const handleEmployerView = (params) => {
        const id = params.row._id;
        window.open(`/admin/employer/${id}/details`);
    };

    const handleEmployerPosts = (params) => {
        const id = params.row._id;
        window.open(`/admin/employer/${id}/posts`, '_blank', 'noopener,noreferrer');
    };

    const handleReasonSubmit = async (aEmpId, aReason) => {
      if (aReason === "")
          return;
      setReason(aReason);
      setShowReasonPopup(false);
      await callEmployerStateUpdateApi(aEmpId, aReason);
    }

    return (
        <div>
            <h1 className="page-title-1 my-3">Employers</h1>
            {showAlert && (
                <AlertMsg
                    open={showAlert}
                    onClose={() => setShowAlert(false)}
                    title="Success"
                    message="The operation was successful."
                    severity="success"
                    buttonText="Close"
                />
            )}
            <CustomLoader setLoaderOpen={setLoaderOpen} loaderOpen={loaderOpen} />
            <DataTable data={apiData} columns={tableColumns} id="_id" />
            <EmployeeDetailsModal
                setModalOpen={setModalOpen}
                modalOpen={modalOpen}
                employerId={selectedId}
                title="Employer's Details"
            />
            <RejectReasonBox selectedId={selectedId} showReasonPopup={showReasonPopup} setShowReasonPopup={setShowReasonPopup} setReason={setReason} handleReasonSubmit={handleReasonSubmit} />
        </div>
    );
};

export default EmployersPage;
