import React, { useState } from "react";
import { Button, Stepper, Step, StepLabel, Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import CompanyDetailsComponent from "../components/CompanyDetailsComponent";
import EmployerProfileComponent from "../components/EmployerProfileComponent";
import SuccessOnboard from "./SuccessOnboard";

const steps = ["Complete your profile", "Complete company details"];

const EmployerOnboard = () => {
    const [activeStep, setActiveStep] = useState(0);

    const stepsCompleted = useSelector((state) => state.stepsCompleted);

    React.useEffect(() =>{
      if(!stepsCompleted.profileCompleted) {
        setActiveStep(0);
      } else if(!stepsCompleted.companyInfoStatus) {
        setActiveStep(1);
      } else {
        setActiveStep(2);
      }
    }, [stepsCompleted]);

    const handleNext = () => {
        setActiveStep((prevStep) => prevStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const setStepCompleted = (step) => {
      if(step <0 && step > 2)
        return;
      setActiveStep(step+1);
    }

    const renderStepContent = (step) => {
        switch (step) {
            //verify phone will come later 
            case 0:
                return <div>Step 1: Complete your profile
                    <EmployerProfileComponent onComplete={() => setStepCompleted(0)} />
                </div>;
            case 1:
                return (<div>Step 2: Complete company details
                    <CompanyDetailsComponent onComplete={() => setStepCompleted(1)}/>
                </div>);
            case 2: 
                return (
                  <div><SuccessOnboard /> </div>)
            default:
                return <div>Unknown Step</div>;
        }
    };

    return (
        <Box
          sx={{
            width: { xs: "100%", sm: "90%", md: "80%" }, // Full width on mobile, 80% on desktop
            margin: "auto",
            padding: "20px",
          }}
        >
          {/* Stepper */}
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
    
          {/* Content */}
          <Box sx={{ marginTop: 3 }}>
              <Box>
                {renderStepContent(activeStep)}
              </Box>
          </Box>
        </Box>
      );
};

export default EmployerOnboard;
