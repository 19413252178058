import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';

const RejectReasonBox = ({ selectedId, showReasonPopup, setShowReasonPopup, setReason, handleReasonSubmit }) => {
    const [reason, setReasonText] = useState("");

    useEffect(() => {
        if (!showReasonPopup) {
            setReasonText(""); // Clear input when dialog is closed
        }
    }, [showReasonPopup]);

    const handleSubmit = () => {
        if (!reason.trim()) return; // Prevent empty submission
        setReason(reason);
        handleReasonSubmit(selectedId, reason);
        setShowReasonPopup(false);
    };

    return (
        <Dialog open={showReasonPopup} onClose={() => setShowReasonPopup(false)}>
            <DialogTitle>Provide a Reason</DialogTitle>
            <DialogContent>
                <TextField
                    label="Reason"
                    type="text"
                    fullWidth
                    multiline
                    rows={4}
                    value={reason}
                    onChange={(e) => setReasonText(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowReasonPopup(false)}>Cancel</Button>
                <Button onClick={handleSubmit} disabled={!reason.trim()}>Submit</Button>
            </DialogActions>
        </Dialog>
    );
};

export default RejectReasonBox;
