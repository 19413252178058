import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Alert } from '@mui/material';

const AlertMsg = ({ open, onClose, title, message, severity = 'info', buttonText = 'OK' }) => {
    const handleOkClick = () => {
        // Automatically close the dialog when OK is clicked
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Alert severity={severity}>{message}</Alert>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOkClick} color="primary">
                    {buttonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AlertMsg;
