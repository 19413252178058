import * as React from 'react';
import { useNavigate } from "react-router-dom"
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
const { DateTime } = require("luxon");
export default function OutlinedCard({data}) {
    // const navigate = useNavigate();
    if(data == undefined || data.post == undefined)
      return;
    return (
      <React.Fragment>
        <CardContent
          sx={{
            border: "1px solid #EEE",
            width: "990px",
            display: "inline-flex",
            flexDirection: "column",
            margin: "10px",
            borderRadius: "10px",
            backgroundColor: "#fff",
          }}
          // onClick={() => {
          //   navigate("/internships/detail");
          // }}
        >
          <Typography variant="h5" component="div">
            {data.post.title}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Odisha Development Management Programme (ODMP)
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {data.post.workModel == "remote"
              ? "Work from home"
              : "work from office"}
          </Typography>
          <div style={{ display: "flex" }}>
            <Typography
              sx={{ fontSize: 14, marginRight: "20px" }}
              color="text.secondary"
              gutterBottom
            >
              START DATE
              <br />
              {formatDate(data.post.startDate)}
            </Typography>
            <Typography
              sx={{ fontSize: 14, marginRight: "20px" }}
              color="text.secondary"
              gutterBottom
            >
              DURATION
              <br />
              {data.post.duration} Months
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              STIPEND
              <br />
              {data.post.stipend}
            </Typography>
          </div>
          <Stack direction="row" sx={{ marginTop: "20px" }} spacing={2}>
            <Chip label="Chip Filled" />
            <Chip label="Chip Outlined" variant="outlined" />
          </Stack>

          <Divider
            sx={{ margin: "0px", marginTop: "20px", marginBottom: "20px" }}
          />
          <CardActions>
            <Button
              size="small"
              sx={{ color: "#ff9800" }}
              // onClick={() => {
              //   handleDetail();
              // }}
            >
              Learn More
            </Button>
          </CardActions>
        </CardContent>
      </React.Fragment>
    );
}

function formatDate(dateString) {
  if (!dateString) {
    return "N/A";
  }

  const dateTime = DateTime.fromISO(dateString);

  return dateTime.toFormat("MMM yyyy");
}