import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Page404 = () => {
    return (
        <Box
            sx={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                gap: 2,
            }}
        >
            {/* Error Icon */}
            <ErrorOutlineIcon sx={{ fontSize: 80, color: "red" }} />

            {/* Error Message */}
            <Typography variant="h4" fontWeight="bold" color="textPrimary">
                404 - Page Not Found
            </Typography>
            <Typography variant="body1" color="textSecondary">
                Oops! The link you followed may be broken or the page may have been removed.
            </Typography>

            {/* Back to Home Button */}
            <Button
                component={Link}
                to="/"
                variant="contained"
                color="primary"
                startIcon={<ArrowBackIcon />}
                sx={{ mt: 2 }}
            >
                Back to Home
            </Button>
        </Box>
    );
};

export default Page404;
