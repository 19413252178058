import React, { useContext, useState, useEffect } from "react";
import CompanyDetailsComponent from "./components/CompanyDetailsComponent";
import { Box } from "@mui/material";
import Breadcrumb from "../components/Breadcrumb";

export default function EditCompanyDetails() {
    return (
        <div className="container employer-profile-page py-3 custom-form">
            <Breadcrumb
                heading="Company Details"
                breadcrumbs={[
                    { label: "Dashboard", href: "/employer/dashboard" },
                    { label: "Company Details", href: null },
                ]}
            />

            <Box>
                <Box mt={4}><CompanyDetailsComponent /> </Box>
            </Box>
        </div>
    )
};