import React from "react";
import { Outlet, useNavigate } from "react-router-dom"
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import GlobalStyles from "@mui/material/GlobalStyles";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import Badge from "@mui/material/Badge";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Avatar, Box, IconButton, Menu, MenuItem, Tooltip, Popover, List, ListItem } from "@mui/material";
import { useSelector } from "react-redux";
import UserContext from '../context/userContext';
import defaultTheme from "../assets/Theme/color";
import { useDispatch } from "react-redux";
import { logout } from "../Store/Action";
// import { useDispatch } from "react-redux"
// TODO remove, this demo shouldn't need to reset the theme.
import websiteSettings from "../common/siteSettings"

const profileTabs = [
    { name: 'LogOut', value: 'logOut' }
]

export default function AdminNavBar() {
    const dispatch=useDispatch()
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [apiData, setApiData] = React.useState("");
    const [loader, setLoader] = React.useState(false);
    const navigate = useNavigate();
    const isAuthenticated = useSelector((state) => state.admin);
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleSelectMenu = async (page) => {
        console.log(page);
        if (page == 'home') {
            navigate('/');
        } else if (page == 'myApplications') {
            navigate('/student/applications')
        } else if (page == "editProfile") {
            navigate("/employer/editProfile");
        } else if (page == "editPreferences") {
            navigate("/student/preference");
        } else if (page == "managedAccount") {
            navigate("/student/managedAccount");
        } else if (page == "logOut") {
            dispatch(logout());
            // window.location.reload();
            navigate("/admin/login");
        } else if (page == "Internships") {
            navigate("/internships");
        } else if (page == "Courses") {
            navigate("/courses");
        } else if (page == "Events") {
            navigate("/events");
        }
        handleCloseUserMenu();
    }
    const [anchorEl, setAnchorEl] = useState(null);

    const handleSignUpClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <GlobalStyles
                styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
            />
            <CssBaseline />
            {loader ? null : (
                <AppBar
                    position="static"
                    color="default"
                    elevation={0}
                    sx={{
                        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                        backgroundColor: "#fff",
                        zIndex:1000
                    }}
                >
                    <Toolbar sx={{ flexWrap: "wrap", justifyContent: "space-between" }}>
                        <Typography variant="h6" color="inherit">
                            <Link
                                to={"/"}
                                style={{
                                    textDecoration: "none",
                                    fontSize: "40px",
                                    color: "#04C53A",
                                }}
                            >
                                {websiteSettings.name}.com
                            </Link>
                        </Typography>

                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <nav>
                                <Link
                                    variant="button"
                                    color="primary"
                                    to={"/admin/dashboard"}
                                    sx={{ my: 1, mx: 1.5, textDecoration: "none" }}
                                    style={{
                                        textDecoration: "none",
                                        padding: "15px",
                                        fontWeight: 800,
                                        color: "#04C53A",
                                    }}
                                >
                                    Dashboard
                                </Link>
                            </nav>
                            {isAuthenticated != null ? (
                                <Box>
                                    <Tooltip title="Open settings">
                                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                            <Avatar
                                                alt="Remy Sharp"
                                                src="https://i.pinimg.com/280x280_RS/79/dd/11/79dd11a9452a92a1accceec38a45e16a.jpg"
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        sx={{ mt: "50px" }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    >
                                        <MenuItem
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                            }}
                                        >
                                            
                                                <>
                                                    <Typography
                                                        style={{ fontFamily: "PT Serif", fontWeight: 700 }}
                                                    >
                                                    {isAuthenticated.user.name}
                                                    </Typography>
                                                    <Typography style={{ color: "#808080" }}>
                                                        {isAuthenticated.user.email}
                                                    </Typography>
                                                </>
                                        </MenuItem>
                                        <hr />
                                        {profileTabs.map((item) => (
                                            <MenuItem
                                                key={item.value}
                                                onClick={() => {
                                                    handleSelectMenu(item.value);
                                                }}
                                            >
                                                <Typography textAlign="center">{item.name}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>
                            ) : (
                                <nav>
                                    <Button variant="outlined" sx={{ my: 1, mx: 1.5 }}>
                                        <Link
                                            to={"/admin/login/"}
                                            style={{
                                                textDecoration: "none",
                                                padding: "5px",
                                                fontWeight: 800,
                                                color: "#04C53A",
                                            }}
                                        >
                                            Login
                                        </Link>
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{ my: 1, mx: 1.5 }}
                                        onClick={()=>navigate("/admin/signup")}
                                    >
                                        <Link
                                            style={{
                                                textDecoration: "none",
                                                padding: "5px",
                                                fontWeight: 800,
                                                color: "#fff",
                                            }}
                                        >
                                            Sign Up 33
                                        </Link>
                                    </Button>
                                    <Popover
                                        open={Boolean(anchorEl)}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "left",
                                        }}
                                        style={{ marginTop: 10 }}
                                    >
                                        <List>
                                            <ListItem>
                                                <Link
                                                    to={"/student/signup"}
                                                    style={{
                                                        textDecoration: "none",
                                                        padding: "5px",
                                                        fontWeight: 800,
                                                        color: "#04C53A",
                                                    }}
                                                >
                                                    As a Student
                                                </Link>
                                            </ListItem>
                                            <ListItem>
                                                <Link
                                                    to={"/employer/signup"}
                                                    style={{
                                                        textDecoration: "none",
                                                        padding: "5px",
                                                        fontWeight: 800,
                                                        color: "#04C53A",
                                                    }}
                                                >
                                                    As a Employee
                                                </Link>
                                            </ListItem>
                                        </List>
                                    </Popover>
                                </nav>
                            )}
                        </Box>
                    </Toolbar>
                </AppBar>
            )}
            <Outlet />
        </div>
    );
}
