import {
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery
} from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom"; // Import useNavigate
import { getActiveRooms, getPostList } from "../../services/employerChat";
import ChatUserBox from "./ChatUserBox";

const ChatSideBar2 = ({
  uType,
  selectedUser,
  setSelectedUser,
  openChatSidebar,
  setOpenChatSidebar,
}) => {
  const [selectedRoomId, setSelectedRoomId] = React.useState(null);
  const [posts, setPosts] = React.useState([]);
  const token = useSelector((state) => state.token);
  const [rooms, setRooms] = React.useState([]);
  const [selectedPostId, setSelectedPostId] = React.useState("none");
  const navigate = useNavigate();
  const { roomid } = useParams();
  const isMobile = useMediaQuery("(max-width: 800px)"); // Check if device is mobile÷

  const handleRoomClick = (roomId) => {
    setSelectedRoomId(roomId);
    const nUrl = "/employer/chat/" + roomId;
    navigate(nUrl);
    setOpenChatSidebar(false);
  };

  const getChatRooms = async () => {
    const data = await getActiveRooms(token, selectedPostId);
    setRooms(data ? data.rooms : []);
  };

  useEffect(() => {
    getAllPosts();
    setSelectedRoomId(roomid);
  }, []);

  useEffect(() => {
    getChatRooms();
  }, [selectedPostId]);

  const getAllPosts = async () => {
    const data = await getPostList(token);
    if (data) {
      const uniquePosts = [...new Set(data.posts)];
      setPosts(uniquePosts);
    }
  };

  const handleSelectChange = (event) => {
    const newPostId = event.target.value;
    setSelectedPostId(newPostId);
  };

  // Render the sidebar content
  const renderSidebarContent = () => (
    <div className="chat-leftbar-body">
      <div className="p-3">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-helper-label">
            Select Internship
          </InputLabel>
          <Select
            labelId="choose-internship"
            id="choose-internship"
            label="Select Internship"
            value={selectedPostId}
            onChange={handleSelectChange}
          >
            <MenuItem value="none">
              <em>All Internships</em>
            </MenuItem>
            {posts &&
              posts.map((item) => {
                return (
                  <MenuItem
                    className="internship-list"
                    value={item._id}
                    key={item._id}
                  >
                    {item.title}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>

        {/* TODO
        <TextField
          fullWidth
          className="chat-seaarch-user"
          placeholder="Search applicant by name..."
          sx={{ mb: 2, marginTop: "10px" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        /> Search Bar */}
      </div>

      <ul className="chat-header-group">
        {rooms.map((tRoom, index) => {
          const isActive = tRoom._id === selectedRoomId;
          return (
            <li key={index} onClick={() => handleRoomClick(tRoom._id)}>
              <ChatUserBox isActive={isActive} tRoom={tRoom} />
            </li>
          );
        })}
      </ul>
    </div>
  );

  // Drawer for mobile view
  const mobileDrawer = (
    <Drawer
      anchor="left"
      PaperProps={{
        sx: { width: "60%" },
      }}
      open={openChatSidebar}
      onClose={() => {
        setOpenChatSidebar(false);
        setSelectedRoomId(null);
      }} // Optional: close the drawer when clicking outside
    >
      {renderSidebarContent()}
    </Drawer>
  );

  return (
    <>
      {isMobile ? (
        mobileDrawer // Use Drawer when on mobile
      ) : (
        <div className="desktop-sidebar">{renderSidebarContent()}</div> // Use regular sidebar for desktop
      )}
    </>
  );
};

export default ChatSideBar2;
