import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate, Link } from "react-router-dom";
import Chip from "@mui/material/Chip"; 
import { cityOptions, degreeOptions, skillOptions, yearOptions } from "../common/constants"; 
import ApplicantBox from "./ApplicantBox";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const ApplicationBox = () => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-sm-3 col-xl-2">
          <div className="status-sidebar-box">
          <h1 className="page-title-1   mb-3">Access Database</h1>
            <ul>
              <li> <Link to=""> Application Received  <Chip label="34"   />  </Link> </li>
              <li> <Link to="">  Shortlisted   <Chip label="34"   /> </Link> </li>
              <li> <Link to="">  Hired <Chip label="34"   /> </Link>  </li>
              <li> <Link to="">  Not Intrested  <Chip label="34"   />  </Link>  </li>
              <hr />
              <li> <Link to="">  Assignments   <Chip label="34"   /> </Link> </li>
              <li> <Link to="">  Interviews  <Chip label="34"   /> </Link> </li>
              <li> <Link to="">  Chat Messages <Chip label="34"   /> </Link> </li>
            </ul>
          </div>
          <div className="status-sidebar-box mt-5">
            <h1 className="page-title-1 mb-3">Filters</h1>
            <Autocomplete
              id="city-list"
              className="mb-3"
              freeSolo
              multiple
              options={cityOptions.map((option) => option.city)}
              renderInput={(params) => (
                <TextField {...params} label="Applicant's Location" />
              )}
            />
            <Autocomplete
              id="skills-list"
              className="mb-3"
              freeSolo
              multiple
              options={skillOptions.map((option) => option.skill)}
              renderInput={(params) => <TextField {...params} label="Skills" />}
            />
            <Autocomplete
              id="academic-list"
              className="mb-3"
              freeSolo
              multiple
              options={degreeOptions.map((option) => option.degree)}
              renderInput={(params) => (
                <TextField {...params} label="Academic Background" />
              )}
            />
            <Autocomplete
              id="graduation-year-list"
              className="mb-3"
              freeSolo
              options={yearOptions.map((option) => option)}
              renderInput={(params) => (
                <TextField {...params} label="Graduation Year" />
              )}
            />
        
            
          </div>
        </div>
        <div className="col-sm-9 col-xl-10">
        <FormGroup>
            <FormControlLabel control={<Switch defaultChecked size="medium" />} label="Select All"  />
            </FormGroup>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ApplicationBox;
