import React, { useState } from "react";
import WebsiteBreadCrumb from "./website-components/WebsiteBreadCrumb";
import { TextField, Typography, Button, Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
const VerifyInternship = () => {
  const [internshipId, setInternshipId] = useState("");
  const [verificationResult, setVerificationResult] = useState(null);

  const handleVerification = () => {
    // Add your verification logic here
    if (internshipId === "12345") {
      // Example: mock check for internship ID
      setVerificationResult("Internship verified successfully.");
    } else {
      setVerificationResult("Invalid internship ID. Please try again.");
    }
  };

  return (
    <div>
      <Helmet>
          <title>HeyInterns | Verify Internship Certificate </title>
      </Helmet>
      <WebsiteBreadCrumb title="Verify Internship" />
      <div className="container policy-page">
        {/* Header Section */}
        <div className="heading-box">
          <h2>Verify Internship Certificate</h2>
          <p>
            You can verify your internship certificate by entering the
            internship ID provided in your HeyInterns Internship Certificate.
          </p>
        </div>

        {/* Verification Form Section */}
        <div>
          <h3>Verification Process</h3>
          <p>
            To verify your internship certificate, simply enter the unique
            internship ID mentioned on your certificate. This ID helps us
            confirm the authenticity of your internship and ensures that the
            certificate has been issued through our platform.
          </p>
          <Grid container spacing={2} alignItems="center">
            {/* Internship ID Input */}
            <Grid item xs={8}>
              <TextField
                label="Internship ID"
                variant="outlined"
                value={internshipId}
                onChange={(e) => setInternshipId(e.target.value)}
                fullWidth
                placeholder="e.g., 12345"
              />
            </Grid>

            {/* Verify Button */}
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                sx={{ height: "56px", fontSize: "1.5rem" }}
                onClick={handleVerification}
                fullWidth
              >
                Verify Certificate
              </Button>
            </Grid>
          </Grid>

          {/* Verification Result */}
          {verificationResult && (
            <div className="verification-result">
              <Typography
                variant="body1"
                color={
                  verificationResult.includes("successfully") ? "green" : "red"
                }
              >
                {verificationResult}
              </Typography>
            </div>
          )}
          <hr />

          <h3>Important Information</h3>
          <p>
            Please ensure that you have the correct internship ID from your
            internship certificate before proceeding with the verification. If
            you encounter any issues, feel free to contact our support team for
            assistance.
          </p>
          <p>
            For any questions or concerns, reach us at:
            <a href="mailto:support@heyinterns.com">
              <strong>support@heyinterns.com</strong>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default VerifyInternship;
