import React from 'react'

import { skillsInDemand } from "../../services/filterPost";
import { Box } from "@mui/material";
import { CircularProgress } from "@material-ui/core";
import { useEffect } from 'react';

const InternshipCategory = () => {

  const [skills, setSkills] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    getLatestSkills();
  }, [])

  const getLatestSkills = async () => {
    const tskills = await skillsInDemand();
    setSkills(tskills?.skills || []);
    setLoading(false);
  }


  return ( <>
    { loading ? ( <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
    <div className="section-full job-category content-inner-1 bg-white">
        <div className="container">
          <div className="section-head text-center style-1">
           
            <h6>Internship Skills</h6>
            <h2 className="section-title-3">Choose Your Desire Skills </h2>
            <p>
              Skills in high demand by companies
            </p>
          </div>
          <div className="row sp20 wrapper-spacing">
          {skills?.length > 0 ? (
            skills.map((skillData, index) => (
              <div className="col-lg-3 col-md-4 col-sm-6 m-b20 job-wraper">
                <a href={`/internships/${skillData.name}_internship`} className="job-bx-wraper">
                <div className="icon-content">
                  <h5 className="job-name">{skillData.name}</h5>
                </div>
                </a>
              </div>
            ))
          ) : (
            <p>No skills available.</p>
          )}
          </div>
        </div>
        <div className="back-circle" />
        <div className="back-circle2" />
      </div>
      )}
      </>
  )
}

export default InternshipCategory