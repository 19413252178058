import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Typography, Box, CircularProgress, Button } from "@mui/material";
import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material"; // Success & Error Icons
import { verifyEmail } from "../services/verification"; // API service

const EmailVerifyPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  // Extract query parameters
  const getQueryParams = (query) => new URLSearchParams(query);

  useEffect(() => {
    const verifyToken = async () => {
      const queryParams = getQueryParams(location.search);
      const tokenidParam = queryParams.get("tokenid");

      if (!tokenidParam) {
        setError("Invalid token.");
        setLoading(false);
        return;
      }

      try {
        const result = await verifyEmail(tokenidParam, "college"); // Call verification API
        if (result.success) {
          setSuccess(true);
        } else {
          setError(result.message || "Email verification failed.");
        }
      } catch (err) {
        setError("An error occurred during verification.");
      } finally {
        setLoading(false);
      }
    };

    verifyToken();
  }, []);

  const handleRedirect = () => navigate("/");

  return (
    <Container maxWidth="sm" sx={{ textAlign: "center", mt: 8 }}>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress />
        </Box>
      ) : success ? (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CheckCircleOutline color="success" sx={{ fontSize: 80, mb: 2 }} />
          <Typography variant="h4" color="success.main" gutterBottom>
            Email Verified!
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ mb: 3, marginBottom: "40px" }}>
            Your email has been successfully verified. Our team will connect with you.
          </Typography>
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <ErrorOutline color="error" sx={{ fontSize: 80, mb: 2 }} />
          <Typography variant="h4" color="error.main" gutterBottom>
            Verification Failed
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ mb: 3 , marginBottom: "40px"}}>
            {error || "The verification link is invalid or has expired."}
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default EmailVerifyPage;
