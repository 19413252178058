import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
} from "@mui/material";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { InterShipDetailsContent } from "../components/InterShipDetailsContent";
import InternshipStoryComponent from "../story/components/InternshipStory";
import { useSelector } from "react-redux";
import { getStoryDetails } from "../services/appliedInternship";
import { showToastAlert } from "../admin/components/CustomAlert";
const SingleInternshipStoryModal = ({
  open,
  storyid,
  closeStoryView,
  uType="student"
}) => {
    const token = useSelector((state) => state.token);
    const [story, setStory] = React.useState(null);
  const handleClose = () => {
    closeStoryView();
  };
  React.useEffect(() => {
    fetchData();
  }, [storyid]);

  const fetchData = async () =>  {
    const response = await getStoryDetails(token, storyid, uType);
    if(response.status !== 200) {
        showToastAlert(response?.data?.message, "error");
        return;
    }
    setStory(response.data.story);
  };
  return (
    <Dialog
      open={open}
      aria-labelledby="internship-details"
      className="internship-details-popup"
      fullWidth
      maxWidth={false} // Disable default max width
      sx={{
        "& .MuiDialog-paper": {
          // Target the dialog's paper (the actual content container)
          width: "80vw", // Set width to 80% of viewport width
          maxWidth: "none", // Disable MUI's default maxWidth for the paper
          maxHeight: "90vh",
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        className="popup-close-btn"
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <InternshipStoryComponent
          story={story}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SingleInternshipStoryModal;
