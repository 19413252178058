import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import { showToastAlert, CustomAlert } from "../admin/components/CustomAlert";
import { studentChangePassword } from "../services/studentRegister";
import PasswordIcon from '@mui/icons-material/Password';

export default function ChangePassword() {
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [current, setCurrent] = React.useState("");
  const [err, setErr] = React.useState(null);

  // Visibility states
  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);

  const validateInputs = () => {
    if (!current || !password || !confirmPassword) {
      setErr("All fields are required.");
      return false;
    }
    if (password !== confirmPassword) {
      setErr("New password and confirm password must match.");
      return false;
    }
    setErr(null); // Clear error if validation passes
    return true;
  };

  const handleChangePassword = async () => {
    if (!validateInputs()) {
      return;
    }

    try {
      const response = await studentChangePassword(token, current, password);
      if (response.status === 200) {
        showToastAlert("Password changed successfully", "success");
        setPassword("");
        setCurrent("");
        setConfirmPassword("");
      } else {
        showToastAlert(response.data.message, "error");
      }
    } catch (error) {
      setErr("An error occurred. Please try again.");
      console.error(error);
    }
  };

  return (
    <div className="themeprovider-wrapper">
      <CustomAlert />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            marginBottom: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "30px",
            borderRadius: "10px",
            boxShadow:
              "-2px -2px 6px rgba(0,107,194,.12), 2px 2px 6px rgba(0,107,194,.12)",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <PasswordIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Change Password
          </Typography>
          <Box noValidate sx={{ mt: 3 }}>
            {err && (
              <Typography
                variant="body2"
                sx={{
                  color: "red",
                  fontSize: "16px",
                  fontWeight: "500",
                  textAlign: "center",
                  mb: 2,
                }}
              >
                {err}
              </Typography>
            )}
            <Grid container spacing={2}>
              {/* Current Password */}
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => setCurrent(e.target.value)}
                  size="small"
                  required
                  fullWidth
                  id="current-password"
                  label="Current Password"
                  name="current-password"
                  autoComplete="current-password"
                  type={showCurrentPassword ? "text" : "password"}
                  value={current}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowCurrentPassword(!showCurrentPassword)
                          }
                          edge="end"
                        >
                          {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {/* New Password */}
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => setPassword(e.target.value)}
                  size="small"
                  required
                  fullWidth
                  label="New Password"
                  name="new-password"
                  autoComplete="new-password"
                  type={showNewPassword ? "text" : "password"}
                  value={password}
                  InputProps={{
                    maxLength: 10,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {/* Confirm Password */}
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  size="small"
                  required
                  fullWidth
                  id="confirm-password"
                  label="Confirm Password"
                  name="confirm-password"
                  autoComplete="confirm-password"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  InputProps={{
                    maxLength: 10,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleChangePassword}
            >
              Update Password
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  );
}
