import React from "react";
import { Outlet } from "react-router-dom";
import { Box, Checkbox, FormControlLabel, Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import PostApplicationSummary from "./ApplicationSumary";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useParams } from "react-router-dom";
import {
  allPostApplications,
  allPostApplicationsStatus,
  sendAssignment,
  updateApplicationStateApi,
} from "../../services/getAllApplicationEmployee";
import ApplicantBox from "../../components/ApplicantBox";
import { useOutletContext } from "react-router-dom";
import AssignmentModal from "../modals/AssignmentModal";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import {
  CustomAlert,
  showToastAlert,
} from "../../admin/components/CustomAlert";
import ApplicantModal from "../modals/ApplicantModal";
const PostApplicationList = () => {
  const { counts, setCounts } = useOutletContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { id, state } = useParams();
  // Access the specific search key value
  let searchKeyValue = state;
  if (searchKeyValue == undefined) {
    searchKeyValue = "pending";
  }

  const value1 = 2;
  const value2 = 1;
  const value3 = 3;
  const value4 = 3;

  const [value, setValue] = React.useState("one");
  const [apiData, setApiData] = useState();
  const [showAssigmentModal, setShowAssigmentModal] = useState(false);
  const [showApplicantModal, setShowApplicanttModal] = useState(false);
  const [applications, setApplications] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]); // Store selected IDs
  const [currApplicantId, setCurrApplicantId] = useState(null);

  const token = useSelector((state) => state.token);
  const updateApplicationState = async (appid, appState) => {
    //appid = application id
    try {
      const data = await updateApplicationStateApi(token, appid, appState);
      console.log(data);
      if (data.msg == "success") {
        //remove application from current array
        const newArr = applications.filter((item) => item._id !== appid);
        setApplications(newArr);
      }
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAssignmentModal = async () => {
    if (selectedIds.length <= 0) {
      showToastAlert("Select atleast one user", "error");
      return;
    }
    console.log("selectedIds:" + selectedIds);
    setShowAssigmentModal(true);
  };
  useEffect(() => {
    viewApplication();
  }, [searchKeyValue]);
  const viewApplication = async () => {
    try {
      const data = await allPostApplications(token, id, searchKeyValue);
      const postStatusData = await allPostApplicationsStatus(token, id);

      console.log(data);
      setApiData(data);
      setApplications(data.applications);
      setQuestions(data.post.questions);
      if (postStatusData.applications) {
        let tCount = {
          pending: 0,
          shortlist: 0,
          rejected: 0,
          hired: 0,
          all: 0,
        };
        tCount.all = postStatusData.applications.length;
        postStatusData.applications.forEach((tApplication) => {
          if (tApplication.state === "pending") tCount.pending += 1;
          else if (tApplication.state === "hired") tCount.hired += 1;
          else if (tApplication.state === "shortlist") tCount.shortlist += 1;
          else if (tApplication.state === "rejected") tCount.rejected += 1;
        });
        setCounts(tCount);
        console.log("counts:" + tCount);
      }

      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  // Handle checkbox change for selecting individual applications
  const handleSelect = (id, checked) => {
    setSelectedIds(
      (prevSelected) =>
        checked
          ? [...prevSelected, id] //select
          : prevSelected.filter((itemId) => itemId !== id) // Deselect
    );
    console.log("selectedid:" + selectedIds);
  };

  // Select/Deselect all applications
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allIds = applications.map((app) => app._id);
      setSelectedIds(allIds); // Select all IDs
    } else {
      setSelectedIds([]); // Deselect all
    }
  };

  const getNamesFromIds = (ids) => {
    let nameArr = [];
    ids.forEach((sid) => {
      for (let i = 0; i < applications.length; i++) {
        const application = applications[i];
        if (application.userId._id == sid) {
          nameArr.push(application.userId.name);
          break; // Exit the inner loop once a match is found
        }
      }
    });
    return nameArr;
  };

  const handleSendAssignment = async (content, deadline) => {
    try {
      const response = await sendAssignment(
        token,
        id,
        selectedIds,
        content,
        deadline
      );
      if (response.status === 200) {
        showToastAlert("Assignment sent", "success");
      } else {
        showToastAlert("Assignment sent failed", "error");
      }
    } catch (error) {
      showToastAlert(error, "error");
    }
  };

  return (
    <Box className="application-box-wrapper">
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between", // Pushes children to left and right edges
            width: "100%",
          }}
        >
          {/* Left Group: FormControlLabel and Button */}
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <FormControlLabel
              control={<Checkbox sx={{ color: "grey" }} defaultunChecked />}
              label="Select All"
              sx={{ color: "grey", margin: "0" }}
              onChange={handleSelectAll}
              checked={selectedIds.length === applications.length}
            />
            <Button
              size="small"
              variant="outlined"
              className="send-assignment-list-btn"
              onClick={() => handleAssignmentModal()}
            >
              Send Assignment
            </Button>
          </div>
          {/* Right Group: Total applications text */}
          <h5 className="applicants-text" style={{ margin: "0" }}>
            Total {applications.length} applications
          </h5>
        </div>

        {applications &&
          applications.map((application) => (
            <ApplicantBox
              key={application.id}
              application={application}
              searchKeyValue={searchKeyValue}
              updateApplicationState={updateApplicationState}
              questions={questions}
              isSelected={selectedIds.includes(application._id)} // Pass selection state
              handleSelect={handleSelect}
              setCurrApplicantId={setCurrApplicantId}
              setShowApplicanttModal={setShowApplicanttModal}
            />
          ))}
      </div>
      <CustomAlert />
      <AssignmentModal
        showAssigmentModal={showAssigmentModal}
        setShowAssigmentModal={setShowAssigmentModal}
        selectedIds={selectedIds}
        getNamesFromIds={getNamesFromIds}
        handleSendAssignment={handleSendAssignment}
      />
      <ApplicantModal showApplicantModal={showApplicantModal} currApplicantId={currApplicantId} setShowApplicanttModal={setShowApplicanttModal} />
    </Box>
  );
};

export default PostApplicationList;
