import React, { useState } from "react";
import { Container, TextField, Button, Typography, Box, MenuItem, Select, FormControl, InputLabel, Grid, Paper, Card, CardContent } from "@mui/material";
import { School, PersonAdd, Verified, Description, People, Work, Label } from "@mui/icons-material";
import CollegeAutoComplete from "../components/autocomplete/CollegeAutoComplete";
import CityAutoComplete from "../components/autocomplete/CityAutoComplete";
import { fetchCaptchaApi } from "../services/captchaApi";
import { CustomAlert, showToastAlert } from "../admin/components/CustomAlert";
import { collegeRegister } from "../services/college";
import { useNavigate } from "react-router";
import { isValidIndianMobileNumber } from "../common/commonFunctions";
import { Helmet } from "react-helmet-async";
const CollegeRegistration = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    collegeName: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    designation: "",
    department: "",
    city: "",
    answer: "",
    captchaHex: "",
  });

  const [captchaImg, setCaptchaImg] = useState("");

  
  const validateForm = () => {
    let validationErrors = {};
  
    if (!formData.collegeName) validationErrors.collegeName = "College Name is required";
    if (!formData.firstName) validationErrors.firstName = "First Name is required";
    if (!formData.lastName) validationErrors.lastName = "Last Name is required";
    if (!formData.email) validationErrors.email = "Email is required";
    if (!formData.phone) validationErrors.phone = "Mobile Number is required";
    if (!formData.designation) validationErrors.designation = "Designation is required";
    if (!formData.department) validationErrors.department = "Department is required";
    if (!formData.city) validationErrors.city = "City is required";
    if (!formData.answer) validationErrors.answer = "CAPTCHA answer is required";
    if(!isValidIndianMobileNumber(formData.phone)) {
      validationErrors.phone = "Mobile Number is not valid indian number";
    }
  
    if (Object.keys(validationErrors).length > 0) {
      showToastAlert(Object.values(validationErrors).join("\n"), "error");
      return false;
    }
  
    return true;
  };

  const registerCollege = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      const response = await collegeRegister({
        collegeData: formData
      });
      if (response.status === 200) {
        navigate("/college-account-created");
      } else {
        showToastAlert(response.data.message, "error");
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    fetchCaptcha();
  }, []);

  const fetchCaptcha = async () => {
    try {
      const res = await fetchCaptchaApi();
      if (res) {
        setCaptchaHex(res.hex);
        setCaptchaImg(res.img);
      }
    } catch (error) {
      console.error("Error fetching CAPTCHA", error);
    }
  };
  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const setCaptchaHex = (name) => {
    setFormData({ ...formData, captchaHex: name });
  }

  const setCollege = (name) => {
    setFormData({ ...formData, collegeName: name });
  }

  const setCity = (name) => {
    setFormData({ ...formData, city: name });
  }

  const setAnswer = (name) => {
    setFormData({ ...formData, answer: name });

  }
  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 5, mb: 5 }}>
      {/* Registration Form */}
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={6}>
          <Helmet>
              <title>HeyInterns | Register College/TPO </title>
          </Helmet>
          <Typography variant="h4" gutterBottom color="primary" fontWeight="bold">
            Register your college with HeyInterns
          </Typography>
          <Typography variant="body1" gutterBottom>
            Maximize your student's opportunities by connecting with 100+ companies.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 4, borderRadius: 2, boxShadow: 3 }}>
            <Typography variant="h5" gutterBottom fontWeight="bold">
              Registration form for college authorities
            </Typography>
            <form onSubmit={handleSubmit}>
              <label className="login-form-label">College Name</label>
              <CollegeAutoComplete value={formData.collegeName} setCollege={setCollege} />
              <Grid container spacing={2}>
                <Grid item xs={6}><TextField label="First Name" name="firstName" value={formData.firstName} onChange={handleChange} fullWidth margin="normal" required /></Grid>
                <Grid item xs={6}><TextField label="Last Name" name="lastName" value={formData.lastName} onChange={handleChange} fullWidth margin="normal" required /></Grid>
              </Grid>
              <TextField label="Official Email" name="email" type="email" value={formData.email} onChange={handleChange} fullWidth margin="normal" required />
              <TextField label="Mobile Number" name="phone" type="tel" value={formData.phone} onChange={handleChange} fullWidth margin="normal" required />
              <FormControl fullWidth margin="normal">
                <label className="login-form-label">Designation</label>
                <Select
                  name="designation"
                  value={formData.designation}
                  onChange={handleChange}
                  required
                >
                  <MenuItem value="" disabled>Choose designation</MenuItem>
                  <MenuItem value="Training & Placement Officer (Faculty)">Training & Placement Officer (Faculty)</MenuItem>
                  <MenuItem value="Training & Placement Officer (Staff)">Training & Placement Officer (Staff)</MenuItem>
                  <MenuItem value="Head of Department">Head of Department</MenuItem>
                  <MenuItem value="Dean">Dean</MenuItem>
                  <MenuItem value="Director">Director</MenuItem>
                  <MenuItem value="Professor">Professor</MenuItem>
                  <MenuItem value="Registrar">Registrar</MenuItem>
                  <MenuItem value="Student Placement Coordinator">Student Placement Coordinator</MenuItem>
                  <MenuItem value="Student">Student</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
              <label className="login-form-label">Department</label>
              <Select
                name="department"
                value={formData.department}
                onChange={handleChange}
                required
              >
                <MenuItem value="" disabled>Choose department</MenuItem>
                <MenuItem value="Engineering">Engineering</MenuItem>
                <MenuItem value="Management">Management</MenuItem>
                <MenuItem value="Science">Science</MenuItem>
                <MenuItem value="Arts">Arts</MenuItem>
                <MenuItem value="Commerce">Commerce</MenuItem>
                <MenuItem value="Design/Architecture">Design/Architecture</MenuItem>
                <MenuItem value="Pharma">Pharma</MenuItem>
                <MenuItem value="Medical">Medical</MenuItem>
                <MenuItem value="Law">Law</MenuItem>
                <MenuItem value="Others">Others</MenuItem>
              </Select>
            </FormControl>

              <label className="login-form-label">College Location</label>
              <CityAutoComplete label="City" setCity={setCity} value={formData.city} required />
              <img src={captchaImg} alt="CAPTCHA" style={{marginTop:"20px"}}/>
              <TextField
                onChange={(e) => setAnswer(e.target.value)}
                size="small"
                required
                fullWidth
                label="Enter CAPTCHA"
                inputProps={{ maxLength: 15 }}
              />
              <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }} onClick={registerCollege}>Register now</Button>
            </form>
          </Paper>
        </Grid>
      </Grid>

      {/* Why Register Section */}
      <Box mt={6} textAlign="center">
        <Typography variant="h4" fontWeight="bold">Why Register?</Typography>
        <Grid container spacing={3} justifyContent="center" mt={3}>
          {[{ icon: <School fontSize="large" />, title: "Personalised Dashboard", desc: "Get regular updates to monitor students' internship selections." },
            { icon: <PersonAdd fontSize="large" />, title: "Student Registration", desc: "We provide all students a free Internshala account." },
            { icon: <Verified fontSize="large" />, title: "Early Access", desc: "Early access to lastest internship and opportunities." }].map((item, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ textAlign: 'center', p: 3, boxShadow: 3 }}>
                {item.icon}
                <CardContent>
                  <Typography variant="h6" fontWeight="bold">{item.title}</Typography>
                  <Typography variant="body2" color="textSecondary">{item.desc}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* How it Works Section */}
      <Box mt={6} textAlign="center" sx={{ backgroundColor: '#f5f5f5', py: 5 }}>
        <Typography variant="h4" fontWeight="bold">How it Works?</Typography>
        <Grid container spacing={3} justifyContent="center" mt={3}>
          {[{ icon: <Description fontSize="large" />, title: "Fill the form", desc: "Provide necessary details about your college." },
            { icon: <People fontSize="large" />, title: "Register Students", desc: "We will create accounts for your students." },
            { icon: <Work fontSize="large" />, title: "Provide Access", desc: "Students can apply for exciting opportunities." }].map((item, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ textAlign: 'center', p: 3, boxShadow: 3 }}>
                {item.icon}
                <CardContent>
                  <Typography variant="h6" fontWeight="bold">{item.title}</Typography>
                  <Typography variant="body2" color="textSecondary">{item.desc}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <CustomAlert />
      </Box>
    </Container>
  );
};

export default CollegeRegistration;
