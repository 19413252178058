import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Button } from "@mui/material";
import { useParams } from "react-router";
import { getApplicant } from "../../services/getAllApplicationEmployee";
import { useSelector } from "react-redux";

const ApplicationDetailsPage = () => {
  const {appid} = useParams();
  const [applicant, setApplicant] = React.useState(null);
  const token = useSelector((state) => state.token);
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  React.useEffect(() => {
    if(appid) {
      getApplicantDeatils(appid);
    }
  }, [appid])

  const getApplicantDeatils = async (appid) => {
    try{
      const response = await getApplicant(token, appid);
      if(response.status === 200) {
        setApplicant(response.data.applicant.userId);
      } else {
  
      }
    } catch (error) {
      console.log(error);
    }
    
  }

  const breadcrumbsData = [
    { label: "Dashboard", href: "/employer/dashboard", onClick: handleClick },
    {
      label: "Applications Received",
      href: "/applications",
      onClick: handleClick,
    },
    { label: "", href: null }, // Last breadcrumb (no link)
  ];

  return (
    <div className="application-details-page container py-3 custom-form">
      <Breadcrumb
        breadcrumbs={breadcrumbsData}
        heading="Applications for Manual Testing Internship"
      />
      {applicant ? (
      <>
      <div className="inpuxbox-group">
        <div className="heading-box">
          <h2>
            <AccountCircleIcon /> {applicant.name}{" "}
          </h2>
          <p className="applied-text">Applied 74 days ago</p>
          <p>
            Delhi (Open to relocate), Total work experience: 9 months{" "}
            <Tooltip title="Includes both internship and job" arrow>
              <InfoOutlinedIcon className="info-icon" />
            </Tooltip>
          </p>
        </div>
        <div className="ad-box">
          <div className="row">
            <div className="col-sm-3">
              <Typography className="input-label1">
                Portfolio/ Work samples
              </Typography>
            </div>
            <div className="col-sm-9">
              <div className="st-iconsbox">
                <a
                  href="https://github.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa-brands fa-github"></i>
                </a>
                <a
                  href="https://linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa-brands fa-linkedin-in"></i>
                </a>
                <a
                  href="https://linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa-solid fa-link"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <Typography className="input-label1">SKILL(S)</Typography>
            </div>
            <div className="col-sm-9">
              <p>
                HTML , C Programming , C++ Programming , CSS , Express.js ,
                Firebase , JavaScript , MongoDB , Next.js , Node.js , OpenCV ,
                PHP , Python , React , ReactJS , TypeScript , C# , Redux ,
                Tailwind CSS , Unity 3D
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <Typography className="input-label1">Language(S)</Typography>
            </div>
            <div className="col-sm-9">
              <p>English, Hindi</p>
            </div>
          </div>
        </div>
      </div>

      <div className="inpuxbox-group">
        <div className="heading-box">
          <h2>Resume</h2>
          <p>Skilled intern with experience in development and testing.</p>
        </div>
        <div className="ad-box">
          <div className="row">
            <div className="col-sm-3">
              <Typography className="input-label1">EDUCATION</Typography>
            </div>
            <div className="col-sm-9">
              <div>
                <p>
                  <b>B.Tech, Computer Science & Engineering</b> <br />
                  CBS Group Of Institutions <br />
                  2021 - 2025
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <Typography className="input-label1">SKILL(S)</Typography>
            </div>
            <div className="col-sm-9">
              <p>
                HTML , C Programming , C++ Programming , CSS , Express.js ,
                Firebase , JavaScript , MongoDB , Next.js , Node.js , OpenCV ,
                PHP , Python , React , ReactJS , TypeScript , C# , Redux ,
                Tailwind CSS , Unity 3D
              </p>
            </div>
          </div>
          {/* Education Section */}
          <div className="row">
            <div className="col-sm-3">
              <Typography className="input-label1">EDUCATION</Typography>
            </div>
            <div className="col-sm-9">
              <p>
                <b>B.Tech, Computer Science & Engineering</b> <br />
                CBS Group Of Institutions <br />
                2021 - 2025
              </p>
            </div>
          </div>

          {/* Jobs Section */}
          <div className="row">
            <div className="col-sm-3">
              <Typography className="input-label1">JOBS</Typography>
            </div>
            <div className="col-sm-9">
              <p>
                <b>Open CV Intern</b> <br />
                Enord, Virtual <br />
                Job • Aug 2023 - Oct 2023 (3 months) <br />I worked on
                ortho-mosaic and Arial image processing.
              </p>
            </div>
          </div>

          {/* Internships Section */}
          <div className="row">
            <div className="col-sm-3">
              <Typography className="input-label1">INTERNSHIPS</Typography>
            </div>
            <div className="col-sm-9">
              <p>
                <b>ReactJS Development</b> <br />
                Jala Academy, Virtual <br />
                Internship • Dec 2023 - May 2024 (6 months) <br />I was there as
                a React frontend developer, did website testing, and worked on
                building the CRM portal for the client.
              </p>
            </div>
          </div>

          {/* Projects Section */}
          <div className="row">
            <div className="col-sm-3">
              <Typography className="input-label1">PROJECTS</Typography>
            </div>
            <div className="col-sm-9">
              <div>
                <p>
                  <b>Presentation CV</b> <br />
                  Dec 2022 - Dec 2023 <br />
                  <Link
                    href="https://github.com/AshutoshStark/open-cv-projects"
                    target="_blank"
                    rel="noopener"
                    className="highlight-link"
                  >
                    https://github.com/AshutoshStark/open-cv-projects
                  </Link>
                  <br />
                  It is a project using OpenCV to control presentations.
                </p>
                <p>
                  <b>Anime Website</b> <br />
                  Jul 2023 - Nov 2023 <br />
                  <Link
                    href="https://animesensei.vercel.app"
                    target="_blank"
                    rel="noopener"
                    className="highlight-link"
                  >
                    https://animesensei.vercel.app
                  </Link>
                  <br />
                  This project is based on APIs and streams anime, similar to
                  Netflix.
                </p>
                <p>
                  <b>Note Compass</b> <br />
                  Nov 2023 - Nov 2023 <br />
                  <Link
                    href="https://notecompass.vercel.app"
                    target="_blank"
                    rel="noopener"
                    className="highlight-link"
                  >
                    https://notecompass.vercel.app
                  </Link>
                  <br />
                  It is a note-sharing website.
                </p>
              </div>
            </div>
          </div>

          {/* Resume Section */}
          <div className="row">
            <div className="col-sm-3">
              <Typography className="input-label1">
                UPLOADED RESUME/CV
              </Typography>
            </div>
            <div className="col-sm-9">
              <Link
                href="ashutoshsresume_1725420297.pdf"
                target="_blank"
                rel="noopener"
                className="highlight-link"
              >
                ashutoshsresume_1725420297.pdf
              </Link>
            </div>
          </div>

          {/* Contact Section */}
          <div className="row">
            <div className="col-sm-3">
              <Typography className="input-label1">CONTACT</Typography>
            </div>
            <div className="col-sm-9">
              <p>
                <b>HeyInterns Chat:</b>{" "}
                <Link href="#" className="highlight-link">
                  Start Chat
                </Link>
                <br />
                <b>Phone:</b> +91 8178****** <br />
                <Link href="#" className="highlight-link">
                  Unlock
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="sp-b mt-3">
          <div>
            <Button
              variant="outlined"
              // onClick={() => handleViewApplication(application.userId._id)}
            >
              View Full Application
            </Button>
            <Button variant="outlined" className="mx-2">
              Add Notes
            </Button>
            <Button
              variant="outlined"
              // onClick={() => handleOpenChat(application.userId._id)}
            >
              Open Chat
            </Button>
          </div>
          <div>
            <Button variant="contained" color="info">
              Shortlist
            </Button>
            <Button variant="contained" color="error" className="mx-2">
              Reject
            </Button>
            <Button variant="contained" color="success">
              Hire
            </Button>
          </div>
        </div>
      </div>
      </>
      ) :  <Typography>Loading applicant details...</Typography> }
    </div>
  );
};

export default ApplicationDetailsPage;
