import LinkIcon from '@mui/icons-material/Link';
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Container,
    FormGroup,
    Tooltip,
    Typography
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toCamelCase } from '../common/commonFunctions';
import SingleInternshipModal from '../modals/SingleInternshipModal';
import { appliedInternship, getMyStories } from '../services/appliedInternship';
import InfoIcon from '@mui/icons-material/Info';
import SingleInternshipStoryModal from '../modals/SingleInternshipStoryModal';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
const filter = ["pending", "rejected", "shortlist", "nointerest", "rejected", "hired"];

const MyInternshipStories = () => {
    const token = useSelector((state) => state.token);
    const [apiData, setApiData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [currFilter, setCurrFilter] = useState();
    const [modalOpen, setModalOpen] = React.useState(false);
    const [currStoryId, setCurrStoryId] = React.useState("");
    const navigate = useNavigate();

    useEffect(() => {
        getStories();
    }, [navigate]);

    const getStatusColor = (status) => {
        switch (status) {
            case "pending":
                return "warning"; // Yellow
            case "shortlist":
                return "info"; // Blue
            case "approved":
                return "approved"; // Green
            default:
                return "default"; // Gray
        }
    };

    const getStories = async () => {
        try {
            const data = await getMyStories(token, currFilter);
            console.log(data);
            setApiData(data.stories || []);
            setLoader(false);
        } catch (err) {
            console.log(err);
        }
    };

    const handleStoryView = (params) => {
        setCurrStoryId(params.row._id);
        setModalOpen(true);
    };

    const closeStoryView = () => {
        setModalOpen(false);
    }

    const editStoryView = (params) => {
        const id = params.row._id;  
        navigate(`/student/stories/edit/${id}`);
    };

    const tableColumns = [
        {
            field: 'profile', headerName: 'Internship Profile', flex: 1,
            renderCell: (params) => (
                <p>{params.row.profile}</p>
            ),
        },
        {
            field: 'company', headerName: 'Company Name', flex: 1,
            renderCell: (params) => (
                <p>{params.row.company}</p>
            ),
        },
        {
            field: 'createdAt', headerName: 'Created On', flex: 1,
            renderCell: (params) => (
                <p>{moment(new Date(params.row.createdAt)).format('DD-MM-YYYY')}</p>
            )
        },
        {
            field: 'status',
            headerName: 'Approval Status',
            flex: 1,
            renderCell: (params) => (
                <p>
                    <Chip label={toCamelCase(params.row.status)} color={getStatusColor(params.row?.status)} />
                </p>
            ),
        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            minWidth: 230,
            editable: false,
            renderCell: (params) => (
                <div>
                    <Tooltip title="Show Story Details">
                        <Button
                            variant="outlined"
                            onClick={() => handleStoryView(params)}
                            className="circular-btn me-2"
                            startIcon={<RemoveRedEyeRoundedIcon />}
                        />
                    </Tooltip>
                    {params.row.status !== "approved" &&
                    <Tooltip title="Edit Story Details">
                        <Button
                            variant="outlined"
                            onClick={() => editStoryView(params)}
                            className="circular-btn me-2"
                            startIcon={<EditIcon />}
                        />
                    </Tooltip>}
                </div>
            ),
        },
    ];

    const rows = apiData.map((storyData, index) => ({
        id: storyData._id,
        company: storyData.company,
        profile: storyData.profile,
        status: storyData.status
    }));

    return (
        <div>
            <FormGroup
                sx={{
                    marginBottom: "20px",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>My Internship Stories</Typography>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "black",
                        color: "white",
                        fontWeight: "bold",
                        borderRadius: "8px",
                        padding: "10px 20px",
                        textTransform: "none",
                        "&:hover": {
                            backgroundColor: "#333",
                        },
                    }}
                    onClick={ (e) => {
                        navigate('/student/stories/add')
                    }}
                >
                    Add New Story
                </Button>
            </FormGroup>

            {loader ? <CircularProgress /> : (
                <Container maxWidth="lg" style={{ marginTop: "20px", height: 400 }}>
                    <Box
                        sx={{
                            width: "100%",
                            height: {
                                xs: 300, // height for small screens
                                sm: 400, // height for medium screens
                                md: 500, // height for large screens
                                lg: 600, // height for extra-large screens
                                xl: 800, // height for extra-large screens
                            },
                        }}
                    >
                        <DataGrid
                            rows={apiData}
                            columns={tableColumns}
                            getRowId={(row) => row['_id']}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            pagination
                            disableRowSelectionOnClick
                            slots={{ toolbar: GridToolbar }}
                            //disableColumnFilter
                            //disableColumnMenu
                            disableColumnSelector
                        />
                    </Box>
                    <SingleInternshipStoryModal open={modalOpen} storyid={currStoryId} closeStoryView={closeStoryView} />

                </Container>
            )}
        </div>
    );
};

export default MyInternshipStories;
