import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const getPostByEmployeeId = async (token) => {
  console.log(token)
  try {
    const response = await heyapi.get(`/employer/posts`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const closePost = async (token, postid) => {
  console.log(token)
  try {
    const response = await heyapi.post(`/employer/post/close`, {postid: postid}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
  }
};
