import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const getPostList = async (token) => {
    console.log(`/employer/chat/posts`)

    try {
        const response = await heyapi.get(
            `/employer/chat/posts`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        console.log(response.data);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const getPostApplicants = async (token, postid) => {
    console.log(`/employer/chat/applicants?postid=${postid}`)

    try {
        const response = await heyapi.get(
            `/employer/chat/applicants?postid=${postid}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        console.log(response.data);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};


export const getActiveRooms = async (token, postid) => {
    console.log(`/employer/chat/rooms?postid=${postid}`)

    try {
        const response = await heyapi.get(
            `/employer/chat/rooms?postid=${postid}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        console.log(response.data);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const getRoomInitialData = async (token, roomid) => {
    console.log(`/employer/chatroom/details?roomid=${roomid}`)
    try {
        const response = await heyapi.get(
            `/employer/chatroom/details?roomid=${roomid}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        console.log(response);
        return response;
    } catch (err) {
        console.log(err);
    }
};

export const updateRoomStatus = async (token, roomid, status, reason) => {
    console.log(`/employer/chatroom/update-status`)
    try {
        const response = await heyapi.post(
            `/employer/chatroom/update-status`,
            {
                roomid,
                status,
                reason
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        console.log(response);
        return response;
    } catch (err) {
        console.log(err);
    }
};


export const getRoomMessages = async (token, roomid) => {
    console.log(`/employer/chat/messages?roomid=${roomid}`)
    try {
        const response = await heyapi.get(
            `/employer/chat/messages?roomid=${roomid}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        console.log(response.data);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const getRoomStudent = async (token, roomid) => {
    console.log(`/employer/chat/student?roomid=${roomid}`)
    try {
        const response = await heyapi.get(
            `/employer/chat/student?roomid=${roomid}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        console.log(response);
        return response;
    } catch (err) {
        console.log(err);
    }
};

export const getChatRoom = async (token, postid, stduentId, applicantionId) => {
    console.log(`/employer/chat/room?postid=${postid}&studentid=${stduentId}`)
    try {
        const response = await heyapi.get(
            `/employer/chat/room?postid=${postid}&studentid=${stduentId}&applicationid=${applicantionId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        console.log(response);
        return response;
    } catch (err) {
        console.log(err);
    }
};