import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { grey } from "@mui/material/colors";
import { useState } from 'react';
import { useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import {
  TableBody,
  TableRow,
  TableCell,
  TextField,
  FormGroup,
  Container,
  tableCellClasses,
  Chip
} from "@mui/material";
import { AddSkill } from '../services/postSkill';
import { getAllSkill } from '../services/getAllSkill';
import PopUp from '../components/PopUp';
import { deleteSkill } from '../services/deleteSkill';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddSkills({ skills }) {
  const [open, setOpen] = React.useState(false);
  const [skill, setSkill] = useState("");
  const [allSkill, setAllSkill] = React.useState();
  const [loader, setLoader] = React.useState(true);
  const [success, setSuccess] = React.useState(false);
  const userId = useSelector((state) => state.id);
  const token = useSelector((state) => state.token);
  const [userSkills, setUserSkills] = React.useState(skills ? skills: []);
  const postSkill = async () => {
    try {
      const data = await AddSkill(userId, skill, token);
      if (data.sucess) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    getProf();
    console.log(skill, "fbdsjb");
  }, [skill]);
  const getProf = async () => {
    try {
      const allSkill = await getAllSkill();
      console.log(allSkill);
      setAllSkill(allSkill.skills);
      setLoader(false);
    } catch (error) {
      console.error("Error fetching training data:", error);
    }
  };

  //for delete skill
  const dltSkill = async (skill, token) => {
    console.log(skill, token)
    try {
      const data = await deleteSkill(skill, token);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {loader ? null : (
        <TableBody
          sx={{
            "&:last-child td, &:last-child th": { borderBottom: 1, borderColor: 'divider' },
          }}>
          <TableRow

          >
            <TableCell>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                {skills.skills.map((item, index) => (
                  <Chip
                    key={index}
                    label={item}
                    onDelete={() => dltSkill(item, token)}
                    deleteIcon={<DeleteIcon />}
                    style={{ marginBottom: "8px" }}
                  />
                ))}
                <Button onClick={handleClickOpen} style={{ padding: "0px" }}>
                  <AddIcon style={{ fontSize: "14px" }} color="primary" /> &nbsp;
                  <span style={{ fontSize: "12px" }}>Add Skills</span>
                </Button>
              </div>
            </TableCell>
          </TableRow>

          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
              sx={{ width: "600px" }}
            >
              Modal title
            </BootstrapDialogTitle>
            {success && (
              <center>
                <PopUp />
              </center>
            )}
            <FormGroup
              sx={{
                alignItems: "flex-start",
                margin: "15px",
                marginBottom: "10px",
              }}
            >
              <Typography>Add Skills</Typography>
              {/* <TextField
            size="small"
            fullWidth
            label="e.g. web development"
            id="fullWidth"
            value={skill}
            onChange={(e) => setSkill(e.target.value)}
          /> */}

              <Autocomplete
                multiple
                size=""
                id="tags-outlined"
                style={{
                  width: "100%",
                }}
                options={allSkill}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                onChange={(event, selectedOption) => {
                  setSkill(selectedOption);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Skills"
                    placeholder="e.g. Marketing"
                  />
                )}
              />
            </FormGroup>
            <DialogActions>
              <Button
                autoFocus
                onClick={() => {
                  postSkill();
                  handleClose();
                }}
              >
                Save changes
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </TableBody>
      )}
    </>
  );
}

