import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, TextField, Box, FormGroup, Autocomplete, IconButton, RadioGroup, FormControlLabel, Radio, MenuItem, Select } from '@mui/material';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from 'react';
import { getEducation } from '../services/postEducation';
import { useSelector } from 'react-redux';
import { addEducation } from '../services/postEducation';
import { CustomAlert, showToastAlert } from '../admin/components/CustomAlert';
import { updateStepCompletion } from "../Store/Action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import CollegeAutoComplete from '../components/autocomplete/CollegeAutoComplete';

const GraduationDialog = ({
  openGraduationDialog, setOpenGraduationDialog, refreshEducation,
  educationId }) => {
  const [college, setCollege] = useState('');
  const [startYear, setStartYear] = useState("2022");
  const [endYear, setEndYear] = useState("2023");
  const [stream, setStream] = useState('');
  const [percentage, setPercentage] = useState('');
  const [errors, setErrors] = useState({});
  const [eduId, setEduId] = useState("new");
  const [degreeVal, setDegreeVal] = useState("");
  const token = useSelector((state) => state.token)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    setEduId(educationId);
    if (openGraduationDialog) {
      if(educationId === "new") {
        //reset 
        setCollege('');
        setStartYear("2022");
        setEndYear("");
        setStream("");
        setPercentage("");
        setErrors([]);
        setDegreeVal("");
      } else {
        editEducation(educationId);
      }
    } else if(openGraduationDialog) { //add mode
      resetValue(); 
    } 
  }, [educationId, openGraduationDialog]);

  const validate = () => {
    const errors = {};
    if (!college) {
      errors.college = 'College name is required';
    }
    if (!startYear) {
      errors.startYear = 'Start year is required';
    }
    if (!endYear) {
      errors.endYear = 'End year is required';
    }

    if (Number(endYear) < Number(startYear)) {
      errors.endYear = 'End year is earlier than start year';
    }
   
    if (!degreeVal) {
      errors.degree = 'Degree is required';
    }
    if (!stream) {
      errors.stream = 'Stream is required';
    }
    if (!percentage) {
      errors.percentage = 'Percentage/CGPA is required';
    }
    if(percentage > 100 || percentage <= 0) {
      errors.percentage = 'Percentage/CGPA is invalid';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const resetValue = () => {
    setStartYear(null);
    setStream("");
    setPercentage("");
    setCollege("");
  }

  const saveEducation = async () => {
    if (validate()) {
      const response = await addEducation(
        token,
        eduId,
        college, //school
        "", //board
        percentage,
        startYear,
        endYear,
        degreeVal,
        stream,
        ""
      );
      if (response.status !== 200) {
        showToastAlert(response.data.message, "error");
        return;
      } else {
        dispatch(updateStepCompletion({resumeMinInfoUpdated: true}));
        showToastAlert("Update success", "success");
        setOpenGraduationDialog(false);
      }
      await refreshEducation();
    } else {
      showToastAlert("Fill the invalid/empty fields", "error");
    }
  };

  const editEducation = async (editId) => {
    const response = await getEducation(editId, token);
    if(response.status !== 200) {
      showToastAlert(response.data.message, "error");
      return;
    }
    const editData = response.data;
    if (editData) {
      setEndYear(editData.education?.endYear || '');
      setStartYear(editData.education?.startYear || '');
      setPercentage(editData.education?.percentage || '');
      setDegreeVal(editData.education?.degree);
      setCollege(editData.education?.school || '');
      setStream(editData.education?.stream || 'Science');
      setOpenGraduationDialog(true);
    }
  };

  return (
    <Dialog open={openGraduationDialog} aria-labelledby="customized-dialog-title">
      <DialogTitle id="customized-dialog-title" sx={{ width: "600px" }}>
        Graduation/Post Graduation Details
        <IconButton
          aria-label="close"
          onClick={() => { setOpenGraduationDialog(false) }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FormGroup sx={{ alignItems: "flex-start", marginBottom: "10px" }}>
          <Typography>College Name</Typography>
          <CollegeAutoComplete 
            value={college} 
            setCollege={setCollege}
            inputProps={{ maxLength: 50 }}
          />
          {errors.college && <Typography color="error">{errors.college}</Typography>}
        </FormGroup>
        <Box sx={{ display: "flex" }}>
          <Box style={{ width: "48%", margin: "5px" }}>
            <FormGroup sx={{ alignItems: "flex-start", marginBottom: "10px" }}>
              <Typography>Start Year</Typography>
              <DatePicker
                selected={startYear ? new Date(startYear, 0, 1): null}
                onChange={(date) => setStartYear(date?.getFullYear().toString())}
                dateFormat="yyyy"
                showYearPicker
                customInput={
                  <TextField placeholder="Start Year" size="small" style={{ width: "100%" }} />
                }
              />
              {errors.startYear && <Typography color="error">{errors.startYear}</Typography>}
            </FormGroup>
          </Box>
          <Box style={{ width: "48%", margin: "5px" }}>
            <FormGroup sx={{ alignItems: "flex-start", marginBottom: "10px" }}>
              <Typography>End Year</Typography>
              <DatePicker
                selected={endYear ? new Date(Number(endYear), 0, 1) : null}
                onChange={(date) => setEndYear(date?.getFullYear().toString())}
                dateFormat="yyyy"
                showYearPicker
                customInput={
                  <TextField placeholder="End Year" size="small" style={{ width: "100%" }} />
                }
              />
              {errors.endYear && <Typography color="error">{errors.endYear}</Typography>}
            </FormGroup>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>

          <Box style={{ width: "48%", margin: "5px" }}>
            <FormGroup sx={{ alignItems: "flex-start", marginBottom: "10px" }}>
              <Typography>Degree</Typography>
              <select
                style={{
                  width: "100%",
                  padding: "8px",
                  fontSize: "14px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
                value={degreeVal || ""}
                onChange={(event) => setDegreeVal(event.target.value)}
              >
                <option value="" disabled>
                  Select
                </option>
                <option value="Bachelor">Bachelor</option>
                <option value="Master">Master</option>
                <option value="PhD">PhD</option>
              </select>
              {errors.degree && <Typography color="error">{errors.degree}</Typography>}
            </FormGroup>
          </Box>

          <Box style={{ width: "48%", margin: "5px" }}>
            <FormGroup sx={{ alignItems: "flex-start", marginBottom: "10px" }}>
              <Typography>Stream</Typography>
              <select
                style={{
                  width: "100%",
                  padding: "8px",
                  fontSize: "14px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
                value={stream || ""}
                onChange={(event) => setStream(event.target.value)}
              >
                <option value="" disabled>
                  Select
                </option>
                <option value="Science">Science</option>
                <option value="Commerce">Commerce</option>
                <option value="Arts">Arts</option>
              </select>
              {errors.stream && <Typography color="error">{errors.stream}</Typography>}
            </FormGroup>
          </Box>

        </Box>
        <FormGroup sx={{ alignItems: "flex-start", marginBottom: "10px" }}>
          <Typography>Percentage/CGPA</Typography>
          <TextField
            size="small"
            fullWidth
            value={percentage}
            onChange={(e) => setPercentage(e.target.value)}
            inputProps={{ maxLength: 5 }}
          />
          {errors.percentage && <Typography color="error">{errors.percentage}</Typography>}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={saveEducation}>
          Save changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const MatriculationDialog = ({
  openMatriuculationDialog,
  setOpenMatriuculationDialog,
  refreshEducation,
  showStream,
  educationId
}) => {
  const [endYear, setEndYear] = useState('');
  const [startYear, setStartYear] = useState('');
  const [board, setBoard] = useState('cbse');
  const [percentage, setPercentage] = useState('');
  const [school, setSchool] = useState('');
  const [stream, setStream] = useState('Science');
  const [degreeVal, setDegreeVal] = useState("X");
  const [errors, setErrors] = useState({});
  const token = useSelector((state) => state.token);
  const [eduId, setEduId] = useState("new");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (openMatriuculationDialog) {
      setEduId(educationId);
      if(educationId === "new") {
        // Reset all fields to their default values when a new entry is being created
        setEndYear('');
        setStartYear('');
        setBoard('cbse');
        setPercentage('');
        setSchool('');
        setStream('Science');
        setDegreeVal('X');
        setErrors({});
      } else {
        editEducation(educationId);
      }
      
    }
  }, [openMatriuculationDialog]);

  // Validation function
  const validate = () => {
    let tempErrors = {};
    if (!school) tempErrors.school = 'School name is required';
    if (!board) tempErrors.board = 'Board is required';
    if (!endYear) tempErrors.endYear = 'Year of completion is required';
    if (!percentage) tempErrors.percentage = 'Percentage/CGPA is required';
    if(percentage > 100 || percentage <= 0) {
      tempErrors.percentage = 'Percentage/CGPA is invalid';
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const saveEducation = async () => {
    if (validate()) {
      const response = await addEducation(
        token,
        eduId,
        school,
        board,
        percentage,
        startYear,
        endYear,
        degreeVal,
        stream,
        ""
      );
      if (response.status !== 200) {
        showToastAlert(response.data.message, "error");
        return;
      } else {
        dispatch(updateStepCompletion({resumeMinInfoUpdated: true}));
        showToastAlert("Update success", "success");
        setOpenMatriuculationDialog(false);
      }
      await refreshEducation();
    } else {
      showToastAlert("Fill the invalid/empty fields", "error");
    }
  };

  const editEducation = async (editId) => {
    const response = await getEducation(editId, token);
    if(response.status !== 200) {
      showToastAlert(response.data.message, "error");
      return;
    }
    const editData = response.data;
    if (editData) {
      setEndYear(editData.education.endYear || '');
      setStartYear(editData.education.startYear || '');
      setBoard(editData.education.board || 'cbse');
      setPercentage(editData.education.percentage || '');
      setSchool(editData.education.school || '');
      setStream(editData.education.stream || 'Science');
      setOpenMatriuculationDialog(true);
      setDegreeVal(editData.education.degree);
    }
  };

  return (
    <Dialog open={openMatriuculationDialog} aria-labelledby="customized-dialog-title">
      <DialogTitle id="customized-dialog-title" sx={{ width: '600px' }}>
        <Typography>Matriculation (X) Details</Typography>
        <IconButton
          aria-label="close"
          onClick={() => setOpenMatriuculationDialog(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <CustomAlert />

        <FormGroup sx={{ alignItems: 'flex-start', marginBottom: '10px' }}>
          <Typography>Year of Completion</Typography>
          <TextField
            size="small"
            fullWidth
            select
            value={endYear}
            onChange={(e) => setEndYear(e.target.value)}
            error={!!errors.endYear}
            helperText={errors.endYear}
          >
            {Array.from({ length: 50 }, (_, i) => new Date().getFullYear() - i).map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </TextField>
        </FormGroup>

        <FormGroup sx={{ alignItems: 'flex-start', marginBottom: '10px' }}>
          <Typography>Board</Typography>
          <Select
            size="small"
            fullWidth
            value={board}
            onChange={(e) => setBoard(e.target.value)}
          >
            <MenuItem value="cbse">CBSE</MenuItem>
            <MenuItem value="icse">ICSE(CISCE)</MenuItem>
            <MenuItem value="nios">NIOS</MenuItem>
            <MenuItem value="state">State Board</MenuItem>
          </Select>
        </FormGroup>
          

        <FormGroup sx={{ alignItems: 'flex-start', marginBottom: '10px' }}>
          <Typography>Percentage/CGPA</Typography>
          <TextField
            size="small"
            fullWidth
            value={percentage}
            onChange={(e) => setPercentage(e.target.value)}
            error={!!errors.percentage}
            helperText={errors.percentage}
            inputProps={{ maxLength: 5 }}
          />
        </FormGroup>

        {showStream && (
          <FormGroup sx={{ alignItems: 'flex-start', marginBottom: '10px' }}>
            <Typography>Stream</Typography>
            <Select
              size="small"
              fullWidth
              value={stream}
              onChange={(e) => setStream(e.target.value)}
            >
              <MenuItem value="Science">Science</MenuItem>
              <MenuItem value="Commerce">Commerce</MenuItem>
              <MenuItem value="Arts">Arts</MenuItem>
            </Select>
          </FormGroup>
        )}

        <FormGroup sx={{ alignItems: 'flex-start', marginBottom: '10px' }}>
          <Typography>School</Typography>
          <TextField
            size="small"
            fullWidth
            value={school}
            onChange={(e) => setSchool(e.target.value)}
            error={!!errors.school}
            helperText={errors.school}
            inputProps={{ maxLength: 50 }}
          />
        </FormGroup>
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={saveEducation}>
          Save changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};


const TwelveDialog = ({
  openTwelveDialog,
  setOpenTwelveDialog,
  refreshEducation,
  educationId,
}) => {
  const [endYear, setEndYear] = useState('');
  const [startYear, setStartYear] = useState('');
  const [board, setBoard] = useState('cbse');
  const [percentage, setPercentage] = useState('');
  const [school, setSchool] = useState('');
  const [stream, setStream] = useState('Science');
  const [errors, setErrors] = useState({});
  const token = useSelector((state) => state.token);
  const [eduId, setEduId] = useState("new");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (openTwelveDialog) {
      setEduId(educationId);
      if(educationId === "new") {
        //reset 
        // Reset all fields to their default values when a new entry is being created
        setEndYear('');
        setStartYear('');
        setBoard('cbse');
        setPercentage('');
        setSchool('');
        setStream('Science');
        setErrors({});
      } else {
        editEducation(educationId);
      }
    }
  }, [openTwelveDialog]);

  // Validation function
  const validate = () => {
    let tempErrors = {};
    if (!school) tempErrors.school = 'School name is required';
    if (!board) tempErrors.board = 'Board is required';
    if (!endYear) tempErrors.endYear = 'Year of completion is required';
    if (!percentage) tempErrors.percentage = 'Percentage/CGPA is required';
    if(percentage > 100 || percentage <= 0) {
      tempErrors.percentage = 'Percentage/CGPA is invalid';
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const saveEducation = async () => {
    if (validate()) {
      const response = await addEducation(
        token,
        eduId,
        school,
        board,
        percentage,
        startYear,
        endYear,
        "XII", //degree
        stream,
        ""
      );
      if (response.status !== 200) {
        showToastAlert(response.data.message, "error");
        return;
      } else {
        dispatch(updateStepCompletion({resumeMinInfoUpdated: true}));
        showToastAlert("Update success", "success");
        setOpenTwelveDialog(false);
      }
      await refreshEducation();
    } else {
      showToastAlert("Fill the invalid/empty fields", "error");
    }
  };

  const editEducation = async (editId) => {
    const response = await getEducation(editId, token);
    if(response.status !== 200) {
      showToastAlert(response.data.message, "error");
      return;
    }
    const editData = response.data;
    if (editData) {
      setEndYear(editData.education.endYear || '');
      setStartYear(editData.education.startYear || '');
      setBoard(editData.education.board || 'cbse');
      setPercentage(editData.education.percentage || '');
      setSchool(editData.education.school || '');
      setStream(editData.education.stream || 'Science');
      setOpenTwelveDialog(true);
    }
  };

  return (
    <Dialog open={openTwelveDialog} aria-labelledby="customized-dialog-title">
      <DialogTitle id="customized-dialog-title" sx={{ width: '600px' }}>
        <Typography>XII Details</Typography>
        <IconButton
          aria-label="close"
          onClick={() => setOpenTwelveDialog(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <CustomAlert />

        <FormGroup sx={{ alignItems: 'flex-start', marginBottom: '10px' }}>
          <Typography>Year of Completion</Typography>
          <TextField
            size="small"
            fullWidth
            select
            value={endYear}
            onChange={(e) => setEndYear(e.target.value)}
            error={!!errors.endYear}
            helperText={errors.endYear}
          >
            {Array.from({ length: 50 }, (_, i) => new Date().getFullYear() - i).map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </TextField>
        </FormGroup>

        <FormGroup sx={{ alignItems: 'flex-start', marginBottom: '10px' }}>
          <Typography>Board</Typography>
          <Select
            size="small"
            fullWidth
            value={board}
            onChange={(e) => setBoard(e.target.value)}
          >
            <MenuItem value="cbse">CBSE</MenuItem>
            <MenuItem value="icse">ICSE(CISCE)</MenuItem>
            <MenuItem value="nios">NIOS</MenuItem>
            <MenuItem value="state">State Board</MenuItem>
          </Select>
        </FormGroup>

        <FormGroup sx={{ alignItems: 'flex-start', marginBottom: '10px' }}>
          <Typography>Percentage/CGPA</Typography>
          <TextField
            size="small"
            fullWidth
            value={percentage}
            onChange={(e) => setPercentage(e.target.value)}
            error={!!errors.percentage}
            helperText={errors.percentage}
            inputProps={{ maxLength: 6 }}
          />
        </FormGroup>


        <FormGroup sx={{ alignItems: 'flex-start', marginBottom: '10px' }}>
          <Typography>Stream</Typography>
          <Select
            size="small"
            fullWidth
            value={stream}
            onChange={(e) => setStream(e.target.value)}
          >
            <MenuItem value="Science">Science</MenuItem>
            <MenuItem value="Commerce">Commerce</MenuItem>
            <MenuItem value="Arts">Arts</MenuItem>
          </Select>
        </FormGroup>

        <FormGroup sx={{ alignItems: 'flex-start', marginBottom: '10px' }}>
          <Typography>School</Typography>
          <TextField
            size="small"
            fullWidth
            value={school}
            onChange={(e) => setSchool(e.target.value)}
            error={!!errors.school}
            helperText={errors.school}
            inputProps={{ maxLength: 50 }}
          />
        </FormGroup>
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={saveEducation}>
          Save changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Main EducationSelectDialog component
const EducationSelectDialog = ({ eduSelectionOpen, setEduSelectionOpen,
  setOpenMatriuculationDialog,
  setOpenTwelveDialog,
  setOpenGraduationDialog,
  setEducationId
}) => {
  const [openBootstrapDialog, setOpenBootstrapDialog] = useState(false);
  const handleBootstrapDialogOpen = () => {
    setOpenBootstrapDialog(true);
  };

  const handleBootstrapDialogClose = () => setOpenBootstrapDialog(false);

  const handleSaveChanges = () => {
    // Handle save functionality
    setOpenBootstrapDialog(false);
  };

  const onButtonClick = (aType) => {
    setEduSelectionOpen(false);
    setEducationId("new");
    if (aType == 'X') {
      setOpenMatriuculationDialog(true);
    } else if (aType == 'XII') {
      setOpenTwelveDialog(true);
    } else {
      setOpenGraduationDialog(true);
    }

  }

  const handleClose = () => {
    // set(false);
  }

  return (
    <>
      <Dialog
        onClose={() => { setEduSelectionOpen(false) }}
        aria-labelledby="customized-dialog-title"
        open={eduSelectionOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Education
          <IconButton
            aria-label="close"
            onClick={() => { setEduSelectionOpen(false) }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div>
            <Button
              startIcon={<AddIcon />}
              onClick={() => onButtonClick('X')}
              fullWidth
              style={{
                marginBottom: '10px',
                justifyContent: 'flex-start', // Align text to the left
                textAlign: 'left', // Ensure text is aligned left
              }}
            >
              Add Secondary (X)
            </Button>
            <Button
              startIcon={<AddIcon />}
              onClick={() => onButtonClick('XII')}
              fullWidth
              style={{
                marginBottom: '10px',
                justifyContent: 'flex-start', // Align text to the left
                textAlign: 'left', // Ensure text is aligned left
              }}
            >
              Add Senior Secondary (XII)
            </Button>
            <Button
              startIcon={<AddIcon />}
              onClick={() => onButtonClick('Graduation')}
              fullWidth
              style={{
                marginBottom: '10px',
                justifyContent: 'flex-start', // Align text to the left
                textAlign: 'left', // Ensure text is aligned left
              }}
            >
              Add Graduation / Post Graduation
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export { EducationSelectDialog, MatriculationDialog, GraduationDialog, TwelveDialog };
