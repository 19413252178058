import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { grey } from "@mui/material/colors";
import {
  TableBody,
  TableRow,
  TableCell,
  TextField,
  FormGroup,
  Container,
  tableCellClasses
} from "@mui/material";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import AddLinkIcon from '@mui/icons-material/AddLink';
import PlayForWorkIcon from '@mui/icons-material/PlayForWork';
import { AddWorkSamples } from '../services/postWorkSamples';
import PopUp from '../components/PopUp';
import { useSelector } from 'react-redux';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddPortFolio({ samples }) {
  const [open, setOpen] = React.useState(false);
  const [bio, setBio] = React.useState("");
  const [github, setGitHub] = React.useState("");
  const [linkedin, setLinkedIn] = React.useState("");
  const [playstore, setPlaystore] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const token = useSelector((state) => state.token);


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const postSocial = async () => {
    try {
      const data = await AddWorkSamples(bio, github, linkedin, playstore, token);
      if (data.sucess) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      }
    } catch (err) {
      console.log(err);
    }
    handleClose();
  };

  React.useEffect(() => {
    if (samples) {
      setBio(samples.bio);
      setLinkedIn(samples.linkedin);
      setPlaystore(samples.playstore);
      setGitHub(samples.github);
    }
  }, [samples])

  return (
    <TableBody>
      <TableRow
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
      >
        <TableCell style={{ fontSize: "12px" }}>
          SOCIAL/ WORK SAMPLES
          {success && (
            <center>
              <PopUp />
            </center>
          )}
        </TableCell>
        <TableRow  >
          <TableCell
            style={{
              padding: "8px 20px 16px",
              borderBottom: "none",
              width: "20vw",
            }}
            onClick={() => {
              handleClickOpen();
            }}
          >
            <LinkedInIcon /> <PlayForWorkIcon /> <GitHubIcon /> <AddLinkIcon />
          </TableCell>
          <TableCell>
            <IconButton edge="end" aria-label="edit" onClick={() => {
                  handleClickOpen();
                }} >
              <EditIcon
                />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableRow>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{ width: "600px" }}
        >
          Social/Portfolio Links
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <FormGroup sx={{ alignItems: "flex-start", marginBottom: "10px" }}>
            <Typography>Linkedin  link</Typography>
            <TextField
              size="small"
              fullWidth
              id="fullWidth"
              value={linkedin}
              onChange={(e) => setLinkedIn(e.target.value)}
              inputProps={{ maxLength: 100 }}
            />
          </FormGroup>
          <FormGroup sx={{ alignItems: "flex-start", marginBottom: "10px" }}>
            <Typography>GitHub Profile Link</Typography>
            <TextField
              size="small"
              fullWidth
              id="fullWidth"
              value={github}
              onChange={(e) => setGitHub(e.target.value)}
              inputProps={{ maxLength: 100 }}
            />
          </FormGroup>
          <FormGroup sx={{ alignItems: "flex-start", marginBottom: "10px" }}>
            <Typography>Play store developer A/c (public link)</Typography>
            <TextField
              size="small"
              fullWidth
              id="fullWidth"
              value={playstore}
              onChange={(e) => setPlaystore(e.target.value)}
              inputProps={{ maxLength: 100 }}
            />
          </FormGroup>
          <FormGroup sx={{ alignItems: "flex-start", marginBottom: "10px" }}>
            <Typography>Other work sample link</Typography>
            <TextField
              size="small"
              fullWidth
              id="fullWidth"
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              inputProps={{ maxLength: 100 }}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={postSocial}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </TableBody>
  );
}