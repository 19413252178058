import React, { useState } from "react";
import styles from "./ReportApplicantModal.module.css"; // Import the CSS module

function ReportApplicantModal({ isOpen, onClose }) {
  const [reason, setReason] = useState("");
  const [blockChat, setBlockChat] = useState(false);

  const handleSubmit = () => {
    console.log("Reason:", reason);
    console.log("Block Chat:", blockChat);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h3 className={styles.modalHeader}>Report applicant</h3>
        <p className={styles.modalDescription}>
          You are reporting [Applicant Name] because he/she:
        </p>
        <form className={styles.formContainer}>
          <div className={styles.formRow}>
            <input
              className={styles.radioInput}
              type="radio"
              id="noResponseMessages"
              value="Did not respond to any messages"
              checked={reason === "Did not respond to any messages"}
              onChange={(e) => setReason(e.target.value)}
            />
            <label className={styles.radioLabel} htmlFor="noResponseMessages">
              Did not respond to any messages
            </label>
          </div>
          <div className={styles.formRow}>
            <input
              className={styles.radioInput}
              type="radio"
              id="noResponseCalls"
              value="Did not respond to calls"
              checked={reason === "Did not respond to calls"}
              onChange={(e) => setReason(e.target.value)}
            />
            <label className={styles.radioLabel} htmlFor="noResponseCalls">
              Did not respond to calls
            </label>
          </div>
          <div className={styles.formRow}>
            <input
              className={styles.radioInput}
              type="radio"
              id="unprofessionalLanguage"
              value="Used unprofessional language"
              checked={reason === "Used unprofessional language"}
              onChange={(e) => setReason(e.target.value)}
            />
            <label
              className={styles.radioLabel}
              htmlFor="unprofessionalLanguage"
            >
              Used unprofessional language (Rude, vague or abusive) in
              application/messages
            </label>
          </div>
          <div className={styles.formRow}>
            <input
              className={styles.radioInput}
              type="radio"
              id="noShow"
              value="Did not appear for interview after confirming"
              checked={reason === "Did not appear for interview after confirming"}
              onChange={(e) => setReason(e.target.value)}
            />
            <label className={styles.radioLabel} htmlFor="noShow">
              Did not appear for interview after confirming
            </label>
          </div>
          <div className={styles.formRow}>
            <input
              className={styles.radioInput}
              type="checkbox"
              id="blockChat"
              checked={blockChat}
              onChange={(e) => setBlockChat(e.target.checked)}
            />
            <label className={styles.radioLabel} htmlFor="blockChat">
              Block this chat conversation
            </label>
          </div>
        </form>
        <div className={styles.modalActions}>
          <button
            className={reason ? styles.buttonEnabled : styles.buttonDisabled}
            onClick={handleSubmit}
            disabled={!reason}
          >
            Submit
          </button>
          <button className={styles.button} onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default ReportApplicantModal;
