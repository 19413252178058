import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Button,
  IconButton,
  Drawer,
  useMediaQuery,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import AccountMenu from "./AccountMenu";
import ChatMenu from "./ChatMenu";
import { useSelector } from "react-redux";
import LoginPopup from "./LoginPopup";

const MainHeader = () => {
  const navigate = useNavigate();
  const userType = useSelector((state) => state.uType);
  const isAuthenticated = useSelector((state) => state.isAuthenticated);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");

  const open = Boolean(anchorEl);

  // Handlers
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    )
      return;
    setDrawerOpen(open);
  };
  const handleNavBar = () => setDrawerOpen(false);

  // Menu Items
  const generalLinks = [
    { to: "/internships", label: "Find Internships" },
    { to: "/about-us", label: "About us" },
    { to: "https://blog.heyinterns.com/", label: "Blogs", external: true },
    { to: "/contact-us", label: "Contact us" },
  ];

  const studentLinks = [
    { to: "/internships", label: "Internships" },
    { to: "/student/applications", label: "My Applications" },
    // { to: "/student/dashboard", label: "Dashboard" },
    { custom: <ChatMenu link="/student/chat" messageCount="*" /> },
  ];

  const employerLinks = [
    { to: "/employer/jobs/form", label: "New Internship" },
    { to: "/employer/dashboard", label: "Dashboard" },
    { custom: <ChatMenu link="/employer/chat" messageCount="*" /> },
  ];

  const renderLinks = (links) =>
    links.map((link, index) =>
      link.custom ? (
        <li key={index}>{link.custom}</li>
      ) : (
        <li key={index}>
          <NavLink
            onClick={handleNavBar}
            to={link.to}
            target={link.external ? "_blank" : undefined}
          >
            {link.label}
          </NavLink>
        </li>
      )
    );

  const NavbarMenu = (
    <div className="navbar-links">
      <ul>
        {/* General Links */}
        {!isAuthenticated && renderLinks(generalLinks)}

        {/* Student Links */}
        {userType === "student" && renderLinks(studentLinks)}

        {/* Employer Links */}
        {userType === "employer" && renderLinks(employerLinks)}

        {/* Login/Signup */}
        {!isAuthenticated ? (
          <div className="login-signup-btns">
            <Button
              variant="outlined"
              className="navbar-outlined-btn"
              onClick={() => setLoginModalOpen(true)}
              sx={{ background: "white" }}
            >
              {/* <NavLink onClick={handleNavBar} to="/student/Login/"> */}
              Login
            </Button>
            <Button
              variant="contained"
              className="navbar-solid-btn"
              onClick={handleClick}
            >
              Signup
            </Button>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleClose}>
                <NavLink
                  className="naviagtion-link"
                  onClick={handleNavBar}
                  to="/employer/signup"
                >
                  <AccountCircleIcon className="me-2" />
                  As an Employer
                </NavLink>
              </MenuItem>
              <Divider />
              <MenuItem>
                <NavLink
                  className="naviagtion-link"
                  onClick={handleNavBar}
                  to="/student/signup"
                >
                  <BadgeOutlinedIcon className="me-2" />
                  As a Student
                </NavLink>
              </MenuItem>
            </Menu>
          </div>
        ) : (
          <li>
            <AccountMenu />
          </li>
        )}
      </ul>
    </div>
  );
  const [isLoginModalOpen, setLoginModalOpen] = React.useState(false);

  useEffect(() => {
    handleNavBar();
    setLoginModalOpen(false);
  }, [isAuthenticated]);

  return (
    <>
      <header className="admin-header p-15 website-navbar">
        <NavLink to="/" className="brand-name">
          <img
            src="/heyinterns-logo.png"
            alt="HeyInterns Logo"
            className="logo-icon"
            style={{ maxWidth: "70%" }}
          />
        </NavLink>
        {isMobile ? (
          <>
            <IconButton
              onClick={toggleDrawer(true)}
              edge="start"
              color="info"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              classes={{ paper: "dashboard-sidebar-drawer" }}
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              <IconButton
                variant="contained"
                aria-label="more"
                className="cancel-drawer-btn"
                onClick={handleNavBar}
              >
                <CancelOutlinedIcon />
              </IconButton>
              <div className="mobile-navbar">{NavbarMenu}</div>
            </Drawer>
          </>
        ) : (
          NavbarMenu
        )}
      </header>
      <LoginPopup
        setLoginModalOpen={setLoginModalOpen}
        open={isLoginModalOpen}
        onClose={() => setLoginModalOpen(false)}
      />
    </>
  );
};

export default MainHeader;
