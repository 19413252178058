import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  Paper,
  Slider,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchIcon from "@mui/icons-material/Search";
import { AccountCircle, AppsOutageOutlined, RampLeft } from "@mui/icons-material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LinkIcon from "@mui/icons-material/Link";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { allPostApplications, updateApplicationStateApi } from "../services/getAllApplicationEmployee";
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useNavigate, Link } from "react-router-dom" 
import websiteSettings from "../common/siteSettings"

export default function Application() {
  const location = useLocation();
  const navigate = useNavigate();
  // Split the pathname on '/' to get path segments
  const pathSegments = location.pathname.split('/');
  // Get the last token (if it exists)
  const id = pathSegments.length > 0 ? pathSegments[pathSegments.length - 1] : undefined;

  const searchParams = new URLSearchParams(location.search);
  // Access the specific search key value
  let searchKeyValue = searchParams.get('state');
  if(searchKeyValue == undefined)
  {
    searchKeyValue = "pending";
  }

  const value1 = 2;
  const value2 = 1;
  const value3 = 3;
  const value4 = 3;

  const [value, setValue] = React.useState("one");
  const [apiData, setApiData] = useState();
  const [applications, setApplications] = useState([]);
  const [loader, setLoader] = useState(true);
  const token = useSelector((state) => state.token);
  const updateApplicationState = async (appid, appState) => {
    //appid = application id 
    try {
      const data = await updateApplicationStateApi(token, appid, appState)
      console.log(data)
      if(data.msg == "success") {
        //remove application from current array
        const newArr = applications.filter(item => item._id !== appid);
        setApplications(newArr);
      }
      setLoader(false)
    }
    catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    viewApplication()
  }, [searchKeyValue])
  const viewApplication = async () => {
    try {
      const data = await allPostApplications(token, id, searchKeyValue);

      console.log(data)
      setApiData(data)
      setApplications(data.applications);
      setLoader(false)
    }
    catch (err) {
      console.log(err)
    }
  }
  const sideMenuArr = [
    { label: 'Pending', url: `/employer/applications/${id}?state=pending` },
    { label: 'Shortlisted', url: `/employer/applications/${id}?state=shortlist` },
    { label: 'Hired', url: `/employer/applications/${id}?state=hired` },
    { label: 'Not Interested', url: `/employer/applications/${id}?state=nointerest` },
    { label: 'Application Received', url: `/employer/applications/${id}?state=all` },
    /*
    { label: 'Assignment', url: `/employer/applications/${id}?state=live` },
    { label: 'Interviews', url: `/employer/applications/${id}?state=live` },
    { label: 'Chat Messages', url: `/employer/applications/${id}?state=live` },*/
  ];

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          overflowX: "hidden",
          justifyContent: "center",
        }}
      >
        <Box sx={{ mt: "3%" }}>
          <p
            style={{
              display: "flex",
              textAlign: "center",
              fontSize: "0.8vw",
            }}
          >
            <Link to="/employer/dashboard"> Dashboard{" "} </Link>
            <NavigateNextIcon fontSize="small" sx={{ color: "grey" }} />{" "}
            ApplicationS received
          </p>
          <p
            style={{
              marginTop: "2%",
              marginBottom: "3%",
              fontWeight: "bold",
            }}
          >
            Application for {apiData?.post ? apiData.post.title: null} internship
          </p>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",

            p: "1vw",
            pl: "20vw",
            paddingRight: "0px",
          }}
        >
          <Box>
            <Box
              sx={{
                mt: "1.6vw",
                width: "20vw",
                height: "2rem",
                display: "flex",
                alignItems: "flex-end",
                border: "1px solid #gray",
                padding: "2px",
                borderRadius: "5px",
              }}
            >
              <TextField
                id="outlined-start-adornment"
                sx={{
                  width: "20vw",
                  height: "2rem",
                  padding: "2px",
                  fontSize: "2px",
                }}
                size="small"
                placeholder="Search Application By Name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              width: "16vw",
              border: "1px solid #ebebeb",
              p: "0.7vw",
              fontSize: "0.7vw",
              ml: "2vw",
            }}
          >
            <p>
              Need help? Reach out your Relationship Manager, at
              <a href="#"> +9192xxxxxxxx </a>
              or <a href="#">customer@breakinterview.com</a>, available from
              Mon-Fri, 9:30 AM to 6:30 PM.
            </p>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          minHeight: "66vw",
          display: "flex",
          mb: "2%",
          m: "5%",
          marginTop: "0",
          // justifyContent: "center",
        }}
      >
        <Box
          sx={{
            border: "1px solid #ebebeb",
            backgroundColor: "#f7f8f8",
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            aria-label="Vertical tabs example"
            sx={{
              borderRight: 1,
              borderColor: "divider",
              width: "14vw",
              // backgroundColor: "lightgray",
            }}
          >
            <Tab
              style={{ borderBottom: "1px grey" }}
              label={
                <>
                  <Paper
                    elevation={0}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textTransform: "none",
                      backgroundColor: "#f7f8f8",
                      height: "16px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        width: "10vw",
                        fontWeight: "500",
                      }}
                    >
                      Access database
                    </p>
                  </Paper>
                </>
              }
            />
            <hr style={{ marginTop: "0px" }} />
            {sideMenuArr.map((menuText, menuIndex) => {
              return (
                <div>
                  <Tab
                    label={
                      <Link to={menuText.url} underline="none" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Paper
                          elevation={0}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textTransform: "none",
                            backgroundColor: "#f7f8f8",
                            height: "16px",
                            textAlign: "left",
                            marginLeft: "35px",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "16px",
                              width: "15vw",
                              fontWeight: "500",
                            }}
                          >
                            {menuText.label}
                          </p>
                        </Paper>
                      </Link>
                    }
                  />
                  {menuIndex == 4 ? <hr style={{ marginTop: "0px" }} /> : null}
                </div>
              );
            })}
          </Tabs>
        </Box>
        {loader ? "Loading..." : (
          <Box
            sx={{
              width: "70%",
            }}
          >
            <Box sx={{ m: "2%" }}>
              <p style={{ fontSize: "1vw", fontWeight: "bold" }}>
                16 results out of 674 applications
              </p>
              <FormControlLabel
                control={<Checkbox sx={{ color: "grey" }} defaultunChecked />}
                label="Select All"
                sx={{ mt: "2%", color: "grey" }}
              />
            </Box>
            {applications.map((application) => (
              <Box key={application._id} sx={{ p: "2%" }}>
                <Paper
                  sx={{
                    // display: "flex",
                    justifyContent: "space-between",
                    // p: "1%",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox sx={{ color: "grey" }} defaultunChecked />
                        }
                        sx={{
                          fontWeight: "bold",
                          color: "black",
                          fontSize: "1.5vw",
                        }}
                        label={application.userId.name}
                      />
                      <p
                        style={{
                          fontSize: "0.9vw",
                          marginTop: "-3%",
                          color: "#5A5A5A",
                          paddingLeft: "2px",
                        }}
                      >
                         Open to relocated
                      </p>
                    </Box>
                    <Box>
                      <p
                        style={{
                          fontSize: "0.9vw",
                          color: "#5A5A5A",
                        }}
                      >
                        Applied {moment(application.createdAt).fromNow()}
                      </p>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      minHeight: "25vw",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        padding: "10px",
                        paddingY: "15px",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "left",
                          minWidth: "150px",
                          fontWeight: "500",
                        }}
                      >
                        EDUCATION
                      </p>
                      <div
                        style={{
                          display: "block",
                          marginLeft: "12%",
                          textAlign: "left",
                        }}
                      >
                        {application.userId.education.length > 0 && (
                          <p style={{ fontSize: "18px", fontWeight: "500" }}>
                            <span>
                              {application.userId.education[0].degreeType}
                            </span>
                            <br />
                            <span
                              style={{
                                fontSize: "16px",
                                fontWeight: "400",
                                color: "#08080",
                              }}
                            >
                              {application.userId.education[0].school},&nbsp;
                              {application.userId.education[0].percentage}%
                            </span>
                            <br />
                            <span
                              style={{
                                fontSize: "16px",
                                fontWeight: "400",
                                color: "#08080",
                              }}
                            >
                              {moment(
                                application.userId.education[0].startDate
                              ).format("MMM YYYY")}
                              &nbsp;-&nbsp;
                              {moment(
                                application.userId.education[0].endDate
                              ).format("MMM YYYY")}
                            </span>
                          </p>
                        )}
                      </div>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        padding: "10px",
                        paddingY: "15px",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "left",
                          minWidth: "150px",
                          fontWeight: "500",
                        }}
                      >
                        PORTFOLIO/ <br /> WORK SAMPLES
                      </p>
                      <p style={{ textAlign: "left", marginLeft: "12%" }}>
                        <AccountBoxIcon
                          sx={{ color: "#17adde", mr: "1.5vw" }}
                        />
                        <LinkIcon
                          sx={{ transform: "rotate(-45deg)", color: "#17adde" }}
                        />
                      </p>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        padding: "10px",
                        paddingY: "15px",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "left",
                          minWidth: "150px",
                          fontWeight: "500",
                        }}
                      >
                        SKILLS
                        <ErrorOutlineIcon fontSize="" />
                      </p>
                      <p
                        style={{
                          textAlign: "left",
                          marginLeft: "12%",
                          width: "50vw",
                          display: "flex",
                        }}
                      >
                        {application.userId.skills.slice(0, 5).map((item) => (
                          <div style={{ marginRight: "30px" }}>
                            <Rating
                              name="customized-03"
                              defaultValue={0}
                              max={3}
                              value={value1}
                              readOnly
                              precision={0.5}
                              sx={{ fontSize: "1vw", mr: "2%" }}
                              emptyIcon={
                                <StarIcon
                                  style={{ opacity: 0.55 }}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: "1vw",
                                  }}
                                />
                              }
                            />
                            <span>{item}</span>
                            {/* <span style={{ color: "gray", marginLeft: "2%" }}>
                              +12 more
                            </span> */}
                          </div>
                        ))}
                      </p>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        padding: "10px",
                        paddingY: "15px",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "left",
                          minWidth: "150px",
                          fontWeight: "500",
                        }}
                      >
                        COVER LETTER
                      </p>
                      <p style={{ textAlign: "left", marginLeft: "12%" }}>
                        Science(2024)
                        <br />
                        <span style={{ color: "#5A5A5A" }}>
                          Lorem ipsum dolor sit, amet consectetur adipisicing
                          elit. Nobis eius amet libero animi. Vero non officiis
                          quasi officia architecto delectus.
                        </span>{" "}
                      </p>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        padding: "10px",
                        paddingY: "15px",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "left",
                          minWidth: "150px",
                          fontWeight: "500",
                        }}
                      >
                        AVAILABILITY
                      </p>

                      <p style={{ textAlign: "left", marginLeft: "12%" }}>
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Nobis eius amet libero animi. Vero non officiis
                        quasi officia architecto delectus.
                      </p>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        padding: "10px",
                        paddingY: "15px",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "left",
                          minWidth: "150px",
                          fontWeight: "500",
                        }}
                      >
                        ASSESMENT
                      </p>
                      <p style={{ textAlign: "left", marginLeft: "12%" }}>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Minima eos iste similique non corrupti explicabo
                        dolorem cupiditate esse voluptates!
                      </p>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        margin: "10px",
                        color: "gray",
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <Box style={{ color: "#17adde" }}>
                          View full applcation
                        </Box>
                        <Box style={{ color: "#17adde", marginLeft: "10px" }}>
                          Add notes
                        </Box>
                      </Box>
                      <Chip label={application.state} />
                      <Box>
                      { ["pending", "rejected", "shortlist", "hired"].includes(searchKeyValue)  &&
                        <Button
                          sx={{
                            height: "1.8vw",
                            fontSize: "0.8vw",
                            color: "red",
                            backgroundColor: "#eca6a6",
                            margin: "5px",
                            borderRadius: "2px",
                          }}
                          onClick={() => updateApplicationState(application._id, "nointerst")}
                          variant="outlined"
                        >
                          Not Interested
                        </Button>
                        } 
                        { ["pending", "rejected", "nointerest", "hired"].includes(searchKeyValue)  && 
                        <Button
                          sx={{
                            height: "1.8vw",
                            fontSize: "0.8vw",
                            color: "blue",
                            backgroundColor: "#deeded",
                            margin: "5px",
                            borderRadius: "2px",
                          }}
                          onClick={() => updateApplicationState(application._id, "shortlist")}
                          variant="outlined"
                        >
                          Shortlist
                        </Button>
                        }
                        { ["pending", "rejected", "nointerest", "shortlist"].includes(searchKeyValue)  && 
                        <Button
                          sx={{
                            height: "1.8vw",
                            fontSize: "0.8vw",
                            color: "white",
                            backgroundColor: "#3288d2",
                            margin: "5px",
                            borderRadius: "2px",
                          }}
                          onClick={() => updateApplicationState(application._id, "hired")}
                          variant="outlined"
                        >
                          Hire
                        </Button>
                        }
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            ))}
          </Box>
        )}
        <Box sx={{ border: "1px solid #ebebeb" }}>
          <Box sx={{ p: "5%" }}>
            <p style={{ fontSize: "1vw", fontWeight: "bold" }}>Filter</p>

            <Box sx={{ m: "10px 0" }}>
              <p
                style={{
                  margin: "5px 0",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Applicants' locations <HelpOutlineIcon fontSize="extrasmall" />
              </p>
              <OutlinedInput placeholder="e.g.Mumbai" sx={{ height: "30px" }} />
            </Box>

            <Box sx={{ m: "10px 0" }}>
              <p
                style={{
                  margin: "5px 0",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                skills <HelpOutlineIcon fontSize="extrasmall" />
              </p>
              <Autocomplete
                multiple
                id="tags-outlined"
                options={top100Films}
                getOptionLabel={(option) => option.title}
                defaultValue={[top100Films[13]]}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField {...params} placeholder="Favorites" />
                )}
              />
            </Box>

            <Box sx={{ m: "10px 0" }}>
              <p
                style={{
                  margin: "5px 0",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Academic background <HelpOutlineIcon fontSize="extrasmall" />
              </p>
              <OutlinedInput
                sx={{ height: "30px" }}
                placeholder="e.g.MBA & similar"
              />
            </Box>

            <Box sx={{ m: "10px 0" }}>
              <p
                style={{
                  margin: "5px 0",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Graduation year <HelpOutlineIcon fontSize="extrasmall" />
              </p>
              <Autocomplete
                multiple
                id="tags-outlined"
                options={top100Films}
                getOptionLabel={(option) => option.title}
                defaultValue={[top100Films[13]]}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField {...params} placeholder="Favorites" />
                )}
              />
            </Box>

            <Box sx={{ m: "10px 0" }}>
              <p
                style={{
                  margin: "5px 0",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Minimum academic performance{" "}
                <HelpOutlineIcon fontSize="extrasmall" />
              </p>

              <Slider
                defaultValue={50}
                aria-label="Default"
                valueLabelDisplay="auto"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
  {
    title: "The Lord of the Rings: The Return of the King",
    year: 2003,
  },
  { title: "The Good, the Bad and the Ugly", year: 1966 },
  { title: "Fight Club", year: 1999 },
  {
    title: "The Lord of the Rings: The Fellowship of the Ring",
    year: 2001,
  },
  {
    title: "Star Wars: Episode V - The Empire Strikes Back",
    year: 1980,
  },
  { title: "Forrest Gump", year: 1994 },
  { title: "Inception", year: 2010 },
  {
    title: "The Lord of the Rings: The Two Towers",
    year: 2002,
  },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: "Goodfellas", year: 1990 },
  { title: "The Matrix", year: 1999 },
  { title: "Seven Samurai", year: 1954 },
  {
    title: "Star Wars: Episode IV - A New Hope",
    year: 1977,
  },
  { title: "City of God", year: 2002 },
  { title: "Se7en", year: 1995 },
  { title: "The Silence of the Lambs", year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: "Life Is Beautiful", year: 1997 },
  { title: "The Usual Suspects", year: 1995 },
  { title: "Léon: The Professional", year: 1994 },
  { title: "Spirited Away", year: 2001 },
  { title: "Saving Private Ryan", year: 1998 },
  { title: "Once Upon a Time in the West", year: 1968 },
  { title: "American History X", year: 1998 },
  { title: "Interstellar", year: 2014 },
  { title: "Casablanca", year: 1942 },
  { title: "City Lights", year: 1931 },
  { title: "Psycho", year: 1960 },
  { title: "The Green Mile", year: 1999 },
  { title: "The Intouchables", year: 2011 },
  { title: "Modern Times", year: 1936 },
  { title: "Raiders of the Lost Ark", year: 1981 },
  { title: "Rear Window", year: 1954 },
  { title: "The Pianist", year: 2002 },
  { title: "The Departed", year: 2006 },
  { title: "Terminator 2: Judgment Day", year: 1991 },
  { title: "Back to the Future", year: 1985 },
  { title: "Whiplash", year: 2014 },
  { title: "Gladiator", year: 2000 },
  { title: "Memento", year: 2000 },
  { title: "The Prestige", year: 2006 },
  { title: "The Lion King", year: 1994 },
  { title: "Apocalypse Now", year: 1979 },
  { title: "Alien", year: 1979 },
  { title: "Sunset Boulevard", year: 1950 },
  {
    title:
      "Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb",
    year: 1964,
  },
  { title: "The Great Dictator", year: 1940 },
  { title: "Cinema Paradiso", year: 1988 },
  { title: "The Lives of Others", year: 2006 },
  { title: "Grave of the Fireflies", year: 1988 },
  { title: "Paths of Glory", year: 1957 },
  { title: "Django Unchained", year: 2012 },
  { title: "The Shining", year: 1980 },
  { title: "WALL·E", year: 2008 },
  { title: "American Beauty", year: 1999 },
  { title: "The Dark Knight Rises", year: 2012 },
  { title: "Princess Mononoke", year: 1997 },
  { title: "Aliens", year: 1986 },
  { title: "Oldboy", year: 2003 },
  { title: "Once Upon a Time in America", year: 1984 },
  { title: "Witness for the Prosecution", year: 1957 },
  { title: "Das Boot", year: 1981 },
  { title: "Citizen Kane", year: 1941 },
  { title: "North by Northwest", year: 1959 },
  { title: "Vertigo", year: 1958 },
  {
    title: "Star Wars: Episode VI - Return of the Jedi",
    year: 1983,
  },
  { title: "Reservoir Dogs", year: 1992 },
  { title: "Braveheart", year: 1995 },
  { title: "M", year: 1931 },
  { title: "Requiem for a Dream", year: 2000 },
  { title: "Amélie", year: 2001 },
  { title: "A Clockwork Orange", year: 1971 },
  { title: "Like Stars on Earth", year: 2007 },
  { title: "Taxi Driver", year: 1976 },
  { title: "Lawrence of Arabia", year: 1962 },
  { title: "Double Indemnity", year: 1944 },
  {
    title: "Eternal Sunshine of the Spotless Mind",
    year: 2004,
  },
  { title: "Amadeus", year: 1984 },
  { title: "To Kill a Mockingbird", year: 1962 },
  { title: "Toy Story 3", year: 2010 },
  { title: "Logan", year: 2017 },
  { title: "Full Metal Jacket", year: 1987 },
  { title: "Dangal", year: 2016 },
  { title: "The Sting", year: 1973 },
  { title: "2001: A Space Odyssey", year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: "Toy Story", year: 1995 },
  { title: "Bicycle Thieves", year: 1948 },
  { title: "The Kid", year: 1921 },
  { title: "Inglourious Basterds", year: 2009 },
  { title: "Snatch", year: 2000 },
  { title: "3 Idiots", year: 2009 },
  { title: "Monty Python and the Holy Grail", year: 1975 },
];
