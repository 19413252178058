import React from "react";
import { Outlet } from "react-router-dom";
import AdminEmployerSidebar from "./Sidebar";
const AdminEmployerLayout = () => {
  return (
    <div className="admin-page-wrapper">
      <div className="admin-page-box dashboard-page-box">
        <AdminEmployerSidebar />
        <div className="dashboard-page-content-wrapper">
          <div className="dashboard-contentbox">
          <Outlet />
          </div>
     
        </div>
      </div>
    </div>
  );
};

export default AdminEmployerLayout;
