import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const AddWorkSamples = async (
    bio, github, linkedin, playstore, token
) => {

    try {
        const entries = {
            bio, github, linkedin, playstore
        }
        const response = await heyapi.post(
            `/student/samples/update`,
            {
                entries
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        console.log(response.data);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};
