import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const getEmployerInfo = async (token) => {
  try {
    const response = await heyapi.get(
      `/employer/details`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getEmployerProfileInfo = async (token) => {
  try {
    const response = await heyapi.get(
      `/employer/profile-details`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getEmployerCompanyInfo = async (token) => {
  try {
    const response = await heyapi.get(
      `/employer/company-details`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const uploadLogo = async (formData, token) => {
  try {
    const response = await heyapi.post(
      `/employer/upload-logo`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};