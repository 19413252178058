import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Alert, Box, Button, FormGroup, Snackbar, Tab, TextField, Typography } from '@mui/material'
import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import Tabs from "@material-ui/core/Tabs";
import React, { useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux"
import { useSelector } from 'react-redux';
import websiteSettings from "../common/siteSettings"

import {
    AdminLoginRequest,
    adminLoginSuccess,
    AdminLoginFailure,
    userType,
} from "./../Store/Action";
import { adminLogin } from '../services/adminLogin';
const AdminLogin = () => {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [err, setErr] = React.useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const { user, updateUser } = useContext(UserContext);
    // console.log("ssp", user)
    // const isLogedIn = async () => {
    //     if (user != null) {
    //         navigate('/');
    //     }
    // }
    // useEffect(() => {
    //     isLogedIn();
    // }, [])
    const loginAdmin = async () => {
        dispatch(adminLogin(email, password));

    };
    const admin = useSelector((state) => state.admin);
    if (admin != null && admin.message === 'login sucessfull') {
        dispatch(userType("admin"));
        navigate('/admin/dashboard')
    }
    const error = useSelector((state) => state.error)
    console.log(error)
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#f8f8f8",
                paddingTop: "20px",
                paddingBottom: "20px",
            }}
        >
            <Box
                sx={{
                    width: "350px",
                    padding: "10px",
                    borderRadius: "10px",
                    background: "#ffffff",
                }}
            >
                <Box>
                    <p style={{ textAlign: "center", fontSize: "18px" }} >Admin Login</p>
                    {error && (
                        <p
                            style={{
                                color: "red",
                                fontSize: "16px",
                                fontWeight: "500",
                                textAlign: "center",
                            }}
                        >
                            * Incorrect email or password
                        </p>
                    )}
                    <FormGroup
                        sx={{ alignItems: "flex-start", marginBottom: "10px" }}
                    >
                        <Typography>Email</Typography>
                        <TextField
                            size="small"
                            fullWidth
                            label="email@example.com"
                            id="fullWidth"
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                        />
                    </FormGroup>
                    <FormGroup sx={{ alignItems: "flex-start", marginBottom: "7px" }}>
                        <Typography>Password</Typography>
                        <TextField
                            size="small"
                            fullWidth
                            label="must be atleast 6 charactors"
                            id="fullWidth"
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                        />
                    </FormGroup>
                    <FormGroup sx={{ alignItems: "flex-end", marginBottom: "7px" }}>
                        <Link
                            to={"/forget-password"}
                            style={{ textDecoration: "none" }}
                        >
                            Forgot Password?
                        </Link>
                    </FormGroup>
                    <FormGroup sx={{ marginBottom: "7px" }}>
                        <Button
                            variant="contained"
                            disableElevation
                            onClick={loginAdmin}
                        >
                            Login
                        </Button>
                    </FormGroup>
                    <FormGroup>
                        <Typography>
                            New to {websiteSettings.name}?<Link
                                to={"/admin/signup"}
                                style={{ textDecoration: "none" }}
                            > Register </Link>
                        </Typography>
                    </FormGroup>
                </Box>
            </Box>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
                    This is a success message!
                </Alert>
            </Snackbar>
        </div>
    );
}

export default AdminLogin
