import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Container,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { employerUpdateOnboardStatus } from "../../services/updateEmployerDetail";
import { useSelector } from "react-redux";
import { showToastAlert } from "../../admin/components/CustomAlert";
import { useDispatch } from "react-redux";
import { updateStepCompletion } from "../../Store/Action";

const SuccessOnboard = () => {
  const token = useSelector((state) => state.token);
  const [loaded, setLoaded] = React.useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    updateOnboardStatus();
  }, []);

  async function updateOnboardStatus() {
    const response = await employerUpdateOnboardStatus(token);
    if(response.status !== 200) {
      showToastAlert(response.data.message, "error");
    } else {
      dispatch(updateStepCompletion({onboarded: true}));
      setLoaded(true);
    }
  }
  return (
    <>
    {loaded ? (<Container
      maxWidth="sm"
      sx={{
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
        marginTop: "40px"
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          marginBottom: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CheckCircleOutlineIcon
          color="success"
          sx={{ fontSize: 80, marginBottom: 2 }}
        />
        <Box textAlign="center">
            <Typography variant="h4" gutterBottom>
                🎉 Congratulations!
            </Typography>
            <Typography variant="h6" gutterBottom>
                You have successfully completed all steps of the onboarding process.
            </Typography>
            <Typography variant="body1" color="error" gutterBottom >
                Your profile is under review. 
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ marginBottom: '10px' }}>
              To complete the verification process, please mail us any of the following documents:
            </Typography>
            <Box sx={{ textAlign: 'left', paddingLeft: '40px' }}>
            <ol style={{ listStyleType: 'decimal', marginLeft: '20px', color: '#333' }}>
              <li style={{ display: 'list-item' }}>Any document of company (GST, PAN)</li>
              <li>Company Registration Proof</li>
              <li>Any additional supporting documents as requested</li>
            </ol>
            </Box>
            <Typography variant="body2" color="textSecondary" sx={{ marginTop: '10px' }}>
              Our team will review your documents and connect with you in case of any issue.
            </Typography>
          </Box> 
           <br/><br/>
          <Button
            component={Link}
            to="/employer/dashboard"
            variant="contained"
            color="primary"
            sx={{ marginTop: '20px' }}
          >
            Go to Dashboard
          </Button>
 
      </Box>
    </Container>) : "Loading onboarding status ..." } 
    </>
  );
};

export default SuccessOnboard;
