import axios from "axios";
import { baseUrl } from "../common/siteSettings";
import heyapi from "../middleware/axiosInstance";

export const getEmployerDetails = async (id, token) => {
    try {
      const response = await heyapi.get(
        `/admin/employer?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
};

export const getAllEmployer = async ( state, token) => {
    try {
        const response = await heyapi.get(
            `/admin/employers?state=${state}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (err) {
        console.log(err);
    }
};
