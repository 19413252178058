import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import { getStatusTag } from "../common/commonFunctions";
import Tooltip from '@mui/material/Tooltip';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import CustomLoader from "../components/CustomLoader";
import DataTable from "./components/DataTable";
import { getAllPosts, updatePostState } from "../services/employerAllPost";
import RejectReasonBox from "../dialogBoxes/SetReason";
import AlertMsg from "../dialogBoxes/AlertMsg";
import SingleInternshipModal from "../modals/SingleInternshipModal";
import EditIcon from '@mui/icons-material/Edit';

const AdminAllPostList = () => {
    const [loaderOpen, setLoaderOpen] = React.useState(true);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [apiData, setApiData] = React.useState([]);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [selectedId, setSelectedId] = useState("");
    const [showOptions, setShowOptions] = useState("Approve");
    const [selectedOptions, setSelectedOptions] = useState(new Map());
    const [showAlert, setShowAlert] = useState(false);
    const [showReasonPopup, setShowReasonPopup] = useState(false);
    const [reason, setReason] = useState("");
    const [currPostId, setCurrPostId] = React.useState("");
    const admin = useSelector((state) => state.admin);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const {state} = useParams();
    // Access the specific search key value
    let searchKeyValue = state;
    if (searchKeyValue == undefined) {
        searchKeyValue = "all";
    }
    
    const {id} = useParams();


    const tableColumns = [
        {
            field: "title",
            headerName: "TITLE",
            flex: 1,
            editable: false
        },
        {
            field: "company",
            headerName: "Company",
            flex: 1,
            editable: false,
            renderCell: (params) => (
                <p>{params.row.ownerId.company.name}</p>
            ),
        },
        {
            field: "numOpening",
            headerName: "NUMBER OF OPENING",
            flex: 1,
            editable: false
        },
        {
            field: "startDate",
            headerName: "Start At",
            flex: 1,
            editable: false,
            valueGetter: (value) => {
                const date = new Date(value);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
                const day = String(date.getDate()).padStart(2, "0");

                return `${day}-${month}-${year}`;
            },
        },
        {
            field: "status",
            headerName: "STATUS",
            flex: 1,
            renderCell: (params) => (
                <Select
                    className="status-selectbox"
                    value={params.value}
                    onChange={(event) => {
                        const newValue = event.target.value;
                        handleOptionChange(params.row._id, newValue);
                        params.api.updateRows([{ ...params.row, status: newValue }]);
                    }}
                >
                    {postOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            {getStatusTag(option)}
                        </MenuItem>
                    ))}
                </Select>
            ),
        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            minWidth: 230,
            editable: false,
            renderCell: (params) => (
                <div>
                    <Button
                        className="circular-btn me-2"
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={() => handlePostStateUpdate(params)}
                    >
                        Update
                    </Button>
                    <Tooltip title="Show Post Details">
                        <Button
                            variant="outlined"
                            onClick={() => handlePostView(params)}
                            className="circular-btn me-2"
                            startIcon={<RemoveRedEyeRoundedIcon />}
                        />
                    </Tooltip>
                    <Tooltip title="Edit Post Details">
                        <Button
                            variant="outlined"
                            onClick={() => editPostView(params)}
                            className="circular-btn me-2"
                            startIcon={<EditIcon />}
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    useEffect(() => {
        getPosts(searchKeyValue)
    }, [admin, searchKeyValue]);

    const getPosts = async (tag) => {
        try {
            setLoaderOpen(true);
            const data = await getAllPosts(tag, admin.token);
            setApiData(data.posts);
            //set initial post status array 
            let tempStatusMap = new Map();
            data.posts.map((item, stIndex) => {
                tempStatusMap.set(item._id, item.status);
            })
            setSelectedOptions(tempStatusMap);
            setLoaderOpen(false);
        }
        catch (err) {
            throw new Error(err)
        }
    }
    const handleUpdate = (params) => {
        const id = params.row._id;
        const status = params.row.status;
        // Call your update function here with the row ID and status value
        //UpdateEmployerState(admin.token, id, status);
        console.log(`Updating row with ID: ${id}, Status: ${status}`);
        // Your update logic here
    };
    const handlePostView = (params) => {
        const id = params.row._id;  
        setCurrPostId(id);
        setModalOpen(true);
    };

    const editPostView = (params) => {
        const id = params.row._id;  
        navigate(`/admin/post/edit/${id}`);
    };

    const closePostView = () => {
        setModalOpen(false);
    }

    const postOptions = ["pending", "approved", "closed", "rejected"]; //live is just approved with live condition
    const handleOptionChange = (rowIndex, value) => {
        setSelectedOptions((prevValues) => {
            const tempValues = new Map(prevValues);
            tempValues.set(rowIndex, value);
            return tempValues;
        })
    }

    const callPostStateUpdateApi = async (postid, aReason) => {
        const curSelOption = selectedOptions.get(postid)
        const data = await updatePostState(postid, curSelOption, admin.token, aReason);
        console.log("after post udpaet: " + data);
        if (data.msg === "success") {
            // Hide alert after 3 seconds
            setShowAlert(true);
            //if state updated and not current one remove this row 
             // Remove the row from the table
            setApiData((prevApiData) => prevApiData.filter((row) => row._id !== postid));
            setTimeout(() => {
                setShowAlert(false);
            }, 2000);

        }
    }

    const handleClose = () => {
        setShowAlert(false);
    }

    const handleReasonSubmit = async (postid, aReason) => {
        if (aReason === "")
            return;
        setReason(aReason);
        setShowReasonPopup(false);
        await callPostStateUpdateApi(postid, aReason);
    }
    const handlePostStateUpdate = async (params) => {
        const postid = params.row._id;
        setSelectedId(postid);
        const curSelOption = selectedOptions.get(postid)
        if(curSelOption === searchKeyValue) //do nothing 
            return; 
        if (curSelOption === "rejected" || curSelOption == "closed") {
            // Show the input popup for the reason
            setShowReasonPopup(true);
        } else {
            await callPostStateUpdateApi(postid, "");
        }
    }

    return (
        <div>
            <h1 className="page-title-1 my-3 asdsd">Employer Posts</h1>
            {showAlert && (
                 <AlertMsg
                    open={showAlert}
                    onClose={handleClose}
                    title="Success"
                    message="The operation was successful."
                    severity="success"
                    buttonText="Close"
                    />
            )}
            <CustomLoader setLoaderOpen={setLoaderOpen} loaderOpen={loaderOpen} />
            <DataTable data={apiData} columns={tableColumns} id="_id" />
            <SingleInternshipModal open={modalOpen} postid={currPostId} closePostView={closePostView} />
            <RejectReasonBox selectedId={selectedId} showReasonPopup={showReasonPopup} setShowReasonPopup={setShowReasonPopup} setReason={setReason} handleReasonSubmit={handleReasonSubmit} />
        </div>
    );
};

export default AdminAllPostList;
