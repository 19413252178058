import React from "react";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { filterPost } from "../services/filterPost";
import { useParams } from "react-router-dom";
import { getSkillAndLocations } from "../services/utilApis";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import { getAllCity } from "../services/getAllCities";
import { getAllSkill } from "../services/getAllSkill";
import { useNavigate } from "react-router-dom";
import { getArrForAutoComplete } from "../services/utilApis";
import CustomLoader from "../components/CustomLoader";
import InternshipSummaryCard from "../components/InternshipSummaryCard";
import { Drawer, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import TuneIcon from "@mui/icons-material/Tune";
import SingleInternshipModal from "../modals/SingleInternshipModal";
import { checkOnboardState } from "../common/redirectFunctions";
import { Helmet } from "react-helmet-async";

const marks = [
  {
    value: 0,
    label: "0K",
  },
  {
    value: 20,
    label: "2K",
  },
  {
    value: 40,
    label: "4K",
  },
  {
    value: 60,
    label: "6K",
  },
  {
    value: 80,
    label: "8K",
  },
  {
    value: 100,
    label: "10K",
  },
];

function valuetext(value) {
  return `${value}°C`;
}

function valueLabelFormat(value) {
  return marks.findIndex((mark) => mark.value === value) + 1;
}

const InternshiplistPage = () => {
  const [selectedLocations, setSelectedLocations] = React.useState([]);
  const [selectedSkills, setSelectedSkills] = React.useState([]); // Add state for skills
  const [wfo, setWfo] = React.useState(false);
  const navigate = useNavigate();
  const [viewMore, setViewMore] = React.useState(false);
  const [stipend, setStipend] = React.useState(null);
  const [maxDuration, setMaxDuration] = React.useState(null);
  const [allSkills, setallSkills] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [allLocations, setAllLocations] = React.useState([]);
  const [openInternshipsFilter, setOpenInternshipsFilter] =
    React.useState(false);
  const [IsGridView, setisGridView] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [showApplyBtn, setShowApplyBtn] = React.useState(true);
  const [currPostId, setCurrPostId] = React.useState("");
  const {uType, stepsCompleted} = useSelector((state) => ({
    uType: state.uType,
    stepsCompleted: state.stepsCompleted}));

  const handleViewPost = (postid) => {
    setCurrPostId(postid);
    setModalOpen(true);
  };

  const closePostView = () => {
    setModalOpen(false);
  };

  const isMobile = useMediaQuery("(max-width:800px)");

  const { suburl } = useParams();
  // Use useEffect to set skills and locations based on suburl
  React.useEffect(() => {
    if(uType === "student") {
        if(!checkOnboardState(stepsCompleted)) {
          return navigate("/student/onboard" )
        } 
    }

    if (suburl) {
      let [skillArr, locArr] = getSkillAndLocations(suburl);
      if (suburl.includes("_work_from_home")) {
        setWfo(true);
        locArr = [];
      }
      setSelectedLocations(locArr);
      setSelectedSkills(skillArr);
    }
  }, [suburl]); // Only run this effect when suburl changes

  React.useEffect(() => {
    getPost();
  }, [selectedLocations, selectedSkills, stipend]);

  const [apiData, setApiData] = React.useState("");
  const token = useSelector((state) => state.token);
  React.useEffect(() => {
    getPref();
    getPost();
  }, [token]);
  const getPost = async () => {
    try {
      const data = await filterPost(
        token,
        selectedLocations,
        selectedSkills,
        stipend,
        maxDuration,
        wfo
      );
      setApiData(data);
      setLoader(false);
    } catch (err) {
      console.log(err, "hlo");
    }
  };
  const handleLocationChange = (event, newValue) => {
    setSelectedLocations(newValue.map((item) => item.city));
  };

  const handleWFH = (e) => {
    setWfo(e.target.checked);
    if (e.target.checked) {
      setSelectedLocations([]); // Reset the allLocations when "Work from home" is checked
    }
  };

  const handleSkillChange = (event, newValue) => {
    setSelectedSkills(newValue.map((item) => item.name));
  };

  const handleSearchClick = () => {
    if (selectedSkills.length == 0 && selectedLocations.length == 0 && !wfo) {
      navigate("/internships");
      return;
    }
    if (selectedSkills.length > 5 || selectedLocations.length > 5) {
      //not allowed more than 5
      return;
    }
    const skillsString = selectedSkills
      .map((skill) => skill.replace(" ", "-"))
      .join(",");
    // Construct the URL
    let newUrl = "/internships/";
    if (skillsString !== "") newUrl += `${skillsString}_`;
    newUrl += "internship";

    if (wfo) newUrl += "_in_work_from_home";
    else {
      const locationsString = selectedLocations
        .map((location) => location.replace(" ", "-"))
        .join(",");
      if (locationsString !== "") newUrl += `_in_${locationsString}`;
    }
    // Navigate to the new URL
    navigate(newUrl);
  };

  const getPref = async () => {
    try {
      const allSkills = await getAllSkill();
      const cityData = await getAllCity();
      setAllLocations([...new Set(cityData.cities)]);
      setallSkills([...new Set(allSkills.skills)]);
      setLoader(false);
    } catch (error) {
      console.error("Error fetching training data:", error);
    }
  };

  const breadcrumbsData = [
    { label: "Dashboard", href: "/employer/dashboard" },
    {
      label: "Applications Received",
      href: "/applications",
    },
    { label: "Ashutosh Kumar", href: null }, // Last breadcrumb (no link)
  ];

  const internshipFilter = (
    <Stack spacing={3} sx={{ m: 2 }}>
      <Typography align="center" sx={{ fontWeight: "medium" }}>
        <FilterAltIcon sx={{ color: "#34a3e3" }} />
        Filter
      </Typography>

      <Autocomplete
        size="small"
        multiple
        id="InternshipFilterSidebar-outlined"
        options={allSkills}
        value={getArrForAutoComplete(selectedSkills)}
        onChange={handleSkillChange}
        getOptionLabel={(option) => option.name}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField {...params} label="Skills" placeholder="e.g. Marketing" />
        )}
      />

      <Autocomplete
        size="small"
        multiple
        disabled={wfo}
        id="InternshipFilterSidebar-outlined"
        options={allLocations}
        value={getArrForAutoComplete(selectedLocations, "city")}
        onChange={handleLocationChange}
        getOptionLabel={(option) => option.city}
        filterSelectedOptions
        filterOptions={(options, { inputValue }) => {
          // Case-insensitive filtering
          return options.filter((option) =>
            option.city.toLowerCase().includes(inputValue.toLowerCase())
          );
        }}
        renderInput={(params) => (
          <TextField {...params} label="Location" placeholder="e.g. Delhi" />
        )}
      />
      <FormGroup gutterBottom>
        <FormControlLabel
          control={<Checkbox defaultChecked={wfo} onChange={handleWFH} />}
          label="Work from home"
        />
      </FormGroup>

      {/* Add the Search Button */}

      <Button
        variant="contained"
        onClick={handleSearchClick} // Handle search click
        startIcon={<SearchIcon />}
        sx={{ mt: 2 }}
      >
        Search
      </Button>

      <Box sx={{ display: viewMore ? "block" : "none" }}>
        <Typography sx={{ fontWeight: "medium" }}>
          Desired minimum monthly stipend (₹)
        </Typography>
        <Box sx={{ width: 250 }}>
          <Slider
            aria-label="Restricted values"
            defaultValue={20}
            value={stipend}
            onChange={(e, newValue) => setStipend(newValue)}
            valueLabelFormat={valueLabelFormat}
            getAriaValueText={valuetext}
            step={null}
            valueLabelDisplay="auto"
            marks={marks}
          />
        </Box>
        <Typography>Max. duration (months)</Typography>
        <Autocomplete
          size="small"
          id="InternshipFilterSidebar-outlined"
          options={[
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
          ]}
          value={maxDuration}
          onChange={(e, newValue) => setMaxDuration(newValue)}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              label="Numbers"
              placeholder="Select Numbers"
            />
          )}
        />
        <FormGroup gutterBottom>
          <FormControlLabel
            control={<Checkbox />}
            label="Internships with job offer"
          />
        </FormGroup>
      </Box>
      <CardActions>
        {viewMore ? (
          <Button
            size="small"
            onClick={(e) => {
              setViewMore(false);
            }}
          >
            View less filters{" "}
            <ExpandLessOutlinedIcon sx={{ marginBottom: "2px" }} />
          </Button>
        ) : (
          <Button
            size="small"
            onClick={(e) => {
              setViewMore(true);
            }}
          >
            View More Filters{" "}
            <ExpandMoreOutlinedIcon sx={{ marginBottom: "2px" }} />
          </Button>
        )}
      </CardActions>
    </Stack>
  );
  return (
    <>
      {loader ? (
        <CustomLoader loaderOpen={loader} />
      ) : (
        <div className="container pt-3">
          <Helmet>
            <title>HeyInterns | Find Internships </title>
          </Helmet>
          <h2 className="d-page-title"> Find Internships</h2>

          <div className="internshiplist-page">
            {isMobile && (
              <Drawer
                anchor="left"
                open={openInternshipsFilter}
                classes={{ paper: "dashboard-sidebar-drawer" }}
                onClose={() => setOpenInternshipsFilter(false)}
              >
                <IconButton
                  variant="contained"
                  aria-label="more"
                  className="cancel-drawer-btn"
                  onClick={() => {
                    setOpenInternshipsFilter(false);
                  }}
                >
                  <CancelIcon />
                </IconButton>
                {internshipFilter}
              </Drawer>
            )}

            {!isMobile && (
              <>
                <div className="filter-box">{internshipFilter}</div>
              </>
            )}
            <div className="internship-box">
              {isMobile && (
                <h2 className="mobile-internships-filter-btn mb-2">
                  <Button
                    variant="text"
                    onClick={() => {
                      setOpenInternshipsFilter((prev) => !prev);
                    }}
                  >
                    Filters <TuneIcon />
                  </Button>
                </h2>
              )}

              <div
                className={`internships-row ${IsGridView ? "grid-view" : ""}`}
              >
                {apiData?.posts?.length > 0 ? (
                  apiData.posts.map((postData, index) => (
                    <InternshipSummaryCard
                      data={postData}
                      key={postData.title + index}
                      setCurrPostId={setCurrPostId}
                      setModalOpen={setModalOpen}
                    />
                  ))
                ) : (
                  <p>No internships available.</p>
                )}
                <SingleInternshipModal
                  open={modalOpen}
                  postid={currPostId}
                  closePostView={closePostView}
                  showApplyBtn={showApplyBtn}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InternshiplistPage;
