import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Box,
  Button
} from "@mui/material";
import { useSelector } from "react-redux";
import { grey } from "@mui/material/colors";
import { useEffect, useState, useCallback } from "react";
import { Download } from "@mui/icons-material";
import { Create } from "@mui/icons-material";
import AddEducation from "../../dialogBoxes/AddEducation";
import AddInternship from "../../dialogBoxes/AddInternship";
import AddPositionResponseblity from "../../dialogBoxes/AddPositionResponseblity";
import AddTraining from "../../dialogBoxes/AddTraining";
import AddProject from "../../dialogBoxes/AddProject";
import AddSkills from "../../dialogBoxes/AddSkills";
import AddPortFolio from "../../dialogBoxes/AddPortFolio";
import AddPersonalDetails from "../../dialogBoxes/AddPersonalDetails";
import { allTraining } from "../../services/getAllTraining";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
const { DateTime } = require("luxon");
const query = new URLSearchParams(window.location.search);
const errorMessage = query.get("error_message");

const ResumeComponent = ({onComplete=null}) => {
  const [data, setdata] = useState();
  const [loader, setLoader] = useState(true);
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();
  const getTraining = async () => {
    try {
      const apiData = await allTraining(token);
      setdata(apiData);
      setLoader(false);
    } catch (error) {
      console.error("Error fetching training data:", error);
    }
  };


  useEffect(() => {
    getTraining();
  }, []);
  return (
    <div>
      {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ThreeDots
            height="60"
            width="60"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      ) : (
        <div>
          {(!onComplete) && <h3
            style={{
              marginTop: 10,
              fontSize: 60,
              textAlign: "center",
              fontFamily: "PT Serif",
            }}
          >
            Resume
            {errorMessage && (<div className="alert alert-danger" role="alert">
          <h5> Your profile is not complete. Complete this to continue your journey </h5> 
          </div>)}
          </h3>}

          <Container
            maxWidth="md"
            style={{ marginTop: "20px", color: grey[500], flex: 1 }}
            sx={{ border: 1, borderRadius: "16px" }}
          >
            
            {(!onComplete) && <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{ verticalAlign: "middle", textAlign: "left" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        {data ? ( // Check if data exists before rendering
                          <>
                            <h3>
                              {data.name} <Create onClick={() => navigate('/student/editprofile')} />
                            </h3>
                            <span style={{ color: '#666666' }}>{data.email}</span>
                            <br />
                            <span style={{ color: '#666666' }}>+91{data.mobile}</span>
                            <br />
                            <span style={{ color: '#666666' }}>{data.location}</span>
                          </>
                        ) : (
                          <p>Data not available.</p> // Display a loading message while data is fetched
                        )}
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table> }
              
            <AddPortFolio samples={data?.samples} />
            <AddEducation getTraining={getTraining} educations={data?.education} />

            <AddInternship getTraining={getTraining} internship={data} />
            <AddTraining getTraining={getTraining} training={data} />
            <AddProject getTraining={getTraining} project={data} />
          </Container>
        </div>
      )}
    </div>
  );
};

export default ResumeComponent;
