import { Box, Button, TextField, Typography } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomAlert, showToastAlert } from "../admin/components/CustomAlert";
import PopUp from "../components/PopUp";
import { deletePreference } from "../services/deletePreference";
import { getAllPreference } from "../services/getAllPreferences";
import { getAllSkill } from '../services/getAllSkill';
import { myPreferences } from "../services/getAllTraining";
import { AddPreference } from "../services/postPreference";
import { updateStepCompletion } from "../Store/Action";

const Preferences = ({onComplete=null}) => {
  const [myPref, setMyPref] = useState([]);
  const [mySkills, setMySkills] = useState([]);
  const [data, setdata] = useState();
  const [loader, setLoader] = useState(true);
  const [allSkills, setAllSkills] = React.useState([]);
  const [allPref, setAllPref] = useState(true);
  const [success, setSuccess] = useState(false);
  const token = useSelector((state) => state.token);
  const userId = useSelector((state) => state.id);
  const dispatch = useDispatch();
  const addPref = async (item) => {
    if (!myPref.includes(item)) {
      setMyPref([...myPref, item]);
    }
  };

  const postPref = async () => {
    try {
      if(myPref.length == 0) {
        showToastAlert("Please add preferences", "error");
        return;
      }
      if(mySkills.length ==0 ) {
        showToastAlert("Please add skills", "error");
        return;
      }
      const res = await AddPreference(
        userId,
        myPref,
        mySkills,
        token
      );
      if(res.status === 200) {
        showToastAlert("Data updated", "success");    
        dispatch(updateStepCompletion({ prefUpdated: true}));
        if(onComplete)
          onComplete();
      } else {
        showToastAlert(res.data.message, "error");
      }
      setSuccess(true);
    } catch (err) {
      console.log(err);
    }
  };
  const getPref = async () => {
    try {
      const apiData = await myPreferences(token);
      const allPref = await getAllPreference();
      const allSkills = await getAllSkill();

      if (allPref) {
        setAllPref(allPref.preferences.map((item) => item.name));
        setAllSkills(allSkills.skills.map((item) => item.name));
        setMyPref(apiData.preferences)
        setMySkills(apiData.skills)
      }
      setdata(apiData);
      setLoader(false);
    } catch (error) {
      console.error("Error fetching training data:", error);
    }
  };
  //for delete preference
  const dltPreference = async (item, token) => {
    try {
      const data = await deletePreference(item, token);
      setSuccess(true);
      getPref();
      setTimeout(() => {
        setSuccess(false);
      }, 2000);

    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getPref();
  }, [token]);
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {loader ? null : (
        <Box sx={{ width: "100%", margin: "10px" }}>
          <Box sx={{ maxWidth: "600px", margin: "auto" }}>
            {(!onComplete) && <Box style={{ marginTop: "20px" }}>
              <h2>Your Preferences</h2>
            </Box>}
            <center>{success && <PopUp />}</center>
            <Box
              sx={{
                border: 1,
                borderRadius: "10px",
                borderColor: "#9e9e9e ",
                padding: "10px",
              }}
              style={{ marginTop: "25px" }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    margin: "5px",
                  }}
                >
                  <Typography>Area/Industry(s) of interest</Typography>
                </Box>
                <Box sx={{ margin: "5px" }}>
                  <Autocomplete
                    multiple
                    id="tags-prefs"
                    options={allPref}
                    getOptionLabel={(option) => option}
                    filterSelectedOptions
                    onChange={(event, selectedOption) => {
                      setMyPref(selectedOption);
                    }}
                    value={myPref}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" placeholder="+Add Like Mobile App Development." />
                    )}
                    sx={{ marginTop: "20px", width: "100%" }}
                  />
                </Box>
                <Box>
                  <hr/>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      marginTop: "5px",
                    }}
                  >
                    <Typography>Add Your Skills</Typography>
                  </Box>
                  <Box sx={{ margin: "5px" }}>
                  <Autocomplete
                    multiple
                    id="tags-prefs"
                    options={allSkills}
                    getOptionLabel={(option) => option}
                    filterSelectedOptions
                    onChange={(event, selectedOption) => {
                      setMySkills(selectedOption);
                    }}
                    value={mySkills}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" placeholder="+Add Skills." />
                    )}
                    sx={{ marginTop: "20px", width: "100%" }}
                  />
                </Box>
                </Box>
              </Box>
            </Box>
            <Box
              style={{
                width: "100%",
                margin: "5px",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  postPref();
                  handleScrollToTop();
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
          <CustomAlert />
        </Box>
      )}
    </>
  );
};

export default Preferences;