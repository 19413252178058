import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Typography, Box, CircularProgress, Button } from '@mui/material';
import { verifyEmail } from '../services/verification'; // Assuming this is the API service

const EmailVerifyPage = () => {
  const location = useLocation();  // Get the location object

  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

   // Function to extract query parameters
   const getQueryParams = (query) => {
    return new URLSearchParams(query);
  };

  useEffect(() => {
    const verifyToken = async () => {
       // Extract the tokenid from query params
      const queryParams = getQueryParams(location.search);
      const tokenidParam = queryParams.get('tokenid');
      
      if (!tokenidParam) {
        setError('Invalid token.');
        setLoading(false);
        return;
      }
      
      try {
        const result = await verifyEmail(tokenidParam, "student"); // Calling verification API
        if (result.success) {
          setSuccess(true);
        } else {
          setError(result.message || 'Email verification failed.');
        }
      } catch (err) {
        setError('An error occurred during verification.');
      } finally {
        setLoading(false);
      }
    };

    verifyToken();
  }, []);

  const handleRedirect = () => {
    navigate('/student/login'); // Redirect after success or failure
  };

  return (
    <Container maxWidth="sm" className="heyinterns-main-content" sx={{ textAlign: 'center', marginTop: '50px', flex: 1 }}>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : success ? (
        <Box>
          <Typography variant="h4" color="primary" gutterBottom>
            Email Verified!
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Your email has been successfully verified. You can now log in to your account.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: '20px' }}
            onClick={handleRedirect}
          >
            Go to Login
          </Button>
        </Box>
      ) : (
        <Box>
          <Typography variant="h4" color="error" gutterBottom>
            Verification Failed
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {error || 'The verification link is invalid or has expired.'}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: '20px' }}
            onClick={handleRedirect}
          >
            Go to Login
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default EmailVerifyPage;
