import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import DatePicker from "react-datepicker";
import {
  TableBody,
  TableRow,
  TableCell,
  TextField,
  FormGroup,
  Box,
  tableCellClasses,
  Tooltip,
} from "@mui/material";
import { EmojiFlags, ErrorOutlineOutlined } from "@material-ui/icons";
import { addEducation } from "../services/postEducation";
import { useSelector } from "react-redux";
import PopUp from "../components/PopUp";
import { deleteEducation } from "../services/deleteEducation";
import { editEducation } from "../services/editEducation";
import { EducationSelectDialog, MatriculationDialog, GraduationDialog, TwelveDialog } from "./AddEducationSelect";
import { getEducationAll } from "../services/postEducation";
import { CustomAlert, showToastAlert } from "../admin/components/CustomAlert";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import { updateStepCompletion } from "../Store/Action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
const { DateTime } = require("luxon");

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddEducation = ({ educations, getTraining }) => {
  const [open, setOpen] = React.useState(false);
  const [eduSelectionOpen, setEduSelectionOpen] = React.useState(false);
  const [school, setSchool] = useState("");
  const [degree, setDegree] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [percentage, setPercentage] = useState();
  const [success, setSuccess] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEll, setAnchorEll] = useState(null);
  const [editMode, setEditMode] = React.useState(false);
  const [educationId, setEducationId] = React.useState(null);
  const token = useSelector((state) => state.token);
  const id = useSelector((state) => state.id);
  const [openMatriuculationDialog, setOpenMatriuculationDialog] = useState(false);
  const [openTwelveDialog, setOpenTwelveDialog] = useState(false);
  const [openGraduationDialog, setOpenGraduationDialog] = useState(false);
  const [showStream, setShowStream] = useState(false);
  const [eduEntries, setEduEntries] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initializeForm = () => {
    setSchool("");
    setDegree("");
    setStart(null);
    setEnd(null);
    setPercentage();
  };

  React.useEffect(() => {
    setEduEntries(educations);
  }, [educations])

  const refreshEducation = async () => {
    const eduList = await getEducationAll(token);
    setEduEntries(eduList.educations);
  }

  const handleEditEducation = (eid, eDegree) => {
    setEducationId(eid);
     // Reset all dialog states
    setOpenMatriuculationDialog(false);
    setOpenTwelveDialog(false);
    setOpenGraduationDialog(false);

    if (eDegree === "X") {
      setOpenMatriuculationDialog(true);
    } else if(eDegree === "XII")  {
      console.log("X11 edit clicked")
      setOpenTwelveDialog(true);
    } else {
      setOpenGraduationDialog(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  //this is used for post education
  const postEducation = async () => {
    try {
      if (!editMode) {
        const response = await addEducation(
          id,
          school,
          percentage,
          start,
          end,
          degree,
          "",
          "",
          token
        );
        getTraining();
        if (response.status === 200) {
          setSuccess(true);
          dispatch(updateStepCompletion({resumeMinInfoUpdated: true}));
          setTimeout(() => {
            setSuccess(false);
          }, 2000);
          navigate('/student/resume', { replace: true });
        }
      } else {
        const data = await editEducation(
          educationId,
          id,
          school,
          percentage,
          start,
          end,
          degree,
          token
        );
        getTraining();
        if (data.sucess) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 2000);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  //for delete education
  const handleDeleteClick = async (id) => {
    // Function to open the delete confirmation dialog

    setDeleteId(id); // Set the selected education ID directly
    setOpenConfirmDialog(true); // Open the dialog

  }
  // Function to handle the actual deletion
  const handleDeleteEducation = async () => {
    console.log("handleDeleteEducation called")
    try {
      const response = await deleteEducation(deleteId, token);
      if(response.status === 200) {
        showToastAlert("Education deleted successfully", "success");
        await refreshEducation();
      } else {
        showToastAlert("Failed to delete education", "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <TableBody>
      <TableRow
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
      >
        <TableCell>EDUCATION <CustomAlert /> </TableCell>
        {eduEntries?.map((education, index) => (
          <TableRow key={index}>
            <TableCell
              style={{
                padding: "8px 20px 16px",
                borderBottom: "none",
                width: "20vw",
              }}
            >
              <p style={{ fontSize: "14px", fontWeight: "500" }}>
                <span>{education.degree}</span>
                <br />
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#6B6B6B",
                  }}
                >
                  {education.school},&nbsp;
                  {education.percentage}%
                </span>
                <br />
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#6B6B6B",
                  }}
                > {(education.degree === "X" || education.degree === "XII") &&  `Completion: ${education.endYear}` }
                  {(education.degree !== "X" && education.degree !== "XII") &&  `${education.startYear} - ${education.endYear}` }
                </span>
              </p>
            </TableCell>
            <TableCell>
              <Tooltip title="Edit Education">
                <IconButton edge="end" aria-label="edit">
                  <EditIcon onClick={() => handleEditEducation(education._id, education.degree)} />
                </IconButton>
              </Tooltip>
              &nbsp; &nbsp; &nbsp;
              <Tooltip title="Delete Education">
                <IconButton edge="end" aria-label="delete">
                  <DeleteIcon
                    onClick={() => handleDeleteClick(education._id)}
                  />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell>
            <Button
              onClick={() => {
                setEduSelectionOpen(true)
              }}
              style={{ padding: "0px" }}
            >
              <AddIcon style={{ fontSize: "14px" }} color="primary" /> &nbsp;
              <span style={{ fontSize: "12px" }}>Add Education</span>
            </Button>
          </TableCell>
        </TableRow>
      </TableRow>
      {/* Delete Confirmation Dialog */}
      <DeleteConfirmationDialog
        open={openConfirmDialog}
        onConfirm={() => {
          handleDeleteEducation();  // Perform delete
          setOpenConfirmDialog(false);  // Close dialog
        }}
        onClose={() => setOpenConfirmDialog(false)}  // Handle closing of the dialog
      />
      <EducationSelectDialog eduSelectionOpen={eduSelectionOpen} setEduSelectionOpen={setEduSelectionOpen}
        setOpenMatriuculationDialog={setOpenMatriuculationDialog}
        setOpenTwelveDialog={setOpenTwelveDialog}
        setOpenGraduationDialog={setOpenGraduationDialog}
        setEducationId={setEducationId}
        />
      <MatriculationDialog refreshEducation={refreshEducation} openMatriuculationDialog={openMatriuculationDialog} setOpenMatriuculationDialog={setOpenMatriuculationDialog} educationId={educationId} />
      <TwelveDialog refreshEducation={refreshEducation} openTwelveDialog={openTwelveDialog} setOpenTwelveDialog={setOpenTwelveDialog} educationId={educationId} />
      <GraduationDialog refreshEducation={refreshEducation} openGraduationDialog={openGraduationDialog} setOpenGraduationDialog={setOpenGraduationDialog} educationId={educationId} />
    </TableBody>
  );
};

export default AddEducation;
