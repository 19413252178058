import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const StudentSafetyGuide = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle align="center" sx={{ fontWeight: "bold" }}>
        Stay Safe While Connecting with Employers
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom sx={{ color: "#d32f2f" }}>
          🚩 Red Flags to Watch Out For:
        </Typography>
        <List>
          {[ 
            "A genuine company never asks for money for certifications, training, or job offers.",
            "Employers using personal or unofficial email domains instead of verified company emails.",
            "Requests for confidential information like bank details or personal documents before onboarding.",
            "Employers pressuring you to pay for courses or training you did not voluntarily choose.",
            "Unclear job descriptions, vague company details, or offers that seem too good to be true."
          ].map((text, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <ReportProblemIcon color="error" />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Typography variant="h6" gutterBottom sx={{ color: "#1976d2", mt: 2 }}>
          🔍 Reconfirm Before Accepting Any Offer
        </Typography>
        <List>
          {[ 
            "Check the employer’s official website and LinkedIn profile.",
            "Search for company reviews on trusted platforms.",
            "Cross-verify the HR’s email domain with the official company website.",
            "Contact the company directly through their listed contact details."
          ].map((text, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <CheckCircleIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Typography variant="body1" align="center" sx={{ mt: 2 }}>
          If you encounter any suspicious activity, report it to <strong>HeyInterns Support</strong> immediately.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default StudentSafetyGuide;
