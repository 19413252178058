import React, { useState } from "react";
import { Button, TextField, Box } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ReportApplicantModal from "../modals/ReportApplicantModal";

const ChatFooter2 = ({ onSendMessage }) => {
  const [newMessage, setNewMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  const handleSendMessage = () => {
    onSendMessage(newMessage);
    setNewMessage(""); // Clear the input field after sending
  };

  const handleAction = () => {
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  return (
    <div className="chat-footer">
      <TextField
        className="chat-input"
        id="chat-inputbox"
        label="Write a message"
        variant="filled"
        value={newMessage}
        onChange={(event) => setNewMessage(event.target.value)}
        inputProps={{ maxLength: 100 }}
        style={{ width: "100%" }}
      />
      <Button
        variant="contained"
        color="info"
        className="send-btn"
        onClick={handleSendMessage}
      >
        Send <OpenInNewIcon />
      </Button>
      {/* <ReportApplicantModal isOpen={isModalOpen} onClose={handleCloseModal} /> */}
    </div>
  );
};

export default ChatFooter2;
