import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DatePicker from "react-datepicker";
import moment from "moment";
import { grey } from "@mui/material/colors";
import { AddProjectApi, deleteProject, getProjectById } from '../services/postProject';
import { useState } from "react";
import { Add } from "@mui/icons-material";
import { useSelector } from "react-redux";
import {
  TableBody,
  TableRow,
  TableCell,
  FormGroup,
  TextField,
  Box,
  Checkbox,
  FormControlLabel,
  Container,
  tableCellClasses,
} from "@mui/material";
import PopUp from '../components/PopUp';
import DeleteConfirmationDialog from "./DeleteConfirmationDialog"; // Import DeleteConfirmationDialog
import { CustomAlert, showToastAlert } from '../admin/components/CustomAlert';

const { DateTime } = require("luxon");

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddProject({ project, getTraining }) {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState(""); // Added description state
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [link, setLink] = useState("");
  const [success, setSuccess] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false); // State for delete confirmation
  const [deleteId, setDeleteId] = useState(null);
  const userId = useSelector((state) => state.id);
  const token = useSelector((state) => state.token);
  const [projectId, setProjectId] = useState("new");
  const [editMode, setEditMode] = React.useState(false);
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = async (editId) => {
    if(editId) {
      const editData = await getProjectById(editId, token);
      if (editData) {
        setTitle(editData.project.title || '');
        setEnd(editData.project.endDate || '');
        setStart(editData.project.startDate || '');
        setDescription(editData.project.description || '');
        setLink(editData.project.link || '');
        setProjectId(editData.project._id || null);
        setEditMode(true);
        setOpen(true);
      } else {
        showToastAlert("Project fetch failed", "error")
        setEditMode(false);
      }
    } else {
      setEditMode(false);
      setOpen(true);
    }
    
  };
  
  const postProject = async () => {
    if (!title || !start || !end || !description) {
      alert("Please fill out all required fields");
      return;
    }
    try {
      const data = await AddProjectApi(token, projectId, title, description, moment(start).format("MM/DD/YYYY"), moment(end).format("MM/DD/YYYY"), link);
      if (data.success) {
        showToastAlert("project added", "success");
      } else {
        showToastAlert("project add failed", "error");
        return;
      }
      setOpen(false);
      getTraining();
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  // Delete confirmation handler
  const handleDeleteClick = (id) => {
    // Logic to delete the project
    setDeleteId(id)
    setDeleteOpen(true);
  };

  
  //for delete internship
  const handleDelete = async () => {
    try {
      const data = await deleteProject(deleteId, token);
      setDeleteOpen(false);
      getTraining();
      console.log(data)
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <TableBody>
      <TableRow
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
      >
        <TableCell style={{ fontSize: "12px" }}>
          ACADEMICS/ PERSONAL PROJECTS
        </TableCell>
        {project?.projects?.map((project, index) => (
          <TableRow key={index}>
            <TableCell
              style={{
                padding: "8px 20px 16px",
                borderBottom: "none",
                width: "20vw",
              }}
            >
              <p style={{ fontSize: "14px", fontWeight: "500" }}>
                {project.title}
                <br />
                <a
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#83C8E4",
                  }}
                  href={project.link}
                >
                  {project.link}
                </a>
                <br />
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#6B6B6B",
                  }}
                >
                  {formatDate(project.startDate)}&nbsp;-&nbsp;
                  {formatDate(project.endDate)}
                </span>
                <br />
              </p>
            </TableCell>
            <TableCell>
              <IconButton edge="end" aria-label="edit">
                <EditIcon onClick={() => handleClickOpen(project._id)} />
              </IconButton>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <IconButton 
                edge="end" 
                aria-label="delete"
                onClick={() => handleDeleteClick(project._id)} // Open delete confirmation dialog
              >
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell>
            <Button onClick={() => handleClickOpen(null)} style={{ padding: "0px" }}>
              <AddIcon style={{ fontSize: "14px" }} color="primary" /> &nbsp;
              <span style={{ fontSize: "12px" }}>Add Projects</span>
            </Button>
          </TableCell>
        </TableRow>
      </TableRow>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{ width: "600px" }}
        >
          Project details
        </BootstrapDialogTitle>
        {success && (
          <center>
            <PopUp />
          </center>
        )}
        <DialogContent dividers>
          <FormGroup sx={{ alignItems: "flex-start", marginBottom: "10px" }}>
            <Typography>Title</Typography>
            <TextField
              size="small"
              fullWidth
              placeholder="e.g. social media app"
              id="fullWidth"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              error={!title}
              helperText={!title ? "Title is required" : ""}
              inputProps={{ maxLength: 100 }}
            />
          </FormGroup>
          <FormGroup sx={{ alignItems: "flex-start", marginBottom: "10px" }}>
            <Typography>Description</Typography>
            <TextField
              size="small"
              fullWidth
              placeholder="Describe your project"
              id="fullWidth"
              multiline
              minRows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              error={!description}
              helperText={!description ? "Description is required" : ""}
              inputProps={{ maxLength: 400 }}
            />
          </FormGroup>
          <Box sx={{ display: "flex" }}>
            <Box style={{ width: "48%", margin: "5px" }}>
              <FormGroup
                sx={{ alignItems: "flex-start", marginBottom: "10px" }}
              >
                <Typography>Start date</Typography>
                <DatePicker
                  selected={start ? new Date(start) : null}
                  onChange={(date) => setStart(date)}
                  dateFormat="dd/MM/yyyy"
                  customInput={
                    <TextField
                      placeholder="start"
                      size="small"
                      style={{ width: "100%" }}
                      error={!start}
                      helperText={!start ? "Start date is required" : ""}
                    />
                  }
                  wrapperClassName="date-picker-wrapper"
                  className="date-picker-input"
                />
              </FormGroup>
            </Box>
            <Box style={{ width: "48%", margin: "5px" }}>
              <FormGroup
                sx={{ alignItems: "flex-start", marginBottom: "10px" }}
              >
                <Typography>End date</Typography>
                <DatePicker
                  selected={end? new Date(end): null}
                  onChange={(date) => setEnd(date)}
                  dateFormat="dd/MM/yyyy"
                  customInput={
                    <TextField
                      placeholder="end"
                      size="small"
                      style={{ width: "100%" }}
                      error={!end}
                      helperText={!end ? "End date is required" : ""}
                    />
                  }
                  wrapperClassName="date-picker-wrapper"
                  className="date-picker-input"
                />
              </FormGroup>
            </Box>
          </Box>
          <FormGroup sx={{ alignItems: "flex-start", marginBottom: "10px" }}>
            <Typography>Project link</Typography>
            <TextField
              size="small"
              fullWidth
              placeholder="your project link"
              id="fullWidth"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              inputProps={{ maxLength: 100 }}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              backgroundColor: grey[900],
              fontWeight: 500,
            }}
            onClick={postProject}
          >
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>

      {/* Delete Confirmation Dialog */}
      <DeleteConfirmationDialog
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onConfirm={handleDelete}
        entityName="project"
      />
      <CustomAlert />
    </TableBody>
  );
}

function formatDate(dateString) {
  if (!dateString) {
    return "N/A";
  }

  const dateTime = DateTime.fromISO(dateString);

  return dateTime.toFormat("MMM yyyy");
}
