import React from "react";
import { Grid, Container, Typography, Button, Box } from "@mui/material";
import { Link } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InternshipStoryCard from "./components/InternshipStoryCard";
import { showToastAlert } from "../admin/components/CustomAlert";
import { latestStories } from "../services/filterStory";
import { Helmet } from "react-helmet-async";
const InternshipStoryList = () => {
  const [stories, setStories] = React.useState([]);

  React.useEffect(() => {
    getPageData();
  }, []);

  const getPageData = async () => {
    const response = await latestStories();
    if (response.status !== 200) {
      showToastAlert(response.data.message, "error");
      return;
    }
    setStories(response.data.stories);
  };

  return (
    <Container sx={{ marginTop: 6 }}>
      <Helmet>
        <title>HeyInterns | Successful Internship Stories</title>
      </Helmet>
      {/* 🌟 Hero Section */}
      <Box textAlign="center" mb={5}>
        <Typography variant="h3" fontWeight="bold" color="primary">
          🌟 Successful Internship Stories
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" sx={{ maxWidth: 600, mx: "auto", mt: 1 }}>
          Read inspiring experiences from interns who turned opportunities into careers.
        </Typography>
      </Box>

      {/* ➕ Add Story Button */}
      <Box display="flex" justifyContent="center" mb={5}>
        <Button
          component={Link}
          to="/student/stories/add"
          variant="contained"
          color="primary"
          startIcon={<AddCircleIcon />}
          sx={{
            textTransform: "none",
            fontSize: "1rem",
            padding: "10px 20px",
            borderRadius: "25px",
            boxShadow: 3,
            "&:hover": { boxShadow: 6 },
          }}
        >
          Share Your Own Story
        </Button>
      </Box>

      {/* 🎯 Internship Stories Grid */}
      {stories.length > 0 ? (
        <Grid container spacing={4} sx={{ marginBottom: "40px" }}>
          {stories.map((blog, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <InternshipStoryCard blog={blog} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box textAlign="center" mt={5}>

          <Typography variant="h6" color="textSecondary" mt={2}>
            No stories available yet. Be the first to inspire others!
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default InternshipStoryList;
