import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { checkOnboardState } from "../common/redirectFunctions";

const EmployerPrivateRoute = ({ verifySteps, verifyState = false }) => {
  const { uType, stepsCompleted, userDetails } = useSelector((state) => ({
    uType: state.uType,
    stepsCompleted: state.stepsCompleted,
    userDetails: state.userDetails
  }));
  if (uType === "employer") {
    if (verifySteps) {
      if (!checkOnboardState(stepsCompleted, "employer")) {
        return <Navigate to="/employer/onboard" />;
      } else if(verifyState) {
          if(!(userDetails?.isApproved)) {
            return <Navigate to="/employer/dashboard" />;
          } else {
            return <Outlet />;
          }
      } else {
        return <Outlet />;
      }
    } else if(verifyState) {
      if(!(userDetails?.isApproved)) {
        return <Navigate to="/employer/dashboard" />;
      } else {
        return <Outlet />;
      }
    } else {
      return <Outlet />;
    }

  } else {
    return <Navigate to="/Employer/Login" />;
  }
};

export default EmployerPrivateRoute;
