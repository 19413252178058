import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, IconButton, FormControlLabel, Checkbox, RadioGroup, Radio, InputLabel, Box } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from 'react-redux';
import { ApplyPost } from '../../services/ApplyPost';
import { useNavigate } from 'react-router-dom';
import { CustomAlert, showToastAlert } from '../../admin/components/CustomAlert';

const InternshipApplyModal = ({ postData, showApplyModal, setShowApplyModal, setAppliedStatus, showSuccessPopup }) => {
  const [availability, setAvailability] = useState('');
  const [reason, setReason] = useState('');
  const [coverLetter, setCoverLetter] = useState('');
  const [answers, setAnswers] = useState(Array(postData?.questions?.length).fill(''));
  const [errors, setErrors] = useState({
    coverLetter: '',
    availability: '',
    reason: ''
  });
  const token = useSelector((state) => state.token);
  const userId = useSelector((state) => state.id);
  const navigate = useNavigate();

  const handleAnswerChange = (index, value) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = value;
    setAnswers(updatedAnswers);
  };

  const validate = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (coverLetter.length < 20) {
      //TODO: add text limit 
      newErrors.coverLetter = 'Cover letter should be at least 20 characters';
      isValid = false;
    } else {
      newErrors.coverLetter = '';
    }

    if (availability === '') {
      newErrors.availability = 'Please select your availability';
      isValid = false;
    } else {
      newErrors.availability = '';
    }

    if (availability === 'no' && reason === '') {
      newErrors.reason = 'Please specify your availability';
      isValid = false;
    } else {
      newErrors.reason = '';
    }

    answers.forEach((item) => {
      if(item.trim() === "")
      { 
        showToastAlert("Fill all answers", "error");
        return false;
      }
    })

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async () => {
    // Handle form submission

    try {
      if (validate()) {
        console.log("available:" + availability);
        console.log("coverLetter:" + coverLetter);
        console.log("reason:" + reason);
        let availabilityReason = availability;
        if (availabilityReason === "no") {
          availabilityReason = reason;
        }
        const data = await ApplyPost(userId, postData?._id, availabilityReason, coverLetter, answers, token);
        if (data.success) {
          showToastAlert(data.messsage, "success");
          setAppliedStatus(data.appliedStatus);
          //showSuccessPopup();
          setShowApplyModal(false);
        } else {
          showToastAlert(data.messsage, "error");
        }
      }
    }
    catch (err) {
      showToastAlert(err, "error");
      console.log(err)
    }
  };

  const handleClose = () => {
    setShowApplyModal(false);
  }

  return (
    <Dialog open={showApplyModal} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Applying for {postData?.title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <p>Your resume • Update with latest data <Button variant="text" onClick={() => navigate('/student/resume')}>Edit resume</Button></p>
        </Box>

        <h6>Why should you be hired for this role?</h6>
        <TextField
          fullWidth
          multiline
          rows={2}
          placeholder="Mention in detail what relevant skills or past experience you have for this internship."
          variant="outlined"
          onChange={(e) => setCoverLetter(e.target.value)}
          error={errors.coverLetter !== ''}
          helperText={errors.coverLetter}
          sx={{ mb: 2 }}
        />

        { postData && postData.questions && postData?.questions.map((question, index) => ( 
          <div key={index}>
            <h6>{question}</h6>
            <TextField
              fullWidth
              multiline
              rows="1"
              variant="outlined"
              onChange={(e) => handleAnswerChange(index, e.target.value)}
              sx={{ mb: 2 }}
              inputProps={{ maxLength: 300 }}
            />
          </div>
        ))
        }
        <h6>Confirm your availability</h6>
        <RadioGroup
          value={availability}
          onChange={(e) => setAvailability(e.target.value)}
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes, I am available to join immediately" />
          <FormControlLabel value="no" control={<Radio />} label="No (Please specify your availability)" />
        </RadioGroup>
        {errors.availability !== '' && <div style={{ color: 'red' }}>{errors.availability}</div>}

        {availability === 'no' && (
          <TextField
            fullWidth
            label="Availability details"
            placeholder="Eg. I am available full-time in Pune for the next 6 months but will have exams for 15 days in June."
            variant="outlined"
            onChange={(e) => setReason(e.target.value)}
            error={errors.reason !== ''}
            helperText={errors.reason}
            sx={{ mt: 2 }}
            inputProps={{ maxLength: 100 }}
          />
        )}
        
        <CustomAlert />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Back</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default InternshipApplyModal;
