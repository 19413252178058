import React from "react";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import { Button, Chip } from "@mui/material";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getChatRoom } from "../services/employerChat";
import {formatDate, toCamelCase} from '../common/commonFunctions';
import { CustomAlert, showToastAlert } from "../admin/components/CustomAlert";

const ApplicantBox = ({
  application,
  searchKeyValue,
  updateApplicationState,
  questions,
  handleSelect, 
  setCurrApplicantId,
  setShowApplicanttModal
}) => {
  console.log("questions:", JSON.stringify(questions));

  const token = useSelector((state) => state.token);
  const location = useLocation();
  const navigate = useNavigate();
  const disabledStates = {
    shortlist: ["shortlist", "hired"],
    rejected: ["rejected", "hired"],
    hired: ["hired"]
};


  const pathSegments = location.pathname.split("/");
  const postId =
    pathSegments.length > 0 ? pathSegments[pathSegments.length - 1] : undefined;

  // Calculate the difference in days from today's date
  const daysDifference = moment().diff(moment(application.createdAt), "days");

  const handleViewApplication = (appId) => {
    setCurrApplicantId(appId);
    setShowApplicanttModal(true);
  };

  const handleOpenChat = async (userId) => {
    const response = await getChatRoom(token, application.postId, userId, application._id);
    if (response.status !== 200) {
      showToastAlert(response.data.msg, "error");
      return;
    }

    console.log("room id:", JSON.stringify(response.data));
    if (response.data?.room?._id) {
      navigate(`/employer/chat/${response.data?.room?._id}`);
    }
  };

  const appliedText = `Applied ${daysDifference} ago`;

  return (
    <div className="applicant-box">
      <div className="applicant-box-header">
        <h4 className="st-name sp-b">
          <div className="st-checkbox">
            <FormControlLabel
              control={<Checkbox size="large" />}
              label={application.userId ? application.userId.name : null}
              className="name-label"
              onChange={(event) =>
                handleSelect(application.userId._id, event.target.checked)
              }
            />
            <span className="st-name-tag">
              <TaskOutlinedIcon />
              {/*Assignment Received*/}
            </span>
          </div>
          <div className="st-iconsbox">
            <a
              href="https://github.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-github"></i>
            </a>
            <a
              href="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-linkedin-in"></i>
            </a>
            <a
              href="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-solid fa-link"></i>
            </a>
          </div>
        </h4>
        <p className="st-exp-desc">
          <div className="sp-b">
            <span>
              {application.userId.location} (Open to relocate)
              <Tooltip title="Includes both internship and job" arrow>
                <InfoOutlinedIcon className="info-icon" />
              </Tooltip>
            </span>
            <span className="applied-text d-flex">{appliedText}</span>
          </div>
        </p>
      </div>
      <div className="applicant-box-content">
        <table className="table table-borderless">
          <tbody>
            {application?.userId?.experience &&
            Array.isArray(application.userId.experience) &&
            application.userId.experience.length > 0 ? (
              <tr>
                <td className="first-column">Experience</td>
                <td className="second-column">
                  {application.userId.experience.map((item, index) => (
                    <div key={index}>
                      <strong>{toCamelCase(item.profile)} At {toCamelCase(item.company)} </strong>. {formatDate(item.startDate)} - {formatDate(item.endDate)}{" "}
                      (Internship)
                    </div>
                  ))}
                </td>
              </tr>
            ) : null}

            {application?.userId?.education &&
            Array.isArray(application.userId.education) &&
            application.userId.education.length > 0 ? (
              <tr>
                <td className="first-column">Education</td>
                <td className="second-column">
                  {application.userId.education.map((item, index) => (
                    <h6 key={index}>
                      {item.degree} {item.startYear} - {item.endYear}
                      <p>{item.school}</p>
                    </h6>
                  ))}
                </td>
              </tr>
            ) : null}

            <tr>
              <td className="first-column">
                Skills{" "}
                <Tooltip title="Self assessments by applicants" arrow>
                  <InfoOutlinedIcon className="info-icon" />
                </Tooltip>
              </td>
              <td className="second-column">
                <div className="row">
                  {application.userId.skills.map((skill, index) => (
                    <div key={index} className="st-skill-box">
                      <Chip label={skill} variant="outlined" />
                    </div>
                  ))}
                </div>
              </td>
            </tr>

            <tr>
              <td className="first-column">
                Cover Letter{" "}
                <Tooltip title="Why should we hire you" arrow>
                  <InfoOutlinedIcon className="info-icon" />
                </Tooltip>
              </td>
              <td className="second-column">
                <p>
                  {application.coverLetter}
                </p>
              </td>
            </tr>

            <tr>
              <td className="first-column">
                Availability{" "}
                <Tooltip title="Availability" arrow>
                  {" "}
                  <InfoOutlinedIcon className="info-icon" />
                </Tooltip>
              </td>
              <td className="second-column">
                {(application.availability === "yes") && (<p>Yes, I am available to join immediately.</p>)}
                {(application.availability == false) && (<p>{application.availability}</p>)}
                
              </td>
            </tr>

            <tr>
              <td className="first-column">
                Assessments{" "}
                <Tooltip title="Assessments" arrow>
                  {" "}
                  <InfoOutlinedIcon className="info-icon" />
                </Tooltip>
              </td>
              <td className="second-column">
                {questions &&
                  questions.map((item, index) => (
                    <div key={index} className="st-questionbox">
                      <h6>{item}</h6>
                      <p>
                        {application.answers && application.answers[index] && (
                          <span>{application.answers[index]}</span>
                        )}
                      </p>
                    </div>
                  ))}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="applicant-box-footer">
        <div className="sp-b">
          <div>
            <Button
              variant="outlined"
              onClick={() => handleViewApplication(application._id)}
            >
              View Full Application
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleOpenChat(application.userId._id)}
            >
              Open Chat
            </Button>
          </div>
          <div>
            {!disabledStates.shortlist.includes(application.state) && (
              <Button
                variant="contained"
                color="info"
                onClick={() =>
                  updateApplicationState(application._id, "shortlist")
                }
              >
                Shortlist
              </Button>
            )}
            {!disabledStates.rejected.includes(application.state) && (
              <Button
                variant="contained"
                color="error"
                className="mx-2"
                onClick={() =>
                  updateApplicationState(application._id, "rejected")
                }
              >
                Reject
              </Button>
            )}
            {!disabledStates.hired.includes(application.state) && (
              <Button
                variant="contained"
                color="success"
                onClick={() => updateApplicationState(application._id, "hired")}
              >
                Hire
              </Button>
            )}
          </div>
        </div>
      </div>
      <CustomAlert />
    </div>
  );
};

export default ApplicantBox;
