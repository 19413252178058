import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSelector, useDispatch } from "react-redux";
import { uploadLogo } from "../../services/getEmployerInfo";
import { UpdateEmployerProfileDetail } from "../../services/updateEmployerDetail";
import { CustomAlert, showToastAlert } from "../../admin/components/CustomAlert";
import { updateStepCompletion } from "../../Store/Action";
import { getEmployerProfileInfo } from "../../services/getEmployerInfo";
import { isValidIndianMobileNumber, validateUrl } from "../../common/commonFunctions";

export default function EmployerProfileComponent({ onComplete = null }) {
    const [name, setName] = useState("");
    const [mail, setMail] = useState("");
    const [linkedin, setLinkedin] = useState("");
    const [mobile, setMobile] = useState("");
    const [loader, setLoader] = useState(true);
    const [isApproved, setIsApproved] = useState(false);

    const token = useSelector((state) => state.token);
    const dispatch = useDispatch();

    // Validation logic
    const validateFields = () => {
        // Validate Personal Details
        if (!name.trim()) return { isValid: false, message: "Name is required." };
        if (!mail.trim()) return { isValid: false, message: "Official Email is required." };
        if (!mobile.trim()) return { isValid: false, message: "Mobile Number is required." };
        if(!isValidIndianMobileNumber(mobile)) {
            return { isValid: false, message: "Mobile Number is not valid." };
        }
        if (!linkedin.trim()) return { isValid: false, message: "Linkedin url is required." };
        if (!validateUrl(linkedin, "linkedin")) {
            return { isValid: false, message: "Linkedin url is not valid." };
        }
        setLinkedin(linkedin);
        return { isValid: true };
    };

    // Update employer details
    const updateEmpDetail = async () => {
        const validation = validateFields();
        if (!validation.isValid) {
            showToastAlert(validation.message, "error");
            return;
        }
        const response = await UpdateEmployerProfileDetail(token, name, linkedin, mobile);
        if (response.status === 200) {
            dispatch(updateStepCompletion({profileCompleted: true}));            
            showToastAlert(response.data.message, "success");
            if(onComplete)
                onComplete();
        } else {
            showToastAlert(response.data.message, "error");
        }
    };
    const userInfo = async () => {
        try {
            const data = await getEmployerProfileInfo(token);
            setLoader(false);
            // Using optional chaining to safely access nested properties
            setName(data.employer?.name || "");
            setMail(data.employer?.email || "");
            setMobile(data.employer?.mobile || "");
            setLinkedin(data.employer?.linkedin || "");
            setIsApproved(data.employer?.isApproved ? data.employer?.isApproved : false);
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        userInfo();
    }, []);

    return (
        <Box>
            <TextField
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                margin="normal"
            />
            <TextField
                label="Official Email"
                value={mail}
                onChange={(e) => setMail(e.target.value)}
                fullWidth
                margin="normal"
                disabled
            />
            <TextField
                label="Linkedin profile link"
                placeholder="Give your linkedin profile link"
                value={linkedin}
                onChange={(e) => setLinkedin(e.target.value)}
                fullWidth
                margin="normal"
            />
            <TextField
                        label="Mobile Number"
                        placeholder="only 10 digits"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
            
            <Button variant="contained" color="primary" onClick={updateEmpDetail}>
                Save
            </Button>
            <CustomAlert />
        </Box>
    );
}
