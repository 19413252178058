//export const baseUrl = "http://localhost:4000";
export const baseUrl = "https://api.heyinterns.com";

export default {
  name: "HeyInterns",
  tagline: "Find your dream internship faster, powered by AI",
  email: "support@heyintern.com",
  phone: "+91 xxxxxxxxx",
  address: "Noida, India",

  social: {
    linkedin: "https://www.linkedin.com/company/heyinterns",
    instagram: "",
    youtube: "https://www.youtube.com/@HeyInternsOfficial",
    twitter: "https://x.com/heyinterns",
    facebook: "https://facebook.com/heyinterns",
    email: "support@heyinterns.com",
  },
};
