export const checkStudentSteps = (uType, stepsCompleted = {}) => {
    if (!uType) {
        return null;
    }
    const stepRedirects = [
        { step: "mobileVerified", path: "/student/verify-mobile" },
        { step: "profileCompleted", path: "/student/editprofile?error_message=incomplete_profile" },
        { step: "resumeMinInfoUpdated", path: "/student/resume?error_message=incomplete_profile" },
    ];

    for (const { step, path } of stepRedirects) {
        if (!stepsCompleted[step]) {
            return path;
        }
    }

    return null; // All steps completed, no redirection needed
}

export const checkOnboardState = (stepsCompleted = {}, uType = "student") => {
    if (!stepsCompleted)
        return false;
    if(!stepsCompleted.onboarded)
        return false;
    if (uType === "student") {
        if (!stepsCompleted.profileCompleted ||
            !stepsCompleted.resumeMinInfoUpdated ||
            !stepsCompleted.prefUpdated
        ) {
            return false;
        }
    } else if(uType === "employer") {
        if (!stepsCompleted.profileCompleted ||
            !stepsCompleted.companyInfoStatus
        ) {
            return false;
        }
    }
    return true;
}